import React from "react";
import { Box } from "@mui/material";

const OddsFlucsLanding = () => {
  return (
    <>
      <Box>OddsFlucsLanding</Box>
    </>
  );
};

export default OddsFlucsLanding;
