import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Breadcrumbs, Button, Typography } from "@mui/material";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import pageHeaderIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import WhiteBulb from "../../../../assets/images/icons/whiteBulb.png";
import AdBannner from "../../AdBanner/AdBanner";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import { Config } from "src/helpers/context";
import OurPartner from "../../ourPartners";
import TournamentTeamDropdown from "../../allsports/teamSpoorts/allTeamSports/TournamentTeamDropdown";
import TeamSportNews from "../../allsports/teamSpoorts/allTeamSports/TeamSportNews";
import "./newsLanding.scss"

const NewsLanding = () => {
  const params = useParams();
  const location = useLocation();
  const teamSportType = params?.sportsTypeName;
  const searchParamsMenu = new URLSearchParams(location.search);
  const touranamentId = searchParamsMenu.get("touranamentId");
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [selectedOrgLabel, setSelectedOrgLabel] = useState("");
  const [selectedTeamsLabel, setSelectedTeamsLabel] = useState("");

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };
  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config.release === "IN" ? pageHeaderIndia : banner,
          )}
        </Box>
        <Box className="bredcrumn-wrap">
          <Breadcrumbs
            separator="/"
            aria-label="breadcrumb"
            className="breadcrumb"
          >
            <Link underline="hover" color="inherit" to="/">
              {localesData?.MENU?.HOME}
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to=""
              className="cursor-default"
            >
              {localesData?.MENU?.SPORTS}
            </Link>
            <Typography>{params?.sportsTypeName}</Typography>
          </Breadcrumbs>
        </Box>
        <Box className="title-btn-box">
          <Typography variant="h1">
            {params?.sportsTypeName === "cricket"
              ? "Cricket"
              : params?.sportsTypeName === "rugbyleague"
                ? "Rugby League"
                : params?.sportsTypeName === "rugbyunion"
                  ? "Rugby Union"
                  : params?.sportsTypeName === "basketball"
                    ? "Basketball "
                    : params?.sportsTypeName === "americanfootball"
                      ? "American Football"
                      : params?.sportsTypeName === "australianrules"
                        ? "Australian Rules "
                        : params?.sportsTypeName === "golf"
                          ? "Golf"
                          : params?.sportsTypeName === "tennis"
                            ? "Tennis"
                            : params?.sportsTypeName === "baseball"
                              ? "Baseball"
                              : params?.sportsTypeName === "icehockey"
                                ? "Ice Hockey"
                                : params?.sportsTypeName === "boxing"
                                  ? "Boxing"
                                  : params?.sportsTypeName === "mma"
                                    ? "mma"
                                    : params?.sportsTypeName === "soccer"
                                      ? "soccer"
                                      : "Rugby Union Sevens"}
          </Typography>
        </Box>
        <Box className="homepage-landing-page-wrap news-landing-page-wrap">
          <Box className="homepage-landing-header">
            <Box className="news-team-select-wrap">
              <TournamentTeamDropdown
                teamSportType={teamSportType}
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg}
                selectedTeams={selectedTeams}
                setSelectedTeams={setSelectedTeams}
                selectedOrgLabel={selectedOrgLabel}
                setSelectedOrgLabel={setSelectedOrgLabel}
                selectedTeamsLabel={selectedTeamsLabel}
                setSelectedTeamsLabel={setSelectedTeamsLabel}
              />
            </Box>
            <Box>
              {(params?.sportsTypeName === "cricket" ||
                params?.sportsTypeName === "rugbyleague" ||
                params?.sportsTypeName === "australianrules") && (
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="exprt-tips-btn"
                >
                  <Box className="exprt-btn-wrap">
                    <img
                      src={WhiteBulb}
                      alt="white bulb"
                      className="white-bulb-img"
                    />
                    <span className="btn-txt">View Expert Tips</span>
                  </Box>
                </Button>
              )}
            </Box>
          </Box>
          <Box>
            <TeamSportNews
              teamSportType={teamSportType}
              newsType={"sport"}
              selectedOrg={selectedOrg}
              selectedTeams={selectedTeams}
              selectedOrgLabel={selectedOrgLabel}
              selectedTeamsLabel={selectedTeamsLabel}
              landingPage="sport-landing-page"
            />
          </Box>
        </Box>
        <OurPartner />
      </Box>
    </>
  );
};

export default NewsLanding;
