import React from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import HomeLanding from "./homeLanding";
import NewsLanding from "./newsLanding";
import FixtureAndResultLanding from "./fixtureAndResultLanding";
import OddsFlucsLanding from "./oddsFlucsLanding";
import RankingsLanding from "./rankingsLanding";
import StatsLanding from "./statsLanding";
import "./newTeamSportPage.scss";
import TeamSportsLayoutsLanding from "./teamSportLayoutLading";

const RendererView = ({ menuValue }) => {
  switch (menuValue) {
    case "home":
      return (
        <Box>
          <HomeLanding />
        </Box>
      );
    case "news":
      return (
        <Box>
          <NewsLanding />
          {/* <TeamSportsLayoutsLanding/> */}
        </Box>
      );
    case "fixtures_results":
      return (
        <Box>
          <FixtureAndResultLanding />
        </Box>
      );
    case "odds_flucs":
      return (
        <Box>
          <OddsFlucsLanding />
        </Box>
      );
    case "rankings":
      return (
        <Box>
          <RankingsLanding />
        </Box>
      );
    case "stats":
      return (
        <Box>
          <StatsLanding />
        </Box>
      );
    default:
      return <Box>Page not found</Box>;
  }
};

const NewTeamSportsComponentPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const menuValue = searchParams.get("menu"); // Extracts the value of "menu"

  return (
    <>
      <Box className="new-team-sports-component-wrap">
        <RendererView menuValue={menuValue} />
      </Box>
    </>
  );
};

export default NewTeamSportsComponentPage;
