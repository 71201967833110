import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Breadcrumbs,
  Tab,
  Tabs,
  Typography,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Button,
  Tooltip,
  Collapse,
  Switch,
  FormControlLabel,
  ClickAwayListener,
  TextField,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { IntlContext } from "src/App";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Select, { components } from "react-select";
import _ from "lodash";
import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
//   DatePicker
// } from "@material-ui/pickers";
import {
  LocalizationProvider,
  DatePicker,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import moment from "moment-timezone";
import { fetchFromStorage } from "src/helpers/context";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as CompareOdds } from "../../../../assets/images/icons/compareOdds.svg";
import { ReactComponent as FluctuationGraph } from "../../../../assets/images/icons/fluctuationGraph.svg";
import { ReactComponent as Stats } from "../../../../assets/images/icons/stats.svg";
import { ReactComponent as Matchups } from "../../../../assets/images/icons/matchups.svg";
import { ReactComponent as Reset } from "../../../../assets/images/reset.svg";
import { ReactComponent as Datepicker } from "src/assets/images/eventcalendar/Datepicker.svg";
import { ReactComponent as DatepickerBG } from "src/assets/images/eventcalendar/date-picker-bg.svg";
import { ReactComponent as BookIcon } from "../../../../assets/images/icons/bookicon.svg";
import { ReactComponent as AddBookIcon } from "../../../../assets/images/add-blackbook.svg";
import { ReactComponent as TooltipArrow } from "../../../../assets/images/icons/tooltip_arrow.svg";
import { ReactComponent as SmartbookAdd } from "../../../../assets/images/icons/smartbook-add.svg";
import { ReactComponent as Search } from "../../../../assets/images/icons/search.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import { ReactComponent as RightBlack } from "../../../../assets/images/icons/rightblack.svg";
import { ReactComponent as PlusBlack } from "../../../../assets/images/icons/plusblack.svg";
import { ReactComponent as BlackbookRight } from "../../../../assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "../../../../assets/images/icons/blackbookPlus.svg";
import { ReactComponent as WhiteBulb } from "../../../../assets/images/icons/bulb-icon.svg";
import { ReactComponent as SeeAllRightArrow } from "../../../../assets/images/icons/seeall-right-arrow.svg";
import { Config } from "../../../../helpers/context/config";
import Brisbane from "src/assets/images/brisbane.png";
import Melbourne from "src/assets/images/melbourne.png";

import AboveEventList from "../../../../assets/images/ad-placeholder/sports1.webp";
import After1StEvent from "../../../../assets/images/ad-placeholder/sports2.webp";
import BannerIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import SportMatchup from "src/views/component/sportMatchupsPage/sportsDetails/nbaMatchup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import HighChart from "src/views/component/UI/graph";
import TeamSportsDataPage from "src/views/component/teamSportData";
import { fetchBetSlipData } from "src/helpers/store/Actions/BetslipData";
import { fetchBetSlipCount } from "src/helpers/store/Actions/BetslipCount";
import { fetchSubscriptionData } from "src/helpers/store/Actions/SubscriptionData";
import { ToastContainer, toast } from "react-toastify";
import { identifiers } from "src/helpers/constants/identifier";
import NoDataComp from "src/views/component/UI/NoData";
import SmartBookModal from "src/views/component/UI/smartbookModal";
import FeaturedSport from "src/views/component/Home/featuredSportsV2";
import { release } from "src/helpers/context/release";
import DefaultImg from "src/assets/images/smartb_default.png";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";
import { parseISO } from "date-fns";
import OutRights from "../../allsports/teamSpoorts/allTeamSports/OutRights";
import TeamSportNews from "../../allsports/teamSpoorts/allTeamSports/TeamSportNews";
import StatisticsData from "../../allsports/teamSpoorts/statisticsData";
import Score from "../../allsports/teamSpoorts/allTeamSports/Score";
import TournamentTeamDropdown from "../../allsports/teamSpoorts/allTeamSports/TournamentTeamDropdown";
import MatchSummary from "../../allsports/teamSpoorts/allTeamSports/matchSummary";

const tabData = [
  {
    id: 0,
    name: "Fixtures",
    icon: <CompareOdds />,
  },
  {
    id: 5,
    name: "News",
  },
  // {
  //   id: 4,
  //   name: "Stats",
  //   icon: <Stats />
  // }
  {
    id: 2,
    name: "Outrights",
  },
  {
    id: 3,
    name: "Fluctuation Graph",
    icon: <FluctuationGraph />,
  },
];
const StatsTabData = [
  {
    id: 0,
    name: "Fixtures",
    icon: <CompareOdds />,
  },
  {
    id: 5,
    name: "News",
  },
  {
    id: 4,
    name: "Leagues",
    icon: <Stats />,
  },
  {
    id: 2,
    name: "Outrights",
  },
  {
    id: 3,
    name: "Fluctuation Graph",
    icon: <FluctuationGraph />,
  },
];
const CricketStatsTabData = [
  {
    id: 0,
    name: "Fixtures",
    icon: <CompareOdds />,
  },
  {
    id: 5,
    name: "News",
  },
  {
    id: 6,
    name: "Statistics",
  },
  {
    id: 2,
    name: "Outrights",
  },
  {
    id: 3,
    name: "Fluctuation Graph",
    icon: <FluctuationGraph />,
  },
  {
    id: 4,
    name: "Leagues",
    icon: <Stats />,
  },
];
const matchuptabData = [
  {
    id: 0,
    name: "Fixtures",
    icon: <CompareOdds />,
  },
  {
    id: 5,
    name: "News",
  },
  // {
  //   id: 4,
  //   name: "Stats",
  //   icon: <Stats />
  // }
  { id: 1, name: "Betting Trends", icon: <Matchups /> },
  {
    id: 2,
    name: "Outrights",
  },
  {
    id: 3,
    name: "Fluctuation Graph",
    icon: <FluctuationGraph />,
  },
];

const outRightsTabData = [
  {
    id: 2,
    name: "Outrights",
  },
  {
    id: 5,
    name: "News",
  },
  {
    id: 3,
    name: "Fluctuation Graph",
    icon: <FluctuationGraph />,
  },
];
const oddsDataOption = [
  // { value: "1", label: "ODDS TYPE", disabled: true },
  { value: 1, label: "Win Fixed" },
  // { value: 2, label: "Place Fixed" },
  // { value: 3, label: "Win Tote" },
  // { value: "4", label: "Win Tote" },
  // { value: "5", label: "Starting Price" }
];

const statsData = [
  { value: 0, label: "Leagues" },
  { value: 1, label: "Teams" },
  { value: 2, label: "Players" },
  // { value: 3, label: "Coaches" },
  { value: 4, label: "Venues" },
];

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const dayFilter = [
  {
    id: 1,
    dayName: "ALL",
  },
  {
    id: 0,
    dayName: "Live",
  },
  {
    id: 3,
    date: moment.tz(moment(), timezone).format("YYYY-MM-DD"),
    dayName: "Today",
  },
  {
    id: 4,
    // date: moment
    //   .tz(moment(), timezone)
    //   .add(1, "days")
    //   .format("YYYY-MM-DD"),
    dayName: "Future",
  },
  {
    id: 2,
    date: moment
      .tz(moment(), timezone)
      .subtract(1, "days")
      .format("YYYY-MM-DD"),
    dayName: "Yesterday",
  },
];
const TeamSportsLayoutsLanding = ({  fetchAds }) => {
  const StickyTableCell = withStyles((theme) => ({
    head: {
      left: 0,
      position: "sticky",
      zIndex: 98,
      minWidth: "338px",
      width: "338px",
      backgroundColor: tooltipOpen ? "transparent" : "#ffffff",
      boxShadow: tooltipOpen ? "none" : "inset -2px 0px 6px 0px #ebebeb",
      "@media(max-width: 1090px)": {
        minWidth: "300px",
        width: "300px",
      },
      "@media(max-width: 479px)": {
        minWidth: "200px",
        width: "200px",
      },
    },
    body: {
      left: 0,
      position: "sticky",
      zIndex: 90,
      minWidth: "338px",
      width: "338px",
      backgroundColor: tooltipOpen ? "transparent" : "#ffffff",
      boxShadow: tooltipOpen ? "none" : "inset -2px 0px 6px 0px #ebebeb",
      "@media(max-width: 1090px)": {
        minWidth: "300px",
        width: "300px",
      },
      "@media(max-width: 479px)": {
        minWidth: "200px",
        width: "200px",
      },
    },
  }))(TableCell);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    head: {},
    cell: {},
  }));
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const teamSportType = params?.sportsTypeName;
  const dispatch = useDispatch();
  const { setApiInfo } = useAutoSaveStore();
  const reduxSubscriptionData = useSelector(
    (state) => state?.reduxData?.SubscripitionData,
  );
  const BookkeeperData = useSelector(
    (state) => state?.reduxData?.BookkeeperData,
  );
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [tabvalue, setTabValue] = useState(
    release[Config.release]?.Outrights
      ? params?.oddstype === "outrights"
        ? 2
        : 0
      : 0,
  );
  const [selectedseries, setselectedseries] = useState(0);
  const [selectedDate, setselectedDate] = useState(null);
  const [selectedteam, setselectedteam] = useState(0);
  const [seeAll, setSeeAll] = useState(params?.seeall);
  const [selectedOption, setSelectedOption] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let [stepperCount, setStepperCount] = useState(0);
  const [isEventLoading, setIsEventLoading] = useState(false);
  const [isIndividualEventLoading, setIsIndividualEventLoading] =
    useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [EventCount, setEventCount] = useState(0);
  const [EventPage, setEventPage] = useState(0);
  const [individualTeamData, setindividualTeamData] = useState({});
  const [scrollX, setscrollX] = useState("left");
  const [TournamentOptions, setTournamentOptions] = useState([]);
  const [TeamOptions, setTeamOptions] = useState([]);
  const [MatchupTournamentOptions, setmatchupTournamentOptions] = useState([]);
  const [MatchupTeamOptions, setmatchupTeamOptions] = useState([]);
  const [matchUpData, setMatchUpData] = useState([]);
  const [matchupLoading, setMatchUpLoading] = useState(false);
  const [timeinterval, setTimeInterval] = useState();
  const [apiTimeout, setAPiTimeout] = useState();
  const [sponsoredId, setSponsoredId] = useState([]);
  const [MobileBestOpenHome, setMobileBestOpenHome] = useState(-1);
  const [MobileBestOpenAway, setMobileBestOpenAway] = useState(-1);
  const [pageHeadingData, setPageHeadingData] = useState([]);
  const [MarketList, setMarketList] = useState([]);
  const [selectedMarket, setselectedMarket] = useState(1);
  const [IndividualMarketList, setIndividualMarketList] = useState([]);
  const [MarketName, setMarketName] = useState("");
  const [MarketType, setMarketType] = useState("");
  const [open, setOpen] = useState(false);
  const [isMultipleTeam, setIsMultipleTeam] = useState(false);
  const [MobileMultipleTeamSelect, setMobileMultipleTeamSelect] = useState(-1);
  const [selectedGraphId, setSelectedGraphId] = useState(-1);
  const [providerOption, setProviderOption] = useState([]);
  const [stateTabValue, setStateTabValue] = useState(0);
  const [dayLiveFilter, setDayLiveFilter] = useState(1);
  const [dateSelected, setDateSelected] = useState(null);
  const [showAllFixture, setShowAllFixtures] = useState(
    reduxSubscriptionData?.allfixtures
      ? reduxSubscriptionData?.allfixtures
      : false,
  );
  const [dropDownSportId, setDropDownSportId] = useState(0);
  const [teamScores, setTeamScores] = useState(
    teamSportType === "cricket"
      ? "ScoreBoard"
      : teamSportType === "basketball"
        ? "NBAScores"
        : teamSportType === "americanfootball"
          ? "AFLScores"
          : teamSportType === "australianrules"
            ? "ARScores"
            : teamSportType === "golf"
              ? "GolfScores"
              : teamSportType === "tennis"
                ? "TennisScores"
                : teamSportType === "baseball"
                  ? "BaseballScores"
                  : teamSportType === "icehockey"
                    ? "IceHockeyScores"
                    : teamSportType === "boxing"
                      ? "BoxingScores"
                      : teamSportType === "mma"
                        ? "MMAScores"
                        : teamSportType === "soccer"
                          ? "ScoreBoard"
                          : "RLScores",
  );
  const [isAdded, setIsAdded] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clickToolTipTeamId, setClickToolTipTeamId] = useState(null);
  const [SBopen, setSBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedSportId, setSelectedSportId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showOdds, setShowOdds] = useState(release[Config.release]?.oddsCheck);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState(null);
  const [selectedOrgLabel, setSelectedOrgLabel] = useState("");
  const [selectedTeamsLabel, setSelectedTeamsLabel] = useState("");
  const [showUpdate, setShowUpdate] = useState(false);
  const [showUpdateIndex, setShowUpdateIndex] = useState("");
  const [toolOutrighttipOpen, setToolOutrighttipOpen] = useState(false);
  const [hoveredOutrightIndex, setHoveredOutrightIndex] = useState(null);
  const [teamOutrightIndex, setTeamOutrightindex] = useState(null);
  const [singleMatchData, setSingleMatchData] = useState({});
  const [matchLoading, setMatchLoading] = useState(false);
  const [expertTipsCount, setExpertTipsCount] = useState(0);
  const [isFixtureTabRedirected, setIsFixtureTabRedirected] = useState(false);
  const [topBookMakersData, setTopBookMakersData] = useState([]);
  const BetslipData = useSelector((state) => state?.reduxData?.BetSlipData);

  const handleChange = (id, value) => {
    setStateTabValue(id);
  };
  const handleLiveDayChange = (id, value, dayName) => {
    setDayLiveFilter(id);
    if (id === 0 && selectedDate === null) {
      fetchAllEvents(
        0,
        selectedOption,
        selectedseries,
        selectedteam,
        selectedDate,
        null,
        "IsLive",
      );
      fetchDropDowndata(0, null, "IsLive");
    } else if (id === 1 && selectedDate === null) {
      fetchAllEvents(
        0,
        selectedOption,
        selectedseries,
        selectedteam,
        selectedDate,
        null,
        "All",
      );
      fetchDropDowndata(0, null, "All");
    } else if (id === 4 && selectedDate === null) {
      fetchAllEvents(
        0,
        selectedOption,
        selectedseries,
        selectedteam,
        selectedDate,
        null,
        "Future",
      );
      fetchDropDowndata(0, null, "Future");
    }

    setDateSelected(value ? value : null);
    setselectedDate(value ? value : null);
  };
  const handleResetAll = () => {
    setDateSelected(null);
    setselectedDate(null);
    setDayLiveFilter(1);
    if (dayLiveFilter === 0 && selectedDate === null) {
      fetchAllEvents(
        0,
        selectedOption,
        selectedseries,
        selectedteam,
        selectedDate,
        null,
        "reset",
      );
      fetchDropDowndata(0, null, "reset");
    }
  };
  const newTabData =
    teamSportType === "basketball" ||
    teamSportType === "icehockey" ||
    teamSportType === "baseball" ||
    teamSportType === "americanfootball"
      ? matchuptabData
      : teamSportType === "golf"
        ? outRightsTabData
        : teamSportType === "rugbyleague" ||
            teamSportType === "rugbyunion" ||
            teamSportType === "soccer"
          ? // teamSportType === "cricket" ||
            StatsTabData
          : teamSportType === "cricket"
            ? CricketStatsTabData
            : tabData;

  const handleTabChange = (event, value) => {
    if (value === 1 || value === 3 || value === 4 || value === 6) {
      if (handleRestrictedUser()) {
        setTabValue(value);
      } else {
        dispatch(fetchRestrictedRoute(true));
        localStorage.setItem("prev_authroute", window.location.pathname);
      }
    } else {
      setTabValue(value);
    }
    if (value === 0 || value === 3) {
      navigate(`/teamsports/${params?.type}/odds/0/false`);
    }
    setselectedseries(0);
    // setselectedDate(null);
    setselectedteam(0);
    if (handleRestrictedUser()) {
      setShowOdds(reduxSubscriptionData?.oddfixtures);
    } else {
      setShowOdds(release[Config.release]?.oddsCheck);
    }
  };
  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handleDateChange = (date) => {
    setselectedDate(
      date ? moment(date).tz(timezone).format("YYYY-MM-DD") : null,
    );
    navigate(`/teamsports/${params?.type}/odds/0/false`);
  };
  const handleLiveDateChange = (date) => {
    setDateSelected(
      date ? moment(date).tz(timezone).format("YYYY-MM-DD") : null,
    );
    setselectedDate(
      date ? moment(date).tz(timezone).format("YYYY-MM-DD") : null,
    );
    let selectedDateTab = dayFilter?.find(
      (item) => item?.date == moment(date).tz(timezone).format("YYYY-MM-DD"),
    );
    setDayLiveFilter(selectedDateTab?.id == 4 ? null : selectedDateTab?.id);
    navigate(`/teamsports/${params?.type}/odds/0/false`);
  };

  const handleBookkeeperCounter = async (BookKeeperId, type, e) => {
    e.stopPropagation();
    let SportId =
      teamSportType === "cricket"
        ? 4
        : teamSportType === "rugbyleague"
          ? 12
          : teamSportType === "rugbyunion"
            ? 13
            : teamSportType === "basketball"
              ? 10
              : teamSportType === "americanfootball"
                ? 15
                : teamSportType === "australianrules"
                  ? 9
                  : teamSportType === "golf"
                    ? 16
                    : teamSportType === "tennis"
                      ? 7
                      : teamSportType === "baseball"
                        ? 11
                        : teamSportType === "icehockey"
                          ? 17
                          : teamSportType === "boxing"
                            ? 6
                            : teamSportType === "mma"
                              ? 5
                              : teamSportType === "soccer"
                                ? 8
                                : 14;
    let payload = {
      BookKeeperId: BookKeeperId,
      type: type,
      SportId: Number(SportId),
    };
    try {
      const { status, data } = await axiosInstance.post(
        `provider/providerClick`,
        payload,
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleAnchorTagClick = (e, url) => {
    window.open(url, "_blank");
    e.stopPropagation();
  };
  const oddsicon = (BookKeeperId, type, item, fullData, oddsType) => {
    const Identifiers =
      teamSportType === "cricket"
        ? fullData?.CricketIdentifiers
        : teamSportType === "basketball"
          ? fullData?.NBAIdentifiers
          : teamSportType === "americanfootball"
            ? fullData?.AFLIdentifiers
            : teamSportType === "australianrules"
              ? fullData?.ARIdentifiers
              : teamSportType === "golf"
                ? fullData?.GolfIdentifiers
                : teamSportType === "tennis"
                  ? fullData?.TennisIdentifiers
                  : teamSportType === "baseball"
                    ? fullData?.BaseballIdentifiers
                    : teamSportType === "icehockey"
                      ? fullData?.IceHockeyIdentifiers
                      : teamSportType === "boxing"
                        ? fullData?.BoxingIdentifiers
                        : teamSportType === "mma"
                          ? fullData?.MMAIdentifiers
                          : teamSportType === "soccer"
                            ? fullData?.SoccerIdentifiers
                            : fullData?.RLIdentifiers;
    const newData = Identifiers?.map((obj) => {
      const BookkeeperObj = obj?.ApiProvider?.BookKeeperProviders?.filter(
        (item) => {
          return item?.BookKeeperId === BookKeeperId;
        },
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null,
      };
    });
    const filteredData = newData
      ?.filter?.((obj) => obj?.BookKeeperId === BookKeeperId)
      ?.filter((obj) => obj?.ApiProviderId !== 17);

    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    let imageUrl =
      oddsType == "currentbest"
        ? iconData?.currentBest_logo
        : iconData?.long_logo;
    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.filter(
        (ele) => ele?.providerMarketId && ele?.providerParticipantId,
      );
      const urlLink =
        Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
          ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
          : `${iconData?.affiliate_link}`;
      return (
        <Box
          // href={
          //
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="current-best-odds-icon"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          {oddsType == "currentbest" ? (
            <img
              className="currentbest-bookmaker-thumb"
              src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
              onClick={(e) => {
                handleBookkeeperCounter(BookKeeperId, type, e);
                handleAnchorTagClick(e, urlLink);
              }}
              alt="Odds Icon"
            />
          ) : (
            <Box className="odd-img">
              <img
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                onClick={(e) => {
                  handleBookkeeperCounter(BookKeeperId, type, e);
                  handleAnchorTagClick(e, urlLink);
                }}
                alt="Odds Icon"
              />
            </Box>
          )}
        </Box>
      );
    } else {
      const urlLink =
        filteredData?.length > 0 && filteredData?.[0]?.url
          ? filteredData?.[0]?.url + `?Referrer=SmartB`
          : iconData?.affiliate_link;
      return (
        <Box
          // href={
          //   filteredData?.length > 0 && filteredData?.[0]?.url
          //     ? filteredData?.[0]?.url + `?Referrer=SmartB`
          //     : iconData?.affiliate_link
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="current-best-odds-icon"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          {oddsType == "currentbest" ? (
            <img
              className="currentbest-bookmaker-thumb"
              src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
              onClick={(e) => {
                handleBookkeeperCounter(BookKeeperId, type, e);
                handleAnchorTagClick(e, urlLink);
              }}
              alt="Odds Icon"
            />
          ) : (
            <Box className="odd-img">
              <img
                src={imageUrl ? Config.mediaURL + imageUrl : DefaultImg}
                onClick={(e) => {
                  handleBookkeeperCounter(BookKeeperId, type, e);
                  handleAnchorTagClick(e, urlLink);
                }}
                alt="Odds Icon"
              />
            </Box>
          )}
        </Box>
      );
    }
  };

  const fetchClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore,
    OddsType,
    teamType,
  ) => {
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    let icon = BookkeeperData?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    let iconData = icon?.[0]?.BookKeeper;
    const oddsInfo = BookKeeperData?.[0];
    let isAdded = BetslipData?.some((el) => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      )
        return true;
      else return false;
    });
    // if (BookKeeperId === 8) {
    //   const Bet365Data = item?.filter(obj => obj?.BookKeeperId === 8);

    //   const Odds = Bet365Data?.filter(
    //     ele => ele?.providerMarketId && ele?.providerParticipantId
    //   );
    //   return (
    //     <a
    //       href={
    //         Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
    //           ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
    //           : `${iconData?.affiliate_link}`
    //       }
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       className="odds-link"
    //     >
    //       {odds === "SP" ? "SP" : Number(odds).toFixed(2)}{" "}
    //     </a>
    //   );
    // } else {
    return (
      // <a
      //   href={iconData?.affiliate_link}
      //   target="_blank"
      //   rel="noopener noreferrer"
      //   className="odds-link"
      // >
      <>
        {odds || odds == 0 ? (
          OddsType == "currentbest" ? (
            <span
              className={`odds-btn cursor-pointer current-best-odds-value  ${
                isAdded && IsBetslip === "betslip" ? "betslip-added" : ""
              } `}
              onClick={(e) => {
                IsBetslip === "nobetslip"
                  ? handleBookkeeperCounter(BookKeeperId, type, e)
                  : isAdded
                    ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
                    : handleAddToBetSlip(
                        BookKeeperId,
                        type,
                        item,
                        fulldata,
                        Isscore,
                        e,
                      );
              }}
            >
              <span className="odds-link">
                {IsBetslip === "betslip" && !Isscore ? (
                  <Tooltip title="Bet" className="bet-tooltip" placement="top">
                    <span className="bet-now">
                      {" "}
                      {odds == 0 ? "SP" : Number(odds).toFixed(2)}{" "}
                    </span>
                  </Tooltip>
                ) : (
                  <>{odds == 0 ? "SP" : Number(odds).toFixed(2)}</>
                )}
              </span>
            </span>
          ) : (
            <>
              <Box className="odds-box-style">
                {OddsType == "featuredOdds" && (
                  <Box className="odds-point">
                    {fetchSeeAllTeamOddsvalue(
                      item,
                      BookKeeperId,
                      teamType == "hometeam"
                        ? MarketName?.toLowerCase()?.includes("total")
                          ? "over"
                          : "hometeam"
                        : teamType == "awayteam"
                          ? MarketName?.toLowerCase()?.includes("total")
                            ? "under"
                            : "awayteam"
                          : null,
                      "spreadpoints",
                    )}
                  </Box>
                )}
                <Box
                  className={`odds-style  ${isAdded ? "active-odd-style" : ""}`}
                  onClick={(e) => {
                    IsBetslip === "nobetslip"
                      ? handleBookkeeperCounter(BookKeeperId, type, e)
                      : isAdded
                        ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
                        : handleAddToBetSlip(
                            BookKeeperId,
                            type,
                            item,
                            fulldata,
                            Isscore,
                            e,
                          );
                  }}
                >
                  {IsBetslip === "betslip" && !Isscore ? (
                    <Tooltip
                      title="Bet"
                      className="bet-tooltip"
                      placement="top"
                    >
                      <Typography className="odds bet-now">
                        {" "}
                        {odds === "SP" ? "SP" : Number(odds).toFixed(2)}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <>{odds === "SP" ? "SP" : Number(odds).toFixed(2)}</>
                  )}
                </Box>
                {OddsType == "featuredOdds" &&
                  oddsicon(
                    BookKeeperId,
                    "header",
                    item,
                    fulldata,
                    "featuredOdds",
                  )}
                {/* <Box className="odd-img">
            <img
              src={
                item?.long_logo
                  ? Config.mediaURL + item?.long_logo
                  : DefaultImg
              }
              alt="bk"
            />
          </Box> */}
              </Box>
            </>
          )
        ) : null}
      </>
    );
  };
  const handleAddToBetSlip = async (
    BookKeeperId,
    type,
    item,
    fulldata,
    Isscore,
    e,
  ) => {
    e.stopPropagation();
    handleBookkeeperCounter(BookKeeperId, type, e);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    const oddsInfo = BookKeeperData?.[0];

    let passTeamId =
      teamSportType === "cricket"
        ? oddsInfo?.CricketTeamId
          ? oddsInfo?.CricketTeamId
          : oddsInfo?.CricketOddLabelId
            ? oddsInfo?.CricketOddLabelId
            : oddsInfo?.CricketPlayerId
        : teamSportType === "basketball"
          ? oddsInfo?.NBATeamId
            ? oddsInfo?.NBATeamId
            : oddsInfo?.NBAOddLabelId
              ? oddsInfo?.NBAOddLabelId
              : oddsInfo?.NBAPlayerId
          : teamSportType === "americanfootball"
            ? oddsInfo?.AFLTeamId
              ? oddsInfo?.AFLTeamId
              : oddsInfo?.AFLOddLabelId
                ? oddsInfo?.AFLOddLabelId
                : oddsInfo?.AFLPlayerId
            : teamSportType === "australianrules"
              ? oddsInfo?.ARTeamId
                ? oddsInfo?.ARTeamId
                : oddsInfo?.AROddLabelId
                  ? oddsInfo?.AROddLabelId
                  : oddsInfo?.ARPlayerId
              : teamSportType === "golf"
                ? oddsInfo?.GolfTeamId
                  ? oddsInfo?.GolfTeamId
                  : oddsInfo?.GolfOddLabelId
                    ? oddsInfo?.GolfOddLabelId
                    : oddsInfo?.GolfPlayerId
                : teamSportType === "tennis"
                  ? oddsInfo?.TennisTeamId
                    ? oddsInfo?.TennisTeamId
                    : oddsInfo?.TennisOddLabelId
                      ? oddsInfo?.TennisOddLabelId
                      : oddsInfo?.TennisPlayerId
                  : teamSportType === "baseball"
                    ? oddsInfo?.BaseballTeamId
                      ? oddsInfo?.BaseballTeamId
                      : oddsInfo?.BaseballOddLabelId
                        ? oddsInfo?.BaseballOddLabelId
                        : oddsInfo?.BaseballPlayerId
                    : teamSportType === "icehockey"
                      ? oddsInfo?.IceHockeyTeamId
                        ? oddsInfo?.IceHockeyTeamId
                        : oddsInfo?.IceHockeyOddLabelId
                          ? oddsInfo?.IceHockeyOddLabelId
                          : oddsInfo?.IceHockeyPlayerId
                      : teamSportType === "boxing"
                        ? oddsInfo?.BoxingTeamId
                          ? oddsInfo?.BoxingTeamId
                          : oddsInfo?.BoxingOddLabelId
                            ? oddsInfo?.BoxingOddLabelId
                            : oddsInfo?.BoxingPlayerId
                        : teamSportType === "mma"
                          ? oddsInfo?.MMATeamId
                            ? oddsInfo?.MMATeamId
                            : oddsInfo?.MMAOddLabelId
                              ? oddsInfo?.MMAOddLabelId
                              : oddsInfo?.MMAPlayerId
                          : teamSportType === "soccer"
                            ? oddsInfo?.SoccerTeamId
                              ? oddsInfo?.SoccerTeamId
                              : oddsInfo?.SoccerOddLabelId
                                ? oddsInfo?.SoccerOddLabelId
                                : oddsInfo?.SoccerPlayerId
                            : oddsInfo?.RLTeamId
                              ? oddsInfo?.RLTeamId
                              : oddsInfo?.RLOddLabelId
                                ? oddsInfo?.RLOddLabelId
                                : oddsInfo?.RLPlayerId;

    let payload = {
      sportId: fulldata?.SportId,
      eventId: fulldata?.id,
      teamId: passTeamId,
      bookKeeperId: BookKeeperId,
      betOfferId: oddsInfo?.id,
    };
    if (isLogin) {
      if (!Isscore) {
        try {
          const { status, data } = await axiosInstance.post(
            `betSlipCard/BetSlipCard`,
            payload,
          );
          if (status === 200) {
            toast.success(data?.message, {
              position: "bottom-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "colored",
            });

            const addedData = [...BetslipData, data?.result];
            dispatch(fetchBetSlipData(addedData));
            dispatch(fetchBetSlipCount(addedData?.length));
          } else {
          }
        } catch (err) {
          console.log("error", err);
        }
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      if (!Isscore) {
        setApiInfo({
          api: `betSlipCard/BetSlipCard`,
          payload: payload,
          method: "post",
        });
      }
    }
  };
  const handleDeleteBetslip = async (BookKeeperId, item, fulldata, e) => {
    e.stopPropagation();
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    const oddsInfo = BookKeeperData?.[0];
    let delslip = BetslipData?.filter((el) => {
      return (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      );
    })?.[0];

    try {
      const { status, data } = await axiosInstance.delete(
        `betSlipCard/BetSlipCard?id=${delslip?.id}`,
      );
      if (status === 200) {
        toast.success(data?.message, {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        let chageData = BetslipData?.filter((obj) => obj?.id !== delslip?.id);

        dispatch(fetchBetSlipData(chageData));
        dispatch(fetchBetSlipCount(chageData?.length));
        // setBetSlipData(chageData);
      } else {
      }
    } catch (err) {}
  };
  const handleStepper = (value) => {
    if (stepperCount >= 0) {
      return value === "left"
        ? stepperCount > 0
          ? setStepperCount(stepperCount - 1)
          : ""
        : setStepperCount(stepperCount + 1);
    }
  };

  const IsFeaturedData = (item, teamType) => {
    return topBookMakersData?.slice(0, 6)?.map((bookkeper, index) => {
      return fetchFeaturedOdds(item, bookkeper?.id, teamType, "odds");
    });
  };
  const handleShowUpdateCheckBox = (e) => {
    e.stopPropagation();
  };
  useEffect(() => {
    let sportId =
      teamSportType === "cricket"
        ? 4
        : teamSportType === "rugbyleague"
          ? 12
          : teamSportType === "australianrules"
            ? 9
            : null;
    if (sportId) {
      getAllTipData(sportId);
    }
  }, [teamSportType]);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    fetchTopBookmakers();
    // fetchAllEvents(EventPage);
    // fetchAllTournament(TournamentPage);
    // fetchAllTeam(TeamPage);
  }, []);
  useEffect(() => {
    // setTournamentCount(0);
    // setTournamentPage(0);
    setselectedseries(0);
    setselectedteam(0);
    // fetchDropDowndata(0);
    // fetchmatchupDropDownData(0);
    // setTournamentData([]);
    // setTeamCount(0);
    // setTeamPage(0);
    // setselectedteam(0);
    // setTeamData([]);
    setStepperCount(0);
    setselectedDate(null);
    setDayLiveFilter(1);
    setDateSelected(null);
    setSeeAll("false");
    // navigate(`/teamsports/${params?.type}/odds/0/false`);
    setindividualTeamData({});
    // setTounamentApiCount(0);
    // setTournamentSelect(false);
    // setTeamApiCount(0);
    // setTeamSelect(false);
    // fetchAllTournament(0, [], 0);
    // fetchAllTeam(0, [], 0);
    scrollToTop();
    // setEventApiCount(0);
    // fetchAllEvents(0, selectedOption, 0, 0, null);
    setTabValue(
      teamSportType === "golf"
        ? 2
        : release[Config.release]?.Outrights
          ? params?.oddstype === "outrights"
            ? 2
            : 0
          : 0,
    );
    GetSponsoredOdds();
    // setSelectedOption(1);
    setselectedMarket(1);
    setEventPage(0);
    if (handleRestrictedUser()) {
      setShowOdds(reduxSubscriptionData?.oddfixtures);
    } else {
      if (teamSportType) {
        fetchmarketList(0, 0, null);
      }
      setShowOdds(release[Config.release]?.oddsCheck);
    }
    setIsFixtureTabRedirected(false);
    setTeamScores(
      teamSportType === "cricket"
        ? "ScoreBoard"
        : teamSportType === "basketball"
          ? "NBAScores"
          : teamSportType === "americanfootball"
            ? "AFLScores"
            : teamSportType === "australianrules"
              ? "ARScores"
              : teamSportType === "golf"
                ? "GolfScores"
                : teamSportType === "tennis"
                  ? "TennisScores"
                  : teamSportType === "baseball"
                    ? "BaseballScores"
                    : teamSportType === "icehockey"
                      ? "IceHockeyScores"
                      : teamSportType === "boxing"
                        ? "BoxingScores"
                        : teamSportType === "mma"
                          ? "MMAScores"
                          : teamSportType === "soccer"
                            ? "ScoreBoard"
                            : "RLScores",
    );
  }, [teamSportType]);

  useEffect(() => {
    if (handleRestrictedUser()) {
      if (teamSportType && reduxSubscriptionData) {
        fetchmarketList(0, 0, null, reduxSubscriptionData?.allfixtures);
        setShowAllFixtures(reduxSubscriptionData?.allfixtures);
      }
    } else {
      setShowAllFixtures(
        reduxSubscriptionData?.allfixtures
          ? reduxSubscriptionData?.allfixtures
          : false,
      );
    }
  }, [teamSportType, reduxSubscriptionData]);

  useEffect(() => {
    clearInterval(timeinterval);
    clearTimeout(apiTimeout);
    if (tabvalue === 0 || tabvalue === 3) {
      if (teamSportType !== "golf") {
        // fetchAllEvents(
        //   0,
        //   selectedOption,
        //   selectedseries,
        //   selectedteam,
        //   selectedDate
        // );
        // setEventPage(0);
        if (handleRestrictedUser()) {
          if (reduxSubscriptionData) {
            fetchmarketList(selectedseries, selectedteam, selectedDate);
          }
        } else {
          fetchmarketList(selectedseries, selectedteam, selectedDate);
        }
      } else {
        if (tabvalue === 3) {
          fetchAllEvents(
            0,
            1,
            selectedseries,
            selectedteam,
            selectedDate,
            "outrights",
          );
        }
      }

      if (tabvalue === 3) {
        fetchTableHeading();
      }
      // navigate(`/teamsports/${params?.type}/odds/0/false`);
    } else if (
      (teamSportType === "basketball" ||
        teamSportType === "icehockey" ||
        teamSportType === "baseball" ||
        teamSportType === "americanfootball") &&
      tabvalue === 1
    ) {
      fetchMatchUpData();
      fetchMatchUpOnDemand();
      navigate(`/teamsports/${params?.type}/odds/0/false`);
    } else if (tabvalue === 2) {
      if (params?.seeall === "false") {
        fetchAllEvents(
          0,
          1,
          selectedseries,
          selectedteam,
          selectedDate,
          "outrights",
        );
      }
      if (params?.type !== "golf") {
        navigate(`/teamsports/${params?.type}/outrights/0/false`);
      } else {
        navigate(
          `/teamsports/${params?.type}/outrights/${params?.id}/${params?.seeall}`,
        );
      }

      setEventPage(0);
    }
    //  else if (tabvalue === 3) {
    //   fetchTableHeading();
    // }
  }, [
    selectedseries,
    selectedDate,
    selectedteam,
    stepperCount,
    tabvalue,
    showAllFixture,
  ]);

  useEffect(() => {
    if (tabvalue === 0 || tabvalue === 3) {
      // teamSportType === "golf" && tabvalue === 3
      //   ? fetchDropDowndata(0, "outrights")
      //   : fetchDropDowndata(0);
    } else if (
      (teamSportType === "basketball" ||
        teamSportType === "icehockey" ||
        teamSportType === "baseball" ||
        teamSportType === "americanfootball") &&
      tabvalue === 1
    ) {
      fetchmatchupDropDownData(0);
    } else if (tabvalue === 2) {
      fetchDropDowndata(0, "outrights");
    }
  }, [tabvalue, teamSportType, selectedDate, showAllFixture]);

  useEffect(() => {
    setEventPage(0);
    setEventCount(0);
    setSeeAll("false");
    // navigate(`/teamsports/${params?.type}/odds/0/false`);
    setindividualTeamData({});
    setEventList([]);
  }, [selectedseries, selectedDate, selectedteam, stepperCount]);

  const fetchAllEvents = async (
    EventPage,
    marketId,
    selectedseries,
    selectedteam,
    selectedDate,
    outrights,
    IsLive,
    isFixture,
  ) => {
    setEventPage(EventPage);
    setIsEventLoading(true);
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;

    let isPublic = !isLogin ? "public/" : "";
    let isSmartBook = !isLogin ? "" : "smartBook=true";
    try {
      let passApi =
        teamSportType === "cricket"
          ? `${isPublic}crickets/event?startDate=${
              selectedDate === null
                ? IsLive === "IsLive"
                  ? ""
                  : IsLive === "Future"
                    ? moment
                        .tz(moment(), timezone)
                        .add(1, "days")
                        .format("YYYY-MM-DD")
                    : moment().tz(timezone).format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null ? "" : selectedDate
            }&CricketTournamentId=${
              selectedseries === 0 ? "" : selectedseries
            }&teamId=${
              selectedteam === 0 ? "" : selectedteam
            }&timezone=${timezone}&oddCheck=true&marketId=${
              outrights ? "" : marketId
            }&isOuright=${
              outrights ? true : ""
            }&limit=20&offset=${EventPage}&isAdmin=${
              isFixture !== undefined ? isFixture : showAllFixture
            }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
              IsLive === "reset" ? "" : IsLive === "IsLive" ? "inprogress" : ""
            }&${isSmartBook}`
          : teamSportType === "rugbyleague"
            ? `${isPublic}rls/event?startDate=${
                selectedDate === null
                  ? IsLive === "Future"
                    ? moment
                        .tz(moment(), timezone)
                        .add(1, "days")
                        .format("YYYY-MM-DD")
                    : moment().tz(timezone).format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null
                  ? IsLive === "reset" || IsLive === "Future"
                    ? ""
                    : IsLive === "IsLive"
                      ? moment().tz(timezone).format("YYYY-MM-DD")
                      : ""
                  : selectedDate
              }&RLTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
                stepperCount === 0 ? "" : stepperCount
              }&timezone=${timezone}&SportId=12&oddCheck=true&marketId=${
                outrights ? "" : marketId
              }&isOuright=${
                outrights ? true : ""
              }&limit=20&offset=${EventPage}&isAdmin=${
                isFixture !== undefined ? isFixture : showAllFixture
              }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                IsLive === "reset"
                  ? ""
                  : IsLive === "IsLive"
                    ? "inprogress"
                    : ""
              }&${isSmartBook}`
            : teamSportType === "rugbyunion"
              ? `${isPublic}rls/event?startDate=${
                  selectedDate === null
                    ? IsLive === "Future"
                      ? moment
                          .tz(moment(), timezone)
                          .add(1, "days")
                          .format("YYYY-MM-DD")
                      : moment().tz(timezone).format("YYYY-MM-DD")
                    : selectedDate
                }&endDate=${
                  selectedDate === null
                    ? IsLive === "reset" || IsLive === "Future"
                      ? ""
                      : IsLive === "IsLive"
                        ? moment().tz(timezone).format("YYYY-MM-DD")
                        : ""
                    : selectedDate
                }&RLTournamentId=${
                  selectedseries === 0 ? "" : selectedseries
                }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
                  stepperCount === 0 ? "" : stepperCount
                }&timezone=${timezone}&SportId=13&oddCheck=true&marketId=${
                  outrights ? "" : marketId
                }&isOuright=${
                  outrights ? true : ""
                }&limit=20&offset=${EventPage}&isAdmin=${
                  isFixture !== undefined ? isFixture : showAllFixture
                }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                  IsLive === "reset"
                    ? ""
                    : IsLive === "IsLive"
                      ? "inprogress"
                      : ""
                }&${isSmartBook}`
              : teamSportType === "basketball"
                ? `${isPublic}nba/event?startDate=${
                    selectedDate === null
                      ? IsLive === "Future"
                        ? moment
                            .tz(moment(), timezone)
                            .add(1, "days")
                            .format("YYYY-MM-DD")
                        : moment().tz(timezone).format("YYYY-MM-DD")
                      : selectedDate
                  }&endDate=${
                    selectedDate === null
                      ? IsLive === "reset" || IsLive === "Future"
                        ? ""
                        : IsLive === "IsLive"
                          ? moment().tz(timezone).format("YYYY-MM-DD")
                          : ""
                      : selectedDate
                  }&NBATournamentId=${
                    selectedseries === 0 ? "" : selectedseries
                  }&teamId=${
                    selectedteam === 0 ? "" : selectedteam
                  }&timezone=${timezone}&oddCheck=true&marketId=${
                    outrights ? "" : marketId
                  }&isOuright=${
                    outrights ? true : ""
                  }&limit=20&offset=${EventPage}&isAdmin=${
                    isFixture !== undefined ? isFixture : showAllFixture
                  }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                    IsLive === "reset"
                      ? ""
                      : IsLive === "IsLive"
                        ? "inprogress"
                        : ""
                  }&${isSmartBook}`
                : teamSportType === "americanfootball"
                  ? `${isPublic}afl/event?startDate=${
                      selectedDate === null
                        ? IsLive === "Future"
                          ? moment
                              .tz(moment(), timezone)
                              .add(1, "days")
                              .format("YYYY-MM-DD")
                          : moment().tz(timezone).format("YYYY-MM-DD")
                        : selectedDate
                    }&endDate=${
                      selectedDate === null
                        ? IsLive === "reset" || IsLive === "Future"
                          ? ""
                          : IsLive === "IsLive"
                            ? moment().tz(timezone).format("YYYY-MM-DD")
                            : ""
                        : selectedDate
                    }&AFLTournamentId=${
                      selectedseries === 0 ? "" : selectedseries
                    }&teamId=${
                      selectedteam === 0 ? "" : selectedteam
                    }&timezone=${timezone}&oddCheck=true&marketId=${
                      outrights ? "" : marketId
                    }&isOuright=${
                      outrights ? true : ""
                    }&limit=20&offset=${EventPage}&isAdmin=${
                      isFixture !== undefined ? isFixture : showAllFixture
                    }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                      IsLive === "reset"
                        ? ""
                        : IsLive === "IsLive"
                          ? "inprogress"
                          : ""
                    }&${isSmartBook}`
                  : teamSportType === "australianrules"
                    ? `${isPublic}ar/event?startDate=${
                        selectedDate === null
                          ? IsLive === "Future"
                            ? moment
                                .tz(moment(), timezone)
                                .add(1, "days")
                                .format("YYYY-MM-DD")
                            : moment().tz(timezone).format("YYYY-MM-DD")
                          : selectedDate
                      }&endDate=${
                        selectedDate === null
                          ? IsLive === "reset" || IsLive === "Future"
                            ? ""
                            : IsLive === "IsLive"
                              ? moment().tz(timezone).format("YYYY-MM-DD")
                              : ""
                          : selectedDate
                      }&ARTournamentId=${
                        selectedseries === 0 ? "" : selectedseries
                      }&teamId=${
                        selectedteam === 0 ? "" : selectedteam
                      }&timezone=${timezone}&oddCheck=true&marketId=${
                        outrights ? "" : marketId
                      }&isOuright=${
                        outrights ? true : ""
                      }&limit=20&offset=${EventPage}&isAdmin=${
                        isFixture !== undefined ? isFixture : showAllFixture
                      }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                        IsLive === "reset"
                          ? ""
                          : IsLive === "IsLive"
                            ? "inprogress"
                            : ""
                      }&${isSmartBook}`
                    : teamSportType === "golf"
                      ? `${isPublic}golf/event?startDate=${
                          selectedDate === null
                            ? IsLive === "Future"
                              ? moment
                                  .tz(moment(), timezone)
                                  .add(1, "days")
                                  .format("YYYY-MM-DD")
                              : moment().tz(timezone).format("YYYY-MM-DD")
                            : selectedDate
                        }&endDate=${
                          selectedDate === null
                            ? IsLive === "reset" || IsLive === "Future"
                              ? ""
                              : IsLive === "IsLive"
                                ? moment().tz(timezone).format("YYYY-MM-DD")
                                : ""
                            : selectedDate
                        }&GolfTournamentId=${
                          selectedseries === 0 ? "" : selectedseries
                        }&teamId=${
                          selectedteam === 0 ? "" : selectedteam
                        }&timezone=${timezone}&oddCheck=true&isOuright=${
                          outrights ? true : ""
                        }&limit=20&offset=${EventPage}&isAdmin=${
                          isFixture !== undefined ? isFixture : showAllFixture
                        }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                          IsLive === "reset"
                            ? ""
                            : IsLive === "IsLive"
                              ? "inprogress"
                              : ""
                        }&${isSmartBook}`
                      : teamSportType === "tennis"
                        ? `${isPublic}tennis/event?startDate=${
                            selectedDate === null
                              ? IsLive === "Future"
                                ? moment
                                    .tz(moment(), timezone)
                                    .add(1, "days")
                                    .format("YYYY-MM-DD")
                                : moment().tz(timezone).format("YYYY-MM-DD")
                              : selectedDate
                          }&endDate=${
                            selectedDate === null
                              ? IsLive === "reset" || IsLive === "Future"
                                ? ""
                                : IsLive === "IsLive"
                                  ? moment().tz(timezone).format("YYYY-MM-DD")
                                  : ""
                              : selectedDate
                          }&TennisTournamentId=${
                            selectedseries === 0 ? "" : selectedseries
                          }&teamId=${
                            selectedteam === 0 ? "" : selectedteam
                          }&timezone=${timezone}&oddCheck=true&marketId=${
                            outrights ? "" : marketId
                          }&isOuright=${
                            outrights ? true : ""
                          }&limit=20&offset=${EventPage}&isAdmin=${
                            isFixture !== undefined ? isFixture : showAllFixture
                          }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                            IsLive === "reset"
                              ? ""
                              : IsLive === "IsLive"
                                ? "inprogress"
                                : ""
                          }&${isSmartBook}`
                        : teamSportType === "baseball"
                          ? `${isPublic}baseball/event?startDate=${
                              selectedDate === null
                                ? IsLive === "Future"
                                  ? moment
                                      .tz(moment(), timezone)
                                      .add(1, "days")
                                      .format("YYYY-MM-DD")
                                  : moment().tz(timezone).format("YYYY-MM-DD")
                                : selectedDate
                            }&endDate=${
                              selectedDate === null
                                ? IsLive === "reset" || IsLive === "Future"
                                  ? ""
                                  : IsLive === "IsLive"
                                    ? moment().tz(timezone).format("YYYY-MM-DD")
                                    : ""
                                : selectedDate
                            }&BaseballTournamentId=${
                              selectedseries === 0 ? "" : selectedseries
                            }&teamId=${
                              selectedteam === 0 ? "" : selectedteam
                            }&timezone=${timezone}&oddCheck=true&marketId=${
                              outrights ? "" : marketId
                            }&isOuright=${
                              outrights ? true : ""
                            }&limit=20&offset=${EventPage}&isAdmin=${
                              isFixture !== undefined
                                ? isFixture
                                : showAllFixture
                            }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                              IsLive === "reset"
                                ? ""
                                : IsLive === "IsLive"
                                  ? "inprogress"
                                  : ""
                            }&${isSmartBook}`
                          : teamSportType === "icehockey"
                            ? `${isPublic}icehockey/event?startDate=${
                                selectedDate === null
                                  ? IsLive === "Future"
                                    ? moment
                                        .tz(moment(), timezone)
                                        .add(1, "days")
                                        .format("YYYY-MM-DD")
                                    : moment().tz(timezone).format("YYYY-MM-DD")
                                  : selectedDate
                              }&endDate=${
                                selectedDate === null
                                  ? IsLive === "reset" || IsLive === "Future"
                                    ? ""
                                    : IsLive === "IsLive"
                                      ? moment()
                                          .tz(timezone)
                                          .format("YYYY-MM-DD")
                                      : ""
                                  : selectedDate
                              }&IceHockeyTournamentId=${
                                selectedseries === 0 ? "" : selectedseries
                              }&teamId=${
                                selectedteam === 0 ? "" : selectedteam
                              }&timezone=${timezone}&oddCheck=true&marketId=${
                                outrights ? "" : marketId
                              }&isOuright=${
                                outrights ? true : ""
                              }&limit=20&offset=${EventPage}&isAdmin=${
                                isFixture !== undefined
                                  ? isFixture
                                  : showAllFixture
                              }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                                IsLive === "reset"
                                  ? ""
                                  : IsLive === "IsLive"
                                    ? "inprogress"
                                    : ""
                              }&${isSmartBook}`
                            : teamSportType === "boxing"
                              ? `${isPublic}boxing/event?startDate=${
                                  selectedDate === null
                                    ? IsLive === "Future"
                                      ? moment
                                          .tz(moment(), timezone)
                                          .add(1, "days")
                                          .format("YYYY-MM-DD")
                                      : moment()
                                          .tz(timezone)
                                          .format("YYYY-MM-DD")
                                    : selectedDate
                                }&endDate=${
                                  selectedDate === null
                                    ? IsLive === "reset" || IsLive === "Future"
                                      ? ""
                                      : IsLive === "IsLive"
                                        ? moment()
                                            .tz(timezone)
                                            .format("YYYY-MM-DD")
                                        : ""
                                    : selectedDate
                                }&BoxingTournamentId=${
                                  selectedseries === 0 ? "" : selectedseries
                                }&teamId=${
                                  selectedteam === 0 ? "" : selectedteam
                                }&timezone=${timezone}&oddCheck=true&marketId=${
                                  outrights ? "" : marketId
                                }&isOuright=${
                                  outrights ? true : ""
                                }&limit=20&offset=${EventPage}&isAdmin=${
                                  isFixture !== undefined
                                    ? isFixture
                                    : showAllFixture
                                }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                                  IsLive === "reset"
                                    ? ""
                                    : IsLive === "IsLive"
                                      ? "inprogress"
                                      : ""
                                }&${isSmartBook}`
                              : teamSportType === "mma"
                                ? `${isPublic}mma/event?startDate=${
                                    selectedDate === null
                                      ? IsLive === "Future"
                                        ? moment
                                            .tz(moment(), timezone)
                                            .add(1, "days")
                                            .format("YYYY-MM-DD")
                                        : moment()
                                            .tz(timezone)
                                            .format("YYYY-MM-DD")
                                      : selectedDate
                                  }&endDate=${
                                    selectedDate === null
                                      ? IsLive === "reset" ||
                                        IsLive === "Future"
                                        ? ""
                                        : IsLive === "IsLive"
                                          ? moment()
                                              .tz(timezone)
                                              .format("YYYY-MM-DD")
                                          : ""
                                      : selectedDate
                                  }&MMATournamentId=${
                                    selectedseries === 0 ? "" : selectedseries
                                  }&teamId=${
                                    selectedteam === 0 ? "" : selectedteam
                                  }&timezone=${timezone}&oddCheck=true&marketId=${
                                    outrights ? "" : marketId
                                  }&isOuright=${
                                    outrights ? true : ""
                                  }&limit=20&offset=${EventPage}&isAdmin=${
                                    isFixture !== undefined
                                      ? isFixture
                                      : showAllFixture
                                  }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                                    IsLive === "reset"
                                      ? ""
                                      : IsLive === "IsLive"
                                        ? "inprogress"
                                        : ""
                                  }&${isSmartBook}`
                                : teamSportType === "soccer"
                                  ? `${isPublic}soccer/event?startDate=${
                                      selectedDate === null
                                        ? IsLive === "Future"
                                          ? moment
                                              .tz(moment(), timezone)
                                              .add(1, "days")
                                              .format("YYYY-MM-DD")
                                          : moment()
                                              .tz(timezone)
                                              .format("YYYY-MM-DD")
                                        : selectedDate
                                    }&endDate=${
                                      selectedDate === null
                                        ? IsLive === "reset" ||
                                          IsLive === "Future"
                                          ? ""
                                          : IsLive === "IsLive"
                                            ? moment()
                                                .tz(timezone)
                                                .format("YYYY-MM-DD")
                                            : ""
                                        : selectedDate
                                    }&SoccerTournamentId=${
                                      selectedseries === 0 ? "" : selectedseries
                                    }&teamId=${
                                      selectedteam === 0 ? "" : selectedteam
                                    }&timezone=${timezone}&oddCheck=true&marketId=${
                                      outrights ? "" : marketId
                                    }&isOuright=${
                                      outrights ? true : ""
                                    }&limit=20&offset=${EventPage}&isAdmin=${
                                      isFixture !== undefined
                                        ? isFixture
                                        : showAllFixture
                                    }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                                      IsLive === "reset"
                                        ? ""
                                        : IsLive === "IsLive"
                                          ? "inprogress"
                                          : ""
                                    }&${isSmartBook}`
                                  : `${isPublic}rls/event?startDate=${
                                      selectedDate === null
                                        ? IsLive === "Future"
                                          ? moment
                                              .tz(moment(), timezone)
                                              .add(1, "days")
                                              .format("YYYY-MM-DD")
                                          : moment()
                                              .tz(timezone)
                                              .format("YYYY-MM-DD")
                                        : selectedDate
                                    }&endDate=${
                                      selectedDate === null
                                        ? IsLive === "reset" ||
                                          IsLive === "Future"
                                          ? ""
                                          : IsLive === "IsLive"
                                            ? moment()
                                                .tz(timezone)
                                                .format("YYYY-MM-DD")
                                            : ""
                                        : selectedDate
                                    }&RLTournamentId=${
                                      selectedseries === 0 ? "" : selectedseries
                                    }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
                                      stepperCount === 0 ? "" : stepperCount
                                    }&timezone=${timezone}&SportId=14&oddCheck=true&marketId=${
                                      outrights ? "" : marketId
                                    }&isOuright=${
                                      outrights ? true : ""
                                    }&limit=20&offset=${EventPage}&isAdmin=${
                                      isFixture !== undefined
                                        ? isFixture
                                        : showAllFixture
                                    }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                                      IsLive === "reset"
                                        ? ""
                                        : IsLive === "IsLive"
                                          ? "inprogress"
                                          : ""
                                    }&${isSmartBook}`;
      // /public/crickets/event?startDate=2023-01-17&endDate=2023-01-18&CricketTournamentId=6&teamId=1&awayTeamId=1&homeTeamId=86
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        // setTimeout(() => {
        // setIsEventLoading(false);
        // }, 3000);
        if (
          data?.result?.rows?.length === 0 &&
          selectedDate === null &&
          selectedseries === 0 &&
          selectedteam === 0 &&
          IsLive !== "IsLive" &&
          !isFixtureTabRedirected
        ) {
          if (params?.seeall == "false") {
            setTabValue(5);
            setIsFixtureTabRedirected(true);
          }
        } else {
          let oddsApiCount = 0;
          let count = data?.result?.count / 20;
          setEventCount(Math.ceil(count));
          let fullData = [];
          let teamdata = [data?.result?.rows?.[0]];
          let newData =
            teamdata?.length > 0
              ? teamSportType === "cricket"
                ? teamdata?.[0]?.CricketBetOffers
                : teamSportType === "basketball"
                  ? teamdata?.[0]?.NBABetOffers
                  : teamSportType === "americanfootball"
                    ? teamdata?.[0]?.AFLBetOffers
                    : teamSportType === "australianrules"
                      ? teamdata?.[0]?.ARBetOffers
                      : teamSportType === "golf"
                        ? teamdata?.[0]?.GolfBetOffers
                        : teamSportType === "tennis"
                          ? teamdata?.[0]?.TennisBetOffers
                          : teamSportType === "baseball"
                            ? teamdata?.[0]?.BaseballBetOffers
                            : teamSportType === "icehockey"
                              ? teamdata?.[0]?.IceHockeyBetOffers
                              : teamSportType === "boxing"
                                ? teamdata?.[0]?.BoxingBetOffers
                                : teamSportType === "mma"
                                  ? teamdata?.[0]?.MMABetOffers
                                  : teamSportType === "soccer"
                                    ? teamdata?.[0]?.SoccerBetOffers
                                    : teamdata?.[0]?.RLBetOffers
              : [];

          let SportsOdds =
            teamSportType === "cricket"
              ? newData?.[0]?.CricketOdds
              : teamSportType === "basketball"
                ? newData?.[0]?.NBAOdds
                : teamSportType === "americanfootball"
                  ? newData?.[0]?.AFLOdds
                  : teamSportType === "australianrules"
                    ? newData?.[0]?.AROdds
                    : teamSportType === "golf"
                      ? newData?.[0]?.GolfOdds
                      : teamSportType === "tennis"
                        ? newData?.[0]?.TennisOdds
                        : teamSportType === "baseball"
                          ? newData?.[0]?.BaseballOdds
                          : teamSportType === "icehockey"
                            ? newData?.[0]?.IceHockeyOdds
                            : teamSportType === "boxing"
                              ? newData?.[0]?.BoxingOdds
                              : teamSportType === "mma"
                                ? newData?.[0]?.MMAOdds
                                : teamSportType === "soccer"
                                  ? newData?.[0]?.SoccerOdds
                                  : newData?.[0]?.RLOdds;

          let SportMarket =
            teamSportType === "cricket"
              ? newData?.[0]?.CricketMarket
              : teamSportType === "basketball"
                ? newData?.[0]?.NBAMarket
                : teamSportType === "americanfootball"
                  ? newData?.[0]?.AFLMarket
                  : teamSportType === "australianrules"
                    ? newData?.[0]?.ARMarket
                    : teamSportType === "golf"
                      ? newData?.[0]?.GolfMarket
                      : teamSportType === "tennis"
                        ? newData?.[0]?.TennisMarket
                        : teamSportType === "baseball"
                          ? newData?.[0]?.BaseballMarket
                          : teamSportType === "icehockey"
                            ? newData?.[0]?.IceHockeyMarket
                            : teamSportType === "boxing"
                              ? newData?.[0]?.BoxingMarket
                              : teamSportType === "mma"
                                ? newData?.[0]?.MMAMarket
                                : teamSportType === "soccer"
                                  ? newData?.[0]?.SoccerMarket
                                  : newData?.[0]?.RLMarket;
          setMarketName(
            SportMarket?.displayName
              ? SportMarket?.displayName
              : SportMarket?.name,
          );
          // let datas = data?.result?.rows?.filter(item => {
          //   return item?.outrights === false;
          // });

          // let Tournaments = [];

          // let TournamentData = data?.result?.rows?.map(obj => {
          //   teamSportType === "cricket"
          //     ? Tournaments.push({
          //         label: obj?.CricketTournament?.name,
          //         value: obj?.CricketTournament?.id
          //       })
          //     : teamSportType === "basketball"
          //     ? Tournaments.push({
          //         label:
          //           obj?.NBATournament?.NBACategory === null
          //             ? obj?.NBATournament?.name
          //             : obj?.NBATournament?.name +
          //               " " +
          //               obj?.NBATournament?.NBACategory?.name,
          //         value: obj?.NBATournament?.id
          //       })
          //     : teamSportType === "americanfootball"
          //     ? Tournaments.push({
          //         label: obj?.AFLTournament?.name,
          //         value: obj?.AFLTournament?.id
          //       })
          //     : teamSportType === "australianrules"
          //     ? Tournaments.push({
          //         label: obj?.ARTournament?.name,
          //         value: obj?.ARTournament?.id
          //       })
          //     : teamSportType === "golf"
          //     ? Tournaments.push({
          //         label: obj?.GolfTournament?.name,
          //         value: obj?.GolfTournament?.id
          //       })
          //     : teamSportType === "tennis"
          //     ? Tournaments.push({
          //         label: obj?.TennisTournament?.name,
          //         value: obj?.TennisTournament?.id
          //       })
          //     : teamSportType === "baseball"
          //     ? Tournaments.push({
          //         label: obj?.BaseballTournament?.name,
          //         value: obj?.BaseballTournament?.id
          //       })
          //     : teamSportType === "icehockey"
          //     ? Tournaments.push({
          //         label: obj?.IceHockeyTournament?.name,
          //         value: obj?.IceHockeyTournament?.id
          //       })
          //     : teamSportType === "boxing"
          //     ? Tournaments.push({
          //         label: obj?.BoxingTournament?.name,
          //         value: obj?.BoxingTournament?.id
          //       })
          //     : teamSportType === "mma"
          //     ? Tournaments.push({
          //         label: obj?.MMATournament?.name,
          //         value: obj?.MMATournament?.id
          //       })
          //     : teamSportType === "soccer"
          //     ? Tournaments.push({
          //         label: obj?.SoccerTournament?.name,
          //         value: obj?.SoccerTournament?.id
          //       })
          //     : Tournaments.push({
          //         label: obj?.RLTournament?.name,
          //         value: obj?.RLTournament?.id
          //       });
          // });
          // let filterTournamentData = _.uniqBy(Tournaments, function(e) {
          //   return e.value;
          // })
          //   ?.filter(a => a?.value !== undefined)
          //   ?.sort((a, b) => {
          //     return a?.label.localeCompare(b?.label);
          //   });
          // let alldatas = filterTournamentData?.unshift({
          //   label: "All Tournaments",
          //   value: 0
          // });
          // setTournamentOptions(filterTournamentData);

          if (selectedseries) {
            let Teams = [];
            let TeamsData = data?.result?.rows?.map((obj) => {
              if (!obj?.outrights) {
                Teams.push({
                  label: obj?.homeTeam?.name,
                  value: obj?.homeTeam?.id,
                });
                Teams.push({
                  label: obj?.awayTeam?.name,
                  value: obj?.awayTeam?.id,
                });
              } else {
                let OutRightTeams =
                  teamSportType === "cricket"
                    ? obj?.CricketOutRightTeams
                    : teamSportType === "basketball"
                      ? obj?.NBAOutRightTeams
                      : teamSportType === "americanfootball"
                        ? obj?.AFLOutRightTeams
                        : teamSportType === "australianrules"
                          ? obj?.AROutRightTeams
                          : teamSportType === "golf"
                            ? obj?.GolfOutRightTeams
                            : teamSportType === "tennis"
                              ? obj?.TennisOutRightTeams
                              : teamSportType === "baseball"
                                ? obj?.BaseballOutRightTeams
                                : teamSportType === "icehockey"
                                  ? obj?.IceHockeyOutRightTeams
                                  : teamSportType === "boxing"
                                    ? obj?.BoxingOutRightTeams
                                    : teamSportType === "mma"
                                      ? obj?.MMAOutRightTeams
                                      : teamSportType === "soccer"
                                        ? obj?.SoccerOutRightTeams
                                        : obj?.RLOutRightTeams;
                let OutRightTeamKey =
                  teamSportType === "cricket"
                    ? "CricketTeam"
                    : teamSportType === "basketball"
                      ? "NBATeam"
                      : teamSportType === "americanfootball"
                        ? "AFLTeam"
                        : teamSportType === "australianrules"
                          ? "ARTeam"
                          : teamSportType === "golf"
                            ? "GolfTeam"
                            : teamSportType === "tennis"
                              ? "TennisTeam"
                              : teamSportType === "baseball"
                                ? "BaseballTeam"
                                : teamSportType === "icehockey"
                                  ? "IceHockeyTeam"
                                  : teamSportType === "boxing"
                                    ? "BoxingTeam"
                                    : teamSportType === "mma"
                                      ? "MMATeam"
                                      : teamSportType === "soccer"
                                        ? "SoccerTeam"
                                        : "RLTeam";

                let TeamData = OutRightTeams?.map((item) => {
                  Teams.push({
                    label: item?.[OutRightTeamKey]?.name,
                    value: item?.[OutRightTeamKey]?.id,
                  });
                });
              }
            });
            let filterTeamData = _.uniqBy(Teams, function (e) {
              return e.value;
            })
              ?.filter((a) => a?.value !== undefined)
              ?.sort((a, b) => {
                return a?.label.localeCompare(b?.label);
              });
            let allteamdatas = filterTeamData?.unshift({
              label: "All Teams",
              value: 0,
            });
            setTeamOptions(filterTeamData);
          }
          // let filteredEvent = data?.result?.rows?.filter(obj => {
          //   let newData =
          //     teamdata?.length > 0
          //       ? teamSportType === "cricket"
          //         ? obj?.CricketBetOffers
          //         : teamSportType === "basketball"
          //         ? obj?.NBABetOffers
          //         : teamSportType === "americanfootball"
          //         ? obj?.AFLBetOffers
          //         : teamSportType === "australianrules"
          //         ? obj?.ARBetOffers
          //         : teamSportType === "golf"
          //         ? obj?.GolfBetOffers
          //         : teamSportType === "tennis"
          //         ? obj?.TennisBetOffers
          //         : teamSportType === "baseball"
          //         ? obj?.BaseballBetOffers
          //         : teamSportType === "icehockey"
          //         ? obj?.IceHockeyBetOffers
          //         : teamSportType === "boxing"
          //         ? obj?.BoxingBetOffers
          //         : teamSportType === "mma"
          //         ? obj?.MMABetOffers
          //         : teamSportType === "soccer"
          //         ? obj?.SoccerBetOffers
          //         : obj?.RLBetOffers
          //       : [];

          //   let SportsOdds =
          //     teamSportType === "cricket"
          //       ? newData?.[0]?.CricketOdds
          //       : teamSportType === "basketball"
          //       ? newData?.[0]?.NBAOdds
          //       : teamSportType === "americanfootball"
          //       ? newData?.[0]?.AFLOdds
          //       : teamSportType === "australianrules"
          //       ? newData?.[0]?.AROdds
          //       : teamSportType === "golf"
          //       ? newData?.[0]?.GolfOdds
          //       : teamSportType === "tennis"
          //       ? newData?.[0]?.TennisOdds
          //       : teamSportType === "baseball"
          //       ? newData?.[0]?.BaseballOdds
          //       : teamSportType === "icehockey"
          //       ? newData?.[0]?.IceHockeyOdds
          //       : teamSportType === "boxing"
          //       ? newData?.[0]?.BoxingOdds
          //       : teamSportType === "mma"
          //       ? newData?.[0]?.MMAOdds
          //       : teamSportType === "soccer"
          //       ? newData?.[0]?.SoccerOdds
          //       : newData?.[0]?.RLOdds;
          //   return SportsOdds?.length > 0;
          // });
          setTimeout(() => {
            setEventList(data?.result?.rows);
            setTimeout(() => {
              setDataFetched(true);
              setIsEventLoading(false);
            }, 1500);
          }, 1500);
        }
      }
    } catch (err) {
      setIsEventLoading(false);
    }
  };

  const handleOnScrollBottomEvents = async (EventPage, marketId, outrights) => {
    if (EventCount !== (EventCount == 1 ? 1 : Math.ceil(EventPage / 20))) {
      const localAuth = fetchFromStorage("auth_token");
      let isLogin = localAuth ? true : false;

      let isPublic = !isLogin ? "public/" : "";
      let isSmartBook = !isLogin ? "" : "smartBook=true";
      try {
        let passApi =
          teamSportType === "cricket"
            ? `${isPublic}crickets/event?startDate=${
                selectedDate === null
                  ? moment().tz(timezone).format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null ? "" : selectedDate
              }&CricketTournamentId=${
                selectedseries === 0 ? "" : selectedseries
              }&teamId=${
                selectedteam === 0 ? "" : selectedteam
              }&timezone=${timezone}&oddCheck=true&marketId=${
                outrights ? "" : marketId
              }&isOuright=${
                outrights ? true : ""
              }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                tabvalue === 3 ? "true" : ""
              }&${isSmartBook}`
            : teamSportType === "rugbyleague"
              ? `${isPublic}rls/event?startDate=${
                  selectedDate === null
                    ? moment().tz(timezone).format("YYYY-MM-DD")
                    : selectedDate
                }&endDate=${
                  selectedDate === null ? "" : selectedDate
                }&RLTournamentId=${
                  selectedseries === 0 ? "" : selectedseries
                }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
                  stepperCount === 0 ? "" : stepperCount
                }&timezone=${timezone}&SportId=12&oddCheck=true&marketId=${
                  outrights ? "" : marketId
                }&isOuright=${
                  outrights ? true : ""
                }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                  tabvalue === 3 ? "true" : ""
                }&${isSmartBook}`
              : teamSportType === "rugbyunion"
                ? `${isPublic}rls/event?startDate=${
                    selectedDate === null
                      ? moment().tz(timezone).format("YYYY-MM-DD")
                      : selectedDate
                  }&endDate=${
                    selectedDate === null ? "" : selectedDate
                  }&RLTournamentId=${
                    selectedseries === 0 ? "" : selectedseries
                  }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
                    stepperCount === 0 ? "" : stepperCount
                  }&timezone=${timezone}&SportId=13&oddCheck=true&marketId=${
                    outrights ? "" : marketId
                  }&isOuright=${
                    outrights ? true : ""
                  }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                    tabvalue === 3 ? "true" : ""
                  }&${isSmartBook}`
                : teamSportType === "basketball"
                  ? `${isPublic}nba/event?startDate=${
                      selectedDate === null
                        ? moment().tz(timezone).format("YYYY-MM-DD")
                        : selectedDate
                    }&endDate=${
                      selectedDate === null ? "" : selectedDate
                    }&NBATournamentId=${
                      selectedseries === 0 ? "" : selectedseries
                    }&teamId=${
                      selectedteam === 0 ? "" : selectedteam
                    }&timezone=${timezone}&oddCheck=true&marketId=${
                      outrights ? "" : marketId
                    }&isOuright=${
                      outrights ? true : ""
                    }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                      tabvalue === 3 ? "true" : ""
                    }&${isSmartBook}`
                  : teamSportType === "americanfootball"
                    ? `${isPublic}afl/event?startDate=${
                        selectedDate === null
                          ? moment().tz(timezone).format("YYYY-MM-DD")
                          : selectedDate
                      }&endDate=${
                        selectedDate === null ? "" : selectedDate
                      }&AFLTournamentId=${
                        selectedseries === 0 ? "" : selectedseries
                      }&teamId=${
                        selectedteam === 0 ? "" : selectedteam
                      }&timezone=${timezone}&oddCheck=true&marketId=${
                        outrights ? "" : marketId
                      }&isOuright=${
                        outrights ? true : ""
                      }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                        tabvalue === 3 ? "true" : ""
                      }&${isSmartBook}`
                    : teamSportType === "australianrules"
                      ? `${isPublic}ar/event?startDate=${
                          selectedDate === null
                            ? moment().tz(timezone).format("YYYY-MM-DD")
                            : selectedDate
                        }&endDate=${
                          selectedDate === null ? "" : selectedDate
                        }&ARTournamentId=${
                          selectedseries === 0 ? "" : selectedseries
                        }&teamId=${
                          selectedteam === 0 ? "" : selectedteam
                        }&timezone=${timezone}&oddCheck=true&marketId=${
                          outrights ? "" : marketId
                        }&isOuright=${
                          outrights ? true : ""
                        }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                          tabvalue === 3 ? "true" : ""
                        }&${isSmartBook}`
                      : teamSportType === "golf"
                        ? `${isPublic}golf/event?startDate=${
                            selectedDate === null
                              ? moment().tz(timezone).format("YYYY-MM-DD")
                              : selectedDate
                          }&endDate=${
                            selectedDate === null ? "" : selectedDate
                          }&GolfTournamentId=${
                            selectedseries === 0 ? "" : selectedseries
                          }&teamId=${
                            selectedteam === 0 ? "" : selectedteam
                          }&timezone=${timezone}&oddCheck=true&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                            tabvalue === 3 ? "true" : ""
                          }&${isSmartBook}`
                        : teamSportType === "tennis"
                          ? `${isPublic}tennis/event?startDate=${
                              selectedDate === null
                                ? moment().tz(timezone).format("YYYY-MM-DD")
                                : selectedDate
                            }&endDate=${
                              selectedDate === null ? "" : selectedDate
                            }&TennisTournamentId=${
                              selectedseries === 0 ? "" : selectedseries
                            }&teamId=${
                              selectedteam === 0 ? "" : selectedteam
                            }&timezone=${timezone}&oddCheck=true&marketId=${
                              outrights ? "" : marketId
                            }&isOuright=${
                              outrights ? true : ""
                            }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                              tabvalue === 3 ? "true" : ""
                            }&${isSmartBook}`
                          : teamSportType === "baseball"
                            ? `${isPublic}baseball/event?startDate=${
                                selectedDate === null
                                  ? moment().tz(timezone).format("YYYY-MM-DD")
                                  : selectedDate
                              }&endDate=${
                                selectedDate === null ? "" : selectedDate
                              }&BaseballTournamentId=${
                                selectedseries === 0 ? "" : selectedseries
                              }&teamId=${
                                selectedteam === 0 ? "" : selectedteam
                              }&timezone=${timezone}&oddCheck=true&marketId=${
                                outrights ? "" : marketId
                              }&isOuright=${
                                outrights ? true : ""
                              }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                                tabvalue === 3 ? "true" : ""
                              }&${isSmartBook}`
                            : teamSportType === "icehockey"
                              ? `${isPublic}icehockey/event?startDate=${
                                  selectedDate === null
                                    ? moment().tz(timezone).format("YYYY-MM-DD")
                                    : selectedDate
                                }&endDate=${
                                  selectedDate === null ? "" : selectedDate
                                }&IceHockeyTournamentId=${
                                  selectedseries === 0 ? "" : selectedseries
                                }&teamId=${
                                  selectedteam === 0 ? "" : selectedteam
                                }&timezone=${timezone}&oddCheck=true&marketId=${
                                  outrights ? "" : marketId
                                }&isOuright=${
                                  outrights ? true : ""
                                }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                                  tabvalue === 3 ? "true" : ""
                                }&${isSmartBook}`
                              : teamSportType === "boxing"
                                ? `${isPublic}boxing/event?startDate=${
                                    selectedDate === null
                                      ? moment()
                                          .tz(timezone)
                                          .format("YYYY-MM-DD")
                                      : selectedDate
                                  }&endDate=${
                                    selectedDate === null ? "" : selectedDate
                                  }&BoxingTournamentId=${
                                    selectedseries === 0 ? "" : selectedseries
                                  }&teamId=${
                                    selectedteam === 0 ? "" : selectedteam
                                  }&timezone=${timezone}&oddCheck=true&marketId=${
                                    outrights ? "" : marketId
                                  }&isOuright=${
                                    outrights ? true : ""
                                  }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                                    tabvalue === 3 ? "true" : ""
                                  }&${isSmartBook}`
                                : teamSportType === "mma"
                                  ? `${isPublic}mma/event?startDate=${
                                      selectedDate === null
                                        ? moment()
                                            .tz(timezone)
                                            .format("YYYY-MM-DD")
                                        : selectedDate
                                    }&endDate=${
                                      selectedDate === null ? "" : selectedDate
                                    }&MMATournamentId=${
                                      selectedseries === 0 ? "" : selectedseries
                                    }&teamId=${
                                      selectedteam === 0 ? "" : selectedteam
                                    }&timezone=${timezone}&oddCheck=true&marketId=${
                                      outrights ? "" : marketId
                                    }&isOuright=${
                                      outrights ? true : ""
                                    }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                                      tabvalue === 3 ? "true" : ""
                                    }&${isSmartBook}`
                                  : teamSportType === "soccer"
                                    ? `${isPublic}soccer/event?startDate=${
                                        selectedDate === null
                                          ? moment()
                                              .tz(timezone)
                                              .format("YYYY-MM-DD")
                                          : selectedDate
                                      }&endDate=${
                                        selectedDate === null
                                          ? ""
                                          : selectedDate
                                      }&SoccerTournamentId=${
                                        selectedseries === 0
                                          ? ""
                                          : selectedseries
                                      }&teamId=${
                                        selectedteam === 0 ? "" : selectedteam
                                      }&timezone=${timezone}&oddCheck=true&marketId=${
                                        outrights ? "" : marketId
                                      }&isOuright=${
                                        outrights ? true : ""
                                      }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                                        tabvalue === 3 ? "true" : ""
                                      }&${isSmartBook}`
                                    : `${isPublic}rls/event?startDate=${
                                        selectedDate === null
                                          ? moment()
                                              .tz(timezone)
                                              .format("YYYY-MM-DD")
                                          : selectedDate
                                      }&endDate=${
                                        selectedDate === null
                                          ? ""
                                          : selectedDate
                                      }&RLTournamentId=${
                                        selectedseries === 0
                                          ? ""
                                          : selectedseries
                                      }&teamId=${selectedteam === 0 ? "" : selectedteam}&round=${
                                        stepperCount === 0 ? "" : stepperCount
                                      }&timezone=${timezone}&SportId=14&oddCheck=true&marketId=${
                                        outrights ? "" : marketId
                                      }&isOuright=${
                                        outrights ? true : ""
                                      }&limit=20&offset=${EventPage}&isAdmin=${showAllFixture}&isHome=false&isGraph=${
                                        tabvalue === 3 ? "true" : ""
                                      }&${isSmartBook}`;
        // /public/crickets/event?startDate=2023-01-17&endDate=2023-01-18&CricketTournamentId=6&teamId=1&awayTeamId=1&homeTeamId=86
        const { status, data } = await axiosInstance.get(passApi);

        if (status === 200) {
          let fullData = [];
          // let filteredEvent = data?.result?.rows?.filter(obj => {
          //   let newData =
          //     teamSportType === "cricket"
          //       ? obj?.CricketBetOffers
          //       : teamSportType === "basketball"
          //       ? obj?.NBABetOffers
          //       : teamSportType === "americanfootball"
          //       ? obj?.AFLBetOffers
          //       : teamSportType === "australianrules"
          //       ? obj?.ARBetOffers
          //       : teamSportType === "golf"
          //       ? obj?.GolfBetOffers
          //       : teamSportType === "tennis"
          //       ? obj?.TennisBetOffers
          //       : teamSportType === "baseball"
          //       ? obj?.BaseballBetOffers
          //       : teamSportType === "icehockey"
          //       ? obj?.IceHockeyBetOffers
          //       : teamSportType === "boxing"
          //       ? obj?.BoxingBetOffers
          //       : teamSportType === "mma"
          //       ? obj?.MMABetOffers
          //       : teamSportType === "soccer"
          //       ? obj?.SoccerBetOffers
          //       : obj?.RLBetOffers;
          //   let SportsOdds =
          //     teamSportType === "cricket"
          //       ? newData?.[0]?.CricketOdds
          //       : teamSportType === "basketball"
          //       ? newData?.[0]?.NBAOdds
          //       : teamSportType === "americanfootball"
          //       ? newData?.[0]?.AFLOdds
          //       : teamSportType === "australianrules"
          //       ? newData?.[0]?.AROdds
          //       : teamSportType === "golf"
          //       ? newData?.[0]?.GolfOdds
          //       : teamSportType === "tennis"
          //       ? newData?.[0]?.TennisOdds
          //       : teamSportType === "baseball"
          //       ? newData?.[0]?.BaseballOdds
          //       : teamSportType === "icehockey"
          //       ? newData?.[0]?.IceHockeyOdds
          //       : teamSportType === "boxing"
          //       ? newData?.[0]?.BoxingOdds
          //       : teamSportType === "mma"
          //       ? newData?.[0]?.MMAOdds
          //       : teamSportType === "soccer"
          //       ? newData?.[0]?.SoccerOdds
          //       : newData?.[0]?.RLOdds;
          //   return SportsOdds?.length > 0;
          // });
          let uniqbydata = _.uniqBy(
            [...eventList, ...data?.result?.rows],
            function (e) {
              return e?.id;
            },
          );
          setTimeout(() => {
            setEventList(uniqbydata);
          }, 1000);
          setEventPage(EventPage);
        }
      } catch (err) {}
    }
  };
  const fetchMatchUpData = async () => {
    setMatchUpLoading(true);
    try {
      let passApi =
        teamSportType === "basketball"
          ? "nba"
          : teamSportType === "americanfootball"
            ? "afl"
            : teamSportType === "baseball"
              ? "baseball"
              : teamSportType === "icehockey"
                ? "icehockey"
                : "";
      let tournamentId =
        teamSportType === "basketball"
          ? "NBATournamentId"
          : teamSportType === "americanfootball"
            ? "AFLTournamentId"
            : teamSportType === "baseball"
              ? "BaseballTournamentId"
              : teamSportType === "icehockey"
                ? "IceHockeyTournamentId"
                : "";

      let Matchups =
        teamSportType === "basketball"
          ? "NBAMatchups"
          : teamSportType === "americanfootball"
            ? "AFLMatchups"
            : teamSportType === "baseball"
              ? "BaseballMatchups"
              : teamSportType === "icehockey"
                ? "IceHockeyMatchups"
                : "";

      const { status, data } = await axiosInstance.get(
        `public/${passApi}/event?startDate=${
          selectedDate === null
            ? moment().tz(timezone).format("YYYY-MM-DD")
            : selectedDate
        }&endDate=${
          selectedDate === null ? "" : selectedDate
        }&${tournamentId}=${
          selectedseries === 0 ? "" : selectedseries
        }&teamId=${
          selectedteam === 0 ? "" : selectedteam
        }&timezone=${timezone}&type=matchup&isAdmin=false&isHome=false`,
      );

      if (status === 200) {
        setMatchUpLoading(false);
        let newData = data?.result?.rows?.filter(
          (item) => item?.[Matchups]?.length > 0,
        );
        setMatchUpData(newData);

        let Teams = [];
        let TeamsData = newData?.map((obj) => {
          Teams.push({
            label: obj?.homeTeam?.name,
            value: obj?.homeTeam?.id,
          });
          Teams.push({
            label: obj?.awayTeam?.name,
            value: obj?.awayTeam?.id,
          });
        });
        let filterTeamData = _.uniqBy(Teams, function (e) {
          return e.value;
        })
          ?.filter((a) => a?.value !== undefined)
          ?.sort((a, b) => {
            return a?.label.localeCompare(b?.label);
          });
        let allteamdatas = filterTeamData?.unshift({
          label: "All Teams",
          value: 0,
        });
        setmatchupTeamOptions(filterTeamData);
      } else {
        setMatchUpLoading(false);
      }
    } catch (err) {
      setMatchUpLoading(false);
    }
  };
  const fetchMatchUpOnDemand = async () => {
    try {
      let passApi =
        teamSportType === "basketball"
          ? "nba"
          : teamSportType === "americanfootball"
            ? "afl"
            : teamSportType === "baseball"
              ? "baseball"
              : teamSportType === "icehockey"
                ? "icehockey"
                : "";
      let SportId =
        teamSportType === "cricket"
          ? 4
          : teamSportType === "rugbyleague"
            ? 12
            : teamSportType === "rugbyunion"
              ? 13
              : teamSportType === "basketball"
                ? 10
                : teamSportType === "americanfootball"
                  ? 15
                  : teamSportType === "australianrules"
                    ? 9
                    : teamSportType === "golf"
                      ? 16
                      : teamSportType === "tennis"
                        ? 7
                        : teamSportType === "baseball"
                          ? 11
                          : teamSportType === "icehockey"
                            ? 17
                            : teamSportType === "boxing"
                              ? 6
                              : teamSportType === "mma"
                                ? 5
                                : teamSportType === "soccer"
                                  ? 8
                                  : 14;
      let payload = {
        date:
          selectedDate === null
            ? moment().tz(timezone).format("YYYY-MM-DD")
            : selectedDate,
        sportId: SportId,
      };

      const { status, data } = await axiosInstance.post(
        `/public/${passApi}/sync/matchUp`,
        payload,
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const fetchDropDowndata = async (
    EventPage,
    outrights,
    IsLive,
    marketId,
    isFixture,
  ) => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;

    let isPublic = !isLogin ? "public/" : "";
    let isSmartBook = !isLogin ? "" : "smartBook=true";

    let passApi =
      teamSportType === "cricket"
        ? `${isPublic}crickets/event?startDate=${
            selectedDate === null
              ? IsLive === "IsLive"
                ? ""
                : IsLive === "Future"
                  ? moment
                      .tz(moment(), timezone)
                      .add(1, "days")
                      .format("YYYY-MM-DD")
                  : moment().tz(timezone).format("YYYY-MM-DD")
              : selectedDate
          }&endDate=${
            selectedDate === null ? "" : selectedDate
          }&timezone=${timezone}&oddCheck=true&marketId=${
            outrights ? "" : marketId ? marketId : 1
          }&isOuright=${outrights ? true : ""}&isAdmin=${
            isFixture !== undefined ? isFixture : showAllFixture
          }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
            IsLive === "reset" ? "" : IsLive === "IsLive" ? "inprogress" : ""
          }&${isSmartBook}`
        : teamSportType === "rugbyleague"
          ? `${isPublic}rls/event?startDate=${
              selectedDate === null
                ? IsLive === "Future"
                  ? moment
                      .tz(moment(), timezone)
                      .add(1, "days")
                      .format("YYYY-MM-DD")
                  : moment().tz(timezone).format("YYYY-MM-DD")
                : selectedDate
            }&endDate=${
              selectedDate === null
                ? IsLive === "reset" || IsLive === "Future"
                  ? ""
                  : IsLive === "IsLive"
                    ? moment().tz(timezone).format("YYYY-MM-DD")
                    : ""
                : selectedDate
            }&timezone=${timezone}&SportId=12&oddCheck=true&marketId=${
              outrights ? "" : marketId ? marketId : 1
            }&isOuright=${outrights ? true : ""}&marketname=h2h&isAdmin=${
              isFixture !== undefined ? isFixture : showAllFixture
            }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
              IsLive === "reset" ? "" : IsLive === "IsLive" ? "inprogress" : ""
            }&${isSmartBook}`
          : teamSportType === "rugbyunion"
            ? `${isPublic}rls/event?startDate=${
                selectedDate === null
                  ? IsLive === "Future"
                    ? moment
                        .tz(moment(), timezone)
                        .add(1, "days")
                        .format("YYYY-MM-DD")
                    : moment().tz(timezone).format("YYYY-MM-DD")
                  : selectedDate
              }&endDate=${
                selectedDate === null
                  ? IsLive === "reset" || IsLive === "Future"
                    ? ""
                    : IsLive === "IsLive"
                      ? moment().tz(timezone).format("YYYY-MM-DD")
                      : ""
                  : selectedDate
              }&timezone=${timezone}&SportId=13&oddCheck=true&marketId=${
                outrights ? "" : marketId ? marketId : 1
              }&isOuright=${outrights ? true : ""}&marketname=h2h&isAdmin=${
                isFixture !== undefined ? isFixture : showAllFixture
              }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                IsLive === "reset"
                  ? ""
                  : IsLive === "IsLive"
                    ? "inprogress"
                    : ""
              }&${isSmartBook}`
            : teamSportType === "basketball"
              ? `${isPublic}nba/event?startDate=${
                  selectedDate === null
                    ? IsLive === "Future"
                      ? moment
                          .tz(moment(), timezone)
                          .add(1, "days")
                          .format("YYYY-MM-DD")
                      : moment().tz(timezone).format("YYYY-MM-DD")
                    : selectedDate
                }&endDate=${
                  selectedDate === null
                    ? IsLive === "reset" || IsLive === "Future"
                      ? ""
                      : IsLive === "IsLive"
                        ? moment().tz(timezone).format("YYYY-MM-DD")
                        : ""
                    : selectedDate
                }&timezone=${timezone}&oddCheck=true&marketId=${
                  outrights ? "" : marketId ? marketId : 1
                }&isOuright=${outrights ? true : ""}&isAdmin=${
                  isFixture !== undefined ? isFixture : showAllFixture
                }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                  IsLive === "reset"
                    ? ""
                    : IsLive === "IsLive"
                      ? "inprogress"
                      : ""
                }&${isSmartBook}`
              : teamSportType === "americanfootball"
                ? `${isPublic}afl/event?startDate=${
                    selectedDate === null
                      ? IsLive === "Future"
                        ? moment
                            .tz(moment(), timezone)
                            .add(1, "days")
                            .format("YYYY-MM-DD")
                        : moment().tz(timezone).format("YYYY-MM-DD")
                      : selectedDate
                  }&endDate=${
                    selectedDate === null
                      ? IsLive === "reset" || IsLive === "Future"
                        ? ""
                        : IsLive === "IsLive"
                          ? moment().tz(timezone).format("YYYY-MM-DD")
                          : ""
                      : selectedDate
                  }&timezone=${timezone}&oddCheck=true&marketId=${
                    outrights ? "" : marketId ? marketId : 1
                  }&isOuright=${outrights ? true : ""}&isAdmin=${
                    isFixture !== undefined ? isFixture : showAllFixture
                  }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                    IsLive === "reset"
                      ? ""
                      : IsLive === "IsLive"
                        ? "inprogress"
                        : ""
                  }&${isSmartBook}`
                : teamSportType === "australianrules"
                  ? `${isPublic}ar/event?startDate=${
                      selectedDate === null
                        ? IsLive === "Future"
                          ? moment
                              .tz(moment(), timezone)
                              .add(1, "days")
                              .format("YYYY-MM-DD")
                          : moment().tz(timezone).format("YYYY-MM-DD")
                        : selectedDate
                    }&endDate=${
                      selectedDate === null
                        ? IsLive === "reset" || IsLive === "Future"
                          ? ""
                          : IsLive === "IsLive"
                            ? moment().tz(timezone).format("YYYY-MM-DD")
                            : ""
                        : selectedDate
                    }&timezone=${timezone}&oddCheck=true&marketId=${
                      outrights ? "" : marketId ? marketId : 1
                    }&isOuright=${outrights ? true : ""}&isAdmin=${
                      isFixture !== undefined ? isFixture : showAllFixture
                    }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                      IsLive === "reset"
                        ? ""
                        : IsLive === "IsLive"
                          ? "inprogress"
                          : ""
                    }&${isSmartBook}`
                  : teamSportType === "golf"
                    ? `${isPublic}golf/event?startDate=${
                        selectedDate === null
                          ? IsLive === "Future"
                            ? moment
                                .tz(moment(), timezone)
                                .add(1, "days")
                                .format("YYYY-MM-DD")
                            : moment().tz(timezone).format("YYYY-MM-DD")
                          : selectedDate
                      }&endDate=${
                        selectedDate === null
                          ? IsLive === "reset" || IsLive === "Future"
                            ? ""
                            : IsLive === "IsLive"
                              ? moment().tz(timezone).format("YYYY-MM-DD")
                              : ""
                          : selectedDate
                      }&timezone=${timezone}&oddCheck=true&marketId=${
                        outrights ? "" : marketId ? marketId : 1
                      }&isOuright=${outrights ? true : ""}&isAdmin=${
                        isFixture !== undefined ? isFixture : showAllFixture
                      }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                        IsLive === "reset"
                          ? ""
                          : IsLive === "IsLive"
                            ? "inprogress"
                            : ""
                      }&${isSmartBook}`
                    : teamSportType === "tennis"
                      ? `${isPublic}tennis/event?startDate=${
                          selectedDate === null
                            ? IsLive === "Future"
                              ? moment
                                  .tz(moment(), timezone)
                                  .add(1, "days")
                                  .format("YYYY-MM-DD")
                              : moment().tz(timezone).format("YYYY-MM-DD")
                            : selectedDate
                        }&endDate=${
                          selectedDate === null
                            ? IsLive === "reset" || IsLive === "Future"
                              ? ""
                              : IsLive === "IsLive"
                                ? moment().tz(timezone).format("YYYY-MM-DD")
                                : ""
                            : selectedDate
                        }&timezone=${timezone}&oddCheck=true&marketId=${
                          outrights ? "" : marketId ? marketId : 1
                        }&isOuright=${outrights ? true : ""}&isAdmin=${
                          isFixture !== undefined ? isFixture : showAllFixture
                        }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                          IsLive === "reset"
                            ? ""
                            : IsLive === "IsLive"
                              ? "inprogress"
                              : ""
                        }&${isSmartBook}`
                      : teamSportType === "baseball"
                        ? `${isPublic}baseball/event?startDate=${
                            selectedDate === null
                              ? IsLive === "Future"
                                ? moment
                                    .tz(moment(), timezone)
                                    .add(1, "days")
                                    .format("YYYY-MM-DD")
                                : moment().tz(timezone).format("YYYY-MM-DD")
                              : selectedDate
                          }&endDate=${
                            selectedDate === null
                              ? IsLive === "reset" || IsLive === "Future"
                                ? ""
                                : IsLive === "IsLive"
                                  ? moment().tz(timezone).format("YYYY-MM-DD")
                                  : ""
                              : selectedDate
                          }&timezone=${timezone}&oddCheck=true&marketId=${
                            outrights ? "" : marketId ? marketId : 1
                          }&isOuright=${outrights ? true : ""}&isAdmin=${
                            isFixture !== undefined ? isFixture : showAllFixture
                          }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                            IsLive === "reset"
                              ? ""
                              : IsLive === "IsLive"
                                ? "inprogress"
                                : ""
                          }&${isSmartBook}`
                        : teamSportType === "icehockey"
                          ? `${isPublic}icehockey/event?startDate=${
                              selectedDate === null
                                ? IsLive === "Future"
                                  ? moment
                                      .tz(moment(), timezone)
                                      .add(1, "days")
                                      .format("YYYY-MM-DD")
                                  : moment().tz(timezone).format("YYYY-MM-DD")
                                : selectedDate
                            }&endDate=${
                              selectedDate === null
                                ? IsLive === "reset" || IsLive === "Future"
                                  ? ""
                                  : IsLive === "IsLive"
                                    ? moment().tz(timezone).format("YYYY-MM-DD")
                                    : ""
                                : selectedDate
                            }&timezone=${timezone}&oddCheck=true&marketId=${
                              outrights ? "" : marketId ? marketId : 1
                            }&isOuright=${outrights ? true : ""}&isAdmin=${
                              isFixture !== undefined
                                ? isFixture
                                : showAllFixture
                            }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                              IsLive === "reset"
                                ? ""
                                : IsLive === "IsLive"
                                  ? "inprogress"
                                  : ""
                            }&${isSmartBook}`
                          : teamSportType === "boxing"
                            ? `${isPublic}boxing/event?startDate=${
                                selectedDate === null
                                  ? IsLive === "Future"
                                    ? moment
                                        .tz(moment(), timezone)
                                        .add(1, "days")
                                        .format("YYYY-MM-DD")
                                    : moment().tz(timezone).format("YYYY-MM-DD")
                                  : selectedDate
                              }&endDate=${
                                selectedDate === null
                                  ? IsLive === "reset" || IsLive === "Future"
                                    ? ""
                                    : IsLive === "IsLive"
                                      ? moment()
                                          .tz(timezone)
                                          .format("YYYY-MM-DD")
                                      : ""
                                  : selectedDate
                              }&timezone=${timezone}&oddCheck=true&marketId=${
                                outrights ? "" : marketId ? marketId : 1
                              }&isOuright=${outrights ? true : ""}&isAdmin=${
                                isFixture !== undefined
                                  ? isFixture
                                  : showAllFixture
                              }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                                IsLive === "reset"
                                  ? ""
                                  : IsLive === "IsLive"
                                    ? "inprogress"
                                    : ""
                              }&${isSmartBook}`
                            : teamSportType === "mma"
                              ? `${isPublic}mma/event?startDate=${
                                  selectedDate === null
                                    ? IsLive === "Future"
                                      ? moment
                                          .tz(moment(), timezone)
                                          .add(1, "days")
                                          .format("YYYY-MM-DD")
                                      : moment()
                                          .tz(timezone)
                                          .format("YYYY-MM-DD")
                                    : selectedDate
                                }&endDate=${
                                  selectedDate === null
                                    ? IsLive === "reset" || IsLive === "Future"
                                      ? ""
                                      : IsLive === "IsLive"
                                        ? moment()
                                            .tz(timezone)
                                            .format("YYYY-MM-DD")
                                        : ""
                                    : selectedDate
                                }&timezone=${timezone}&oddCheck=true&marketId=${
                                  outrights ? "" : marketId ? marketId : 1
                                }&isOuright=${outrights ? true : ""}&isAdmin=${
                                  isFixture !== undefined
                                    ? isFixture
                                    : showAllFixture
                                }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                                  IsLive === "reset"
                                    ? ""
                                    : IsLive === "IsLive"
                                      ? "inprogress"
                                      : ""
                                }&${isSmartBook}`
                              : teamSportType === "soccer"
                                ? `${isPublic}soccer/event?startDate=${
                                    selectedDate === null
                                      ? IsLive === "Future"
                                        ? moment
                                            .tz(moment(), timezone)
                                            .add(1, "days")
                                            .format("YYYY-MM-DD")
                                        : moment()
                                            .tz(timezone)
                                            .format("YYYY-MM-DD")
                                      : selectedDate
                                  }&endDate=${
                                    selectedDate === null
                                      ? IsLive === "reset" ||
                                        IsLive === "Future"
                                        ? ""
                                        : IsLive === "IsLive"
                                          ? moment()
                                              .tz(timezone)
                                              .format("YYYY-MM-DD")
                                          : ""
                                      : selectedDate
                                  }&timezone=${timezone}&oddCheck=true&marketId=${
                                    outrights ? "" : marketId ? marketId : 1
                                  }&isOuright=${outrights ? true : ""}&isAdmin=${
                                    isFixture !== undefined
                                      ? isFixture
                                      : showAllFixture
                                  }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                                    IsLive === "reset"
                                      ? ""
                                      : IsLive === "IsLive"
                                        ? "inprogress"
                                        : ""
                                  }&${isSmartBook}`
                                : `${isPublic}rls/event?startDate=${
                                    selectedDate === null
                                      ? IsLive === "Future"
                                        ? moment
                                            .tz(moment(), timezone)
                                            .add(1, "days")
                                            .format("YYYY-MM-DD")
                                        : moment()
                                            .tz(timezone)
                                            .format("YYYY-MM-DD")
                                      : selectedDate
                                  }&endDate=${
                                    selectedDate === null
                                      ? IsLive === "reset" ||
                                        IsLive === "Future"
                                        ? ""
                                        : IsLive === "IsLive"
                                          ? moment()
                                              .tz(timezone)
                                              .format("YYYY-MM-DD")
                                          : ""
                                      : selectedDate
                                  }&timezone=${timezone}&SportId=14&oddCheck=true&marketId=${
                                    outrights ? "" : marketId ? marketId : 1
                                  }&isOuright=${outrights ? true : ""}&isAdmin=${
                                    isFixture !== undefined
                                      ? isFixture
                                      : showAllFixture
                                  }&isHome=false&isGraph=${tabvalue === 3 ? "true" : ""}&status=${
                                    IsLive === "reset"
                                      ? ""
                                      : IsLive === "IsLive"
                                        ? "inprogress"
                                        : ""
                                  }&${isSmartBook}`;
    const { status, data } = await axiosInstance.get(passApi);

    if (status === 200) {
      let Tournaments = [];
      // let Teams = [];
      let TournamentData = data?.result?.rows?.map((obj) => {
        teamSportType === "cricket"
          ? Tournaments.push({
              label: obj?.CricketTournament?.name,
              value: obj?.CricketTournament?.id,
            })
          : teamSportType === "basketball"
            ? Tournaments.push({
                label:
                  obj?.NBATournament?.NBACategory === null
                    ? obj?.NBATournament?.name
                    : obj?.NBATournament?.name +
                      " " +
                      obj?.NBATournament?.NBACategory?.name,
                value: obj?.NBATournament?.id,
              })
            : teamSportType === "americanfootball"
              ? Tournaments.push({
                  label: obj?.AFLTournament?.name,
                  value: obj?.AFLTournament?.id,
                })
              : teamSportType === "australianrules"
                ? Tournaments.push({
                    label: obj?.ARTournament?.name,
                    value: obj?.ARTournament?.id,
                  })
                : teamSportType === "golf"
                  ? Tournaments.push({
                      label: obj?.GolfTournament?.name,
                      value: obj?.GolfTournament?.id,
                    })
                  : teamSportType === "tennis"
                    ? Tournaments.push({
                        label: obj?.TennisTournament?.name,
                        value: obj?.TennisTournament?.id,
                      })
                    : teamSportType === "baseball"
                      ? Tournaments.push({
                          label: obj?.BaseballTournament?.name,
                          value: obj?.BaseballTournament?.id,
                        })
                      : teamSportType === "icehockey"
                        ? Tournaments.push({
                            label: obj?.IceHockeyTournament?.name,
                            value: obj?.IceHockeyTournament?.id,
                          })
                        : teamSportType === "boxing"
                          ? Tournaments.push({
                              label: obj?.BoxingTournament?.name,
                              value: obj?.BoxingTournament?.id,
                            })
                          : teamSportType === "mma"
                            ? Tournaments.push({
                                label: obj?.MMATournament?.name,
                                value: obj?.MMATournament?.id,
                              })
                            : teamSportType === "soccer"
                              ? Tournaments.push({
                                  label: obj?.SoccerTournament?.name,
                                  value: obj?.SoccerTournament?.id,
                                })
                              : Tournaments.push({
                                  label: obj?.RLTournament?.name,
                                  value: obj?.RLTournament?.id,
                                });
      });
      let filterTournamentData = _.uniqBy(Tournaments, function (e) {
        return e.value;
      })
        ?.filter((a) => a?.value !== undefined)
        ?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });
      let alldatas = filterTournamentData?.unshift({
        label: "All Tournaments",
        value: 0,
      });
      setTournamentOptions(filterTournamentData);

      let Teams = [];
      let TeamsData = data?.result?.rows?.map((obj) => {
        if (!obj?.outrights) {
          Teams.push({
            label: obj?.homeTeam?.name,
            value: obj?.homeTeam?.id,
          });
          Teams.push({
            label: obj?.awayTeam?.name,
            value: obj?.awayTeam?.id,
          });
        } else {
          let OutRightTeams =
            teamSportType === "cricket"
              ? obj?.CricketOutRightTeams
              : teamSportType === "basketball"
                ? obj?.NBAOutRightTeams
                : teamSportType === "americanfootball"
                  ? obj?.AFLOutRightTeams
                  : teamSportType === "australianrules"
                    ? obj?.AROutRightTeams
                    : teamSportType === "golf"
                      ? obj?.GolfOutRightTeams
                      : teamSportType === "tennis"
                        ? obj?.TennisOutRightTeams
                        : teamSportType === "baseball"
                          ? obj?.BaseballOutRightTeams
                          : teamSportType === "icehockey"
                            ? obj?.IceHockeyOutRightTeams
                            : teamSportType === "boxing"
                              ? obj?.BoxingOutRightTeams
                              : teamSportType === "mma"
                                ? obj?.MMAOutRightTeams
                                : teamSportType === "soccer"
                                  ? obj?.SoccerOutRightTeams
                                  : obj?.RLOutRightTeams;
          let OutRightTeamKey =
            teamSportType === "cricket"
              ? "CricketTeam"
              : teamSportType === "basketball"
                ? "NBATeam"
                : teamSportType === "americanfootball"
                  ? "AFLTeam"
                  : teamSportType === "australianrules"
                    ? "ARTeam"
                    : teamSportType === "golf"
                      ? "GolfTeam"
                      : teamSportType === "tennis"
                        ? "TennisTeam"
                        : teamSportType === "baseball"
                          ? "BaseballTeam"
                          : teamSportType === "icehockey"
                            ? "IceHockeyTeam"
                            : teamSportType === "boxing"
                              ? "BoxingTeam"
                              : teamSportType === "mma"
                                ? "MMATeam"
                                : teamSportType === "soccer"
                                  ? "SoccerTeam"
                                  : "RLTeam";

          let TeamData = OutRightTeams?.map((item) => {
            Teams.push({
              label: item?.[OutRightTeamKey]?.name,
              value: item?.[OutRightTeamKey]?.id,
            });
          });
        }
      });
      let filterTeamData = _.uniqBy(Teams, function (e) {
        return e.value;
      })
        ?.filter((a) => a?.value !== undefined)
        ?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });
      let allteamdatas = filterTeamData?.unshift({
        label: "All Teams",
        value: 0,
      });
      setTeamOptions(filterTeamData);
    }
  };

  const fetchmatchupDropDownData = async (EventPage) => {
    let passSport =
      teamSportType === "basketball"
        ? "nba"
        : teamSportType === "americanfootball"
          ? "afl"
          : teamSportType === "baseball"
            ? "baseball"
            : teamSportType === "icehockey"
              ? "icehockey"
              : "";

    let Matchups =
      teamSportType === "basketball"
        ? "NBAMatchups"
        : teamSportType === "americanfootball"
          ? "AFLMatchups"
          : teamSportType === "baseball"
            ? "BaseballMatchups"
            : teamSportType === "icehockey"
              ? "IceHockeyMatchups"
              : "";
    let SportTournament =
      teamSportType === "basketball"
        ? "NBATournament"
        : teamSportType === "americanfootball"
          ? "AFLTournament"
          : teamSportType === "baseball"
            ? "BaseballTournament"
            : teamSportType === "icehockey"
              ? "IceHockeyTournament"
              : "";

    let passApi = `public/${passSport}/event?startDate=${
      selectedDate === null
        ? moment().tz(timezone).format("YYYY-MM-DD")
        : selectedDate
    }&endDate=${
      selectedDate === null ? "" : selectedDate
    }&timezone=${timezone}&type=matchup&isAdmin=false&isHome=false`;

    const { status, data } = await axiosInstance.get(passApi);

    if (status === 200) {
      let newData = data?.result?.rows?.filter(
        (item) => item?.[Matchups]?.length > 0,
      );
      let Tournaments = [];
      // let Teams = [];

      let TournamentData = newData?.map((obj) => {
        teamSportType === "basketball"
          ? Tournaments.push({
              label:
                obj?.NBATournament?.NBACategory === null
                  ? obj?.NBATournament?.name
                  : obj?.NBATournament?.name +
                    " " +
                    obj?.NBATournament?.NBACategory?.name,
              value: obj?.NBATournament?.id,
            })
          : Tournaments.push({
              label: obj?.[SportTournament]?.name,
              value: obj?.[SportTournament]?.id,
            });
      });
      let filterTournamentData = _.uniqBy(Tournaments, function (e) {
        return e.value;
      })
        ?.filter((a) => a?.value !== undefined)
        ?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });
      let alldatas = filterTournamentData?.unshift({
        label: "All Tournaments",
        value: 0,
      });
      setmatchupTournamentOptions(filterTournamentData);

      // let TeamsData = newData?.map(obj => {
      //   Teams.push({
      //     label: obj?.homeTeam?.name,
      //     value: obj?.homeTeam?.id
      //   });
      //   Teams.push({
      //     label: obj?.awayTeam?.name,
      //     value: obj?.awayTeam?.id
      //   });
      // });
      // let filterTeamData = _.uniqBy(Teams, function(e) {
      //   return e.value;
      // })
      //   ?.filter(a => a?.value !== undefined)
      //   ?.sort((a, b) => {
      //     return a?.label.localeCompare(b?.label);
      //   });
      // let allteamdatas = filterTeamData?.unshift({
      //   label: "All Teams",
      //   value: 0
      // });
      // setmatchupTeamOptions(filterTeamData);
    }
  };

  useEffect(() => {
    // setselectedMarket(1);
    setSeeAll(params?.seeall);
    if (params?.oddstype === "odds" && params?.seeall === "true") {
      // fetchIndividualTeamdata(selectedOption);
      // fetchTableHeading();
      fetchLatestOdds();
      fetchIndividualMarket();
    } else {
      setindividualTeamData({});
    }
  }, [params?.id, params?.seeall]);

  const fetchIndividualTeamdata = async (marketId) => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;

    let isPublic = !isLogin ? "public/" : "";
    let isSmartBook = !isLogin ? "" : "smartBook=true";
    try {
      setIsIndividualEventLoading(true);
      const passApi =
        teamSportType === "cricket"
          ? `${isPublic}crickets/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
          : teamSportType === "rugbyleague"
            ? `${isPublic}rls/event/${params?.id}?SportId=12&marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
            : teamSportType === "rugbyunion"
              ? `${isPublic}rls/event/${params?.id}?SportId=13&marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
              : teamSportType === "basketball"
                ? `${isPublic}nba/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
                : teamSportType === "americanfootball"
                  ? `${isPublic}afl/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
                  : teamSportType === "australianrules"
                    ? `${isPublic}ar/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
                    : teamSportType === "golf"
                      ? `${isPublic}golf/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
                      : teamSportType === "tennis"
                        ? `${isPublic}tennis/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
                        : teamSportType === "baseball"
                          ? `${isPublic}baseball/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
                          : teamSportType === "icehockey"
                            ? `${isPublic}icehockey/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
                            : teamSportType === "boxing"
                              ? `${isPublic}boxing/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
                              : teamSportType === "mma"
                                ? `${isPublic}mma/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
                                : teamSportType === "soccer"
                                  ? `${isPublic}soccer/event/${params?.id}?marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`
                                  : `${isPublic}rls/event/${params?.id}?SportId=14&marketId=${marketId}&isAdmin=false&isHome=false&${isSmartBook}`;
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        let fullData = [];
        let teamdata = [data?.result];
        let datas = teamdata?.map((item) => {
          let newData =
            teamdata?.length > 0
              ? teamSportType === "cricket"
                ? teamdata?.[0]?.CricketBetOffers
                : teamSportType === "basketball"
                  ? teamdata?.[0]?.NBABetOffers
                  : teamSportType === "americanfootball"
                    ? teamdata?.[0]?.AFLBetOffers
                    : teamSportType === "australianrules"
                      ? teamdata?.[0]?.ARBetOffers
                      : teamSportType === "golf"
                        ? teamdata?.[0]?.GolfBetOffers
                        : teamSportType === "tennis"
                          ? teamdata?.[0]?.TennisBetOffers
                          : teamSportType === "baseball"
                            ? teamdata?.[0]?.BaseballBetOffers
                            : teamSportType === "icehockey"
                              ? teamdata?.[0]?.IceHockeyBetOffers
                              : teamSportType === "boxing"
                                ? teamdata?.[0]?.BoxingBetOffers
                                : teamSportType === "mma"
                                  ? teamdata?.[0]?.MMABetOffers
                                  : teamSportType === "soccer"
                                    ? teamdata?.[0]?.SoccerBetOffers
                                    : teamdata?.[0]?.RLBetOffers
              : [];

          let SportsOdds =
            teamSportType === "cricket"
              ? newData?.[0]?.CricketOdds
              : teamSportType === "basketball"
                ? newData?.[0]?.NBAOdds
                : teamSportType === "americanfootball"
                  ? newData?.[0]?.AFLOdds
                  : teamSportType === "australianrules"
                    ? newData?.[0]?.AROdds
                    : teamSportType === "golf"
                      ? newData?.[0]?.GolfOdds
                      : teamSportType === "tennis"
                        ? newData?.[0]?.TennisOdds
                        : teamSportType === "baseball"
                          ? newData?.[0]?.BaseballOdds
                          : teamSportType === "icehockey"
                            ? newData?.[0]?.IceHockeyOdds
                            : teamSportType === "boxing"
                              ? newData?.[0]?.BoxingOdds
                              : teamSportType === "mma"
                                ? newData?.[0]?.MMAOdds
                                : teamSportType === "soccer"
                                  ? newData?.[0]?.SoccerOdds
                                  : newData?.[0]?.RLOdds;

          let SportMarket =
            teamSportType === "cricket"
              ? newData?.[0]?.CricketMarket
              : teamSportType === "basketball"
                ? newData?.[0]?.NBAMarket
                : teamSportType === "americanfootball"
                  ? newData?.[0]?.AFLMarket
                  : teamSportType === "australianrules"
                    ? newData?.[0]?.ARMarket
                    : teamSportType === "golf"
                      ? newData?.[0]?.GolfMarket
                      : teamSportType === "tennis"
                        ? newData?.[0]?.TennisMarket
                        : teamSportType === "baseball"
                          ? newData?.[0]?.BaseballMarket
                          : teamSportType === "icehockey"
                            ? newData?.[0]?.IceHockeyMarket
                            : teamSportType === "boxing"
                              ? newData?.[0]?.BoxingMarket
                              : teamSportType === "mma"
                                ? newData?.[0]?.MMAMarket
                                : teamSportType === "soccer"
                                  ? newData?.[0]?.SoccerMarket
                                  : newData?.[0]?.RLMarket;
          setMarketName(
            SportMarket?.displayName
              ? SportMarket?.displayName
              : SportMarket?.name,
          );
          setMarketType(SportMarket?.type);

          const teamKey =
            teamSportType === "cricket"
              ? "CricketTeamId"
              : teamSportType === "basketball"
                ? "NBATeamId"
                : teamSportType === "americanfootball"
                  ? "AFLTeamId"
                  : teamSportType === "australianrules"
                    ? "ARTeamId"
                    : teamSportType === "golf"
                      ? "GolfTeamId"
                      : teamSportType === "tennis"
                        ? "TennisTeamId"
                        : teamSportType === "baseball"
                          ? "BaseballTeamId"
                          : teamSportType === "icehockey"
                            ? "IceHockeyTeamId"
                            : teamSportType === "boxing"
                              ? "BoxingTeamId"
                              : teamSportType === "mma"
                                ? "MMATeamId"
                                : teamSportType === "soccer"
                                  ? "SoccerTeamId"
                                  : "RLTeamId";

          const isTeamdata = SportsOdds?.some(
            (item) => item?.[teamKey] !== null,
          );

          const LabelName =
            teamSportType === "cricket"
              ? SportsOdds?.[0]?.CricketOddLabel?.displayName
                ? SportsOdds?.[0]?.CricketOddLabel?.displayName
                : SportsOdds?.[0]?.CricketOddLabel?.name
              : teamSportType === "basketball"
                ? SportsOdds?.[0]?.NBAOddLabel?.displayName
                  ? SportsOdds?.[0]?.NBAOddLabel?.displayName
                  : SportsOdds?.[0]?.NBAOddLabel?.name
                : teamSportType === "americanfootball"
                  ? SportsOdds?.[0]?.AFLOddLabel?.displayName
                    ? SportsOdds?.[0]?.AFLOddLabel?.displayName
                    : SportsOdds?.[0]?.AFLOddLabel?.name
                  : teamSportType === "australianrules"
                    ? SportsOdds?.[0]?.AROddLabel?.displayName
                      ? SportsOdds?.[0]?.AROddLabel?.displayName
                      : SportsOdds?.[0]?.AROddLabel?.name
                    : teamSportType === "golf"
                      ? SportsOdds?.[0]?.GolfOddLabel?.displayName
                        ? SportsOdds?.[0]?.GolfOddLabel?.displayName
                        : SportsOdds?.[0]?.GolfOddLabel?.name
                      : teamSportType === "tennis"
                        ? SportsOdds?.[0]?.TennisOddLabel?.displayName
                          ? SportsOdds?.[0]?.TennisOddLabel?.displayName
                          : SportsOdds?.[0]?.TennisOddLabel?.name
                        : teamSportType === "baseball"
                          ? SportsOdds?.[0]?.BaseballOddLabel?.displayName
                            ? SportsOdds?.[0]?.BaseballOddLabel?.displayName
                            : SportsOdds?.[0]?.BaseballOddLabel?.name
                          : teamSportType === "icehockey"
                            ? SportsOdds?.[0]?.IceHockeyOddLabel?.displayName
                              ? SportsOdds?.[0]?.IceHockeyOddLabel?.displayName
                              : SportsOdds?.[0]?.IceHockeyOddLabel?.name
                            : teamSportType === "boxing"
                              ? SportsOdds?.[0]?.BoxingOddLabel?.displayName
                                ? SportsOdds?.[0]?.BoxingOddLabel?.displayName
                                : SportsOdds?.[0]?.BoxingOddLabel?.name
                              : teamSportType === "mma"
                                ? SportsOdds?.[0]?.MMAOddLabel?.displayName
                                  ? SportsOdds?.[0]?.MMAOddLabel?.displayName
                                  : SportsOdds?.[0]?.MMAOddLabel?.name
                                : teamSportType === "soccer"
                                  ? SportsOdds?.[0]?.SoccerOddLabel?.displayName
                                    ? SportsOdds?.[0]?.SoccerOddLabel
                                        ?.displayName
                                    : SportsOdds?.[0]?.SoccerOddLabel?.name
                                  : SportsOdds?.[0]?.RLOddLabel?.displayName
                                    ? SportsOdds?.[0]?.RLOddLabel?.displayName
                                    : SportsOdds?.[0]?.RLOddLabel?.name;
          const name = LabelName ? LabelName : SportsOdds?.[0]?.label;
          setIsMultipleTeam(
            SportsOdds
              ? !isTeamdata &&
                  !(
                    name?.toLowerCase()?.includes("over") ||
                    name?.toLowerCase()?.includes("under") ||
                    name?.toLowerCase()?.includes("totalover") ||
                    name?.toLowerCase()?.includes("totalunder") ||
                    name?.toLowerCase()?.includes("total")
                  )
              : false,
          );
          // setIsMultipleTeam(
          //   SportMarket?.type
          //     ? SportMarket?.type === "team"
          //       ? false
          //       : true
          //     : false
          // );
          // handleRemoveSeeAllOddsColumn(newData);
          // setoddsApiData(newData);
          let Bookkeeper = [];
          let BookkeeperList = SportsOdds?.map((element) => {
            return Bookkeeper?.push(element?.BookKeeperId);
          });
          fetchTableHeading([...new Set(Bookkeeper)]);

          // item.homeTeamOdds =
          //   SportsOdds?.length > 0
          //     ? SportsOdds?.filter(homeTeam => {
          //         return teamSportType === "cricket"
          //           ? homeTeam?.CricketTeamId == item?.homeTeamId
          //           : teamSportType === "basketball"
          //           ? homeTeam?.NBATeamId == item?.homeTeamId
          //           : teamSportType === "americanfootball"
          //           ? homeTeam?.AFLTeamId == item?.homeTeamId
          //           : teamSportType === "australianrules"
          //           ? homeTeam?.ARTeamId == item?.homeTeamId
          //           : teamSportType === "golf"
          //           ? homeTeam?.GolfTeamId == item?.homeTeamId
          //           : teamSportType === "tennis"
          //           ? homeTeam?.TennisTeamId == item?.homeTeamId
          //           : teamSportType === "baseball"
          //           ? homeTeam?.BaseballTeamId == item?.homeTeamId
          //           : teamSportType === "icehockey"
          //           ? homeTeam?.IceHockeyTeamId == item?.homeTeamId
          //           : teamSportType === "boxing"
          //           ? homeTeam?.BoxingTeamId == item?.homeTeamId
          //           : teamSportType === "mma"
          //           ? homeTeam?.MMATeamId == item?.homeTeamId
          //           : teamSportType === "soccer"
          //           ? homeTeam?.SoccerTeamId == item?.homeTeamId
          //           : homeTeam?.RLTeamId == item?.homeTeamId;
          //       })
          //     : [];
          // item.awayTeamOdds =
          //   SportsOdds?.length > 0
          //     ? SportsOdds?.filter(awayTeam => {
          //         return teamSportType === "cricket"
          //           ? awayTeam?.CricketTeamId == item?.awayTeamId
          //           : teamSportType === "basketball"
          //           ? awayTeam?.NBATeamId == item?.awayTeamId
          //           : teamSportType === "americanfootball"
          //           ? awayTeam?.AFLTeamId == item?.awayTeamId
          //           : teamSportType === "australianrules"
          //           ? awayTeam?.ARTeamId == item?.awayTeamId
          //           : teamSportType === "golf"
          //           ? awayTeam?.GolfTeamId == item?.awayTeamId
          //           : teamSportType === "tennis"
          //           ? awayTeam?.TennisTeamId == item?.awayTeamId
          //           : teamSportType === "baseball"
          //           ? awayTeam?.BaseballTeamId == item?.awayTeamId
          //           : teamSportType === "icehockey"
          //           ? awayTeam?.IceHockeyTeamId == item?.awayTeamId
          //           : teamSportType === "boxing"
          //           ? awayTeam?.BoxingTeamId == item?.awayTeamId
          //           : teamSportType === "mma"
          //           ? awayTeam?.MMATeamId == item?.awayTeamId
          //           : teamSportType === "soccer"
          //           ? awayTeam?.SoccerTeamId == item?.awayTeamId
          //           : awayTeam?.RLTeamId == item?.awayTeamId;
          //       })
          //     : [];
          setindividualTeamData(item);
          fullData.push(item);
          setTimeout(() => {
            setIsIndividualEventLoading(false);
          }, 4000);
        });
      }
    } catch {
      setIsIndividualEventLoading(false);
    }
  };

  const fetchLatestOdds = async () => {
    try {
      let SportsType =
        teamSportType === "cricket"
          ? `crickets`
          : teamSportType === "rugbyleague"
            ? `rls`
            : teamSportType === "rugbyunion"
              ? `rls`
              : teamSportType === "basketball"
                ? `nba`
                : teamSportType === "americanfootball"
                  ? `afl`
                  : teamSportType === "australianrules"
                    ? `ar`
                    : teamSportType === "golf"
                      ? `golf`
                      : teamSportType === "tennis"
                        ? `tennis`
                        : teamSportType === "baseball"
                          ? `baseball`
                          : teamSportType === "icehockey"
                            ? `icehockey`
                            : teamSportType === "boxing"
                              ? `boxing`
                              : teamSportType === "mma"
                                ? `mma`
                                : teamSportType === "soccer"
                                  ? `soccer`
                                  : `rls`;
      let SportId =
        teamSportType === "cricket"
          ? 4
          : teamSportType === "rugbyleague"
            ? 12
            : teamSportType === "rugbyunion"
              ? 13
              : teamSportType === "basketball"
                ? 10
                : teamSportType === "americanfootball"
                  ? 15
                  : teamSportType === "australianrules"
                    ? 9
                    : teamSportType === "golf"
                      ? 16
                      : teamSportType === "tennis"
                        ? 7
                        : teamSportType === "baseball"
                          ? 11
                          : teamSportType === "icehockey"
                            ? 17
                            : teamSportType === "boxing"
                              ? 6
                              : teamSportType === "mma"
                                ? 5
                                : teamSportType === "soccer"
                                  ? 8
                                  : 14;
      const { status, data } = await axiosInstance.get(
        `sync/${SportsType}/oddOnDemand/${params?.id}?SportId=${SportId}`,
      );
      if (status === 200) {
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const fetchDayName = (date) => {
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    return dayName;
  };
  const scrollToTop = () => {
    document.getElementById("inner-content").scrollIntoView();
  };
  const handleSeeAll = (item, e) => {
    e.stopPropagation();
    setSeeAll("true");
    navigate(`/teamsports/${params?.type}/odds/${item?.id}/true`);
    // setindividualTeamData(item);
    scrollToTop();
  };

  const handleEventBoxClick = (item) => {
    if (
      teamSportType === "cricket" ||
      teamSportType === "soccer" ||
      teamSportType === "rugbyleague"
    ) {
      const sportType =
        teamSportType === "cricket"
          ? "Cricket"
          : teamSportType === "soccer"
            ? "Soccer"
            : "RL";
      const tournament = item?.[`${sportType}Tournament`];
      const eventSlug = item?.eventName
        ? item.eventName.trim().toLowerCase().replaceAll(" ", "-")
        : "event";

      if (handleRestrictedUser()) {
        const TournamentName =
          teamSportType === "rugbyleague"
            ? item?.RLTournament?.name
            : tournament?.Scd;
        const tournamentSlug = TournamentName
          ? TournamentName.trim().toLowerCase().replaceAll(" ", "-")
          : "tournament";

        const route = `/teamsports/${
          params?.type
        }/${tournamentSlug}/${eventSlug}/info/${item?.SportId}/${
          item?.[`${sportType}TournamentId`]
        }/${item?.id}`;
        navigate(route);
      } else {
        dispatch(fetchRestrictedRoute(true));
        localStorage.setItem("prev_authroute", window.location.pathname);
      }
    }
  };
  const handleSeeBest = () => {
    setSeeAll("false");
    navigate(`/teamsports/${params?.type}/odds/0/false`);
    setindividualTeamData({});
    fetchAllEvents(
      0,
      selectedOption,
      selectedseries,
      selectedteam,
      selectedDate,
    );
    setEventPage(0);
    // setSelectedOption(1);
  };
  const fetchCurrentBestOdds = (data, type, team, teamid, Isscore) => {
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : teamSportType === "americanfootball"
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : teamSportType === "australianrules"
              ? data?.ARBetOffers?.[0]?.AROdds
              : teamSportType === "golf"
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : teamSportType === "tennis"
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : teamSportType === "baseball"
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : teamSportType === "icehockey"
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : teamSportType === "boxing"
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : teamSportType === "mma"
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : teamSportType === "soccer"
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.homeTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.homeTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.homeTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.homeTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.homeTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.homeTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.awayTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.awayTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.awayTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.awayTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.awayTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.awayTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });
    let OutrightsTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == teamid
        : teamSportType === "basketball"
          ? item?.NBATeamId == teamid
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == teamid
            : teamSportType === "australianrules"
              ? item?.ARTeamId == teamid
              : teamSportType === "golf"
                ? item?.GolfTeamId == teamid
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == teamid
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == teamid
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == teamid
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == teamid
                        : teamSportType === "mma"
                          ? item?.MMATeamId == teamid
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == teamid
                            : item?.RLTeamId == teamid;
    });

    let teamInfo =
      team === "hometeam"
        ? HomeTeamOdds
        : team === "awayteam"
          ? AwayTeamOdds
          : OutrightsTeamOdds;
    let teamData = MarketName?.toLowerCase()?.includes("total")
      ? allTeamOdds
      : teamInfo;
    if (MarketName?.toLowerCase()?.includes("total")) {
      let OverData = allTeamOdds?.filter((ele) => {
        let LabelName =
          teamSportType === "cricket"
            ? ele?.CricketOddLabel?.displayName
              ? ele?.CricketOddLabel?.displayName
              : ele?.CricketOddLabel?.name
            : teamSportType === "basketball"
              ? ele?.NBAOddLabel?.displayName
                ? ele?.NBAOddLabel?.displayName
                : ele?.NBAOddLabel?.name
              : teamSportType === "americanfootball"
                ? ele?.AFLOddLabel?.displayName
                  ? ele?.AFLOddLabel?.displayName
                  : ele?.AFLOddLabel?.name
                : teamSportType === "australianrules"
                  ? ele?.AROddLabel?.displayName
                    ? ele?.AROddLabel?.displayName
                    : ele?.AROddLabel?.name
                  : teamSportType === "golf"
                    ? ele?.GolfOddLabel?.displayName
                      ? ele?.GolfOddLabel?.displayName
                      : ele?.GolfOddLabel?.name
                    : teamSportType === "tennis"
                      ? ele?.TennisOddLabel?.displayName
                        ? ele?.TennisOddLabel?.displayName
                        : ele?.TennisOddLabel?.name
                      : teamSportType === "baseball"
                        ? ele?.BaseballOddLabel?.displayName
                          ? ele?.BaseballOddLabel?.displayName
                          : ele?.BaseballOddLabel?.name
                        : teamSportType === "icehockey"
                          ? ele?.IceHockeyOddLabel?.displayName
                            ? ele?.IceHockeyOddLabel?.displayName
                            : ele?.IceHockeyOddLabel?.name
                          : teamSportType === "boxing"
                            ? ele?.BoxingOddLabel?.displayName
                              ? ele?.BoxingOddLabel?.displayName
                              : ele?.BoxingOddLabel?.name
                            : teamSportType === "mma"
                              ? ele?.MMAOddLabel?.displayName
                                ? ele?.MMAOddLabel?.displayName
                                : ele?.MMAOddLabel?.name
                              : teamSportType === "soccer"
                                ? ele?.SoccerOddLabel?.displayName
                                  ? ele?.SoccerOddLabel?.displayName
                                  : ele?.SoccerOddLabel?.name
                                : ele?.RLOddLabel?.displayName
                                  ? ele?.RLOddLabel?.displayName
                                  : ele?.RLOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("over") ||
          name?.toLowerCase()?.includes("totalover")
        );
      });
      let UnderData = allTeamOdds?.filter((ele) => {
        let LabelName =
          teamSportType === "cricket"
            ? ele?.CricketOddLabel?.displayName
              ? ele?.CricketOddLabel?.displayName
              : ele?.CricketOddLabel?.name
            : teamSportType === "basketball"
              ? ele?.NBAOddLabel?.displayName
                ? ele?.NBAOddLabel?.displayName
                : ele?.NBAOddLabel?.name
              : teamSportType === "americanfootball"
                ? ele?.AFLOddLabel?.displayName
                  ? ele?.AFLOddLabel?.displayName
                  : ele?.AFLOddLabel?.name
                : teamSportType === "australianrules"
                  ? ele?.AROddLabel?.displayName
                    ? ele?.AROddLabel?.displayName
                    : ele?.AROddLabel?.name
                  : teamSportType === "golf"
                    ? ele?.GolfOddLabel?.displayName
                      ? ele?.GolfOddLabel?.displayName
                      : ele?.GolfOddLabel?.name
                    : teamSportType === "tennis"
                      ? ele?.TennisOddLabel?.displayName
                        ? ele?.TennisOddLabel?.displayName
                        : ele?.TennisOddLabel?.name
                      : teamSportType === "baseball"
                        ? ele?.BaseballOddLabel?.displayName
                          ? ele?.BaseballOddLabel?.displayName
                          : ele?.BaseballOddLabel?.name
                        : teamSportType === "icehockey"
                          ? ele?.IceHockeyOddLabel?.displayName
                            ? ele?.IceHockeyOddLabel?.displayName
                            : ele?.IceHockeyOddLabel?.name
                          : teamSportType === "boxing"
                            ? ele?.BoxingOddLabel?.displayName
                              ? ele?.BoxingOddLabel?.displayName
                              : ele?.BoxingOddLabel?.name
                            : teamSportType === "mma"
                              ? ele?.MMAOddLabel?.displayName
                                ? ele?.MMAOddLabel?.displayName
                                : ele?.MMAOddLabel?.name
                              : teamSportType === "soccer"
                                ? ele?.SoccerOddLabel?.displayName
                                  ? ele?.SoccerOddLabel?.displayName
                                  : ele?.SoccerOddLabel?.name
                                : ele?.RLOddLabel?.displayName
                                  ? ele?.RLOddLabel?.displayName
                                  : ele?.RLOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("under") ||
          name?.toLowerCase()?.includes("totalunder")
        );
      });
      teamData = team === "over" ? OverData : UnderData;
    }
    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (type === "odds") {
      if (teamData) {
        if (maxno !== -1) {
          if (maxno !== 0) {
            let bookkeeperid = teamData
              ?.map((obj) => {
                if (obj?.odd === maxno) {
                  return obj?.BookKeeperId;
                }
              })
              ?.filter((x) => x !== undefined);
            return fetchClickableOdds(
              maxno,
              bookkeeperid?.[0],
              "header",
              teamData,
              data,
              "betslip",
              Isscore,
              "currentbest",
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else if (type === "points") {
      if (maxno !== -1) {
        if (maxno !== 0) {
          let point = teamData
            ?.map((obj) => {
              if (obj?.odd === maxno) {
                let LabelName =
                  teamSportType === "cricket"
                    ? obj?.CricketOddLabel?.displayName
                      ? obj?.CricketOddLabel?.displayName
                      : obj?.CricketOddLabel?.name
                    : teamSportType === "basketball"
                      ? obj?.NBAOddLabel?.displayName
                        ? obj?.NBAOddLabel?.displayName
                        : obj?.NBAOddLabel?.name
                      : teamSportType === "americanfootball"
                        ? obj?.AFLOddLabel?.displayName
                          ? obj?.AFLOddLabel?.displayName
                          : obj?.AFLOddLabel?.name
                        : teamSportType === "australianrules"
                          ? obj?.AROddLabel?.displayName
                            ? obj?.AROddLabel?.displayName
                            : obj?.AROddLabel?.name
                          : teamSportType === "golf"
                            ? obj?.GolfOddLabel?.displayName
                              ? obj?.GolfOddLabel?.displayName
                              : obj?.GolfOddLabel?.name
                            : teamSportType === "tennis"
                              ? obj?.TennisOddLabel?.displayName
                                ? obj?.TennisOddLabel?.displayName
                                : obj?.TennisOddLabel?.name
                              : teamSportType === "baseball"
                                ? obj?.BaseballOddLabel?.displayName
                                  ? obj?.BaseballOddLabel?.displayName
                                  : obj?.BaseballOddLabel?.name
                                : teamSportType === "icehockey"
                                  ? obj?.IceHockeyOddLabel?.displayName
                                    ? obj?.IceHockeyOddLabel?.displayName
                                    : obj?.IceHockeyOddLabel?.name
                                  : teamSportType === "boxing"
                                    ? obj?.BoxingOddLabel?.displayName
                                      ? obj?.BoxingOddLabel?.displayName
                                      : obj?.BoxingOddLabel?.name
                                    : teamSportType === "mma"
                                      ? obj?.MMAOddLabel?.displayName
                                        ? obj?.MMAOddLabel?.displayName
                                        : obj?.MMAOddLabel?.name
                                      : teamSportType === "soccer"
                                        ? obj?.SoccerOddLabel?.displayName
                                          ? obj?.SoccerOddLabel?.displayName
                                          : obj?.SoccerOddLabel?.name
                                        : obj?.RLOddLabel?.displayName
                                          ? obj?.RLOddLabel?.displayName
                                          : obj?.RLOddLabel?.name;
                let name = LabelName ? LabelName : obj?.label;
                return obj?.point
                  ? MarketName?.toLowerCase()?.includes("total")
                    ? name + " " + obj?.point
                    : obj?.point
                  : "";
              }
            })
            ?.filter((x) => x !== undefined);
          return point?.[0];
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  };
  const fetchCurrentBestsOddsIcon = (data, type, team, teamid) => {
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : teamSportType === "americanfootball"
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : teamSportType === "australianrules"
              ? data?.ARBetOffers?.[0]?.AROdds
              : teamSportType === "golf"
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : teamSportType === "tennis"
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : teamSportType === "baseball"
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : teamSportType === "icehockey"
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : teamSportType === "boxing"
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : teamSportType === "mma"
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : teamSportType === "soccer"
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.homeTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.homeTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.homeTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.homeTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.homeTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.homeTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.awayTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.awayTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.awayTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.awayTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.awayTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.awayTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });
    let OutrightsTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == teamid
        : teamSportType === "basketball"
          ? item?.NBATeamId == teamid
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == teamid
            : teamSportType === "australianrules"
              ? item?.ARTeamId == teamid
              : teamSportType === "golf"
                ? item?.GolfTeamId == teamid
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == teamid
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == teamid
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == teamid
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == teamid
                        : teamSportType === "mma"
                          ? item?.MMATeamId == teamid
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == teamid
                            : item?.RLTeamId == teamid;
    });
    let teamInfo =
      team === "hometeam"
        ? HomeTeamOdds
        : team === "awayteam"
          ? AwayTeamOdds
          : OutrightsTeamOdds;
    let teamData = MarketName?.toLowerCase()?.includes("total")
      ? allTeamOdds
      : teamInfo;
    if (MarketName?.toLowerCase()?.includes("total")) {
      let OverData = allTeamOdds?.filter((ele) => {
        return ele?.label?.toLowerCase()?.includes("over");
      });
      let UnderData = allTeamOdds?.filter((ele) => {
        return ele?.label?.toLowerCase()?.includes("under");
      });
      teamData = team === "over" ? OverData : UnderData;
    }
    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno && maxno !== 0) {
        let providerid = teamData
          ?.map((obj) => {
            let oddsType = obj?.odd;
            if (oddsType === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter((x) => x !== undefined);

        return oddsicon(
          providerid?.[0],
          "header",
          teamData,
          data,
          "currentbest",
        );
      } else {
        return "";
        // <span className="odds"> - </span>
      }
    } else {
      return "";
      // <span className="odds"> - </span>
    }
  };

  const fetchBestAtOpenOdds = (data, type, team, teamid) => {
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : teamSportType === "americanfootball"
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : teamSportType === "australianrules"
              ? data?.ARBetOffers?.[0]?.AROdds
              : teamSportType === "golf"
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : teamSportType === "tennis"
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : teamSportType === "baseball"
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : teamSportType === "icehockey"
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : teamSportType === "boxing"
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : teamSportType === "mma"
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : teamSportType === "soccer"
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.homeTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.homeTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.homeTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.homeTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.homeTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.homeTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.awayTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.awayTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.awayTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.awayTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.awayTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.awayTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });
    let OutrightsTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == teamid
        : teamSportType === "basketball"
          ? item?.NBATeamId == teamid
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == teamid
            : teamSportType === "australianrules"
              ? item?.ARTeamId == teamid
              : teamSportType === "golf"
                ? item?.GolfTeamId == teamid
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == teamid
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == teamid
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == teamid
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == teamid
                        : teamSportType === "mma"
                          ? item?.MMATeamId == teamid
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == teamid
                            : item?.RLTeamId == teamid;
    });

    let teamInfo =
      team === "hometeam"
        ? HomeTeamOdds
        : team === "awayteam"
          ? AwayTeamOdds
          : OutrightsTeamOdds;
    let teamData = MarketName?.toLowerCase()?.includes("total")
      ? allTeamOdds
      : teamInfo;
    if (MarketName?.toLowerCase()?.includes("total")) {
      let OverData = allTeamOdds?.filter((ele) => {
        let LabelName =
          teamSportType === "cricket"
            ? ele?.CricketOddLabel?.displayName
              ? ele?.CricketOddLabel?.displayName
              : ele?.CricketOddLabel?.name
            : teamSportType === "basketball"
              ? ele?.NBAOddLabel?.displayName
                ? ele?.NBAOddLabel?.displayName
                : ele?.NBAOddLabel?.name
              : teamSportType === "americanfootball"
                ? ele?.AFLOddLabel?.displayName
                  ? ele?.AFLOddLabel?.displayName
                  : ele?.AFLOddLabel?.name
                : teamSportType === "australianrules"
                  ? ele?.AROddLabel?.displayName
                    ? ele?.AROddLabel?.displayName
                    : ele?.AROddLabel?.name
                  : teamSportType === "golf"
                    ? ele?.GolfOddLabel?.displayName
                      ? ele?.GolfOddLabel?.displayName
                      : ele?.GolfOddLabel?.name
                    : teamSportType === "tennis"
                      ? ele?.TennisOddLabel?.displayName
                        ? ele?.TennisOddLabel?.displayName
                        : ele?.TennisOddLabel?.name
                      : teamSportType === "baseball"
                        ? ele?.BaseballOddLabel?.displayName
                          ? ele?.BaseballOddLabel?.displayName
                          : ele?.BaseballOddLabel?.name
                        : teamSportType === "icehockey"
                          ? ele?.IceHockeyOddLabel?.displayName
                            ? ele?.IceHockeyOddLabel?.displayName
                            : ele?.IceHockeyOddLabel?.name
                          : teamSportType === "boxing"
                            ? ele?.BoxingOddLabel?.displayName
                              ? ele?.BoxingOddLabel?.displayName
                              : ele?.BoxingOddLabel?.name
                            : teamSportType === "mma"
                              ? ele?.MMAOddLabel?.displayName
                                ? ele?.MMAOddLabel?.displayName
                                : ele?.MMAOddLabel?.name
                              : teamSportType === "soccer"
                                ? ele?.SoccerOddLabel?.displayName
                                  ? ele?.SoccerOddLabel?.displayName
                                  : ele?.SoccerOddLabel?.name
                                : ele?.RLOddLabel?.displayName
                                  ? ele?.RLOddLabel?.displayName
                                  : ele?.RLOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("over") ||
          name?.toLowerCase()?.includes("totalover")
        );
      });
      let UnderData = allTeamOdds?.filter((ele) => {
        let LabelName =
          teamSportType === "cricket"
            ? ele?.CricketOddLabel?.displayName
              ? ele?.CricketOddLabel?.displayName
              : ele?.CricketOddLabel?.name
            : teamSportType === "basketball"
              ? ele?.NBAOddLabel?.displayName
                ? ele?.NBAOddLabel?.displayName
                : ele?.NBAOddLabel?.name
              : teamSportType === "americanfootball"
                ? ele?.AFLOddLabel?.displayName
                  ? ele?.AFLOddLabel?.displayName
                  : ele?.AFLOddLabel?.name
                : teamSportType === "australianrules"
                  ? ele?.AROddLabel?.displayName
                    ? ele?.AROddLabel?.displayName
                    : ele?.AROddLabel?.name
                  : teamSportType === "golf"
                    ? ele?.GolfOddLabel?.displayName
                      ? ele?.GolfOddLabel?.displayName
                      : ele?.GolfOddLabel?.name
                    : teamSportType === "tennis"
                      ? ele?.TennisOddLabel?.displayName
                        ? ele?.TennisOddLabel?.displayName
                        : ele?.TennisOddLabel?.name
                      : teamSportType === "baseball"
                        ? ele?.BaseballOddLabel?.displayName
                          ? ele?.BaseballOddLabel?.displayName
                          : ele?.BaseballOddLabel?.name
                        : teamSportType === "icehockey"
                          ? ele?.IceHockeyOddLabel?.displayName
                            ? ele?.IceHockeyOddLabel?.displayName
                            : ele?.IceHockeyOddLabel?.name
                          : teamSportType === "boxing"
                            ? ele?.BoxingOddLabel?.displayName
                              ? ele?.BoxingOddLabel?.displayName
                              : ele?.BoxingOddLabel?.name
                            : teamSportType === "mma"
                              ? ele?.MMAOddLabel?.displayName
                                ? ele?.MMAOddLabel?.displayName
                                : ele?.MMAOddLabel?.name
                              : teamSportType === "soccer"
                                ? ele?.SoccerOddLabel?.displayName
                                  ? ele?.SoccerOddLabel?.displayName
                                  : ele?.SoccerOddLabel?.name
                                : ele?.RLOddLabel?.displayName
                                  ? ele?.RLOddLabel?.displayName
                                  : ele?.RLOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("under") ||
          name?.toLowerCase()?.includes("totalunder")
        );
      });
      teamData = team === "over" ? OverData : UnderData;
    }
    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.bestOpen ? obj?.bestOpen : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (type === "odds") {
      if (maxno !== -1) {
        if (maxno !== 0) {
          let bookkeeperid = teamData
            ?.map((obj) => {
              if (obj?.bestOpen === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter((x) => x !== undefined);
          return fetchNewOutrightClickableOdds(
            maxno,
            bookkeeperid?.[0],
            "header",
            teamData,
            data,
            "nobetslip",
          );
          // return maxno;
        } else {
          return <span className="odds-btn">SP</span>;
        }
      } else {
        return <span className="odds-btn">-</span>;
      }
    } else if (type === "points") {
      if (maxno !== -1) {
        if (maxno !== 0) {
          let point = teamData
            ?.map((obj) => {
              if (obj?.bestOpen === maxno) {
                let LabelName =
                  teamSportType === "cricket"
                    ? obj?.CricketOddLabel?.displayName
                      ? obj?.CricketOddLabel?.displayName
                      : obj?.CricketOddLabel?.name
                    : teamSportType === "basketball"
                      ? obj?.NBAOddLabel?.displayName
                        ? obj?.NBAOddLabel?.displayName
                        : obj?.NBAOddLabel?.name
                      : teamSportType === "americanfootball"
                        ? obj?.AFLOddLabel?.displayName
                          ? obj?.AFLOddLabel?.displayName
                          : obj?.AFLOddLabel?.name
                        : teamSportType === "australianrules"
                          ? obj?.AROddLabel?.displayName
                            ? obj?.AROddLabel?.displayName
                            : obj?.AROddLabel?.name
                          : teamSportType === "golf"
                            ? obj?.GolfOddLabel?.displayName
                              ? obj?.GolfOddLabel?.displayName
                              : obj?.GolfOddLabel?.name
                            : teamSportType === "tennis"
                              ? obj?.TennisOddLabel?.displayName
                                ? obj?.TennisOddLabel?.displayName
                                : obj?.TennisOddLabel?.name
                              : teamSportType === "baseball"
                                ? obj?.BaseballOddLabel?.displayName
                                  ? obj?.BaseballOddLabel?.displayName
                                  : obj?.BaseballOddLabel?.name
                                : teamSportType === "icehockey"
                                  ? obj?.IceHockeyOddLabel?.displayName
                                    ? obj?.IceHockeyOddLabel?.displayName
                                    : obj?.IceHockeyOddLabel?.name
                                  : teamSportType === "boxing"
                                    ? obj?.BoxingOddLabel?.displayName
                                      ? obj?.BoxingOddLabel?.displayName
                                      : obj?.BoxingOddLabel?.name
                                    : teamSportType === "mma"
                                      ? obj?.MMAOddLabel?.displayName
                                        ? obj?.MMAOddLabel?.displayName
                                        : obj?.MMAOddLabel?.name
                                      : teamSportType === "soccer"
                                        ? obj?.SoccerOddLabel?.displayName
                                          ? obj?.SoccerOddLabel?.displayName
                                          : obj?.SoccerOddLabel?.name
                                        : obj?.RLOddLabel?.displayName
                                          ? obj?.RLOddLabel?.displayName
                                          : obj?.RLOddLabel?.name;
                let name = LabelName ? LabelName : obj?.label;
                return obj?.point
                  ? MarketName?.toLowerCase()?.includes("total")
                    ? name + " " + obj?.point
                    : obj?.point
                  : "";
              }
            })
            ?.filter((x) => x !== undefined);
          return point?.[0];
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  };

  const fetchBestAtOpenIconOdds = (data, type, team, teamid) => {
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : teamSportType === "americanfootball"
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : teamSportType === "australianrules"
              ? data?.ARBetOffers?.[0]?.AROdds
              : teamSportType === "golf"
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : teamSportType === "tennis"
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : teamSportType === "baseball"
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : teamSportType === "icehockey"
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : teamSportType === "boxing"
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : teamSportType === "mma"
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : teamSportType === "soccer"
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.homeTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.homeTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.homeTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.homeTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.homeTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.homeTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.awayTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.awayTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.awayTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.awayTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.awayTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.awayTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });
    let OutrightsTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == teamid
        : teamSportType === "basketball"
          ? item?.NBATeamId == teamid
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == teamid
            : teamSportType === "australianrules"
              ? item?.ARTeamId == teamid
              : teamSportType === "golf"
                ? item?.GolfTeamId == teamid
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == teamid
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == teamid
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == teamid
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == teamid
                        : teamSportType === "mma"
                          ? item?.MMATeamId == teamid
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == teamid
                            : item?.RLTeamId == teamid;
    });
    let teamInfo =
      team === "hometeam"
        ? HomeTeamOdds
        : team === "awayteam"
          ? AwayTeamOdds
          : OutrightsTeamOdds;
    let teamData = MarketName?.toLowerCase()?.includes("total")
      ? allTeamOdds
      : teamInfo;
    if (MarketName?.toLowerCase()?.includes("total")) {
      let OverData = allTeamOdds?.filter((ele) => {
        return ele?.label?.toLowerCase()?.includes("over");
      });
      let UnderData = allTeamOdds?.filter((ele) => {
        return ele?.label?.toLowerCase()?.includes("under");
      });
      teamData = team === "over" ? OverData : UnderData;
    }
    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.bestOpen ? obj?.bestOpen : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno && maxno !== 0) {
        let providerid = teamData
          ?.map((obj) => {
            let oddsType = obj?.bestOpen;
            if (oddsType === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter((x) => x !== undefined);

        return oddsiconNewOutright(providerid?.[0], "header", teamData, data);
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const fetchFluctuationGraph = (data, team, teamid) => {
    let selectedId = null;
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : teamSportType === "americanfootball"
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : teamSportType === "australianrules"
              ? data?.ARBetOffers?.[0]?.AROdds
              : teamSportType === "golf"
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : teamSportType === "tennis"
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : teamSportType === "baseball"
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : teamSportType === "icehockey"
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : teamSportType === "boxing"
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : teamSportType === "mma"
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : teamSportType === "soccer"
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.homeTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.homeTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.homeTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.homeTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.homeTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.homeTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.awayTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.awayTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.awayTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.awayTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.awayTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.awayTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });
    let OutrightsTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == teamid
        : teamSportType === "basketball"
          ? item?.NBATeamId == teamid
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == teamid
            : teamSportType === "australianrules"
              ? item?.ARTeamId == teamid
              : teamSportType === "golf"
                ? item?.GolfTeamId == teamid
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == teamid
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == teamid
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == teamid
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == teamid
                        : teamSportType === "mma"
                          ? item?.MMATeamId == teamid
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == teamid
                            : item?.RLTeamId == teamid;
    });

    let teamInfo =
      team === "hometeam"
        ? HomeTeamOdds
        : team === "awayteam"
          ? AwayTeamOdds
          : OutrightsTeamOdds;
    // let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    const teamData = teamInfo;

    if (selectedGraphId === -2) {
      let maxno = teamData?.reduce((max, obj) => {
        let oddsType = obj?.bestOpen ? obj?.bestOpen : 0;
        oddsType > max ? (max = oddsType) : (max = max);
        return max;
      }, -1);
      if (maxno !== -1) {
        if (maxno !== 0) {
          let bookkeeperid = teamData
            ?.map((obj) => {
              if (obj?.bestOpen === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter((x) => x !== undefined);
          selectedId = bookkeeperid?.[0];
          // return maxno;
        } else {
          selectedId = null;
        }
      } else {
        selectedId = null;
      }
    } else if (selectedGraphId === -1) {
      let maxno = teamData?.reduce((max, obj) => {
        let oddsType = obj?.odd ? obj?.odd : 0;
        oddsType > max ? (max = oddsType) : (max = max);
        return max;
      }, -1);

      if (maxno !== -1) {
        if (maxno !== 0) {
          let bookkeeperid = teamData
            ?.map((obj) => {
              if (obj?.odd === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter((x) => x !== undefined);
          selectedId = bookkeeperid?.[0];
        } else {
          selectedId = null;
        }
      } else {
        selectedId = null;
      }
    } else {
      selectedId = selectedGraphId;
    }
    if (selectedId) {
      const selectedFilterData = teamData?.filter((item) => {
        return item?.BookKeeperId === selectedId;
      });
      let FlucData = [];
      const selectedGraphIdData = selectedFilterData?.[0]?.oddFlucs?.map(
        (odds) => {
          return FlucData?.push({
            point: odds?.odd,
          });
        },
      );

      return FlucData?.length > 0 ? (
        <HighChart FlucData={FlucData} />
      ) : (
        <Box className="nodata"> No Data Available</Box>
      );
    } else {
      return <Box className="nodata"> No Data Available</Box>;
    }
  };

  const fetchSeeAllTeamOddsvalue = (data, ProviderId, team, type) => {
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : teamSportType === "americanfootball"
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : teamSportType === "australianrules"
              ? data?.ARBetOffers?.[0]?.AROdds
              : teamSportType === "golf"
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : teamSportType === "tennis"
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : teamSportType === "baseball"
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : teamSportType === "icehockey"
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : teamSportType === "boxing"
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : teamSportType === "mma"
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : teamSportType === "soccer"
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.homeTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.homeTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.homeTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.homeTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.homeTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.homeTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.awayTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.awayTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.awayTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.awayTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.awayTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.awayTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });
    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    let teamData = MarketName?.toLowerCase()?.includes("total")
      ? allTeamOdds
      : teamInfo;

    if (MarketName?.toLowerCase()?.includes("total")) {
      let OverData = allTeamOdds?.filter((ele) => {
        let LabelName =
          teamSportType === "cricket"
            ? ele?.CricketOddLabel?.displayName
              ? ele?.CricketOddLabel?.displayName
              : ele?.CricketOddLabel?.name
            : teamSportType === "basketball"
              ? ele?.NBAOddLabel?.displayName
                ? ele?.NBAOddLabel?.displayName
                : ele?.NBAOddLabel?.name
              : teamSportType === "americanfootball"
                ? ele?.AFLOddLabel?.displayName
                  ? ele?.AFLOddLabel?.displayName
                  : ele?.AFLOddLabel?.name
                : teamSportType === "australianrules"
                  ? ele?.AROddLabel?.displayName
                    ? ele?.AROddLabel?.displayName
                    : ele?.AROddLabel?.name
                  : teamSportType === "golf"
                    ? ele?.GolfOddLabel?.displayName
                      ? ele?.GolfOddLabel?.displayName
                      : ele?.GolfOddLabel?.name
                    : teamSportType === "tennis"
                      ? ele?.TennisOddLabel?.displayName
                        ? ele?.TennisOddLabel?.displayName
                        : ele?.TennisOddLabel?.name
                      : teamSportType === "baseball"
                        ? ele?.BaseballOddLabel?.displayName
                          ? ele?.BaseballOddLabel?.displayName
                          : ele?.BaseballOddLabel?.name
                        : teamSportType === "icehockey"
                          ? ele?.IceHockeyOddLabel?.displayName
                            ? ele?.IceHockeyOddLabel?.displayName
                            : ele?.IceHockeyOddLabel?.name
                          : teamSportType === "boxing"
                            ? ele?.BoxingOddLabel?.displayName
                              ? ele?.BoxingOddLabel?.displayName
                              : ele?.BoxingOddLabel?.name
                            : teamSportType === "mma"
                              ? ele?.MMAOddLabel?.displayName
                                ? ele?.MMAOddLabel?.displayName
                                : ele?.MMAOddLabel?.name
                              : teamSportType === "soccer"
                                ? ele?.SoccerOddLabel?.displayName
                                  ? ele?.SoccerOddLabel?.displayName
                                  : ele?.SoccerOddLabel?.name
                                : ele?.RLOddLabel?.displayName
                                  ? ele?.RLOddLabel?.displayName
                                  : ele?.RLOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("over") ||
          name?.toLowerCase()?.includes("totalover")
        );
      });
      let UnderData = allTeamOdds?.filter((ele) => {
        let LabelName =
          teamSportType === "cricket"
            ? ele?.CricketOddLabel?.displayName
              ? ele?.CricketOddLabel?.displayName
              : ele?.CricketOddLabel?.name
            : teamSportType === "basketball"
              ? ele?.NBAOddLabel?.displayName
                ? ele?.NBAOddLabel?.displayName
                : ele?.NBAOddLabel?.name
              : teamSportType === "americanfootball"
                ? ele?.AFLOddLabel?.displayName
                  ? ele?.AFLOddLabel?.displayName
                  : ele?.AFLOddLabel?.name
                : teamSportType === "australianrules"
                  ? ele?.AROddLabel?.displayName
                    ? ele?.AROddLabel?.displayName
                    : ele?.AROddLabel?.name
                  : teamSportType === "golf"
                    ? ele?.GolfOddLabel?.displayName
                      ? ele?.GolfOddLabel?.displayName
                      : ele?.GolfOddLabel?.name
                    : teamSportType === "tennis"
                      ? ele?.TennisOddLabel?.displayName
                        ? ele?.TennisOddLabel?.displayName
                        : ele?.TennisOddLabel?.name
                      : teamSportType === "baseball"
                        ? ele?.BaseballOddLabel?.displayName
                          ? ele?.BaseballOddLabel?.displayName
                          : ele?.BaseballOddLabel?.name
                        : teamSportType === "icehockey"
                          ? ele?.IceHockeyOddLabel?.displayName
                            ? ele?.IceHockeyOddLabel?.displayName
                            : ele?.IceHockeyOddLabel?.name
                          : teamSportType === "boxing"
                            ? ele?.BoxingOddLabel?.displayName
                              ? ele?.BoxingOddLabel?.displayName
                              : ele?.BoxingOddLabel?.name
                            : teamSportType === "mma"
                              ? ele?.MMAOddLabel?.displayName
                                ? ele?.MMAOddLabel?.displayName
                                : ele?.MMAOddLabel?.name
                              : teamSportType === "soccer"
                                ? ele?.SoccerOddLabel?.displayName
                                  ? ele?.SoccerOddLabel?.displayName
                                  : ele?.SoccerOddLabel?.name
                                : ele?.RLOddLabel?.displayName
                                  ? ele?.RLOddLabel?.displayName
                                  : ele?.RLOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("under") ||
          name?.toLowerCase()?.includes("totalunder")
        );
      });
      teamData = team === "over" ? OverData : UnderData;
    }
    if (type === "odds") {
      if (teamData) {
        let odds = teamData
          ?.map((obj) => {
            if (obj?.BookKeeperId === ProviderId) {
              return { odds: obj?.odd, bookkeeper: obj?.BookKeeperId };
            }
          })
          ?.filter((x) => x !== undefined);
        if (odds?.length > 0) {
          return fetchClickableOdds(
            odds?.[0]?.odds,
            odds?.[0]?.bookkeeper,
            "header",
            teamData,
            data,
            "betslip",
            false,
            "seeallOdds",
          );
          // return odds?.[0];
        } else {
          return (
            <Tooltip
              title="No odds available"
              className="odds-tooltip"
              placement="top"
            >
              <span className="odds-style">
                <span className="odds">NOA</span>
              </span>
            </Tooltip>
          );
        }
      } else {
        return (
          <Tooltip
            title="No odds available"
            className="odds-tooltip"
            placement="top"
          >
            <span className="odds-style">
              <span className="odds">NOA</span>
            </span>
          </Tooltip>
        );
      }
    } else if (type === "spreadpoints") {
      if (teamData) {
        let odds = teamData
          ?.map((obj) => {
            if (obj?.BookKeeperId === ProviderId) {
              let LabelName =
                teamSportType === "cricket"
                  ? obj?.CricketOddLabel?.displayName
                    ? obj?.CricketOddLabel?.displayName
                    : obj?.CricketOddLabel?.name
                  : teamSportType === "basketball"
                    ? obj?.NBAOddLabel?.displayName
                      ? obj?.NBAOddLabel?.displayName
                      : obj?.NBAOddLabel?.name
                    : teamSportType === "americanfootball"
                      ? obj?.AFLOddLabel?.displayName
                        ? obj?.AFLOddLabel?.displayName
                        : obj?.AFLOddLabel?.name
                      : teamSportType === "australianrules"
                        ? obj?.AROddLabel?.displayName
                          ? obj?.AROddLabel?.displayName
                          : obj?.AROddLabel?.name
                        : teamSportType === "golf"
                          ? obj?.GolfOddLabel?.displayName
                            ? obj?.GolfOddLabel?.displayName
                            : obj?.GolfOddLabel?.name
                          : teamSportType === "tennis"
                            ? obj?.TennisOddLabel?.displayName
                              ? obj?.TennisOddLabel?.displayName
                              : obj?.TennisOddLabel?.name
                            : teamSportType === "baseball"
                              ? obj?.BaseballOddLabel?.displayName
                                ? obj?.BaseballOddLabel?.displayName
                                : obj?.BaseballOddLabel?.name
                              : teamSportType === "icehockey"
                                ? obj?.IceHockeyOddLabel?.displayName
                                  ? obj?.IceHockeyOddLabel?.displayName
                                  : obj?.IceHockeyOddLabel?.name
                                : teamSportType === "boxing"
                                  ? obj?.BoxingOddLabel?.displayName
                                    ? obj?.BoxingOddLabel?.displayName
                                    : obj?.BoxingOddLabel?.name
                                  : teamSportType === "mma"
                                    ? obj?.MMAOddLabel?.displayName
                                      ? obj?.MMAOddLabel?.displayName
                                      : obj?.MMAOddLabel?.name
                                    : teamSportType === "soccer"
                                      ? obj?.SoccerOddLabel?.displayName
                                        ? obj?.SoccerOddLabel?.displayName
                                        : obj?.SoccerOddLabel?.name
                                      : obj?.RLOddLabel?.displayName
                                        ? obj?.RLOddLabel?.displayName
                                        : obj?.RLOddLabel?.name;
              let name = LabelName ? LabelName : obj?.label;
              return obj?.point
                ? MarketName?.toLowerCase()?.includes("total")
                  ? name + " " + obj?.point
                  : obj?.point
                : "";
            }
          })
          ?.filter((x) => x !== undefined);
        if (odds?.length > 0) {
          return odds?.[0];
        } else {
        }
      } else {
      }
    }
  };

  const SlideTable = (direction) => {
    setscrollX(direction);
    var container = document.getElementById("individual-team-table");
    var scrollCompleted = 0;
    var slideVar = setInterval(function () {
      if (direction == "left") {
        container.scrollLeft -= 10;
      } else {
        container.scrollLeft += 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 600) {
        window.clearInterval(slideVar);
      }
    }, 10);
  };
  const fetchTableHeading = async (Bookkeeper) => {
    try {
      let SportId =
        teamSportType === "cricket"
          ? 4
          : teamSportType === "rugbyleague"
            ? 12
            : teamSportType === "rugbyunion"
              ? 13
              : teamSportType === "basketball"
                ? 10
                : teamSportType === "americanfootball"
                  ? 15
                  : teamSportType === "australianrules"
                    ? 9
                    : teamSportType === "golf"
                      ? 16
                      : teamSportType === "tennis"
                        ? 7
                        : teamSportType === "baseball"
                          ? 11
                          : teamSportType === "icehockey"
                            ? 17
                            : teamSportType === "boxing"
                              ? 6
                              : teamSportType === "mma"
                                ? 5
                                : teamSportType === "soccer"
                                  ? 8
                                  : 14;
      const { status, data } = await axiosInstance.get(
        `public/apiProviders/bookkeeperproviders?SportId=${SportId}`,
      );
      if (status === 200) {
        let filteredHeading = data?.result?.filter((item) =>
          Bookkeeper?.includes(item?.BookKeeperId),
        );
        let providerList = [];
        const list = data?.result?.map((item) => {
          return providerList?.push({
            label: item?.BookKeeper?.name,
            value: item?.BookKeeperId,
          });
        });
        let alldatas = providerList?.unshift(
          {
            label: "Current Best",
            value: -1,
          },
          {
            label: "Best At Open",
            value: -2,
          },
        );
        setProviderOption(providerList);
        setPageHeadingData(filteredHeading);
      } else {
      }
    } catch (err) {}
  };

  const renderTableCell = (BookKeeperId, fullData) => {
    return (
      <>
        <TableCell className={classes.head}>
          {oddsicon(BookKeeperId, "header", null, fullData, "featuredOdds")}
        </TableCell>
      </>
    );
  };

  const fetchTeamlogo = (item, type) => {
    if (type === "hometeam") {
      if (item?.homeTeam) {
        let TeamLogo = item?.homeTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.homeTeam?.flag ? (
          <img
            src={item?.homeTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Brisbane} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Brisbane} alt="Odds Icon" />;
      }
    } else {
      if (item?.awayTeam) {
        let TeamLogo = item?.awayTeam?.flag?.includes("uploads") ? (
          <img
            src={Config.mediaURL + item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : item?.awayTeam?.flag ? (
          <img
            src={item?.awayTeam?.flag}
            className="team-img"
            alt="team Icon"
          />
        ) : (
          <img className="team-img" src={Melbourne} alt="Odds Icon" />
        );
        return TeamLogo;
      } else {
        return <img className="team-img" src={Melbourne} alt="Odds Icon" />;
      }
    }
  };

  const fetchOutrightTeamlogo = (item) => {
    let TeamLogo = item?.flag?.includes("uploads") ? (
      <img
        src={Config.mediaURL + item?.flag}
        className="team-img"
        alt="team Icon"
      />
    ) : item?.homeTeam?.flag ? (
      <img src={item?.flag} className="team-img" alt="team Icon" />
    ) : (
      <img className="team-img" src={Brisbane} alt="Odds Icon" />
    );
    return TeamLogo;
  };

  const neweventListData = eventList?.sort(
    (a, b) => new Date(a?.startTime) - new Date(b?.startTime),
  );

  const GetSponsoredOdds = async () => {
    try {
      let SportId =
        teamSportType === "cricket"
          ? 4
          : teamSportType === "rugbyleague"
            ? 12
            : teamSportType === "rugbyunion"
              ? 13
              : teamSportType === "basketball"
                ? 10
                : teamSportType === "americanfootball"
                  ? 15
                  : teamSportType === "australianrules"
                    ? 9
                    : teamSportType === "golf"
                      ? 16
                      : teamSportType === "tennis"
                        ? 7
                        : teamSportType === "baseball"
                          ? 11
                          : teamSportType === "icehockey"
                            ? 17
                            : teamSportType === "boxing"
                              ? 6
                              : teamSportType === "mma"
                                ? 5
                                : teamSportType === "soccer"
                                  ? 8
                                  : 14;
      const { status, data } = await axiosInstance.get(
        `public/sponsor?timeZone=${timezone}&SportId=${SportId}`,
      );
      if (status === 200) {
        let providerIDs = [];
        let Ids = data?.result?.map((item) =>
          providerIDs?.push(item?.bookKeepersId),
        );
        setSponsoredId(providerIDs);
      } else {
        setSponsoredId([]);
      }
    } catch (err) {
      setSponsoredId([]);
    }
  };

  const fetchMultipleTeamOdds = (
    teamData,
    BookkeeperId,
    type,
    data,
    fulldata,
  ) => {
    if (teamData) {
      let odds = teamData
        ?.map((obj) => {
          if (obj?.BookKeeperId === BookkeeperId) {
            return { odds: obj?.odd, bookkeeper: obj?.BookKeeperId };
          }
        })
        ?.filter((x) => x !== undefined);
      if (odds?.length > 0) {
        return fetchClickableOdds(
          odds?.[0]?.odds,
          odds?.[0]?.bookkeeper,
          "header",
          data,
          fulldata,
          "betslip",
          false,
          "seeallOdds",
        );
        // return odds?.[0];
      } else {
        return (
          <Tooltip
            title="No odds available"
            className="odds-tooltip"
            placement="top"
          >
            <span className="odds-style">
              <span className="odds">NOA</span>
            </span>
          </Tooltip>
        );
      }
    } else {
      return (
        <Tooltip
          title="No odds available"
          className="odds-tooltip"
          placement="top"
        >
          <span className="odds-style">
            <span className="odds">NOA</span>
          </span>
        </Tooltip>
      );
    }
  };
  const fetchMultipleTeamLabelName = (teamdata) => {
    let PlayerName =
      teamSportType === "cricket"
        ? teamdata?.CricketPlayer?.name
        : teamSportType === "basketball"
          ? teamdata?.NBAPlayer?.name
          : teamSportType === "americanfootball"
            ? teamdata?.AFLPlayer?.name
            : teamSportType === "australianrules"
              ? teamdata?.ARPlayer?.name
              : teamSportType === "golf"
                ? teamdata?.GolfPlayer?.name
                : teamSportType === "tennis"
                  ? teamdata?.TennisPlayer?.name
                  : teamSportType === "baseball"
                    ? teamdata?.BaseballPlayer?.name
                    : teamSportType === "icehockey"
                      ? teamdata?.IceHockeyPlayer?.name
                      : teamSportType === "boxing"
                        ? teamdata?.BoxingPlayer?.name
                        : teamSportType === "mma"
                          ? teamdata?.MMAPlayer?.name
                          : teamSportType === "soccer"
                            ? teamdata?.SoccerPlayer?.name
                            : teamdata?.RLPlayer?.name;

    let LabelName =
      teamSportType === "cricket"
        ? teamdata?.CricketOddLabel?.displayName
          ? teamdata?.CricketOddLabel?.displayName
          : teamdata?.CricketOddLabel?.name
        : teamSportType === "basketball"
          ? teamdata?.NBAOddLabel?.displayName
            ? teamdata?.NBAOddLabel?.displayName
            : teamdata?.NBAOddLabel?.name
          : teamSportType === "americanfootball"
            ? teamdata?.AFLOddLabel?.displayName
              ? teamdata?.AFLOddLabel?.displayName
              : teamdata?.AFLOddLabel?.name
            : teamSportType === "australianrules"
              ? teamdata?.AROddLabel?.displayName
                ? teamdata?.AROddLabel?.displayName
                : teamdata?.AROddLabel?.name
              : teamSportType === "golf"
                ? teamdata?.GolfOddLabel?.displayName
                  ? teamdata?.GolfOddLabel?.displayName
                  : teamdata?.GolfOddLabel?.name
                : teamSportType === "tennis"
                  ? teamdata?.TennisOddLabel?.displayName
                    ? teamdata?.TennisOddLabel?.displayName
                    : teamdata?.TennisOddLabel?.name
                  : teamSportType === "baseball"
                    ? teamdata?.BaseballOddLabel?.displayName
                      ? teamdata?.BaseballOddLabel?.displayName
                      : teamdata?.BaseballOddLabel?.name
                    : teamSportType === "icehockey"
                      ? teamdata?.IceHockeyOddLabel?.displayName
                        ? teamdata?.IceHockeyOddLabel?.displayName
                        : teamdata?.IceHockeyOddLabel?.name
                      : teamSportType === "boxing"
                        ? teamdata?.BoxingOddLabel?.displayName
                          ? teamdata?.BoxingOddLabel?.displayName
                          : teamdata?.BoxingOddLabel?.name
                        : teamSportType === "mma"
                          ? teamdata?.MMAOddLabel?.displayName
                            ? teamdata?.MMAOddLabel?.displayName
                            : teamdata?.MMAOddLabel?.name
                          : teamSportType === "soccer"
                            ? teamdata?.SoccerOddLabel?.displayName
                              ? teamdata?.SoccerOddLabel?.displayName
                              : teamdata?.SoccerOddLabel?.name
                            : teamdata?.RLOddLabel?.displayName
                              ? teamdata?.RLOddLabel?.displayName
                              : teamdata?.RLOddLabel?.name;
    let name;
    if (PlayerName) {
      name = PlayerName;
      return name;
    } else if (LabelName) {
      name = LabelName;
      return name;
    } else {
      name = teamdata?.label;
      return name;
    }
  };
  const fetchSeeAllMultipleTeamData = (item) => {
    let allTeamOdds =
      teamSportType === "cricket"
        ? item?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
          ? item?.NBABetOffers?.[0]?.NBAOdds
          : teamSportType === "americanfootball"
            ? item?.AFLBetOffers?.[0]?.AFLOdds
            : teamSportType === "australianrules"
              ? item?.ARBetOffers?.[0]?.AROdds
              : teamSportType === "golf"
                ? item?.GolfBetOffers?.[0]?.GolfOdds
                : teamSportType === "tennis"
                  ? item?.TennisBetOffers?.[0]?.TennisOdds
                  : teamSportType === "baseball"
                    ? item?.BaseballBetOffers?.[0]?.BaseballOdds
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : teamSportType === "boxing"
                        ? item?.BoxingBetOffers?.[0]?.BoxingOdds
                        : teamSportType === "mma"
                          ? item?.MMABetOffers?.[0]?.MMAOdds
                          : teamSportType === "soccer"
                            ? item?.SoccerBetOffers?.[0]?.SoccerOdds
                            : item?.RLBetOffers?.[0]?.RLOdds;
    const labelKey =
      teamSportType === "cricket"
        ? "CricketOddLabelId"
        : teamSportType === "basketball"
          ? "NBAOddLabelId"
          : teamSportType === "americanfootball"
            ? "AFLOddLabelId"
            : teamSportType === "australianrules"
              ? "AROddLabelId"
              : teamSportType === "golf"
                ? "GolfOddLabelId"
                : teamSportType === "tennis"
                  ? "TennisOddLabelId"
                  : teamSportType === "baseball"
                    ? "BaseballOddLabelId"
                    : teamSportType === "icehockey"
                      ? "IceHockeyOddLabelId"
                      : teamSportType === "boxing"
                        ? "BoxingOddLabelId"
                        : teamSportType === "mma"
                          ? "MMAOddLabelId"
                          : teamSportType === "soccer"
                            ? "SoccerOddLabelId"
                            : "RLOddLabelId";
    const PlayerKey =
      teamSportType === "cricket"
        ? "CricketPlayerId"
        : teamSportType === "basketball"
          ? "NBAPlayerId"
          : teamSportType === "americanfootball"
            ? "AFLPlayerId"
            : teamSportType === "australianrules"
              ? "ARPlayerId"
              : teamSportType === "golf"
                ? "GolfPlayerId"
                : teamSportType === "tennis"
                  ? "TennisPlayerId"
                  : teamSportType === "baseball"
                    ? "BaseballPlayerId"
                    : teamSportType === "icehockey"
                      ? "IceHockeyPlayerId"
                      : teamSportType === "boxing"
                        ? "BoxingPlayerId"
                        : teamSportType === "mma"
                          ? "MMAPlayerId"
                          : teamSportType === "soccer"
                            ? "SoccerPlayerId"
                            : "RLPlayerId";
    const groupedData = _.groupBy(allTeamOdds, (item) =>
      item[labelKey] === null ? "label" : item[labelKey],
    );
    let playerData = _.groupBy(groupedData["label"], PlayerKey);
    const transformedData = _.mapKeys(playerData, (values, key) =>
      key !== "null" ? `key_${key}` : "custom_null",
    );
    const mergedObject = _.omit(groupedData, "label");

    _.merge(mergedObject, transformedData);
    const groupedDataASDASDAS = _.groupBy(
      mergedObject?.custom_null,
      (item) => `key_${item.id}`,
    );
    let payload = {
      ...mergedObject,
      ...groupedDataASDASDAS,
    };
    const finalObject = _.omit(payload, "custom_null");

    return (
      <>
        {allTeamOdds?.length > 0 ? (
          Object.keys(finalObject)?.map((ele, index) => {
            return (
              <React.Fragment key={index}>
                <TableRow className="individual-team-row multiple-team-row">
                  <StickyTableCell
                    className={`${classes.cell} body-runner-name`}
                  >
                    <Box className="team-wrap">
                      {/* <span className="team-img-wrap">
                      {fetchOutrightTeamlogo(teamdata)}
                    </span> */}
                      <span className="team-name">
                        {fetchMultipleTeamLabelName(finalObject[ele]?.[0])}
                      </span>
                    </Box>
                  </StickyTableCell>
                  {pageHeadingData?.length > 0 ? (
                    pageHeadingData?.map((id, index) => (
                      <TableCell className={classes.cell} key={index}>
                        <span className="odds">
                          {/* {fetchClickableOdds(
                          teamdata?.odd,
                          id?.BookKeeperId,
                          "header",
                          allTeamOdds
                        )} */}
                          {fetchMultipleTeamOdds(
                            finalObject[ele],
                            id?.BookKeeperId,
                            "header",
                            allTeamOdds,
                            item,
                          )}
                        </span>
                      </TableCell>
                    ))
                  ) : (
                    <TableCell className={classes.cell} key={index}>
                      {" "}
                      <span className="odds">
                        <span className="odds-btn">NOA</span>
                      </span>{" "}
                    </TableCell>
                  )}
                  {/* <TableCell className={classes.cell}></TableCell> */}
                </TableRow>
              </React.Fragment>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={100}>
              <Box
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                }}
              >
                {" "}
                No Data Available
              </Box>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  const fetchSponsoredOddsScore = (data, team) => {
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : teamSportType === "americanfootball"
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : teamSportType === "australianrules"
              ? data?.ARBetOffers?.[0]?.AROdds
              : teamSportType === "golf"
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : teamSportType === "tennis"
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : teamSportType === "baseball"
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : teamSportType === "icehockey"
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : teamSportType === "boxing"
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : teamSportType === "mma"
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : teamSportType === "soccer"
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.homeTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.homeTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.homeTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.homeTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.homeTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.homeTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.awayTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.awayTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.awayTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.awayTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.awayTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.awayTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });
    let teamData = team === "homeTeam" ? HomeTeamOdds : AwayTeamOdds;

    let newOdds = teamData?.filter((odds) => {
      return sponsoredId?.includes(odds.BookKeeperId);
    });
    let firstSponsored = newOdds?.filter(
      (item) => item?.BookKeeperId === sponsoredId?.[0],
    );
    let secondSponsored = newOdds?.filter(
      (item) => item?.BookKeeperId === sponsoredId?.[1],
    );
    let SponsoredOdds =
      sponsoredId?.length > 0 ? (
        <>
          <Box
            className={`${
              sponsoredId?.length > 1 ? "border-bottom-score" : ""
            } sponsored-odd `}
          >
            <Box className="odds-label"> {firstSponsored?.[0]?.label}</Box>
            <Box className="odds-wrap">
              {firstSponsored?.[0]?.odd ? (
                <span className="odds odds-left">
                  {fetchClickableOdds(
                    firstSponsored?.[0]?.odd ? firstSponsored?.[0]?.odd : "NOA",
                    firstSponsored?.[0]?.BookKeeperId,
                    "sponsored",
                    firstSponsored,
                    data,
                    "nobetslip",
                  )}
                </span>
              ) : (
                <></>
              )}
              {oddsicon(sponsoredId?.[0], "sponsored", firstSponsored, data)}
            </Box>
          </Box>
          {sponsoredId?.[1] ? (
            <>
              <Box className="sponsored-odd">
                <Box className="odds-label"> {secondSponsored?.[0]?.label}</Box>
                <Box className="odds-wrap">
                  {secondSponsored?.[0]?.odd ? (
                    <span className="odds odds-left">
                      {fetchClickableOdds(
                        secondSponsored?.[0]?.odd
                          ? secondSponsored?.[0]?.odd
                          : "NOA",
                        secondSponsored?.[0]?.BookKeeperId,
                        "sponsored",
                        secondSponsored,
                        data,
                        "nobetslip",
                      )}
                    </span>
                  ) : (
                    <></>
                  )}
                  {oddsicon(
                    sponsoredId?.[1],
                    "sponsored",
                    secondSponsored,
                    data,
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      );
    return SponsoredOdds;
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Return empty string if the input is falsy
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const fetchmarketList = async (
    selectedseries,
    selectedteam,
    selectedDate,
    isFixture,
  ) => {
    setIsEventLoading(true);
    try {
      let SportsType =
        teamSportType === "cricket"
          ? `crickets`
          : teamSportType === "rugbyleague"
            ? `rls`
            : teamSportType === "rugbyunion"
              ? `rls`
              : teamSportType === "basketball"
                ? `nba`
                : teamSportType === "americanfootball"
                  ? `afl`
                  : teamSportType === "australianrules"
                    ? `ar`
                    : teamSportType === "golf"
                      ? `golf`
                      : teamSportType === "tennis"
                        ? `tennis`
                        : teamSportType === "baseball"
                          ? `baseball`
                          : teamSportType === "icehockey"
                            ? `icehockey`
                            : teamSportType === "boxing"
                              ? `boxing`
                              : teamSportType === "mma"
                                ? `mma`
                                : teamSportType === "soccer"
                                  ? `soccer`
                                  : `rls`;
      const { status, data } = await axiosInstance.get(
        `public/${SportsType}/market?isPrimary=true&SportId=${
          teamSportType === "rugbyleague"
            ? 12
            : teamSportType === "rugbyunion"
              ? 13
              : ""
        }`,
      );
      if (status === 200) {
        let newdata = [];
        let dropdownData = data?.result?.rows?.map((item) => {
          newdata.push({
            label: capitalizeFirstLetter(item?.displayName),
            value: item?.id,
          });
        });
        const marketId = newdata?.[0]?.value ? newdata?.[0]?.value : 1;
        setSelectedOption(marketId);
        {
          teamSportType !== "golf" &&
            params?.oddstype !== "outrights" &&
            fetchAllEvents(
              0,
              marketId,
              selectedseries,
              selectedteam,
              selectedDate,
              null,
              dayLiveFilter == 4
                ? "Future"
                : dayLiveFilter == 0
                  ? "IsLive"
                  : null,
              isFixture,
            );
        }
        teamSportType === "golf" && tabvalue === 3
          ? fetchDropDowndata(0, "outrights", null, marketId, isFixture)
          : fetchDropDowndata(
              0,
              null,
              dayLiveFilter == 4
                ? "Future"
                : dayLiveFilter == 0
                  ? "IsLive"
                  : null,
              marketId,
              isFixture,
            );
        setMarketList(newdata);
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handleRedirectTab = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    if (isLogin) {
      if (
        teamSportType === "rugbyleague" ||
        teamSportType === "rugbyunion" ||
        teamSportType === "cricket"
      ) {
        setTabValue(4);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };
  const fetchIndividualMarket = async () => {
    setIsIndividualEventLoading(true);
    try {
      let SportsType =
        teamSportType === "cricket"
          ? `crickets`
          : teamSportType === "rugbyleague"
            ? `rls`
            : teamSportType === "rugbyunion"
              ? `rls`
              : teamSportType === "basketball"
                ? `nba`
                : teamSportType === "americanfootball"
                  ? `afl`
                  : teamSportType === "australianrules"
                    ? `ar`
                    : teamSportType === "golf"
                      ? `golf`
                      : teamSportType === "tennis"
                        ? `tennis`
                        : teamSportType === "baseball"
                          ? `baseball`
                          : teamSportType === "icehockey"
                            ? `icehockey`
                            : teamSportType === "boxing"
                              ? `boxing`
                              : teamSportType === "mma"
                                ? `mma`
                                : teamSportType === "soccer"
                                  ? `soccer`
                                  : `rls`;
      const { status, data } = await axiosInstance.get(
        `public/${SportsType}/event/${params?.id}?marketId=&SportId=${
          teamSportType === "rugbyleague"
            ? 12
            : teamSportType === "rugbyunion"
              ? 13
              : ""
        }`,
      );
      if (status === 200) {
        let allTeamOdds =
          teamSportType === "cricket"
            ? data?.result?.CricketBetOffers
            : teamSportType === "basketball"
              ? data?.result?.NBABetOffers
              : teamSportType === "americanfootball"
                ? data?.result?.AFLBetOffers
                : teamSportType === "australianrules"
                  ? data?.result?.ARBetOffers
                  : teamSportType === "golf"
                    ? data?.result?.GolfBetOffers
                    : teamSportType === "tennis"
                      ? data?.result?.TennisBetOffers
                      : teamSportType === "baseball"
                        ? data?.result?.BaseballBetOffers
                        : teamSportType === "icehockey"
                          ? data?.result?.IceHockeyBetOffers
                          : teamSportType === "boxing"
                            ? data?.result?.BoxingBetOffers
                            : teamSportType === "mma"
                              ? data?.result?.MMABetOffers
                              : teamSportType === "soccer"
                                ? data?.result?.SoccerBetOffers
                                : data?.result?.RLBetOffers;
        let newdata = [];
        let dropdownData = allTeamOdds?.map((item) => {
          let Market =
            teamSportType === "cricket"
              ? item?.CricketMarket
              : teamSportType === "basketball"
                ? item?.NBAMarket
                : teamSportType === "americanfootball"
                  ? item?.AFLMarket
                  : teamSportType === "australianrules"
                    ? item?.ARMarket
                    : teamSportType === "golf"
                      ? item?.GolfMarket
                      : teamSportType === "tennis"
                        ? item?.TennisMarket
                        : teamSportType === "baseball"
                          ? item?.BaseballMarket
                          : teamSportType === "icehockey"
                            ? item?.IceHockeyMarket
                            : teamSportType === "boxing"
                              ? item?.BoxingMarket
                              : teamSportType === "mma"
                                ? item?.MMAMarket
                                : teamSportType === "soccer"
                                  ? item?.SoccerMarket
                                  : item?.RLMarket;

          if (Market) {
            newdata.push({
              label: item?.replacer
                ? Market?.displayName
                    .replace("{homeTeam}", item?.replacer)
                    .replace("{awayTeam}", item?.replacer)
                : Market?.displayName,
              value: Market?.id,
            });
          }
        });
        const filteredMarketlist = _.uniqBy(newdata, function (e) {
          return e.value;
        });
        const marketId = newdata?.[0]?.value ? newdata?.[0]?.value : 1;
        fetchIndividualTeamdata(marketId);
        setselectedMarket(marketId);
        setIndividualMarketList(filteredMarketlist);
      } else {
      }
    } catch (err) {
      console.log("error", err);
      setIsIndividualEventLoading(false);
    }
  };
  const handleIndividualMarketChange = async (e) => {
    setselectedMarket(e?.value);
    // if (params?.seeall === "true") {
    fetchIndividualTeamdata(e?.value);
    // } else {
    //   fetchAllEvents(0, e?.value);
    //   setEventPage(0);
    // }
  };
  const handleMarketChange = (e) => {
    setSelectedOption(e?.value);
    if (params?.seeall === "true") {
      fetchIndividualTeamdata(e?.value);
    } else {
      fetchAllEvents(0, e?.value, selectedseries, selectedteam, selectedDate);
      setEventPage(0);
    }
  };

  const clearDate = () => {
    setselectedDate(null);
    setDateSelected(null);
    setDayLiveFilter(1);
    setOpen(false);
  };
  const renderInput = (props) => {
    return (
      <input {...props} placeholder={!selectedDate ? "Select a date" : null} />
    );
  };
  useEffect(() => {
    if (handleRestrictedUser()) {
      setShowOdds(reduxSubscriptionData?.oddfixtures);
    } else {
      setShowOdds(release[Config.release]?.oddsCheck);
    }
    // setShowAllFixtures(reduxSubscriptionData?.allfixtures);
  }, [reduxSubscriptionData]);
  const handleupdateFixturesOdds = async (e, type) => {
    const payload = {
      allfixtures: type === "fixture" ? e : showAllFixture,
      oddfixtures: type === "odds" ? e : showOdds,
    };
    try {
      const { status, data } = await axiosInstance.put(`/user`, payload);
      if (status === 200 && data?.success === true) {
        let tempUserData = _.cloneDeep(reduxSubscriptionData);
        if (type === "fixture") {
          tempUserData.allfixtures = e;
        } else if (type === "odds") {
          tempUserData.oddfixtures = e;
        }
        dispatch(fetchSubscriptionData(tempUserData));
      } else {
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleShowAllFixture = (e) => {
    if (handleRestrictedUser()) {
      setShowAllFixtures(e?.target?.checked);
      handleupdateFixturesOdds(e?.target?.checked, "fixture");
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const handleShowAllOdds = (e) => {
    if (handleRestrictedUser()) {
      setShowOdds(e?.target?.checked);
      handleupdateFixturesOdds(e?.target?.checked, "odds");
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };
  const handleModal = () => {
    setSBOpen(true);
  };

  const handleClose = () => {
    setSBOpen(false);
  };
  const handleBlackBookModal = (title, id, type, item) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    setSelectedSportId(item?.SportId);
    handleModal();
  };

  const handleTooltipContentClick = (event, teamId, objindex) => {
    event.stopPropagation();
    setTooltipOpen(!tooltipOpen);
    setHoveredIndex(objindex);
    setClickToolTipTeamId(teamId);
  };

  const handleOutsideClick = (item, teamId, index) => {
    if (hoveredIndex === index && clickToolTipTeamId === teamId) {
      if (tooltipOpen) {
        setTooltipOpen(false);
      }
    }
  };

  const handleOpenTooltip = (item, teamId, index, teamType, score) => {
    let tournamentName =
      item?.SportId === 4 && item?.CricketTournament?.name
        ? item?.CricketTournament?.name
        : item?.SportId === 10 && item?.NBATournament?.name
          ? item?.NBATournament?.name
          : item?.SportId === 15 && item?.AFLTournament?.name
            ? item?.AFLTournament?.name
            : item?.SportId === 9 && item?.ARTournament?.name
              ? item?.ARTournament?.name
              : item?.SportId === 16 && item?.GolfTournament?.name
                ? item?.GolfTournament?.name
                : item?.SportId === 7 && item?.TennisTournament?.name
                  ? item?.TennisTournament?.name
                  : item?.SportId === 11 && item?.BaseballTournament?.name
                    ? item?.BaseballTournament?.name
                    : item?.SportId === 17 && item?.IceHockeyTournament?.name
                      ? item?.IceHockeyTournament?.name
                      : item?.SportId === 6 && item?.BoxingTournament?.name
                        ? item?.BoxingTournament?.name
                        : item?.SportId === 5 && item?.MMATournament?.name
                          ? item?.MMATournament?.name
                          : item?.SportId === 8 && item?.SoccerTournament?.name
                            ? item?.SoccerTournament?.name
                            : item?.RLTournament?.name;

    let tournamentId =
      item?.SportId === 4 && item?.CricketTournamentId
        ? item?.CricketTournamentId
        : item?.SportId === 10 && item?.NBATournamentId
          ? item?.NBATournamentId
          : item?.SportId === 15 && item?.AFLTournamentId
            ? item?.AFLTournamentId
            : item?.SportId === 9 && item?.ARTournamentId
              ? item?.ARTournamentId
              : item?.SportId === 16 && item?.GolfTournamentId
                ? item?.GolfTournamentId
                : item?.SportId === 7 && item?.TennisTournamentId
                  ? item?.TennisTournamentId
                  : item?.SportId === 11 && item?.BaseballTournamentId
                    ? item?.BaseballTournamentId
                    : item?.SportId === 17 && item?.IceHockeyTournamentId
                      ? item?.IceHockeyTournamentId
                      : item?.SportId === 6 && item?.BoxingTournamentId
                        ? item?.BoxingTournamentId
                        : item?.SportId === 5 && item?.MMATournamentId
                          ? item?.MMATournamentId
                          : item?.SportId === 8 && item?.SoccerTournamentId
                            ? item?.SoccerTournamentId
                            : item?.RLTournamentId;

    return (
      <Box
        className="custom-tooltip"
        onClick={(e) => handleTooltipContentClick(e, teamId, index)}
      >
        <Box
          // className={showOdds ? "smartboook-icon-box" : "smartboook-icon-box"}
          className="smartboook-icon-box"
        >
          {(teamType === "hometeam"
            ? item?.isSmartBookHomeTeam
            : item?.isSmartBookAwayTeam) && item?.isSmartBookTournament ? (
            <BlackbookRight className="book-icon" />
          ) : (
            <BlackbookPlus className="book-icon" />
          )}
        </Box>

        {hoveredIndex === index && clickToolTipTeamId === teamId ? (
          tooltipOpen && (
            <Box
              className={
                showOdds || score === "score"
                  ? "tooltip"
                  : "show-tooltip tooltip"
              }
            >
              <TooltipArrow className="tooltip-arrow" />
              <Typography className="add-smartbook">
                <AddBookIcon /> Add To My Smartbook
              </Typography>
              <Box className="black-details">
                <Box className="tooltip-details">
                  <Typography
                    className="tooltip-txt"
                    onClick={() =>
                      handleBlackBookModal(
                        teamType === "hometeam"
                          ? item?.homeTeam?.name
                          : item?.awayTeam?.name,
                        teamType === "hometeam"
                          ? item?.homeTeam?.id
                          : item?.awayTeam?.id,
                        "Team",
                        item,
                      )
                    }
                  >
                    <span>
                      {" "}
                      Team:{" "}
                      {teamType === "hometeam"
                        ? item?.homeTeam?.name
                        : item?.awayTeam?.name}
                    </span>
                    {(
                      teamType === "hometeam"
                        ? item?.isSmartBookHomeTeam
                        : item?.isSmartBookAwayTeam
                    ) ? (
                      <RightBlack />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                    {/* {(teamType === "hometeam" &&
                      item?.isSmartBookAwayTeam === true) ||
                    (teamType === "awayteam" &&
                      item?.isSmartBookHomeTeam === true) ? (
                      <RightBlack className="icon-blackbook" />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )} */}
                  </Typography>
                </Box>

                <Box className="tooltip-details">
                  <Typography
                    className="tooltip-txt"
                    onClick={() =>
                      handleBlackBookModal(
                        tournamentName,
                        tournamentId,
                        "Tournament",
                        item,
                      )
                    }
                  >
                    <span>
                      League: {tournamentName ? tournamentName : "N/A"}
                    </span>
                    {item?.isSmartBookTournament ? (
                      <RightBlack />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleOutsideOutrightsClick = (item, index, teamdata, teamindex) => {
    if (hoveredOutrightIndex === index && teamOutrightIndex === teamindex) {
      if (toolOutrighttipOpen) {
        setToolOutrighttipOpen(false);
      }
    }
  };

  const handleTooltipOutrightContentClick = (event, index, id) => {
    event.stopPropagation();
    setToolOutrighttipOpen(!toolOutrighttipOpen);
    setHoveredOutrightIndex(index);
    setTeamOutrightindex(id);
  };

  const handleOpenOutrughtTooltip = (
    item,
    index,
    teamdata,
    teamindex,
    smartbookAdded,
    sportId,
  ) => {
    let tournamentName =
      item?.SportId === 4 && item?.CricketTournament?.name
        ? item?.CricketTournament?.name
        : item?.SportId === 10 && item?.NBATournament?.name
          ? item?.NBATournament?.name
          : item?.SportId === 15 && item?.AFLTournament?.name
            ? item?.AFLTournament?.name
            : item?.SportId === 9 && item?.ARTournament?.name
              ? item?.ARTournament?.name
              : item?.SportId === 16 && item?.GolfTournament?.name
                ? item?.GolfTournament?.name
                : item?.SportId === 7 && item?.TennisTournament?.name
                  ? item?.TennisTournament?.name
                  : item?.SportId === 11 && item?.BaseballTournament?.name
                    ? item?.BaseballTournament?.name
                    : item?.SportId === 17 && item?.IceHockeyTournament?.name
                      ? item?.IceHockeyTournament?.name?.name
                      : item?.SportId === 6 && item?.BoxingTournament?.name
                        ? item?.BoxingTournament?.name
                        : item?.SportId === 5 && item?.MMATournament?.name
                          ? item?.MMATournament?.name
                          : item?.SportId === 8 && item?.SoccerTournament?.name
                            ? item?.SoccerTournament?.name
                            : item?.RLTournament?.name;

    let tournamentId =
      item?.SportId === 4 && item?.CricketTournamentId
        ? item?.CricketTournamentId
        : item?.SportId === 10 && item?.NBATournamentId
          ? item?.NBATournamentId
          : item?.SportId === 15 && item?.AFLTournamentId
            ? item?.AFLTournamentId
            : item?.SportId === 9 && item?.ARTournamentId
              ? item?.ARTournamentId
              : item?.SportId === 16 && item?.GolfTournamentId
                ? item?.GolfTournamentId
                : item?.SportId === 7 && item?.TennisTournamentId
                  ? item?.TennisTournamentId
                  : item?.SportId === 11 && item?.BaseballTournamentId
                    ? item?.BaseballTournamentId
                    : item?.SportId === 17 && item?.IceHockeyTournamentId
                      ? item?.IceHockeyTournamentId
                      : item?.SportId === 6 && item?.BoxingTournamentId
                        ? item?.BoxingTournamentId
                        : item?.SportId === 5 && item?.MMATournamentId
                          ? item?.MMATournamentId
                          : item?.SportId === 8 && item?.SoccerTournamentId
                            ? item?.SoccerTournamentId
                            : item?.RLTournamentId;
    return (
      <Box
        className="custom-tooltip"
        onClick={(e) => handleTooltipOutrightContentClick(e, index, teamindex)}
      >
        <Box
          className={
            showOdds ? "smartboook-icon-box" : "smartboook-icon-box mr"
          }
        >
          {smartbookAdded === 1 ? (
            <BlackbookRight className="book-icon" />
          ) : (
            <BlackbookPlus className="book-icon" />
          )}
        </Box>
        {hoveredOutrightIndex === index && teamOutrightIndex === teamindex ? (
          toolOutrighttipOpen && (
            <Box
              className={`tooltip ${
                showOdds ? "" : "show-tooltip outright-tooltip"
              }`}
            >
              <TooltipArrow className="tooltip-arrow" />
              <Typography className="add-smartbook">
                <AddBookIcon /> Add To My Smartbook
              </Typography>

              <Box className="black-details">
                <Box className="tooltip-details">
                  <Typography
                    className="tooltip-txt"
                    onClick={() =>
                      handleBlackBookModal(
                        teamdata?.name,
                        teamdata?.id,
                        "Team",
                        sportId,
                      )
                    }
                  >
                    <span>Team: {teamdata?.name}</span>
                    {smartbookAdded === 1 ? (
                      <RightBlack />
                    ) : (
                      <PlusBlack className="icon-blacbook" />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  };

  const fetchSingleMatchData = async (data) => {
    setSingleMatchData(data);
  };

  const handleViewExpertTips = () => {
    if (handleRestrictedUser()) {
      navigate(`/teamsports/${teamSportType}/sport-expert-tips`);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem(
        "prev_authroute",
        `/teamsports/${teamSportType}/sport-expert-tips`,
      );
    }
  };

  const getAllTipData = async (sId) => {
    // setLoading(true);

    try {
      const { status, data } = await axiosInstance.get(
        `/expertTips/web/getAllTips/count?SportId=${sId}&timezone=${timezone}`,
      );
      if (status === 200) {
        setExpertTipsCount(data?.count);
      } else {
        // setLoading(false);
      }
    } catch (err) {
      // setLoading(false);
    }
  };

  const fetchTopBookmakers = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `apiProviders/bookKeepers`,
      );
      if (status === 200) {
        const bookMakersData = data?.result;
        const isFeaturedData = bookMakersData?.filter(
          (item) => item?.isFeatured === true,
        );
        const recommendedBookmaker = bookMakersData?.filter(
          (item) => item?.isFeatured === false,
        );
        const sortedData = isFeaturedData?.sort(
          (a, b) => a.featured_order - b.featured_order,
        );
        const recommendedSortedData = recommendedBookmaker?.sort(
          (a, b) => a.featured_order - b.featured_order,
        );
        setTopBookMakersData(sortedData);
      }
    } catch (error) {}
  };
  const fetchFeaturedOdds = (data, ProviderId, team, type) => {
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : teamSportType === "americanfootball"
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : teamSportType === "australianrules"
              ? data?.ARBetOffers?.[0]?.AROdds
              : teamSportType === "golf"
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : teamSportType === "tennis"
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : teamSportType === "baseball"
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : teamSportType === "icehockey"
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : teamSportType === "boxing"
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : teamSportType === "mma"
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : teamSportType === "soccer"
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.homeTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.homeTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.homeTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.homeTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.homeTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.homeTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.awayTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.awayTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.awayTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.awayTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.awayTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.awayTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });
    let teamInfo = team === "hometeam" ? HomeTeamOdds : AwayTeamOdds;
    let teamData = MarketName?.toLowerCase()?.includes("total")
      ? allTeamOdds
      : teamInfo;
    if (MarketName?.toLowerCase()?.includes("total")) {
      let OverData = allTeamOdds?.filter((ele) => {
        let LabelName =
          teamSportType === "cricket"
            ? ele?.CricketOddLabel?.displayName
              ? ele?.CricketOddLabel?.displayName
              : ele?.CricketOddLabel?.name
            : teamSportType === "basketball"
              ? ele?.NBAOddLabel?.displayName
                ? ele?.NBAOddLabel?.displayName
                : ele?.NBAOddLabel?.name
              : teamSportType === "americanfootball"
                ? ele?.AFLOddLabel?.displayName
                  ? ele?.AFLOddLabel?.displayName
                  : ele?.AFLOddLabel?.name
                : teamSportType === "australianrules"
                  ? ele?.AROddLabel?.displayName
                    ? ele?.AROddLabel?.displayName
                    : ele?.AROddLabel?.name
                  : teamSportType === "golf"
                    ? ele?.GolfOddLabel?.displayName
                      ? ele?.GolfOddLabel?.displayName
                      : ele?.GolfOddLabel?.name
                    : teamSportType === "tennis"
                      ? ele?.TennisOddLabel?.displayName
                        ? ele?.TennisOddLabel?.displayName
                        : ele?.TennisOddLabel?.name
                      : teamSportType === "baseball"
                        ? ele?.BaseballOddLabel?.displayName
                          ? ele?.BaseballOddLabel?.displayName
                          : ele?.BaseballOddLabel?.name
                        : teamSportType === "icehockey"
                          ? ele?.IceHockeyOddLabel?.displayName
                            ? ele?.IceHockeyOddLabel?.displayName
                            : ele?.IceHockeyOddLabel?.name
                          : teamSportType === "boxing"
                            ? ele?.BoxingOddLabel?.displayName
                              ? ele?.BoxingOddLabel?.displayName
                              : ele?.BoxingOddLabel?.name
                            : teamSportType === "mma"
                              ? ele?.MMAOddLabel?.displayName
                                ? ele?.MMAOddLabel?.displayName
                                : ele?.MMAOddLabel?.name
                              : teamSportType === "soccer"
                                ? ele?.SoccerOddLabel?.displayName
                                  ? ele?.SoccerOddLabel?.displayName
                                  : ele?.SoccerOddLabel?.name
                                : ele?.RLOddLabel?.displayName
                                  ? ele?.RLOddLabel?.displayName
                                  : ele?.RLOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("over") ||
          name?.toLowerCase()?.includes("totalover")
        );
      });
      let UnderData = allTeamOdds?.filter((ele) => {
        let LabelName =
          teamSportType === "cricket"
            ? ele?.CricketOddLabel?.displayName
              ? ele?.CricketOddLabel?.displayName
              : ele?.CricketOddLabel?.name
            : teamSportType === "basketball"
              ? ele?.NBAOddLabel?.displayName
                ? ele?.NBAOddLabel?.displayName
                : ele?.NBAOddLabel?.name
              : teamSportType === "americanfootball"
                ? ele?.AFLOddLabel?.displayName
                  ? ele?.AFLOddLabel?.displayName
                  : ele?.AFLOddLabel?.name
                : teamSportType === "australianrules"
                  ? ele?.AROddLabel?.displayName
                    ? ele?.AROddLabel?.displayName
                    : ele?.AROddLabel?.name
                  : teamSportType === "golf"
                    ? ele?.GolfOddLabel?.displayName
                      ? ele?.GolfOddLabel?.displayName
                      : ele?.GolfOddLabel?.name
                    : teamSportType === "tennis"
                      ? ele?.TennisOddLabel?.displayName
                        ? ele?.TennisOddLabel?.displayName
                        : ele?.TennisOddLabel?.name
                      : teamSportType === "baseball"
                        ? ele?.BaseballOddLabel?.displayName
                          ? ele?.BaseballOddLabel?.displayName
                          : ele?.BaseballOddLabel?.name
                        : teamSportType === "icehockey"
                          ? ele?.IceHockeyOddLabel?.displayName
                            ? ele?.IceHockeyOddLabel?.displayName
                            : ele?.IceHockeyOddLabel?.name
                          : teamSportType === "boxing"
                            ? ele?.BoxingOddLabel?.displayName
                              ? ele?.BoxingOddLabel?.displayName
                              : ele?.BoxingOddLabel?.name
                            : teamSportType === "mma"
                              ? ele?.MMAOddLabel?.displayName
                                ? ele?.MMAOddLabel?.displayName
                                : ele?.MMAOddLabel?.name
                              : teamSportType === "soccer"
                                ? ele?.SoccerOddLabel?.displayName
                                  ? ele?.SoccerOddLabel?.displayName
                                  : ele?.SoccerOddLabel?.name
                                : ele?.RLOddLabel?.displayName
                                  ? ele?.RLOddLabel?.displayName
                                  : ele?.RLOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("under") ||
          name?.toLowerCase()?.includes("totalunder")
        );
      });
      teamData = team === "over" ? OverData : UnderData;
    }
    if (type === "odds") {
      if (teamData) {
        let odds = teamData
          ?.map((obj) => {
            if (obj?.BookKeeperId === ProviderId) {
              return { odds: obj?.odd, bookkeeper: obj?.BookKeeperId };
            }
          })
          ?.filter((x) => x !== undefined);
        if (odds?.length > 0 && odds?.[0]?.odds != 0) {
          return fetchClickableOdds(
            odds?.[0]?.odds,
            odds?.[0]?.bookkeeper,
            "header",
            teamData,
            data,
            "betslip",
            false,
            "featuredOdds",
            team,
          );
          // return odds?.[0];
        } else {
          return null;
        }
      } else {
        return null;
        // <Tooltip
        //   title="No odds available"
        //   className="odds-tooltip"
        //   placement="top"
        // >
        //   <span className="odds-btn">
        //     <span className="no-odds">NOA</span>
        //   </span>
        // </Tooltip>
      }
    } else if (type === "spreadpoints") {
      if (teamData) {
        let odds = teamData
          ?.map((obj) => {
            if (obj?.BookKeeperId === ProviderId) {
              let LabelName =
                teamSportType === "cricket"
                  ? obj?.CricketOddLabel?.displayName
                    ? obj?.CricketOddLabel?.displayName
                    : obj?.CricketOddLabel?.name
                  : teamSportType === "basketball"
                    ? obj?.NBAOddLabel?.displayName
                      ? obj?.NBAOddLabel?.displayName
                      : obj?.NBAOddLabel?.name
                    : teamSportType === "americanfootball"
                      ? obj?.AFLOddLabel?.displayName
                        ? obj?.AFLOddLabel?.displayName
                        : obj?.AFLOddLabel?.name
                      : teamSportType === "australianrules"
                        ? obj?.AROddLabel?.displayName
                          ? obj?.AROddLabel?.displayName
                          : obj?.AROddLabel?.name
                        : teamSportType === "golf"
                          ? obj?.GolfOddLabel?.displayName
                            ? obj?.GolfOddLabel?.displayName
                            : obj?.GolfOddLabel?.name
                          : teamSportType === "tennis"
                            ? obj?.TennisOddLabel?.displayName
                              ? obj?.TennisOddLabel?.displayName
                              : obj?.TennisOddLabel?.name
                            : teamSportType === "baseball"
                              ? obj?.BaseballOddLabel?.displayName
                                ? obj?.BaseballOddLabel?.displayName
                                : obj?.BaseballOddLabel?.name
                              : teamSportType === "icehockey"
                                ? obj?.IceHockeyOddLabel?.displayName
                                  ? obj?.IceHockeyOddLabel?.displayName
                                  : obj?.IceHockeyOddLabel?.name
                                : teamSportType === "boxing"
                                  ? obj?.BoxingOddLabel?.displayName
                                    ? obj?.BoxingOddLabel?.displayName
                                    : obj?.BoxingOddLabel?.name
                                  : teamSportType === "mma"
                                    ? obj?.MMAOddLabel?.displayName
                                      ? obj?.MMAOddLabel?.displayName
                                      : obj?.MMAOddLabel?.name
                                    : teamSportType === "soccer"
                                      ? obj?.SoccerOddLabel?.displayName
                                        ? obj?.SoccerOddLabel?.displayName
                                        : obj?.SoccerOddLabel?.name
                                      : obj?.RLOddLabel?.displayName
                                        ? obj?.RLOddLabel?.displayName
                                        : obj?.RLOddLabel?.name;
              let name = LabelName ? LabelName : obj?.label;
              return obj?.point
                ? MarketName?.toLowerCase()?.includes("total")
                  ? name + " " + obj?.point
                  : obj?.point
                : "";
            }
          })
          ?.filter((x) => x !== undefined);
        if (odds?.length > 0) {
          return odds?.[0];
        } else {
        }
      } else {
      }
    }
  };
  const OutrightsTab =
    release[Config.release]?.Outrights === false ? "Outrights" : "";
  const FluctuationGraph =
    release[Config.release]?.FluctuationGraph === false
      ? "Fluctuation Graph"
      : "";

  const fetchNewOutrightClickableOdds = (
    odds,
    BookKeeperId,
    type,
    item,
    fulldata,
    IsBetslip,
    Isscore,
  ) => {
    const BookKeeperData = item?.filter(
      (obj) => obj?.BookKeeperId === BookKeeperId,
    );
    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    const oddsInfo = BookKeeperData?.[0];
    let isAdded = BetslipData?.some((el) => {
      if (
        el?.BookKeeperId == BookKeeperId &&
        el?.betOfferId == oddsInfo?.id &&
        el?.eventId == fulldata?.id
      )
        return true;
      else return false;
    });

    return (
      // {/* <span
      //   className="odds-link"
      //   onClick={() => handleAddToBetSlip(BookKeeperId, type, item, fulldata)}
      //   //  onClick={() => { IsBetslip === "nobetslip" ? handleBookkeeperCounter(BookKeeperId, type) : handleAddToBetSlip(BookKeeperId, type, item, fulldata) }}
      // >
      //   {Number(odds).toFixed(2)}
      // </span> */}
      // </a>
      <>
        {odds || odds === 0 ? (
          <span
            className="odds-btn cursor-pointer outright-odds-btn"
            onClick={(e) => {
              IsBetslip === "nobetslip"
                ? handleBookkeeperCounter(BookKeeperId, type, e)
                : isAdded
                  ? handleDeleteBetslip(BookKeeperId, item, fulldata, e)
                  : handleAddToBetSlip(
                      BookKeeperId,
                      type,
                      item,
                      fulldata,
                      Isscore,
                      e,
                    );
            }}
          >
            <span
              className={
                isAdded && IsBetslip === "betslip"
                  ? "betslip-added odds-link"
                  : "odds-link"
              }
            >
              {IsBetslip === "betslip" && !Isscore ? (
                <Tooltip title="Bet" className="bet-tooltip" placement="top">
                  <span className="bet-now">
                    {" "}
                    {odds === 0 ? "SP" : Number(odds).toFixed(2)}{" "}
                  </span>
                </Tooltip>
              ) : (
                <>{odds === 0 ? "SP" : Number(odds).toFixed(2)}</>
              )}
            </span>
          </span>
        ) : (
          <span className="odds-btn">-</span>
        )}
      </>
    );
    // }
  };

  const fetchNewOutrightCurrentBestOdds = (
    data,
    type,
    team,
    teamid,
    Isscore,
  ) => {
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : teamSportType === "americanfootball"
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : teamSportType === "australianrules"
              ? data?.ARBetOffers?.[0]?.AROdds
              : teamSportType === "golf"
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : teamSportType === "tennis"
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : teamSportType === "baseball"
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : teamSportType === "icehockey"
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : teamSportType === "boxing"
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : teamSportType === "mma"
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : teamSportType === "soccer"
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.homeTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.homeTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.homeTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.homeTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.homeTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.homeTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.awayTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.awayTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.awayTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.awayTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.awayTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.awayTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });
    let OutrightsTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == teamid
        : teamSportType === "basketball"
          ? item?.NBATeamId == teamid
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == teamid
            : teamSportType === "australianrules"
              ? item?.ARTeamId == teamid
              : teamSportType === "golf"
                ? item?.GolfTeamId == teamid
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == teamid
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == teamid
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == teamid
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == teamid
                        : teamSportType === "mma"
                          ? item?.MMATeamId == teamid
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == teamid
                            : item?.RLTeamId == teamid;
    });

    let teamInfo =
      team === "hometeam"
        ? HomeTeamOdds
        : team === "awayteam"
          ? AwayTeamOdds
          : OutrightsTeamOdds;
    let teamData = MarketName?.toLowerCase()?.includes("total")
      ? allTeamOdds
      : teamInfo;
    if (MarketName?.toLowerCase()?.includes("total")) {
      let OverData = allTeamOdds?.filter((ele) => {
        let LabelName =
          teamSportType === "cricket"
            ? ele?.CricketOddLabel?.displayName
              ? ele?.CricketOddLabel?.displayName
              : ele?.CricketOddLabel?.name
            : teamSportType === "basketball"
              ? ele?.NBAOddLabel?.displayName
                ? ele?.NBAOddLabel?.displayName
                : ele?.NBAOddLabel?.name
              : teamSportType === "americanfootball"
                ? ele?.AFLOddLabel?.displayName
                  ? ele?.AFLOddLabel?.displayName
                  : ele?.AFLOddLabel?.name
                : teamSportType === "australianrules"
                  ? ele?.AROddLabel?.displayName
                    ? ele?.AROddLabel?.displayName
                    : ele?.AROddLabel?.name
                  : teamSportType === "golf"
                    ? ele?.GolfOddLabel?.displayName
                      ? ele?.GolfOddLabel?.displayName
                      : ele?.GolfOddLabel?.name
                    : teamSportType === "tennis"
                      ? ele?.TennisOddLabel?.displayName
                        ? ele?.TennisOddLabel?.displayName
                        : ele?.TennisOddLabel?.name
                      : teamSportType === "baseball"
                        ? ele?.BaseballOddLabel?.displayName
                          ? ele?.BaseballOddLabel?.displayName
                          : ele?.BaseballOddLabel?.name
                        : teamSportType === "icehockey"
                          ? ele?.IceHockeyOddLabel?.displayName
                            ? ele?.IceHockeyOddLabel?.displayName
                            : ele?.IceHockeyOddLabel?.name
                          : teamSportType === "boxing"
                            ? ele?.BoxingOddLabel?.displayName
                              ? ele?.BoxingOddLabel?.displayName
                              : ele?.BoxingOddLabel?.name
                            : teamSportType === "mma"
                              ? ele?.MMAOddLabel?.displayName
                                ? ele?.MMAOddLabel?.displayName
                                : ele?.MMAOddLabel?.name
                              : teamSportType === "soccer"
                                ? ele?.SoccerOddLabel?.displayName
                                  ? ele?.SoccerOddLabel?.displayName
                                  : ele?.SoccerOddLabel?.name
                                : ele?.RLOddLabel?.displayName
                                  ? ele?.RLOddLabel?.displayName
                                  : ele?.RLOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("over") ||
          name?.toLowerCase()?.includes("totalover")
        );
      });
      let UnderData = allTeamOdds?.filter((ele) => {
        let LabelName =
          teamSportType === "cricket"
            ? ele?.CricketOddLabel?.displayName
              ? ele?.CricketOddLabel?.displayName
              : ele?.CricketOddLabel?.name
            : teamSportType === "basketball"
              ? ele?.NBAOddLabel?.displayName
                ? ele?.NBAOddLabel?.displayName
                : ele?.NBAOddLabel?.name
              : teamSportType === "americanfootball"
                ? ele?.AFLOddLabel?.displayName
                  ? ele?.AFLOddLabel?.displayName
                  : ele?.AFLOddLabel?.name
                : teamSportType === "australianrules"
                  ? ele?.AROddLabel?.displayName
                    ? ele?.AROddLabel?.displayName
                    : ele?.AROddLabel?.name
                  : teamSportType === "golf"
                    ? ele?.GolfOddLabel?.displayName
                      ? ele?.GolfOddLabel?.displayName
                      : ele?.GolfOddLabel?.name
                    : teamSportType === "tennis"
                      ? ele?.TennisOddLabel?.displayName
                        ? ele?.TennisOddLabel?.displayName
                        : ele?.TennisOddLabel?.name
                      : teamSportType === "baseball"
                        ? ele?.BaseballOddLabel?.displayName
                          ? ele?.BaseballOddLabel?.displayName
                          : ele?.BaseballOddLabel?.name
                        : teamSportType === "icehockey"
                          ? ele?.IceHockeyOddLabel?.displayName
                            ? ele?.IceHockeyOddLabel?.displayName
                            : ele?.IceHockeyOddLabel?.name
                          : teamSportType === "boxing"
                            ? ele?.BoxingOddLabel?.displayName
                              ? ele?.BoxingOddLabel?.displayName
                              : ele?.BoxingOddLabel?.name
                            : teamSportType === "mma"
                              ? ele?.MMAOddLabel?.displayName
                                ? ele?.MMAOddLabel?.displayName
                                : ele?.MMAOddLabel?.name
                              : teamSportType === "soccer"
                                ? ele?.SoccerOddLabel?.displayName
                                  ? ele?.SoccerOddLabel?.displayName
                                  : ele?.SoccerOddLabel?.name
                                : ele?.RLOddLabel?.displayName
                                  ? ele?.RLOddLabel?.displayName
                                  : ele?.RLOddLabel?.name;
        let name = LabelName ? LabelName : ele?.label;
        return (
          name?.toLowerCase()?.includes("under") ||
          name?.toLowerCase()?.includes("totalunder")
        );
      });
      teamData = team === "over" ? OverData : UnderData;
    }
    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (type === "odds") {
      if (maxno !== -1) {
        if (maxno !== 0) {
          let bookkeeperid = teamData
            ?.map((obj) => {
              if (obj?.odd === maxno) {
                return obj?.BookKeeperId;
              }
            })
            ?.filter((x) => x !== undefined);
          return fetchNewOutrightClickableOdds(
            maxno,
            bookkeeperid?.[0],
            "header",
            teamData,
            data,
            "betslip",
            Isscore,
          );
        } else {
          return <span className="odds-btn">SP</span>;
        }
      } else {
        return <span className="odds-btn">-</span>;
      }
    } else if (type === "points") {
      if (maxno !== -1) {
        if (maxno !== 0) {
          let point = teamData
            ?.map((obj) => {
              if (obj?.odd === maxno) {
                let LabelName =
                  teamSportType === "cricket"
                    ? obj?.CricketOddLabel?.displayName
                      ? obj?.CricketOddLabel?.displayName
                      : obj?.CricketOddLabel?.name
                    : teamSportType === "basketball"
                      ? obj?.NBAOddLabel?.displayName
                        ? obj?.NBAOddLabel?.displayName
                        : obj?.NBAOddLabel?.name
                      : teamSportType === "americanfootball"
                        ? obj?.AFLOddLabel?.displayName
                          ? obj?.AFLOddLabel?.displayName
                          : obj?.AFLOddLabel?.name
                        : teamSportType === "australianrules"
                          ? obj?.AROddLabel?.displayName
                            ? obj?.AROddLabel?.displayName
                            : obj?.AROddLabel?.name
                          : teamSportType === "golf"
                            ? obj?.GolfOddLabel?.displayName
                              ? obj?.GolfOddLabel?.displayName
                              : obj?.GolfOddLabel?.name
                            : teamSportType === "tennis"
                              ? obj?.TennisOddLabel?.displayName
                                ? obj?.TennisOddLabel?.displayName
                                : obj?.TennisOddLabel?.name
                              : teamSportType === "baseball"
                                ? obj?.BaseballOddLabel?.displayName
                                  ? obj?.BaseballOddLabel?.displayName
                                  : obj?.BaseballOddLabel?.name
                                : teamSportType === "icehockey"
                                  ? obj?.IceHockeyOddLabel?.displayName
                                    ? obj?.IceHockeyOddLabel?.displayName
                                    : obj?.IceHockeyOddLabel?.name
                                  : teamSportType === "boxing"
                                    ? obj?.BoxingOddLabel?.displayName
                                      ? obj?.BoxingOddLabel?.displayName
                                      : obj?.BoxingOddLabel?.name
                                    : teamSportType === "mma"
                                      ? obj?.MMAOddLabel?.displayName
                                        ? obj?.MMAOddLabel?.displayName
                                        : obj?.MMAOddLabel?.name
                                      : teamSportType === "soccer"
                                        ? obj?.SoccerOddLabel?.displayName
                                          ? obj?.SoccerOddLabel?.displayName
                                          : obj?.SoccerOddLabel?.name
                                        : obj?.RLOddLabel?.displayName
                                          ? obj?.RLOddLabel?.displayName
                                          : obj?.RLOddLabel?.name;
                let name = LabelName ? LabelName : obj?.label;
                return obj?.point
                  ? MarketName?.toLowerCase()?.includes("total")
                    ? name + " " + obj?.point
                    : obj?.point
                  : "";
              }
            })
            ?.filter((x) => x !== undefined);
          return point?.[0];
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  };
  const fetchNewOutrightCurrentBestsOddsIcon = (data, type, team, teamid) => {
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
          ? data?.NBABetOffers?.[0]?.NBAOdds
          : teamSportType === "americanfootball"
            ? data?.AFLBetOffers?.[0]?.AFLOdds
            : teamSportType === "australianrules"
              ? data?.ARBetOffers?.[0]?.AROdds
              : teamSportType === "golf"
                ? data?.GolfBetOffers?.[0]?.GolfOdds
                : teamSportType === "tennis"
                  ? data?.TennisBetOffers?.[0]?.TennisOdds
                  : teamSportType === "baseball"
                    ? data?.BaseballBetOffers?.[0]?.BaseballOdds
                    : teamSportType === "icehockey"
                      ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
                      : teamSportType === "boxing"
                        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
                        : teamSportType === "mma"
                          ? data?.MMABetOffers?.[0]?.MMAOdds
                          : teamSportType === "soccer"
                            ? data?.SoccerBetOffers?.[0]?.SoccerOdds
                            : data?.RLBetOffers?.[0]?.RLOdds;
    let HomeTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.homeTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.homeTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.homeTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.homeTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.homeTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.homeTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.homeTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.homeTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.homeTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.homeTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.homeTeamId
                            : item?.RLTeamId == data?.homeTeamId;
    });
    let AwayTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == data?.awayTeamId
        : teamSportType === "basketball"
          ? item?.NBATeamId == data?.awayTeamId
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == data?.awayTeamId
            : teamSportType === "australianrules"
              ? item?.ARTeamId == data?.awayTeamId
              : teamSportType === "golf"
                ? item?.GolfTeamId == data?.awayTeamId
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == data?.awayTeamId
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == data?.awayTeamId
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == data?.awayTeamId
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == data?.awayTeamId
                        : teamSportType === "mma"
                          ? item?.MMATeamId == data?.awayTeamId
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == data?.awayTeamId
                            : item?.RLTeamId == data?.awayTeamId;
    });
    let OutrightsTeamOdds = allTeamOdds?.filter((item) => {
      return teamSportType === "cricket"
        ? item?.CricketTeamId == teamid
        : teamSportType === "basketball"
          ? item?.NBATeamId == teamid
          : teamSportType === "americanfootball"
            ? item?.AFLTeamId == teamid
            : teamSportType === "australianrules"
              ? item?.ARTeamId == teamid
              : teamSportType === "golf"
                ? item?.GolfTeamId == teamid
                : teamSportType === "tennis"
                  ? item?.TennisTeamId == teamid
                  : teamSportType === "baseball"
                    ? item?.BaseballTeamId == teamid
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyTeamId == teamid
                      : teamSportType === "boxing"
                        ? item?.BoxingTeamId == teamid
                        : teamSportType === "mma"
                          ? item?.MMATeamId == teamid
                          : teamSportType === "soccer"
                            ? item?.SoccerTeamId == teamid
                            : item?.RLTeamId == teamid;
    });
    let teamInfo =
      team === "hometeam"
        ? HomeTeamOdds
        : team === "awayteam"
          ? AwayTeamOdds
          : OutrightsTeamOdds;
    let teamData = MarketName?.toLowerCase()?.includes("total")
      ? allTeamOdds
      : teamInfo;
    if (MarketName?.toLowerCase()?.includes("total")) {
      let OverData = allTeamOdds?.filter((ele) => {
        return ele?.label?.toLowerCase()?.includes("over");
      });
      let UnderData = allTeamOdds?.filter((ele) => {
        return ele?.label?.toLowerCase()?.includes("under");
      });
      teamData = team === "over" ? OverData : UnderData;
    }
    let maxno = teamData?.reduce((max, obj) => {
      let oddsType = obj?.odd ? obj?.odd : 0;
      oddsType > max ? (max = oddsType) : (max = max);
      return max;
    }, -1);
    if (maxno !== -1) {
      if (maxno && maxno !== 0) {
        let providerid = teamData
          ?.map((obj) => {
            let oddsType = obj?.odd;
            if (oddsType === maxno) {
              return obj?.BookKeeperId;
            }
          })
          ?.filter((x) => x !== undefined);

        return oddsiconNewOutright(providerid?.[0], "header", teamData, data);
      } else {
        return "";
        // <span className="odds"> - </span>
      }
    } else {
      return "";
      // <span className="odds"> - </span>
    }
  };
  const oddsiconNewOutright = (BookKeeperId, type, item, fullData) => {
    const Identifiers =
      teamSportType === "cricket"
        ? fullData?.CricketIdentifiers
        : teamSportType === "basketball"
          ? fullData?.NBAIdentifiers
          : teamSportType === "americanfootball"
            ? fullData?.AFLIdentifiers
            : teamSportType === "australianrules"
              ? fullData?.ARIdentifiers
              : teamSportType === "golf"
                ? fullData?.GolfIdentifiers
                : teamSportType === "tennis"
                  ? fullData?.TennisIdentifiers
                  : teamSportType === "baseball"
                    ? fullData?.BaseballIdentifiers
                    : teamSportType === "icehockey"
                      ? fullData?.IceHockeyIdentifiers
                      : teamSportType === "boxing"
                        ? fullData?.BoxingIdentifiers
                        : teamSportType === "mma"
                          ? fullData?.MMAIdentifiers
                          : teamSportType === "soccer"
                            ? fullData?.SoccerIdentifiers
                            : fullData?.RLIdentifiers;
    const newData = Identifiers?.map((obj) => {
      const BookkeeperObj = obj?.ApiProvider?.BookKeeperProviders?.filter(
        (item) => {
          return item?.BookKeeperId === BookKeeperId;
        },
      );
      return {
        ...obj,
        BookKeeperId:
          BookkeeperObj?.length > 0 ? BookkeeperObj?.[0]?.BookKeeperId : null,
      };
    });
    const filteredData = newData
      ?.filter?.((obj) => obj?.BookKeeperId === BookKeeperId)
      ?.filter((obj) => obj?.ApiProviderId !== 17);

    let icon = BookkeeperData?.filter((obj) => obj?.id === BookKeeperId);
    let iconData = icon?.[0];
    if (BookKeeperId === 8) {
      const Bet365Data = item?.filter((obj) => obj?.BookKeeperId === 8);

      const Odds = Bet365Data?.filter(
        (ele) => ele?.providerMarketId && ele?.providerParticipantId,
      );
      const urlLink =
        Odds?.[0]?.providerMarketId && Odds?.[0]?.providerParticipantId
          ? `https://www.bet365.com/dl/sportsbookredirect?affiliate=365_00967140&bs=${Odds?.[0]?.providerMarketId}-${Odds?.[0]?.providerParticipantId}~${Odds?.[0]?.odd}~1&bet=1`
          : `${iconData?.affiliate_link}`;
      return (
        <Box
          // href={
          //
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="odds-link bookmaker-wrap outright-icon-wrap"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          <img
            className="bookmaker-thumb outright-bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            onClick={(e) => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              handleAnchorTagClick(e, urlLink);
            }}
            alt="Odds Icon"
          />
        </Box>
      );
    } else {
      const urlLink =
        filteredData?.length > 0 && filteredData?.[0]?.url
          ? filteredData?.[0]?.url + `?Referrer=SmartB`
          : iconData?.affiliate_link;
      return (
        <Box
          // href={
          //   filteredData?.length > 0 && filteredData?.[0]?.url
          //     ? filteredData?.[0]?.url + `?Referrer=SmartB`
          //     : iconData?.affiliate_link
          // }
          // target="_blank"
          // rel="noopener noreferrer"
          className="bookmaker-wrap outright-bookmaker-thumb"
          // onClick={e => handleAnchorTagClick(e, urlLink)}
        >
          <img
            className="bookmaker-thumb outright-bookmaker-thumb"
            src={
              iconData?.small_logo?.includes("uploads")
                ? Config.mediaURL + iconData?.small_logo
                : iconData?.small_logo
            }
            onClick={(e) => {
              handleBookkeeperCounter(BookKeeperId, type, e);
              handleAnchorTagClick(e, urlLink);
            }}
            alt="Odds Icon"
          />
        </Box>
      );
    }
  };
  return (
    <Box className="team-sports-layout">
      <Box className="layout-header">
        <Box className="bredcrumn-wrap">
          <Breadcrumbs
            separator="/"
            aria-label="breadcrumb"
            className="breadcrumb"
          >
            <Link underline="hover" color="inherit" to="/">
              {localesData?.MENU?.HOME}
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to=""
              className="cursor-default"
            >
              {localesData?.MENU?.SPORTS}
            </Link>
            {params?.seeall === "false" && !(tabvalue === 1) ? (
              <Typography>{params?.type}</Typography>
            ) : (
              <Link
                underline="hover"
                color="inherit"
                to={`/teamsports/${params?.type}/odds/0/false`}
              >
                {teamSportType}
              </Link>
            )}
            {params?.seeall === "true" && (
              <Typography>{individualTeamData?.eventName}</Typography>
            )}
            {tabvalue === 1 ? <Typography> MATCHUPS</Typography> : ""}
          </Breadcrumbs>
        </Box>
        <Box className="title-btn-box">
          <Typography variant="h1">
            {tabvalue === 1 ? (
              " MATCHUPS "
            ) : (
              <>
                {teamSportType === "cricket"
                  ? "Cricket"
                  : teamSportType === "rugbyleague"
                    ? "Rugby League"
                    : teamSportType === "rugbyunion"
                      ? "Rugby Union"
                      : teamSportType === "basketball"
                        ? "Basketball "
                        : teamSportType === "americanfootball"
                          ? "American Football"
                          : teamSportType === "australianrules"
                            ? "Australian Rules "
                            : teamSportType === "golf"
                              ? "Golf"
                              : teamSportType === "tennis"
                                ? "Tennis"
                                : teamSportType === "baseball"
                                  ? "Baseball"
                                  : teamSportType === "icehockey"
                                    ? "Ice Hockey"
                                    : teamSportType === "boxing"
                                      ? "Boxing"
                                      : teamSportType === "mma"
                                        ? "mma"
                                        : teamSportType === "soccer"
                                          ? "soccer"
                                          : "Rugby Union Sevens"}
              </>
            )}
          </Typography>

          {expertTipsCount > 0 &&
            (teamSportType === "cricket" ||
              teamSportType === "rugbyleague" ||
              teamSportType === "australianrules") && (
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                className="exprt-tips-btn"
                onClick={() => {
                  handleViewExpertTips();
                }}
                startIcon={<WhiteBulb />}
              >
                <span className="btn-txt">View Expert Tips</span>
              </Button>
            )}
        </Box>
      </Box>
      <Box className="team-sports-tab">
        <Tabs
          value={tabvalue}
          variant="scrollable"
          scrollButtons={false}
          indicatorColor="primary"
          textColor="primary"
          className="teamsport-tab-detail"
        >
          {newTabData
            ?.filter(
              (item) =>
                item?.name !== OutrightsTab && item?.name !== FluctuationGraph,
            )
            ?.map((item, index) => {
              return (
                <Box key={index}>
                  <Tab
                    label={
                      <Box className="tab-label">
                        <span className="label-name">{item?.name}</span>
                        {/* <span className="label-icon">{item?.icon}</span> */}
                      </Box>
                    }
                    value={item?.id}
                    className={item?.id === tabvalue ? "active " : ""}
                    onChange={(event, newValue) =>
                      handleTabChange(event, item?.id)
                    }
                  />
                </Box>
              );
            })}
        </Tabs>
        {tabvalue === 6 ? (
          <>
            <Box>
              <Tabs
                value={stateTabValue}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                className="state-tab-details"
                // disableRipple
                // disableFocusRipple
                id="state-tab"
              >
                {statsData.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Tab
                        label={item?.label}
                        value={item?.value}
                        onChange={() => handleChange(item?.value, item?.label)}
                        className={
                          item?.value === stateTabValue ? "active" : ""
                        }
                      />
                    </Box>
                  );
                })}
              </Tabs>
            </Box>
          </>
        ) : tabvalue === 4 ? (
          <>
            <TextField
              placeholder={"Search"}
              variant="outlined"
              type="text"
              name="search"
              className="search_input search_input_15"
              onChange={(e) => {
                handleSearch(e);
              }}
              value={searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : tabvalue === 0 || tabvalue === 2 || tabvalue === 3 ? (
          <Box className="select-wrap">
            <Select
              className="React series-select"
              value={TournamentOptions?.find((item) => {
                return item?.value === selectedseries;
              })}
              onChange={(e) => {
                setselectedseries(e?.value == 0 ? "" : e?.value);
                // setTournamentSelect(false);
                navigate(`/teamsports/${params?.type}/odds/0/false`);
                if (e?.value) {
                } else {
                  fetchDropDowndata(
                    0,
                    tabvalue === 2 ? "outrights" : undefined,
                  );
                }
              }}
              // onMenuScrollToBottom={e => handleOnScrollBottomTournament(e)}
              options={TournamentOptions}
              classNamePrefix="select"
              placeholder="All Tournaments"
              // isSearchable={false}
              // onFocus={() => setTournamentSelect(true)}
              // onBlur={() => setTournamentSelect(false)}
              components={{ DropdownIndicator }}
            />

            {tabvalue === 3 && reduxSubscriptionData?.isPremiumUser && (
              <Select
                className="React odds-select"
                value={providerOption?.find((item) => {
                  return item?.value === selectedGraphId;
                })}
                onChange={(e) => setSelectedGraphId(e?.value)}
                options={providerOption}
                isOptionDisabled={(option) => option.disabled}
                classNamePrefix="select"
                placeholder="Odds"
                isSearchable={false}
                components={{ DropdownIndicator }}
              />
            )}
            {!(tabvalue == 0 && params?.seeall === "false") && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  clearable
                  autoOk
                  // disableToolbar
                  // variant="inline"
                  format="dd/MM/yyyy"
                  placeholder="All"
                  margin="normal"
                  id="date-picker-inline"
                  inputVariant="outlined"
                  value={selectedDate ? parseISO(selectedDate) : null}
                  onChange={(e) => handleDateChange(e)}
                  // onClear={clearDate}
                  // open={open}
                  // onOpen={() => setOpen(true)}
                  // onClose={() => setOpen(false)}
                  // views={["year", "month", "date"]}
                  // disableFuture={value == 8}
                  // disablePast={value == 7}
                  // style={{ marginRight: 5 }}
                  slots={{
                    openPickerIcon: DatepickerBG, // Custom icon
                  }}
                  slotProps={{
                    field: {
                      id: "date-picker-inline",
                      placeholder: "DD/MM/YYYY",
                    },
                  }}
                  className="details-search-picker"
                />
              </LocalizationProvider>
            )}
            <Select
              className={
                tabvalue == 0
                  ? `React team-select fixture-team-select`
                  : `React team-select`
              }
              value={TeamOptions?.find((item) => {
                return item?.value === selectedteam;
              })}
              onChange={(e) => {
                setselectedteam(e?.value == 0 ? "" : e?.value);
                // setTeamSelect(false);
                navigate(`/teamsports/${params?.type}/odds/0/false`);
              }}
              // onFocus={() => setTeamSelect(true)}
              // onBlur={() => setTeamSelect(false)}
              // onMenuScrollToBottom={e => handleOnScrollBottomTeam(e)}
              options={TeamOptions}
              classNamePrefix="select"
              placeholder="All Teams"
              // isSearchable={false}
              components={{ DropdownIndicator }}
            />
            {tabvalue === 0 ? (
              params?.seeall === "false" ? (
                <Select
                  className={
                    showOdds
                      ? `React market-select`
                      : `React market-select disable-state`
                  }
                  value={MarketList?.find((item) => {
                    return item?.value === selectedOption;
                  })}
                  onChange={(e) => handleMarketChange(e)}
                  menuPosition="fixed"
                  options={MarketList}
                  classNamePrefix="select"
                  isSearchable={false}
                  components={{ DropdownIndicator }}
                  isDisabled={!showOdds}
                />
              ) : (
                <Select
                  className="React market-select"
                  value={IndividualMarketList?.find((item) => {
                    return item?.value === selectedMarket;
                  })}
                  onChange={(e) => handleIndividualMarketChange(e)}
                  menuPosition="fixed"
                  options={IndividualMarketList}
                  classNamePrefix="select"
                  isSearchable={false}
                  components={{ DropdownIndicator }}
                />
              )
            ) : (
              <></>
            )}
            {/* </Box> */}
            {teamSportType === "rugbyunion" ? (
              <Box className="stepper-input">
                <Button
                  className="stepper-input__button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleStepper("left");
                    navigate(`/teamsports/${params?.type}/odds/0/false`);
                  }}
                  disabled={stepperCount == 0}
                >
                  <LeftArrow />
                </Button>

                <div className="stepper-input__content">
                  {stepperCount === 0 ? "Round" : `Round ${stepperCount}`}
                </div>

                <Button
                  className="stepper-input__button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleStepper("right");
                    navigate(`/teamsports/${params?.type}/odds/0/false`);
                  }}
                >
                  <RightArrow />
                </Button>
              </Box>
            ) : (
              <></>
            )}

            <Box className="switch-box">
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    checked={showAllFixture}
                    onChange={(e) => handleShowAllFixture(e)}
                  />
                }
                label="Show All Fixtures"
                labelPlacement="start"
              />
            </Box>
            {Config.release == "AU" ? (
              (tabvalue === 0 && params?.seeall === "false") ||
              tabvalue === 2 ? (
                <>
                  <Box className="switch-box">
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          color="primary"
                          checked={showOdds}
                          onChange={(e) => handleShowAllOdds(e)}
                        />
                      }
                      label="Show Odds"
                      labelPlacement="start"
                    />
                  </Box>
                </>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Box>
        ) : tabvalue === 5 ? (
          <TournamentTeamDropdown
            teamSportType={teamSportType}
            selectedOrg={selectedOrg}
            setSelectedOrg={setSelectedOrg}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            selectedOrgLabel={selectedOrgLabel}
            setSelectedOrgLabel={setSelectedOrgLabel}
            selectedTeamsLabel={selectedTeamsLabel}
            setSelectedTeamsLabel={setSelectedTeamsLabel}
          />
        ) : (
          <Box className="select-wrap">
            <Select
              className="React series-select"
              value={MatchupTournamentOptions?.find((item) => {
                return item?.value === selectedseries;
              })}
              onChange={(e) => {
                setselectedseries(e?.value == 0 ? "" : e?.value);
                // setTournamentSelect(false);
                navigate(`/teamsports/${params?.type}/odds/0/false`);
              }}
              // onMenuScrollToBottom={e => handleOnScrollBottomTournament(e)}
              options={MatchupTournamentOptions}
              classNamePrefix="select"
              placeholder="All Tournaments"
              // isSearchable={false}
              // onFocus={() => setTournamentSelect(true)}
              // onBlur={() => setTournamentSelect(false)}
              components={{ DropdownIndicator }}
            />
            {/* <Box className="year-team-select-wrap"> */}
            {/* {tabvalue === 3 && (
              <Select
                className="React odds-select"
                value={providerOption?.find(item => {
                  return item?.value === selectedGraphId;
                })}
                onChange={e => setSelectedGraphId(e?.value)}
                options={providerOption}
                isOptionDisabled={option => option.disabled}
                classNamePrefix="select"
                placeholder="Odds"
                isSearchable={false}
                components={{ DropdownIndicator }}
              />
            )} */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                clearable
                autoOk
                // disableToolbar
                // variant="inline"
                format="dd/MM/yyyy"
                placeholder="All"
                margin="normal"
                id="date-picker-inline"
                inputVariant="outlined"
                value={selectedDate ? parseISO(selectedDate) : null}
                onChange={(e) => handleDateChange(e)}
                // onClear={clearDate}
                // open={open}
                // onOpen={() => setOpen(true)}
                // onClose={() => setOpen(false)}
                // views={["year", "month", "date"]}
                // disableFuture={value == 8}
                // disablePast={value == 7}
                // style={{ marginRight: 5 }}
                // keyboardIcon={<DatepickerBG className="keyboard-icon-bg" />}
                slots={{
                  openPickerIcon: DatepickerBG, // Custom icon
                }}
                slotProps={{
                  field: {
                    id: "date-picker-inline",
                    placeholder: "DD/MM/YYYY",
                  },
                }}
                className="details-search-picker"
              />
            </LocalizationProvider>
            <Select
              className="React team-select"
              value={MatchupTeamOptions?.find((item) => {
                return item?.value === selectedteam;
              })}
              onChange={(e) => {
                setselectedteam(e?.value == 0 ? "" : e?.value);
                // setTeamSelect(false);
                navigate(`/teamsports/${params?.type}/odds/0/false`);
              }}
              // onFocus={() => setTeamSelect(true)}
              // onBlur={() => setTeamSelect(false)}
              // onMenuScrollToBottom={e => handleOnScrollBottomTeam(e)}
              options={MatchupTeamOptions}
              classNamePrefix="select"
              placeholder="All Teams"
              // isSearchable={false}
              components={{ DropdownIndicator }}
            />
            {/* </Box> */}
            {teamSportType === "rugbyunion" ? (
              <Box className="stepper-input">
                <Button
                  className="stepper-input__button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleStepper("left");
                    navigate(`/teamsports/${params?.type}/odds/0/false`);
                  }}
                  disabled={stepperCount == 0}
                >
                  <LeftArrow />
                </Button>

                <div className="stepper-input__content">
                  {stepperCount === 0 ? "Round" : `Round ${stepperCount}`}
                </div>

                <Button
                  className="stepper-input__button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleStepper("right");
                    navigate(`/teamsports/${params?.type}/odds/0/false`);
                  }}
                >
                  <RightArrow />
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        )}
        {tabvalue === 4 || tabvalue === 5 || tabvalue === 6 ? (
          <></>
        ) : (
          <Box className="disclaimer">
            {" "}
            *{localesData?.RUNNER_TABLE?.DISCLAIMER_TEXT}
          </Box>
        )}
        {tabvalue === 0 && params?.seeall === "false" && (
          <>
            <Box
              className="reset-all cursor-pointer"
              onClick={() => handleResetAll()}
            >
              <Reset />
              <Typography className="reset-all-text">Reset All</Typography>
            </Box>
            <Box className="live-date-filter-tab-wrap">
              <Box className="live-tab">
                <Tabs
                  value={dayLiveFilter}
                  variant="scrollable"
                  scrollButtons="auto"
                  indicatorColor="primary"
                  textColor="primary"
                  className="state-tab-details live-tab-details"
                  // disableRipple
                  // disableFocusRipple
                  id="state-tab"
                >
                  {dayFilter?.map((item, index) => {
                    return (
                      <Box key={index}>
                        <Tab
                          label={item?.dayName}
                          value={item?.id}
                          onChange={() =>
                            handleLiveDayChange(
                              item?.id,
                              item?.date,
                              item?.dayName,
                            )
                          }
                          className={
                            item?.id === dayLiveFilter ||
                            (item?.date && item?.date == dateSelected)
                              ? "active"
                              : ""
                          }
                        />
                      </Box>
                    );
                  })}
                </Tabs>
              </Box>
              <Box className="date-selected-wrap">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    clearable
                    autoOk
                    // disableToolbar
                    // variant="inline"
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    margin="normal"
                    id="date-picker-inline"
                    inputVariant="outlined"
                    value={dateSelected ? parseISO(dateSelected) : null}
                    onChange={(e) => handleLiveDateChange(e)}
                    // onClear={clearDate}
                    // open={open}
                    // onOpen={() => setOpen(true)}
                    // onClose={() => setOpen(false)}
                    // views={["year", "month", "date"]}
                    // KeyboardButtonProps={{
                    //   "aria-label": "Select Date"
                    // }}
                    // keyboardIcon={<Datepicker className="keyboard-icon" />}
                    slots={{
                      openPickerIcon: Datepicker, // Custom icon
                    }}
                    slotProps={{
                      field: {
                        id: "date-picker-inline",
                        placeholder: "DD/MM/YYYY",
                      },
                    }}
                    className="details-search-picker"
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {tabvalue === 0 && params?.seeall === "false" && (
        <Box className="team-sport-featured">
          <FeaturedSport data={params} />
        </Box>
      )}
      {tabvalue === 0 || (teamSportType !== "golf" && tabvalue === 3) ? (
        params?.seeall === "false" ? (
          isEventLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : (
            <>
              {neweventListData?.length > 0 ? (
                <>
                  {/* {fetchAds(2, "102px", AboveEventList)} */}
                  <Box
                    id="scrollableDiv"
                    // style={{ maxHeight: 1200, overflow: "auto" }}
                  >
                    <InfiniteScroll
                      dataLength={neweventListData?.length}
                      next={() =>
                        handleOnScrollBottomEvents(
                          EventPage + 20,
                          selectedOption,
                        )
                      }
                      hasMore={EventCount !== Math.ceil(EventPage / 20 + 1)}
                      loader={
                        <div className="allsport-loader-center ">
                          <Loader />
                        </div>
                      }
                      // scrollableTarget="scrollableDiv"
                    >
                      {neweventListData?.map((item, index) => {
                        const IsHomeCurrentBestAvailable =
                          fetchCurrentBestOdds(
                            item,
                            "odds",
                            MarketName?.toLowerCase()?.includes("total")
                              ? "over"
                              : "hometeam",
                          ) !== null;
                        const IsAwayCurrentBestAvailable =
                          fetchCurrentBestOdds(
                            item,
                            "odds",
                            MarketName?.toLowerCase()?.includes("total")
                              ? "under"
                              : "awayteam",
                          ) !== null;
                        return (
                          <>
                            <Box
                              className="team-sport-table-wrap cursor-pointer"
                              key={index}
                              onClick={() => handleEventBoxClick(item)}
                            >
                              <Box className="table-header-wrap">
                                <Box className="table-indicator-wrap">
                                  {/* <span className="sport-indicator-btn">
                                    {teamSportType === "cricket"
                                      ? item?.CricketTournament?.name
                                      : teamSportType === "rugbyleague" ||
                                        teamSportType === "rugbyunion" ||
                                        teamSportType === "rugbyunionsevens"
                                      ? item?.RLTournament?.name
                                      : teamSportType === "americanfootball"
                                      ? item?.AFLTournament?.name
                                      : teamSportType === "australianrules"
                                      ? item?.ARTournament?.name
                                      : teamSportType === "golf"
                                      ? item?.GolfTournament?.name
                                      : teamSportType === "tennis"
                                      ? item?.TennisTournament?.name
                                      : teamSportType === "baseball"
                                      ? item?.BaseballTournament?.name
                                      : teamSportType === "icehockey"
                                      ? item?.IceHockeyTournament?.name
                                      : teamSportType === "boxing"
                                      ? item?.BoxingTournament?.name
                                      : teamSportType === "mma"
                                      ? item?.MMATournament?.name
                                      : teamSportType === "soccer"
                                      ? item?.SoccerTournament?.name
                                      : teamSportType === "basketball"
                                      ? item?.NBATournament?.NBACategory ===
                                        null
                                        ? item?.NBATournament?.name
                                        : item?.NBATournament?.name +
                                          " " +
                                          item?.NBATournament?.NBACategory?.name
                                      : ""}
                                  </span> */}
                                  {((teamSportType === "cricket" ||
                                    teamSportType === "soccer") &&
                                    item?.ScoreBoard?.Epr === 1) ||
                                  item?.status == "inprogress" ? (
                                    <Box className="show-update-wrap">
                                      <Box>
                                        <FormControlLabel
                                          value="selling"
                                          className="show-update-checkbox"
                                          control={
                                            <Checkbox
                                              size="small"
                                              icon={
                                                <CheckboxUnChecked className="radio-icon" />
                                              }
                                              checkedIcon={
                                                <CheckBoxChecked className="radio-icon" />
                                              }
                                              disableRipple
                                              onClick={(e) =>
                                                handleShowUpdateCheckBox(e)
                                              }
                                              onChange={(e) => {
                                                setShowUpdate(
                                                  e?.target?.checked,
                                                );
                                                setShowUpdateIndex((pre) =>
                                                  pre === index ? "" : index,
                                                );
                                                fetchSingleMatchData(item);
                                              }}
                                              checked={
                                                showUpdateIndex === index
                                              }
                                            />
                                          }
                                        />
                                      </Box>
                                      <Typography
                                        className="show-update"
                                        onClick={(e) => {
                                          handleShowUpdateCheckBox(e);
                                          setShowUpdateIndex((pre) =>
                                            pre === index ? "" : index,
                                          );
                                          setShowUpdate(
                                            showUpdateIndex === index
                                              ? !showUpdate
                                              : true,
                                          );
                                          fetchSingleMatchData(item);
                                        }}
                                      >
                                        Show updates |
                                      </Typography>
                                    </Box>
                                  ) : (
                                    <Box className="team-sport-table-indicator">
                                      <Box>
                                        <span className="start-time-date">
                                          {fetchDayName(item?.startTime)}{" "}
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("DD/MM/YYYY")}{" "}
                                          |{" "}
                                          {moment
                                            .utc(item?.startTime)
                                            .local()
                                            .format("hh:mm A")}{" "}
                                          |
                                        </span>
                                      </Box>
                                    </Box>
                                  )}
                                  {release[Config.release]?.oddsCheck ? (
                                    <span className="markets">
                                      {" "}
                                      {`${
                                        item?.marketLength
                                          ? item?.marketLength
                                          : 0
                                      } Markets`}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </Box>

                                <Box className="currentbest-seeall">
                                  {" "}
                                  {IsHomeCurrentBestAvailable &&
                                    tabvalue == 0 && (
                                      <span
                                        className="teamsports-seeall"
                                        onClick={(e) => handleSeeAll(item, e)}
                                      >
                                        <Typography variant="h1">
                                          See all odds
                                        </Typography>
                                      </span>
                                    )}
                                  {screenWidth >= 1023 &&
                                    (item?.SportId === 4 ||
                                      item?.SportId === 8 ||
                                      item?.SportId === 12) && (
                                      <span
                                        className="teamsports-seeall"
                                        onClick={() =>
                                          handleEventBoxClick(item)
                                        }
                                      >
                                        <Typography variant="h1">
                                          Fixture info
                                        </Typography>
                                      </span>
                                    )}
                                </Box>
                              </Box>
                              {screenWidth >= 1023 ? (
                                tabvalue === 0 &&
                                (item?.SportId === 4 || item?.SportId === 8) &&
                                (item?.ScoreBoard?.Epr === 1 ||
                                  item?.ScoreBoard?.Epr === 2) ? (
                                  <>
                                    <Score
                                      item={item}
                                      index={index}
                                      teamSportType={teamSportType}
                                      fetchCurrentBestOdds={
                                        fetchCurrentBestOdds
                                      }
                                      fetchCurrentBestsOddsIcon={
                                        fetchCurrentBestsOddsIcon
                                      }
                                      sponsoredId={sponsoredId}
                                      fetchSponsoredOddsScore={
                                        fetchSponsoredOddsScore
                                      }
                                      // fetchSponsoredOddsHome={
                                      //   fetchSponsoredOddsHome
                                      // }
                                      // fetchSponsoredOddsAway={
                                      //   fetchSponsoredOddsAway
                                      // }
                                      scoreData={[item?.ScoreBoard]}
                                      MarketName={MarketName}
                                      fetchDayName={fetchDayName}
                                      showOdds={showOdds}
                                      handleOpenTooltip={handleOpenTooltip}
                                      handleOutsideClick={handleOutsideClick}
                                      fetchTeamlogo={fetchTeamlogo}
                                      IsHomeCurrentBestAvailable={
                                        IsHomeCurrentBestAvailable
                                      }
                                      IsAwayCurrentBestAvailable={
                                        IsAwayCurrentBestAvailable
                                      }
                                    />
                                  </>
                                ) : tabvalue === 0 &&
                                  item?.[teamScores]?.length > 0 ? (
                                  <Score
                                    item={item}
                                    index={index}
                                    teamSportType={teamSportType}
                                    fetchCurrentBestOdds={fetchCurrentBestOdds}
                                    fetchCurrentBestsOddsIcon={
                                      fetchCurrentBestsOddsIcon
                                    }
                                    sponsoredId={sponsoredId}
                                    fetchSponsoredOddsScore={
                                      fetchSponsoredOddsScore
                                    }
                                    // fetchSponsoredOddsHome={
                                    //   fetchSponsoredOddsHome
                                    // }
                                    // fetchSponsoredOddsAway={
                                    //   fetchSponsoredOddsAway
                                    // }
                                    scoreData={item?.[teamScores]}
                                    MarketName={MarketName}
                                    fetchDayName={fetchDayName}
                                    showOdds={showOdds}
                                    handleOpenTooltip={handleOpenTooltip}
                                    handleOutsideClick={handleOutsideClick}
                                    fetchTeamlogo={fetchTeamlogo}
                                    IsHomeCurrentBestAvailable={
                                      IsHomeCurrentBestAvailable
                                    }
                                    IsAwayCurrentBestAvailable={
                                      IsAwayCurrentBestAvailable
                                    }
                                  />
                                ) : (
                                  <Table className="team-sport-table main-featured-table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          className={
                                            tabvalue === 0
                                              ? showOdds &&
                                                IsHomeCurrentBestAvailable
                                                ? "border-right"
                                                : "show-odds-th"
                                              : ""
                                          }
                                        >
                                          <span className="sport-indicator-btn">
                                            {teamSportType === "cricket"
                                              ? item?.CricketTournament?.name
                                              : teamSportType ===
                                                    "rugbyleague" ||
                                                  teamSportType ===
                                                    "rugbyunion" ||
                                                  teamSportType ===
                                                    "rugbyunionsevens"
                                                ? item?.RLTournament?.name
                                                : teamSportType ===
                                                    "americanfootball"
                                                  ? item?.AFLTournament?.name
                                                  : teamSportType ===
                                                      "australianrules"
                                                    ? item?.ARTournament?.name
                                                    : teamSportType === "golf"
                                                      ? item?.GolfTournament
                                                          ?.name
                                                      : teamSportType ===
                                                          "tennis"
                                                        ? item?.TennisTournament
                                                            ?.name
                                                        : teamSportType ===
                                                            "baseball"
                                                          ? item
                                                              ?.BaseballTournament
                                                              ?.name
                                                          : teamSportType ===
                                                              "icehockey"
                                                            ? item
                                                                ?.IceHockeyTournament
                                                                ?.name
                                                            : teamSportType ===
                                                                "boxing"
                                                              ? item
                                                                  ?.BoxingTournament
                                                                  ?.name
                                                              : teamSportType ===
                                                                  "mma"
                                                                ? item
                                                                    ?.MMATournament
                                                                    ?.name
                                                                : teamSportType ===
                                                                    "soccer"
                                                                  ? item
                                                                      ?.SoccerTournament
                                                                      ?.name
                                                                  : teamSportType ===
                                                                      "basketball"
                                                                    ? item
                                                                        ?.NBATournament
                                                                        ?.NBACategory ===
                                                                      null
                                                                      ? item
                                                                          ?.NBATournament
                                                                          ?.name
                                                                      : item
                                                                          ?.NBATournament
                                                                          ?.name +
                                                                        " " +
                                                                        item
                                                                          ?.NBATournament
                                                                          ?.NBACategory
                                                                          ?.name
                                                                    : ""}
                                          </span>
                                        </TableCell>

                                        {tabvalue === 0 ? (
                                          <>
                                            {/* {showOdds ? (
                                              <TableCell
                                                style={{
                                                  width:
                                                    tabvalue === 0 &&
                                                    sponsoredId?.length > 0
                                                      ? "25%"
                                                      : "auto"
                                                }}
                                                className={
                                                  "table-head table-head-after"
                                                }
                                              >
                                                {
                                                  localesData?.sport
                                                    ?.BEST_AT_OPEN
                                                }
                                              </TableCell>
                                            ) : (
                                              <></>
                                            )} */}
                                          </>
                                        ) : (
                                          <TableCell className="fluctuation-graph-tablehead">
                                            Fluctuation Graph
                                          </TableCell>
                                        )}

                                        {((tabvalue === 0 && showOdds) ||
                                          tabvalue !== 0) &&
                                        IsHomeCurrentBestAvailable ? (
                                          <TableCell
                                            className={`table-head table-current ${
                                              tabvalue === 0 &&
                                              sponsoredId?.length > 0
                                                ? "table-head-after"
                                                : ""
                                            } `}
                                          >
                                            {localesData?.sport?.CURRENT_BEST}
                                          </TableCell>
                                        ) : (
                                          <></>
                                        )}

                                        {!IsFeaturedData(
                                          item,
                                          "hometeam",
                                        )?.every((ele) => ele == null) &&
                                        tabvalue === 0 ? (
                                          <>
                                            {showOdds ? (
                                              <TableCell className="table-head featured-header">
                                                Featured bookmaker Odds
                                              </TableCell>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {item?.outrights === false ||
                                      tabvalue === 3 ? (
                                        <>
                                          <TableRow
                                            className={
                                              showOdds
                                                ? " teamsports-tablerow hometeam-row teamsport-row"
                                                : "show-teamsports-tablerow teamsports-tablerow hometeam-row teamsport-row"
                                            }
                                          >
                                            <TableCell
                                              className={
                                                showOdds
                                                  ? "team-wrap"
                                                  : tabvalue == 0
                                                    ? "show-odds-team-wrap team-wrap"
                                                    : "team-wrap"
                                              }
                                            >
                                              <Box className="team-d-flex team-align-center">
                                                <span className="team-img-wrap">
                                                  {fetchTeamlogo(
                                                    item,
                                                    "hometeam",
                                                  )}
                                                </span>
                                                <span className="team-name ">
                                                  {item?.homeTeam?.name
                                                    ? item?.homeTeam?.name
                                                    : ""}
                                                </span>
                                              </Box>
                                              <ClickAwayListener
                                                onClickAway={() =>
                                                  handleOutsideClick(
                                                    item,
                                                    item?.homeTeamId,
                                                    index,
                                                  )
                                                }
                                              >
                                                <Box className="blackbook-icon">
                                                  {handleOpenTooltip(
                                                    item,
                                                    item?.homeTeamId,
                                                    index,
                                                    "hometeam",
                                                  )}
                                                </Box>
                                              </ClickAwayListener>
                                            </TableCell>
                                            {tabvalue === 0 ? (
                                              <>
                                                {/* {showOdds ? (
                                                  <TableCell className="border-right border-left td-cell">
                                                    <Box className="odds-wrap">
                                                      <span>
                                                        <Box className="points">
                                                          {fetchBestAtOpenOdds(
                                                            item,
                                                            "points",
                                                            MarketName?.toLowerCase()?.includes(
                                                              "total"
                                                            )
                                                              ? "over"
                                                              : "hometeam"
                                                          )}
                                                        </Box>
                                                        <span className="odds odds-left">
                                                          {fetchBestAtOpenOdds(
                                                            item,
                                                            "odds",
                                                            MarketName?.toLowerCase()?.includes(
                                                              "total"
                                                            )
                                                              ? "over"
                                                              : "hometeam"
                                                          )}
                                                        </span>
                                                      </span>
                                                      {fetchBestAtOpenIconOdds(
                                                        item,
                                                        "odds",
                                                        MarketName?.toLowerCase()?.includes(
                                                          "total"
                                                        )
                                                          ? "over"
                                                          : "hometeam"
                                                      )}
                                                    </Box>
                                                  </TableCell>
                                                ) : (
                                                  <></>
                                                )} */}
                                              </>
                                            ) : (
                                              <TableCell className="hight-chart-cell">
                                                {fetchFluctuationGraph(
                                                  item,
                                                  "hometeam",
                                                )}
                                              </TableCell>
                                            )}
                                            {((tabvalue === 0 && showOdds) ||
                                              tabvalue !== 0) &&
                                            IsHomeCurrentBestAvailable ? (
                                              <TableCell className="border-right border-left ">
                                                {" "}
                                                <Box className="odds-wrap current-best-odds-wrap">
                                                  <span>
                                                    <Box className="points">
                                                      {fetchCurrentBestOdds(
                                                        item,
                                                        "points",
                                                        MarketName?.toLowerCase()?.includes(
                                                          "total",
                                                        )
                                                          ? "over"
                                                          : "hometeam",
                                                      )}
                                                    </Box>
                                                    <span>
                                                      {fetchCurrentBestOdds(
                                                        item,
                                                        "odds",
                                                        MarketName?.toLowerCase()?.includes(
                                                          "total",
                                                        )
                                                          ? "over"
                                                          : "hometeam",
                                                      )}
                                                    </span>
                                                  </span>
                                                  {fetchCurrentBestsOddsIcon(
                                                    item,
                                                    "odds",
                                                    MarketName?.toLowerCase()?.includes(
                                                      "total",
                                                    )
                                                      ? "over"
                                                      : "hometeam",
                                                  )}
                                                </Box>
                                              </TableCell>
                                            ) : (
                                              <></>
                                            )}
                                            {!IsFeaturedData(
                                              item,
                                              "hometeam",
                                            )?.every((ele) => ele == null) &&
                                            tabvalue === 0 &&
                                            showOdds ? (
                                              <>
                                                {topBookMakersData?.length ===
                                                  0 && (
                                                  <p
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    No Data Available
                                                  </p>
                                                )}
                                                {topBookMakersData?.length >
                                                  0 && (
                                                  <TableCell className="hide-scroll max-w">
                                                    <Box className="odds-flex">
                                                      {IsFeaturedData(
                                                        item,
                                                        "hometeam",
                                                      )}
                                                    </Box>
                                                  </TableCell>
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </TableRow>
                                          <TableRow className="awayteam-row teamsport-row">
                                            <TableCell
                                              className={
                                                showOdds
                                                  ? "team-wrap"
                                                  : tabvalue == 0
                                                    ? "show-odds-team-wrap team-wrap"
                                                    : "team-wrap"
                                              }
                                            >
                                              <Box className="team-d-flex team-align-center">
                                                <span className="team-img-wrap">
                                                  {fetchTeamlogo(
                                                    item,
                                                    "awayteam",
                                                  )}
                                                </span>
                                                <span className="team-name ">
                                                  {" "}
                                                  {item?.awayTeam?.name
                                                    ? item?.awayTeam?.name
                                                    : ""}
                                                </span>
                                              </Box>
                                              <ClickAwayListener
                                                onClickAway={() =>
                                                  handleOutsideClick(
                                                    item,
                                                    item?.awayTeamId,
                                                    index,
                                                  )
                                                }
                                              >
                                                <Box className="blackbook-icon">
                                                  {handleOpenTooltip(
                                                    item,
                                                    item?.awayTeamId,
                                                    index,
                                                    "awayteam",
                                                  )}
                                                </Box>
                                              </ClickAwayListener>
                                            </TableCell>
                                            {tabvalue === 0 ? (
                                              <>
                                                {/* {showOdds ? (
                                                  <TableCell className="border-right border-left td-cell">
                                                    <Box className="odds-wrap">
                                                      <span>
                                                        <Box className="points">
                                                          {fetchBestAtOpenOdds(
                                                            item,
                                                            "points",
                                                            MarketName?.toLowerCase()?.includes(
                                                              "total"
                                                            )
                                                              ? "under"
                                                              : "awayteam"
                                                          )}
                                                        </Box>
                                                        <span className="odds odds-left">
                                                          {" "}
                                                          {fetchBestAtOpenOdds(
                                                            item,
                                                            "odds",
                                                            MarketName?.toLowerCase()?.includes(
                                                              "total"
                                                            )
                                                              ? "under"
                                                              : "awayteam"
                                                          )}
                                                        </span>
                                                      </span>
                                                      {fetchBestAtOpenIconOdds(
                                                        item,
                                                        "odds",
                                                        MarketName?.toLowerCase()?.includes(
                                                          "total"
                                                        )
                                                          ? "under"
                                                          : "awayteam"
                                                      )}
                                                    </Box>
                                                  </TableCell>
                                                ) : (
                                                  <></>
                                                )} */}
                                              </>
                                            ) : (
                                              <TableCell className="hight-chart-cell">
                                                {fetchFluctuationGraph(
                                                  item,
                                                  "awayteam",
                                                )}
                                              </TableCell>
                                            )}
                                            {((tabvalue === 0 && showOdds) ||
                                              tabvalue !== 0) &&
                                            IsAwayCurrentBestAvailable ? (
                                              <TableCell className="border-right border-left ">
                                                <Box className="odds-wrap current-best-odds-wrap">
                                                  <span>
                                                    <Box className="points">
                                                      {fetchCurrentBestOdds(
                                                        item,
                                                        "points",
                                                        MarketName?.toLowerCase()?.includes(
                                                          "total",
                                                        )
                                                          ? "under"
                                                          : "awayteam",
                                                      )}
                                                    </Box>
                                                    <span>
                                                      {fetchCurrentBestOdds(
                                                        item,
                                                        "odds",
                                                        MarketName?.toLowerCase()?.includes(
                                                          "total",
                                                        )
                                                          ? "under"
                                                          : "awayteam",
                                                      )}
                                                    </span>
                                                  </span>
                                                  {fetchCurrentBestsOddsIcon(
                                                    item,
                                                    "odds",
                                                    MarketName?.toLowerCase()?.includes(
                                                      "total",
                                                    )
                                                      ? "under"
                                                      : "awayteam",
                                                  )}
                                                </Box>
                                              </TableCell>
                                            ) : (
                                              <></>
                                            )}
                                            {!IsFeaturedData(
                                              item,
                                              "hometeam",
                                            )?.every((ele) => ele == null) &&
                                            tabvalue === 0 &&
                                            showOdds ? (
                                              <>
                                                {topBookMakersData?.length ===
                                                  0 && (
                                                  <p
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    No Data Available
                                                  </p>
                                                )}
                                                {topBookMakersData?.length >
                                                  0 && (
                                                  <TableCell className="hide-scroll max-w">
                                                    <Box className="odds-flex">
                                                      {IsFeaturedData(
                                                        item,
                                                        "awayteam",
                                                      )}
                                                    </Box>
                                                  </TableCell>
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </TableRow>
                                        </>
                                      ) : (
                                        <>
                                          {/* {fetchOutrightsTable(item, index)} */}
                                        </>
                                      )}
                                    </TableBody>
                                  </Table>
                                )
                              ) : (
                                <Box className="mobileview-teamsport-table-wrap">
                                  <Box className="team-match-wrap">
                                    <Box className="time-market-team-wrap">
                                      <Box className="mobile-temsport-indicator">
                                        <Box className="mobile-teamsport-left">
                                          {(tabvalue === 0 &&
                                            (item?.SportId === 4 ||
                                              item?.SportId === 8) &&
                                            item?.ScoreBoard?.Epr === 1) ||
                                          (tabvalue === 0 &&
                                            item?.[teamScores]?.length > 0 &&
                                            item?.status &&
                                            item?.status === "inprogress") ? (
                                            <span className="live-indicator">
                                              LIVE
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          <span className="sport-indicator-btn">
                                            {teamSportType === "cricket"
                                              ? item?.CricketTournament?.name
                                              : teamSportType ===
                                                    "rugbyleague" ||
                                                  teamSportType ===
                                                    "rugbyunion" ||
                                                  teamSportType ===
                                                    "rugbyunionsevens"
                                                ? item?.RLTournament?.name
                                                : teamSportType ===
                                                    "americanfootball"
                                                  ? item?.AFLTournament?.name
                                                  : teamSportType ===
                                                      "australianrules"
                                                    ? item?.ARTournament?.name
                                                    : teamSportType === "golf"
                                                      ? item?.GolfTournament
                                                          ?.name
                                                      : teamSportType ===
                                                          "tennis"
                                                        ? item?.TennisTournament
                                                            ?.name
                                                        : teamSportType ===
                                                            "baseball"
                                                          ? item
                                                              ?.BaseballTournament
                                                              ?.name
                                                          : teamSportType ===
                                                              "icehockey"
                                                            ? item
                                                                ?.IceHockeyTournament
                                                                ?.name
                                                            : teamSportType ===
                                                                "boxing"
                                                              ? item
                                                                  ?.BoxingTournament
                                                                  ?.name
                                                              : teamSportType ===
                                                                  "mma"
                                                                ? item
                                                                    ?.MMATournament
                                                                    ?.name
                                                                : teamSportType ===
                                                                    "soccer"
                                                                  ? item
                                                                      ?.SoccerTournament
                                                                      ?.name
                                                                  : teamSportType ===
                                                                      "basketball"
                                                                    ? item
                                                                        ?.NBATournament
                                                                        ?.NBACategory ===
                                                                      null
                                                                      ? item
                                                                          ?.NBATournament
                                                                          ?.name
                                                                      : item
                                                                          ?.NBATournament
                                                                          ?.name +
                                                                        " " +
                                                                        item
                                                                          ?.NBATournament
                                                                          ?.NBACategory
                                                                          ?.name
                                                                    : ""}
                                          </span>
                                        </Box>
                                        <Box className="mobile-teamsport-right">
                                          {screenWidth < 1023 ? (
                                            <>
                                              {release[Config.release]
                                                ?.oddsCheck && (
                                                <>
                                                  {tabvalue === 0 &&
                                                    (item?.SportId === 4 ||
                                                      item?.SportId === 8 ||
                                                      item?.SportId === 12) && (
                                                      <Box className="currentbest-seeall">
                                                        {" "}
                                                        <span
                                                          className="teamsports-seeall"
                                                          onClick={() =>
                                                            handleEventBoxClick(
                                                              item,
                                                            )
                                                          }
                                                        >
                                                          <Typography variant="h1">
                                                            Fixture info
                                                          </Typography>
                                                        </span>
                                                      </Box>
                                                    )}
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </Box>
                                      </Box>
                                      <Box className="teams-details">
                                        <Box className="teams-details-wrap">
                                          <Box className="hometeam-row">
                                            <Box className="team-wrap">
                                              <Box className="team-d-flex team-align-center team-logo-name">
                                                <span className="team-img-wrap">
                                                  {fetchTeamlogo(
                                                    item,
                                                    "hometeam",
                                                  )}
                                                </span>
                                                <span className="team-name ">
                                                  {item?.homeTeam?.name
                                                    ? item?.homeTeam?.name
                                                    : ""}
                                                </span>
                                              </Box>
                                              <ClickAwayListener
                                                onClickAway={() =>
                                                  handleOutsideClick(
                                                    item,
                                                    item?.homeTeamId,
                                                    index,
                                                  )
                                                }
                                              >
                                                <Box className="blackbook-icon">
                                                  {handleOpenTooltip(
                                                    item,
                                                    item?.homeTeamId,
                                                    index,
                                                    "hometeam",
                                                  )}
                                                </Box>
                                              </ClickAwayListener>
                                            </Box>
                                            {IsHomeCurrentBestAvailable &&
                                              showOdds && (
                                                <Box>
                                                  <Box className="odds-wrap current-best-odds-wrap">
                                                    <span>
                                                      <Box className="points">
                                                        {fetchCurrentBestOdds(
                                                          item,
                                                          "points",
                                                          MarketName?.toLowerCase()?.includes(
                                                            "total",
                                                          )
                                                            ? "over"
                                                            : "hometeam",
                                                          null,
                                                          true,
                                                        )}
                                                      </Box>
                                                      <span>
                                                        {fetchCurrentBestOdds(
                                                          item,
                                                          "odds",
                                                          MarketName?.toLowerCase()?.includes(
                                                            "total",
                                                          )
                                                            ? "over"
                                                            : "hometeam",
                                                          null,
                                                          true,
                                                        )}
                                                      </span>
                                                    </span>
                                                    {fetchCurrentBestsOddsIcon(
                                                      item,
                                                      "odds",
                                                      MarketName?.toLowerCase()?.includes(
                                                        "total",
                                                      )
                                                        ? "over"
                                                        : "hometeam",
                                                    )}
                                                  </Box>
                                                </Box>
                                              )}
                                          </Box>
                                          {!IsFeaturedData(
                                            item,
                                            "hometeam",
                                          )?.every((ele) => ele == null) &&
                                            !Boolean(
                                              (tabvalue === 0 &&
                                                (item?.SportId === 4 ||
                                                  item?.SportId === 8) &&
                                                (item?.ScoreBoard?.Epr === 1 ||
                                                  item?.ScoreBoard?.Epr ===
                                                    2)) ||
                                                (tabvalue === 0 &&
                                                  item?.[teamScores]?.length >
                                                    0),
                                            ) &&
                                            tabvalue === 0 &&
                                            showOdds && (
                                              <Box className="mobile-table-container">
                                                <TableContainer>
                                                  <Table>
                                                    <TableHead className="mobile-data-head">
                                                      <TableCell
                                                        className="mobile-table-data sponsor-odds-header"
                                                        width="33.33%"
                                                      >
                                                        {" "}
                                                        Featured bookmaker Odds
                                                      </TableCell>
                                                    </TableHead>
                                                    <TableBody className="mobile-table-body">
                                                      <TableCell className="mobile-table-data current-best-odds-column gray-bg">
                                                        {topBookMakersData?.length ===
                                                          0 && (
                                                          <p
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            No Data Available
                                                          </p>
                                                        )}
                                                        {topBookMakersData?.length >
                                                          0 && (
                                                          <Box className="hide-scroll max-w">
                                                            <Box className="odds-flex">
                                                              {topBookMakersData
                                                                ?.slice(0, 6)
                                                                ?.map(
                                                                  (
                                                                    bookkeper,
                                                                    index,
                                                                  ) => {
                                                                    return fetchFeaturedOdds(
                                                                      item,
                                                                      bookkeper?.id,
                                                                      "hometeam",
                                                                      "odds",
                                                                    );
                                                                  },
                                                                )}
                                                            </Box>
                                                          </Box>
                                                        )}
                                                      </TableCell>
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>
                                              </Box>
                                            )}
                                          <Box className="awayteam-row">
                                            <Box className="team-wrap">
                                              <Box className="team-d-flex team-align-center team-logo-name">
                                                <span className="team-img-wrap">
                                                  {fetchTeamlogo(
                                                    item,
                                                    "awayteam",
                                                  )}
                                                </span>
                                                <span className="team-name ">
                                                  {" "}
                                                  {item?.awayTeam?.name
                                                    ? item?.awayTeam?.name
                                                    : ""}
                                                </span>
                                              </Box>
                                              <ClickAwayListener
                                                onClickAway={() =>
                                                  handleOutsideClick(
                                                    item,
                                                    item?.awayTeamId,
                                                    index,
                                                  )
                                                }
                                              >
                                                <Box className="blackbook-icon">
                                                  {handleOpenTooltip(
                                                    item,
                                                    item?.awayTeamId,
                                                    index,
                                                    "awayteam",
                                                  )}
                                                </Box>
                                              </ClickAwayListener>
                                            </Box>
                                            {IsAwayCurrentBestAvailable &&
                                              showOdds && (
                                                <Box>
                                                  <Box className="odds-wrap current-best-odds-wrap">
                                                    <span>
                                                      <Box className="points">
                                                        {fetchCurrentBestOdds(
                                                          item,
                                                          "points",
                                                          MarketName?.toLowerCase()?.includes(
                                                            "total",
                                                          )
                                                            ? "over"
                                                            : "awayteam",
                                                          null,
                                                          true,
                                                        )}
                                                      </Box>
                                                      <span>
                                                        {fetchCurrentBestOdds(
                                                          item,
                                                          "odds",
                                                          MarketName?.toLowerCase()?.includes(
                                                            "total",
                                                          )
                                                            ? "over"
                                                            : "awayteam",
                                                          null,
                                                          true,
                                                        )}
                                                      </span>
                                                    </span>
                                                    {fetchCurrentBestsOddsIcon(
                                                      item,
                                                      "odds",
                                                      MarketName?.toLowerCase()?.includes(
                                                        "total",
                                                      )
                                                        ? "over"
                                                        : "awayteam",
                                                    )}
                                                  </Box>
                                                </Box>
                                              )}
                                          </Box>
                                          {!IsFeaturedData(
                                            item,
                                            "awayteam",
                                          )?.every((ele) => ele == null) &&
                                            !Boolean(
                                              (tabvalue === 0 &&
                                                (item?.SportId === 4 ||
                                                  item?.SportId === 8) &&
                                                (item?.ScoreBoard?.Epr === 1 ||
                                                  item?.ScoreBoard?.Epr ===
                                                    2)) ||
                                                (tabvalue === 0 &&
                                                  item?.[teamScores]?.length >
                                                    0),
                                            ) &&
                                            tabvalue === 0 &&
                                            showOdds && (
                                              <Box className="mobile-table-container awayteam-table">
                                                <TableContainer>
                                                  <Table>
                                                    <TableHead className="mobile-data-head">
                                                      <TableCell
                                                        className="mobile-table-data sponsor-odds-header"
                                                        width="33.33%"
                                                      >
                                                        {" "}
                                                        Featured bookmaker Odds
                                                      </TableCell>
                                                    </TableHead>
                                                    <TableBody className="mobile-table-body">
                                                      <TableCell className="mobile-table-data current-best-odds-column gray-bg">
                                                        {topBookMakersData?.length ===
                                                          0 && (
                                                          <p
                                                            style={{
                                                              textAlign:
                                                                "center",
                                                            }}
                                                          >
                                                            No Data Available
                                                          </p>
                                                        )}
                                                        {topBookMakersData?.length >
                                                          0 && (
                                                          <Box className="hide-scroll max-w">
                                                            <Box className="odds-flex">
                                                              {topBookMakersData
                                                                ?.slice(0, 6)
                                                                ?.map(
                                                                  (
                                                                    bookkeper,
                                                                    index,
                                                                  ) => {
                                                                    return fetchFeaturedOdds(
                                                                      item,
                                                                      bookkeper?.id,
                                                                      "awayteam",
                                                                      "odds",
                                                                    );
                                                                  },
                                                                )}
                                                            </Box>
                                                          </Box>
                                                        )}
                                                      </TableCell>
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>
                                              </Box>
                                            )}
                                        </Box>
                                      </Box>
                                      {(tabvalue === 0 &&
                                        (item?.SportId === 4 ||
                                          item?.SportId === 8) &&
                                        (item?.ScoreBoard?.Epr === 1 ||
                                          item?.ScoreBoard?.Epr === 2)) ||
                                      (tabvalue === 0 &&
                                        item?.[teamScores]?.length > 0) ? (
                                        <Score
                                          item={item}
                                          index={index}
                                          teamSportType={teamSportType}
                                          fetchCurrentBestOdds={
                                            fetchCurrentBestOdds
                                          }
                                          fetchCurrentBestsOddsIcon={
                                            fetchCurrentBestsOddsIcon
                                          }
                                          sponsoredId={sponsoredId}
                                          fetchSponsoredOddsScore={
                                            fetchSponsoredOddsScore
                                          }
                                          scoreData={
                                            item?.SportId === 4 ||
                                            item?.SportId === 8
                                              ? [item?.ScoreBoard]
                                              : item?.[teamScores]
                                          }
                                          MarketName={MarketName}
                                          showOdds={showOdds}
                                          handleOpenTooltip={handleOpenTooltip}
                                          handleOutsideClick={
                                            handleOutsideClick
                                          }
                                          fetchTeamlogo={fetchTeamlogo}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </Box>

                                    {tabvalue === 3 && (
                                      <Box className="mobile-fluc-details">
                                        <Box className="mobile-team-fluc-wrap">
                                          <Box className="team-fluc-wrap">
                                            <Typography className="team-name">
                                              {item?.homeTeam?.name}
                                            </Typography>
                                            <Box className="ptb-10 mobile-chart">
                                              {fetchFluctuationGraph(
                                                item,
                                                "hometeam",
                                              )}
                                            </Box>
                                          </Box>
                                          <Box className="team-fluc-wrap">
                                            <Typography className="team-name">
                                              {item?.awayTeam?.name}
                                            </Typography>
                                            <Box className="ptb-10 mobile-chart">
                                              {fetchFluctuationGraph(
                                                item,
                                                "awayteam",
                                              )}
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              )}
                              <MatchSummary
                                showUpdate={showUpdate}
                                showUpdateIndex={showUpdateIndex}
                                index={index}
                                singleMatchData={singleMatchData}
                                matchLoading={matchLoading}
                              />
                            </Box>
                            {neweventListData?.length > 1 && index === 0 ? (
                              fetchAds(
                                1,
                                "100%",
                                Config?.release == "IN"
                                  ? BannerIndia
                                  : After1StEvent,
                              )
                            ) : (
                              <></>
                            )}
                          </>
                        );
                      })}
                    </InfiniteScroll>
                  </Box>{" "}
                </>
              ) : (
                <Box style={{ textAlign: "center" }}>
                  {/* {/ No Data Available  /} */}
                  <NoDataComp />
                </Box>
              )}
            </>
          )
        ) : isIndividualEventLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : individualTeamData &&
          Object.entries(individualTeamData)?.length > 0 ? (
          <>
            {/* {screenWidth >= 1023 ? ( */}
            <Box className="teamsports-seeall-tablewrap">
              <Box className="table-header-wrap">
                <Box className="table-indicator-wrap">
                  <Box className="team-sport-table-indicator">
                    <Box>
                      <span className="start-time-date">
                        {fetchDayName(individualTeamData?.startTime)}{" "}
                        {moment
                          .utc(individualTeamData?.startTime)
                          .local()
                          .format("DD/MM/YYYY")}{" "}
                        |{" "}
                        {moment
                          .utc(individualTeamData?.startTime)
                          .local()
                          .format("hh:mm A")}{" "}
                        |
                      </span>
                    </Box>
                  </Box>

                  {release[Config.release]?.oddsCheck ? (
                    <span className="markets">
                      {" "}
                      {`${
                        individualTeamData?.marketLength
                          ? individualTeamData?.marketLength
                          : 0
                      } Markets`}
                    </span>
                  ) : (
                    <></>
                  )}
                </Box>
                {screenWidth > 799 && (
                  <Box className="currentbest-seeall">
                    {" "}
                    <span
                      className="teamsports-seeall"
                      onClick={() => handleSeeBest()}
                    >
                      <Typography variant="h1"> See Best</Typography>
                    </span>
                    {(individualTeamData?.SportId === 4 ||
                      individualTeamData?.SportId === 8 ||
                      individualTeamData?.SportId === 12) && (
                      <span
                        className="teamsports-seeall"
                        onClick={() => handleEventBoxClick(individualTeamData)}
                      >
                        <Typography variant="h1">Fixture info</Typography>
                      </span>
                    )}
                    <Box className="seeall-arrow-wrap">
                      <span
                        className={`seeall-arrow left-arrow ${
                          scrollX == "right" ? "" : "disable"
                        }`}
                        onClick={() => SlideTable("left")}
                      >
                        <SeeAllRightArrow />
                      </span>

                      <span
                        className={`seeall-arrow right-arrow ${
                          scrollX == "left" ? "" : "disable"
                        }`}
                        onClick={() => SlideTable("right")}
                      >
                        <SeeAllRightArrow />
                      </span>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box className="responsive-table-wrap">
                {screenWidth < 800 && (
                  <Box className="table-header-wrap">
                    <Box className="table-indicator-wrap">
                      <Box className="team-sport-table-indicator">
                        <span className="sport-indicator-btn">
                          {teamSportType === "cricket"
                            ? individualTeamData?.CricketTournament?.name
                            : teamSportType === "rugbyleague" ||
                                teamSportType === "rugbyunion" ||
                                teamSportType === "rugbyunionsevens"
                              ? individualTeamData?.RLTournament?.name
                              : teamSportType === "americanfootball"
                                ? individualTeamData?.AFLTournament?.name
                                : teamSportType === "australianrules"
                                  ? individualTeamData?.ARTournament?.name
                                  : teamSportType === "golf"
                                    ? individualTeamData?.GolfTournament?.name
                                    : teamSportType === "tennis"
                                      ? individualTeamData?.TennisTournament
                                          ?.name
                                      : teamSportType === "baseball"
                                        ? individualTeamData?.BaseballTournament
                                            ?.name
                                        : teamSportType === "icehockey"
                                          ? individualTeamData
                                              ?.IceHockeyTournament?.name
                                          : teamSportType === "boxing"
                                            ? individualTeamData
                                                ?.BoxingTournament?.name
                                            : teamSportType === "mma"
                                              ? individualTeamData
                                                  ?.MMATournament?.name
                                              : teamSportType === "soccer"
                                                ? individualTeamData
                                                    ?.SoccerTournament?.name
                                                : teamSportType === "basketball"
                                                  ? individualTeamData
                                                      ?.NBATournament
                                                      ?.NBACategory === null
                                                    ? individualTeamData
                                                        ?.NBATournament?.name
                                                    : individualTeamData
                                                        ?.NBATournament?.name +
                                                      " " +
                                                      individualTeamData
                                                        ?.NBATournament
                                                        ?.NBACategory?.name
                                                  : ""}
                        </span>
                      </Box>
                    </Box>

                    <Box className="currentbest-seeall">
                      {" "}
                      <span
                        className="teamsports-seeall"
                        onClick={() => handleSeeBest()}
                      >
                        <Typography variant="h1"> See Best</Typography>
                      </span>
                      {(individualTeamData?.SportId === 4 ||
                        individualTeamData?.SportId === 8 ||
                        individualTeamData?.SportId === 12) && (
                        <span
                          className="teamsports-seeall"
                          onClick={() =>
                            handleEventBoxClick(individualTeamData)
                          }
                        >
                          <Typography variant="h1">Fixture info</Typography>
                        </span>
                      )}
                    </Box>
                  </Box>
                )}
                <TableContainer
                  className={`table-seeall-container ${
                    tooltipOpen ? "overflow-clip" : ""
                  }`}
                  id="individual-team-table"
                >
                  <Box className="overlay"></Box>
                  <Table className="individual-table">
                    <TableHead className="individual-team-head">
                      <TableRow>
                        <StickyTableCell className={`${classes.head}`}>
                          {screenWidth > 799 && (
                            <Box className="mobile-teamsport-left">
                              {((individualTeamData?.SportId === 4 ||
                                individualTeamData?.SportId === 8) &&
                                individualTeamData?.ScoreBoard?.Epr === 1) ||
                              (individualTeamData?.[teamScores]?.length > 0 &&
                                individualTeamData?.status &&
                                individualTeamData?.status === "inprogress") ? (
                                <span className="live-indicator">LIVE</span>
                              ) : (
                                <></>
                              )}
                              <span className="sport-indicator-btn">
                                {teamSportType === "cricket"
                                  ? individualTeamData?.CricketTournament?.name
                                  : teamSportType === "rugbyleague" ||
                                      teamSportType === "rugbyunion" ||
                                      teamSportType === "rugbyunionsevens"
                                    ? individualTeamData?.RLTournament?.name
                                    : teamSportType === "americanfootball"
                                      ? individualTeamData?.AFLTournament?.name
                                      : teamSportType === "australianrules"
                                        ? individualTeamData?.ARTournament?.name
                                        : teamSportType === "golf"
                                          ? individualTeamData?.GolfTournament
                                              ?.name
                                          : teamSportType === "tennis"
                                            ? individualTeamData
                                                ?.TennisTournament?.name
                                            : teamSportType === "baseball"
                                              ? individualTeamData
                                                  ?.BaseballTournament?.name
                                              : teamSportType === "icehockey"
                                                ? individualTeamData
                                                    ?.IceHockeyTournament?.name
                                                : teamSportType === "boxing"
                                                  ? individualTeamData
                                                      ?.BoxingTournament?.name
                                                  : teamSportType === "mma"
                                                    ? individualTeamData
                                                        ?.MMATournament?.name
                                                    : teamSportType === "soccer"
                                                      ? individualTeamData
                                                          ?.SoccerTournament
                                                          ?.name
                                                      : teamSportType ===
                                                          "basketball"
                                                        ? individualTeamData
                                                            ?.NBATournament
                                                            ?.NBACategory ===
                                                          null
                                                          ? individualTeamData
                                                              ?.NBATournament
                                                              ?.name
                                                          : individualTeamData
                                                              ?.NBATournament
                                                              ?.name +
                                                            " " +
                                                            individualTeamData
                                                              ?.NBATournament
                                                              ?.NBACategory
                                                              ?.name
                                                        : ""}
                              </span>
                            </Box>
                          )}
                        </StickyTableCell>
                        {pageHeadingData?.map((id) =>
                          renderTableCell(id?.BookKeeperId, individualTeamData),
                        )}
                        {/* <TableCell className={classes.head}></TableCell> */}
                      </TableRow>
                    </TableHead>
                    {individualTeamData ? (
                      <TableBody className="individual-team-body">
                        {/* <TableRow>
                          <StickyTableCell className="time-market">
                           
                          </StickyTableCell>
                        </TableRow> */}
                        {!isMultipleTeam ? (
                          <>
                            <TableRow className="individual-team-row">
                              <StickyTableCell
                                className={`${classes.cell} body-runner-name`}
                              >
                                <Box className="team-wrap">
                                  <span className="team-img-wrap">
                                    {fetchTeamlogo(
                                      individualTeamData,
                                      "hometeam",
                                    )}
                                  </span>
                                  <span className="team-name">
                                    {individualTeamData?.homeTeam?.name
                                      ? individualTeamData?.homeTeam?.name
                                      : ""}
                                  </span>
                                  <ClickAwayListener
                                    onClickAway={() =>
                                      handleOutsideClick(
                                        individualTeamData,
                                        individualTeamData?.homeTeamId,
                                        individualTeamData?.id,
                                      )
                                    }
                                  >
                                    <Box className="blackbook-icon">
                                      {handleOpenTooltip(
                                        individualTeamData,
                                        individualTeamData?.homeTeamId,
                                        individualTeamData?.id,
                                        "hometeam",
                                        "",
                                      )}
                                    </Box>
                                  </ClickAwayListener>
                                </Box>
                              </StickyTableCell>
                              {pageHeadingData?.length > 0 ? (
                                pageHeadingData?.map((id, index) => (
                                  <TableCell
                                    className={`${classes.cell} individual-tablecell`}
                                    key={index}
                                  >
                                    <Box className="odds-point">
                                      {fetchSeeAllTeamOddsvalue(
                                        individualTeamData,
                                        id?.BookKeeperId,
                                        MarketName?.toLowerCase()?.includes(
                                          "total",
                                        )
                                          ? "over"
                                          : "hometeam",
                                        "spreadpoints",
                                      )}
                                    </Box>
                                    <span className="odds">
                                      {fetchSeeAllTeamOddsvalue(
                                        individualTeamData,
                                        id?.BookKeeperId,
                                        MarketName?.toLowerCase()?.includes(
                                          "total",
                                        )
                                          ? "over"
                                          : "hometeam",
                                        "odds",
                                      )}
                                    </span>
                                  </TableCell>
                                ))
                              ) : (
                                <TableCell className={classes.cell}>
                                  <span className="odds">
                                    <span className="odds-btn">NOA</span>
                                  </span>
                                </TableCell>
                              )}
                              <TableCell className={classes.cell}></TableCell>
                            </TableRow>
                            <TableRow className="individual-team-row">
                              <StickyTableCell
                                className={`${classes.cell} body-runner-name `}
                              >
                                <Box className="team-wrap">
                                  <span className="team-img-wrap">
                                    {fetchTeamlogo(
                                      individualTeamData,
                                      "awayteam",
                                    )}
                                  </span>
                                  <span className="team-name">
                                    {" "}
                                    {individualTeamData?.awayTeam?.name
                                      ? individualTeamData?.awayTeam?.name
                                      : ""}
                                  </span>
                                  <ClickAwayListener
                                    onClickAway={() =>
                                      handleOutsideClick(
                                        individualTeamData,
                                        individualTeamData?.awayTeamId,
                                        individualTeamData?.id,
                                      )
                                    }
                                  >
                                    <Box className="blackbook-icon">
                                      {handleOpenTooltip(
                                        individualTeamData,
                                        individualTeamData?.awayTeamId,
                                        individualTeamData?.id,
                                        "awayteam",
                                        "",
                                      )}
                                    </Box>
                                  </ClickAwayListener>
                                </Box>
                              </StickyTableCell>
                              {pageHeadingData?.length > 0 ? (
                                pageHeadingData?.map((id, index) => (
                                  <TableCell
                                    className={`${classes.cell} individual-tablecell`}
                                    key={index}
                                  >
                                    <Box className="odds-point">
                                      {fetchSeeAllTeamOddsvalue(
                                        individualTeamData,
                                        id?.BookKeeperId,
                                        MarketName?.toLowerCase()?.includes(
                                          "total",
                                        )
                                          ? "under"
                                          : "awayteam",
                                        "spreadpoints",
                                      )}
                                    </Box>
                                    <span className="odds">
                                      {fetchSeeAllTeamOddsvalue(
                                        individualTeamData,
                                        id?.BookKeeperId,
                                        MarketName?.toLowerCase()?.includes(
                                          "total",
                                        )
                                          ? "under"
                                          : "awayteam",
                                        "odds",
                                      )}
                                    </span>
                                  </TableCell>
                                ))
                              ) : (
                                <TableCell
                                  className={`${classes.cell} individual-tablecell`}
                                >
                                  <span className="odds">
                                    <span className="odds-btn">NOA</span>
                                  </span>
                                </TableCell>
                              )}
                              {/* <TableCell className={classes.cell}></TableCell> */}
                            </TableRow>
                          </>
                        ) : (
                          fetchSeeAllMultipleTeamData(individualTeamData)
                        )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={100}>
                            <Box style={{ textAlign: "center" }}>
                              {" "}
                              No Data Available
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </>
        ) : (
          <Box style={{ textAlign: "center" }}>
            {/* {/ No Data Available  /} */}
            <NoDataComp />
          </Box>
        )
      ) : tabvalue === 1 ? (
        <SportMatchup
          matchUpData={matchUpData}
          matchupLoading={matchupLoading}
          teamSportType={teamSportType}
        />
      ) : tabvalue === 2 || (teamSportType === "golf" && tabvalue === 3) ? (
        <>
          <OutRights
            eventList={eventList}
            teamSportType={teamSportType}
            fetchDayName={fetchDayName}
            isEventLoading={isEventLoading}
            // fetchOutrightsTable={fetchOutrightsTable}
            sponsoredId={sponsoredId}
            handleSeeAll={handleSeeAll}
            SlideTable={SlideTable}
            fetchSeeAllTeamOddsvalue={fetchSeeAllTeamOddsvalue}
            oddsiconNewOutright={oddsiconNewOutright}
            fetchAllEvents={fetchAllEvents}
            fetchOutrightTeamlogo={fetchOutrightTeamlogo}
            fetchLatestOdds={fetchLatestOdds}
            tabvalue={tabvalue}
            showOdds={showOdds}
            handleOutsideOutrightsClick={handleOutsideOutrightsClick}
            handleOpenOutrughtTooltip={handleOpenOutrughtTooltip}
            fetchBestAtOpenOdds={fetchBestAtOpenOdds}
            fetchBestAtOpenIconOdds={fetchBestAtOpenIconOdds}
            fetchFluctuationGraph={fetchFluctuationGraph}
            fetchNewOutrightCurrentBestOdds={fetchNewOutrightCurrentBestOdds}
            fetchNewOutrightCurrentBestsOddsIcon={
              fetchNewOutrightCurrentBestsOddsIcon
            }
            fetchNewOutrightClickableOdds={fetchNewOutrightClickableOdds}
          />
        </>
      ) : tabvalue === 4 ? (
        <>
          <TeamSportsDataPage
            teamSportType={teamSportType}
            searchInput={searchInput}
          />
        </>
      ) : tabvalue === 5 ? (
        <>
          {" "}
          <TeamSportNews
            teamSportType={teamSportType}
            newsType={"sport"}
            selectedOrg={selectedOrg}
            selectedTeams={selectedTeams}
            selectedOrgLabel={selectedOrgLabel}
            selectedTeamsLabel={selectedTeamsLabel}
            handleRedirectTab={handleRedirectTab}
          />
        </>
      ) : tabvalue === 6 ? (
        <>
          <StatisticsData
            teamSportType={teamSportType}
            stateTabValue={stateTabValue}
          />
        </>
      ) : (
        <></>
      )}
      <SmartBookModal
        open={SBopen}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
        dataId={selectedId}
        sportId={selectedSportId}
        listingFunction={() => {
          if (teamSportType === "golf") {
            fetchAllEvents(
              0,
              1,
              selectedseries,
              selectedteam,
              selectedDate,
              "outrights",
            );
          } else {
            fetchAllEvents(
              0,
              selectedOption,
              selectedseries,
              selectedteam,
              selectedDate,
            );
          }
        }}
      />
    </Box>
  );
};

export default TeamSportsLayoutsLanding;
