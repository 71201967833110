import React from "react";
import { Box, Typography } from "@mui/material";
import DefaultImg from "../../../../assets/images/smartb_default.png";
import { Config } from "src/helpers/context";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "src/components/Loader";
import moment from "moment";
import AR from "src/assets/images/SportImages/australia-rules.png";
import AFL from "src/assets/images/SportImages/american-football.png";
import Tennis from "src/assets/images/SportImages/tennis.png";
import Soccer from "src/assets/images/SportImages/soccer.png";
import Baseball from "src/assets/images/SportImages/baseball.png";
import Basketball from "src/assets/images/SportImages/basketball.png";
import Boxing from "src/assets/images/SportImages/boxing.png";
import Cricket from "src/assets/images/SportImages/cricket.png";
import Golf from "src/assets/images/SportImages/golf.png";
import IceHockey from "src/assets/images/SportImages/ice-hokey.png";
import MMA from "src/assets/images/SportImages/mma.png";
import RL from "src/assets/images/SportImages/rugby-league.png";
import RU from "src/assets/images/SportImages/rugby-union.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CompExpertsTips = ({
  featureRaceLoader,
  featureRaceData,
  settingsRace,
  handleNavigate,
  type,
  afterChange
}) => {
  const getSportImage = id => {
    return id === 4
      ? Cricket
      : id === 12
      ? RL
      : id === 13
      ? RU
      : id === 10
      ? Basketball
      : id === 15
      ? AFL
      : id === 9
      ? AR
      : id === 16
      ? Golf
      : id === 7
      ? Tennis
      : id === 11
      ? Baseball
      : id === 17
      ? IceHockey
      : id === 6
      ? Boxing
      : id === 5
      ? MMA
      : Soccer;
    // type === "soccer"
    // ? Soccer
    // : "Rugby Union Sevens";
  };

  return (
    <Box>
      {featureRaceLoader ? (
        <div className="allsport-loader-center">
          <Loader />
        </div>
      ) : featureRaceData && featureRaceData?.length > 0 ? (
        <Slider
          {...settingsRace}
          afterChange={e => afterChange(e, type)}
          className="experts-tips-slick-slider"
        >
          {featureRaceData?.map(race => {
            return (
              <>
                <Box
                  className="experts-tips-slider-details cursor-pointer"
                  onClick={() => {
                    handleNavigate(race, type);
                  }}
                >
                  {/* <Box
                    className="background-overlay"
                    style={{
                      content: "''",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage: `url('${
                        type === "racing"
                          ? race?.Event?.track?.banner_image
                            ? Config.mediaURL + race?.Event?.track?.banner_image
                            : DefaultImg
                          : getSportImage(race?.Sport?.id)
                      }')`,
                      backgroundSize: "cover",
                      backgroundPosition: "center top",
                      filter: "blur(6px)",
                      zIndex: -1,
                      borderRadius: "8px"
                    }}
                  /> */}
                  <Box className="slider-img">
                    <LazyLoadImage
                      src={
                        type === "racing"
                          ? race?.Event?.track?.banner_image
                            ? Config.mediaURL + race?.Event?.track?.banner_image
                            : DefaultImg
                          : getSportImage(race?.Sport?.id)
                      }
                      onError={e => {
                        e.target.src = DefaultImg;
                      }}
                      alt="expert tips"
                    />
                  </Box>

                  {type === "racing" ? (
                    <Box className="details-wrapper">
                      <Typography className="details-title" title>
                        {race?.SportId === 3
                          ? "Greyhound"
                          : race?.SportId === 2
                          ? "Harness"
                          : "Horse"}{" "}
                        Racing Tips : {race?.Event?.track?.State?.stateCode}{" "}
                        {race?.Event?.eventDate &&
                          moment(race?.Event?.eventDate).format(
                            "dddd Do MMMM YYYY"
                          )}
                      </Typography>
                      <Typography className="tips-analyst-details">
                        Analyst :{" "}
                        <span className="tips-analyst-name">
                          {" "}
                          {race?.User?.firstName} {race?.User?.lastName}
                        </span>
                      </Typography>
                    </Box>
                  ) : (
                    <Box className="details-wrapper sports-details-wrapper">
                      <Typography className="tips-sport-title" title>
                        {race?.tournamentName}
                      </Typography>
                      <Typography className="details-title sports-name">
                        Expert Tips for Round {race?.round}
                      </Typography>
                      <Typography className="tips-analyst-details sports-time">
                        {race?.updatedAt &&
                          moment(race?.updatedAt).format("ddd DD MMMM, YYYY")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </>
            );
          })}
        </Slider>
      ) : (
        <Box className="no-data-wrapper">
          <Typography className="no-data-text f-16">
            No data Available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CompExpertsTips;
