import React from "react";
import { Box } from "@mui/material";
import "./homeLandingLeft.scss";
import LandingFeaturedNews from "./landingFeaturedNews";
import LeadingFeaturedSportV2 from "./landingFeaturedSport";
import LeadingExpertTips from "./landingExpertTips";
import LeadingPublicTippingComp from "./landingTippingComp";
import LeadingHomeRanking from "./landingTippingCompRanking";
import LandingNews from "./landingNews";

const HomeLandingLeft = () => {
  return (
    <>
      <Box className="landing-home-left-wrap">
        {/* <Typography className="hander-text">featured News</Typography> */}
        <LandingFeaturedNews />
        <LeadingFeaturedSportV2 />
        <LeadingExpertTips />
        <LandingNews />
        <LeadingPublicTippingComp />
        <LeadingHomeRanking />
      </Box>
    </>
  );
};

export default HomeLandingLeft;
