import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as SortingArrow } from "src/assets/images/icons/sorting-arrow.svg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
// import India from "../../../../../../assets/images/flag/india.png";
import "./landingTableDetails.scss";
// import { useLocation, useParams } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
// import { Config } from "src/helpers/context";
import _ from "lodash";
import TournamnetListSelect from "../../../tournamnetListSelect";
import SeasonListSelect from "../../../seasonListSelect";

const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    backgroundColor: "#ffffff",
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    backgroundColor: "#ffffff",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  head: {},
  cell: {},
}));

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const LandingTableDetails = () => {
  // const params = useParams();
  // const location = useLocation();
  // const searchParamsMenu = new URLSearchParams(location.search);
  // const touranamentId = searchParamsMenu.get("touranamentId");
  const classes = useStyles();
  const [tournamentList, setTournamentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortingType, setSortingType] = useState({
    rankShort: null,
    teamShort: null,
    MShort: null,
    WShort: null,
    LShort: null,
    TShort: null,
    NRShort: null,
    NRRShort: null,
    pointShort: null,
  });
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [leagueOption, setLeagueOption] = useState([]);
  const [seasonOption, setSeasonOption] = useState([]);

  useEffect(() => {
    if (selectedLeague) {
      fetchICCRanking(selectedLeague, selectedSeason);
    }
  }, [selectedLeague, selectedSeason]);

  const fetchICCRanking = async (tId, sId) => {
    setLoading(true);
    const passApi = `sports/statistics/cricket/table/tournament/${tId}?seasonId=${sId ? sId : ""}`;
    const { status, data } = await axiosInstance.get(passApi);
    try {
      if (status === 200) {
        const groupedData = _.groupBy(data?.result?.result, "name");

        // Sort keys alphabetically
        const sortedData = Object.keys(groupedData)
          .sort() // Sort keys alphabetically
          .reduce((sortedObj, key) => {
            sortedObj[key] = groupedData[key]; // Use groupedData to access the values
            return sortedObj;
          }, {});

        setTournamentList(sortedData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleSorting = (field, isShorting, group) => {
    // Sort only the selected group
    const sortedData = { ...tournamentList };

    sortedData[group] = [...tournamentList[group]]; // Copy the specific group

    if (
      field === "rnk" ||
      field === "pld" ||
      field === "win" ||
      field === "lstn" ||
      field === "drw" ||
      field === "nr" ||
      field === "nrr" ||
      field === "pts"
    ) {
      sortedData[group].sort((a, b) => {
        if (isShorting === null || isShorting === false) {
          return a?.[field] - b?.[field];
        } else {
          return b?.[field] - a?.[field];
        }
      });
    } else if (field === "Tnm") {
      sortedData[group].sort((a, b) => {
        if (isShorting === null || isShorting === false) {
          return a?.Tnm.localeCompare(b?.Tnm);
        } else {
          return b?.Tnm.localeCompare(a?.Tnm);
        }
      });
    }

    setTournamentList(sortedData);

    // Update sorting state for the specific group
    setSortingType({
      ...sortingType,
      [group]: {
        rankShort: field === "rnk" ? !isShorting : null,
        teamShort: field === "Tnm" ? !isShorting : null,
        MShort: field === "pld" ? !isShorting : null,
        WShort: field === "win" ? !isShorting : null,
        LShort: field === "lstn" ? !isShorting : null,
        TShort: field === "drw" ? !isShorting : null,
        NRShort: field === "nr" ? !isShorting : null,
        NRRShort: field === "nrr" ? !isShorting : null,
        pointShort: field === "pts" ? !isShorting : null,
      },
    });
  };

  const handleleagueChange = (e) => {
    setSelectedLeague(e?.value);
    setSelectedSeason(null);
    setSeasonOption([]);
  };

  const handleSeasonChange = (e) => {
    setSelectedSeason(e?.value);
  };

  console.log("tournamentList", tournamentList);
  console.log("sortingType", sortingType);
  return (
    <>
      <Box className="leading-table-container-wrap">
        <Box className="leading-select-wrap">
          <Box className="table-select league-table-select">
            <TournamnetListSelect
              selectOption={leagueOption}
              setSelectOption={setLeagueOption}
              selectedValue={selectedLeague}
              setSelectedValue={setSelectedLeague}
              handleSelectedValueChange={handleleagueChange}
            />
          </Box>
          <Box className="table-select season-table-select">
            <SeasonListSelect
              selectOption={seasonOption}
              setSelectOption={setSeasonOption}
              selectedValue={selectedSeason}
              setSelectedValue={setSelectedSeason}
              handleSelectedValueChange={handleSeasonChange}
              selectedLeague={selectedLeague}
            />
          </Box>
        </Box>
        <Box className="table-container-details">
          {loading ? (
            <div className="allsport-loader-center">
              <Loader />
            </div>
          ) : Object.keys(tournamentList)?.length > 0 ? (
            Object.entries(tournamentList)?.map(([category, items]) => (
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className="new-landing-table"
                >
                  <TableHead>
                    <TableRow>
                      <StickyTableCell className={`${classes.head} rnk-cell`}>
                        <Box
                          className="sorting-cell"
                          onClick={() =>
                            handleSorting(
                              "rnk",
                              sortingType?.[category]?.rankShort,
                              category,
                            )
                          }
                        >
                          #{" "}
                          <Box className="sort-icon-wrap">
                            {/* Ascending Arrow */}
                            <span>
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.rankShort === true
                                    ? "asc-sort" // Active ascending state
                                    : ""
                                }
                              />
                            </span>

                            {/* Descending Arrow */}
                            <span className="down-arrow">
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.rankShort === false
                                    ? "desc-sort" // Active descending state
                                    : ""
                                }
                              />
                            </span>
                          </Box>
                        </Box>
                      </StickyTableCell>
                      <StickyTableCell
                        className={`${classes.head} team-cell`}
                        align="left"
                      >
                        <Box
                          className="sorting-cell"
                          onClick={() =>
                            handleSorting(
                              "Tnm",
                              sortingType?.[category]?.teamShort,
                              category,
                            )
                          }
                        >
                          {category}{" "}
                          <Box className="sort-icon-wrap">
                            {/* Ascending Arrow */}
                            <span>
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.teamShort === true
                                    ? "asc-sort" // Active ascending state
                                    : ""
                                }
                              />
                            </span>

                            {/* Descending Arrow */}
                            <span className="down-arrow">
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.teamShort === false
                                    ? "desc-sort" // Active descending state
                                    : ""
                                }
                              />
                            </span>
                          </Box>
                        </Box>
                      </StickyTableCell>
                      <TableCell className={classes.head} align="center">
                        <Box
                          className="sorting-cell text-align-center"
                          onClick={() =>
                            handleSorting(
                              "pld",
                              sortingType?.[category]?.MShort,
                              category,
                            )
                          }
                        >
                          M{" "}
                          <Box className="sort-icon-wrap">
                            {/* Ascending Arrow */}
                            <span>
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.MShort === true
                                    ? "asc-sort" // Active ascending state
                                    : ""
                                }
                              />
                            </span>

                            {/* Descending Arrow */}
                            <span className="down-arrow">
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.MShort === false
                                    ? "desc-sort" // Active descending state
                                    : ""
                                }
                              />
                            </span>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.head} align="center">
                        <Box
                          className="sorting-cell text-align-center"
                          onClick={() =>
                            handleSorting(
                              "win",
                              sortingType?.[category]?.WShort,
                              category,
                            )
                          }
                        >
                          W{" "}
                          <Box className="sort-icon-wrap">
                            {/* Ascending Arrow */}
                            <span>
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.WShort === true
                                    ? "asc-sort" // Active ascending state
                                    : ""
                                }
                              />
                            </span>

                            {/* Descending Arrow */}
                            <span className="down-arrow">
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.WShort === false
                                    ? "desc-sort" // Active descending state
                                    : ""
                                }
                              />
                            </span>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.head} align="center">
                        <Box
                          className="sorting-cell text-align-center"
                          onClick={() =>
                            handleSorting(
                              "lstn",
                              sortingType?.[category]?.LShort,
                              category,
                            )
                          }
                        >
                          L{" "}
                          <Box className="sort-icon-wrap">
                            {/* Ascending Arrow */}
                            <span>
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.LShort === true
                                    ? "asc-sort" // Active ascending state
                                    : ""
                                }
                              />
                            </span>

                            {/* Descending Arrow */}
                            <span className="down-arrow">
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.LShort === false
                                    ? "desc-sort" // Active descending state
                                    : ""
                                }
                              />
                            </span>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.head} align="center">
                        <Box
                          className="sorting-cell text-align-center"
                          onClick={() =>
                            handleSorting(
                              "drw",
                              sortingType?.[category]?.TShort,
                              category,
                            )
                          }
                        >
                          T{" "}
                          <Box className="sort-icon-wrap">
                            {/* Ascending Arrow */}
                            <span>
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.TShort === true
                                    ? "asc-sort" // Active ascending state
                                    : ""
                                }
                              />
                            </span>

                            {/* Descending Arrow */}
                            <span className="down-arrow">
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.TShort === false
                                    ? "desc-sort" // Active descending state
                                    : ""
                                }
                              />
                            </span>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.head} align="center">
                        <Box
                          className="sorting-cell text-align-center"
                          onClick={() =>
                            handleSorting(
                              "nr",
                              sortingType?.[category]?.NRShort,
                              category,
                            )
                          }
                        >
                          NR{" "}
                          <Box className="sort-icon-wrap">
                            {/* Ascending Arrow */}
                            <span>
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.NRShort === true
                                    ? "asc-sort" // Active ascending state
                                    : ""
                                }
                              />
                            </span>

                            {/* Descending Arrow */}
                            <span className="down-arrow">
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.NRShort === false
                                    ? "desc-sort" // Active descending state
                                    : ""
                                }
                              />
                            </span>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.head} align="center">
                        <Box
                          className="sorting-cell text-align-center"
                          onClick={() =>
                            handleSorting(
                              "nrr",
                              sortingType?.[category]?.NRRShort,
                              category,
                            )
                          }
                        >
                          NRR{" "}
                          <Box className="sort-icon-wrap">
                            {/* Ascending Arrow */}
                            <span>
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.NRRShort === true
                                    ? "asc-sort" // Active ascending state
                                    : ""
                                }
                              />
                            </span>

                            {/* Descending Arrow */}
                            <span className="down-arrow">
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.NRRShort === false
                                    ? "desc-sort" // Active descending state
                                    : ""
                                }
                              />
                            </span>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className={classes.head} align="center">
                        <Box
                          className="sorting-cell text-align-center"
                          onClick={() =>
                            handleSorting(
                              "pts",
                              sortingType?.[category]?.pointShort,
                              category,
                            )
                          }
                        >
                          Pts{" "}
                          <Box className="sort-icon-wrap">
                            {/* Ascending Arrow */}
                            <span>
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.pointShort === true
                                    ? "asc-sort" // Active ascending state
                                    : ""
                                }
                              />
                            </span>

                            {/* Descending Arrow */}
                            <span className="down-arrow">
                              <SortingArrow
                                className={
                                  sortingType?.[category]?.pointShort === false
                                    ? "desc-sort" // Active descending state
                                    : ""
                                }
                              />
                            </span>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <div className="allsport-loader-center ">
                            <Loader />
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    {!loading && items?.length > 0
                      ? items?.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <StickyTableCell
                                className={`${classes.cell} rnk-cell`}
                              >
                                {item?.rnk}
                              </StickyTableCell>
                              <StickyTableCell
                                className={`${classes.cell} team-cell`}
                                align="left"
                              >
                                <Box className="team-wrap">
                                  {/* <Box className="team-image-wrap">
                                <img
                                  src={
                                    item?.CricketTeam?.flag
                                      ? item?.CricketTeam?.flag?.includes(
                                          "uploads",
                                        )
                                        ? Config.mediaURL +
                                          item?.CricketTeam?.flag
                                        : item?.CricketTeam?.flag
                                      : India
                                  }
                                  onError={(e) => {
                                    e.target.src = India;
                                  }}
                                  alt="Team 1"
                                  className="team-image"
                                />
                              </Box> */}
                                  <Box className="team-name-wrap">
                                    <Typography className="team-name">
                                      {item?.Tnm}
                                    </Typography>
                                  </Box>
                                </Box>
                              </StickyTableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.pld}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.win}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.lstn}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.drw}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.nr}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.nrr}
                              </TableCell>
                              <TableCell
                                className={classes.cell}
                                align="center"
                              >
                                {item?.pts}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : !loading && (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <div className="allsport-loader-center ">
                                No Data Available
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                  </TableBody>
                </Table>
              </TableContainer>
            ))
          ) : (
            <div className="allsport-loader-center ">No Data Available</div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default LandingTableDetails;
