import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Box, Collapse, Typography } from "@mui/material";
import { ReactComponent as Bat } from "../../../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Ball } from "../../../../../assets/images/SportIcon/ball.svg";
import { IntlContext } from "src/App";
import moment from "moment";
import "./matchSummary.scss";
import Loader from "src/components/Loader";
import SoccerCard from "src/views/component/teamSportData/soccerSportData/soccerCard";

const summaryData = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  }
];

const MatchSummary = ({
  showUpdate,
  showUpdateIndex,
  index,
  singleMatchData,
  matchLoading
}) => {
  const params = useParams();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  const getFooterText = (data, toss, choose) => {
    // if (toss == 0 && choose == 0 && data?.ScoreBoard?.Epr === 8) {
    //   return "";
    // } else
    if (toss == 0 && choose == 0) {
      return (
        <Typography className="match-status">
          {data?.ScoreBoard?.ECo && data?.ScoreBoard?.ECo}
        </Typography>
      );
    } else if (toss == 1 && choose == 1) {
      return (
        <Typography className="match-status">
          {`${data?.homeTeam?.name} win the Toss and choose to bat`}
        </Typography>
      );
    } else if (toss == 1 && choose == 2) {
      return (
        <Typography className="match-status">
          {`${data?.homeTeam?.name} win the Toss and choose to bowling`}
        </Typography>
      );
    } else if (toss == 2 && choose == 1) {
      return (
        <Typography className="match-status">
          {`${data?.awayTeam?.name} win the Toss and choose to bat`}
        </Typography>
      );
    } else if (toss == 2 && choose == 2) {
      return (
        <Typography className="match-status">
          {`${data?.awayTeam?.name} win the Toss and choose to bowling`}
        </Typography>
      );
    }
  };
  const teamScore = singleMatchData?.ScoreBoard;
  return (
    <>
      <Box className="summary-section-wrap">
        <Collapse
          in={showUpdateIndex === index && showUpdate}
          className="summary-collapse"
        >
          <Box className="summary-wrap">
            {params?.type === "cricket" ? (
              matchLoading ? (
                <div className="allsport-loader-center">
                  <Loader />
                </div>
              ) : teamScore?.Epr === 2 ? (
                <Box className="match-point-Score-board">
                  <Box className="match-details-wrap">
                    <Box className="d-flex align-center content-space-between">
                      <Typography className="match-title">
                        {teamScore?.EtTx && teamScore?.EtTx}{" "}
                        {teamScore?.ErnInf?.trim() && `(${teamScore?.ErnInf})`}
                      </Typography>
                      <Typography className="match-title">
                        {teamScore?.Esd && teamScore?.Exd > "1"
                          ? teamScore?.Esd &&
                            `${moment(teamScore?.Esd).format(
                              "DD MMM"
                            )} - ${moment(teamScore?.Esd)
                              .add(Number(teamScore?.Exd) - 1, "days")
                              .format("DD MMM")}`
                          : teamScore?.Esd &&
                            moment(teamScore?.Esd).format("DD MMM")}
                      </Typography>
                    </Box>
                    <Typography className="match-status">
                      {teamScore?.EpsL && teamScore?.EpsL}
                    </Typography>
                    <Box className="d-flex align-center content-space-between">
                      <Typography className="match-team-name">
                        {singleMatchData?.homeTeam?.name}
                      </Typography>
                      {teamScore?.Exd > "1" ? (
                        <Typography className="team-score">
                          {teamScore?.Tr1C1 ?? "-"}
                          {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
                            ? ""
                            : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
                              `/${teamScore?.Tr1CW1}${
                                teamScore?.Tr1CD1 && teamScore?.Tr1CD1 === 1
                                  ? "d"
                                  : ""
                              }`}{" "}
                          {(teamScore?.Tr1C2 || teamScore?.Tr1C2 === 0) &&
                            `& ${teamScore?.Tr1C2}`}
                          {teamScore?.Tr1CW2 && teamScore?.Tr1CW2 === 10
                            ? ""
                            : (teamScore?.Tr1CW2 || teamScore?.Tr1CW2 === 0) &&
                              `/${teamScore?.Tr1CW2}${
                                teamScore?.Tr1CD2 && teamScore?.Tr1CD2 === 1
                                  ? "d"
                                  : ""
                              }`}{" "}
                        </Typography>
                      ) : (
                        <Typography className="team-score">
                          {teamScore?.Tr1C1 ?? "-"}
                          {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
                            ? ""
                            : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
                              `/ ${teamScore?.Tr1CW1}`}{" "}
                          <span>
                            {" "}
                            {teamScore?.Tr1CO1 ? `(${teamScore?.Tr1CO1})` : ""}
                          </span>{" "}
                        </Typography>
                      )}
                    </Box>
                    <Box className="d-flex align-center content-space-between">
                      <Typography className="match-team-name">
                        {singleMatchData?.awayTeam?.name}
                      </Typography>
                      {teamScore?.Exd > "1" ? (
                        <Typography className="team-score">
                          {teamScore?.Tr2C1 ?? "-"}
                          {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
                            ? ""
                            : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
                              `/${teamScore?.Tr2CW1}${
                                teamScore?.Tr2CD1 && teamScore?.Tr2CD1 === 1
                                  ? "d"
                                  : ""
                              }`}{" "}
                          {(teamScore?.Tr2C2 || teamScore?.Tr2C2 === 0) &&
                            `& ${teamScore?.Tr2C2}`}
                          {teamScore?.Tr2CW2 && teamScore?.Tr2CW2 === 10
                            ? ""
                            : (teamScore?.Tr2CW2 || teamScore?.Tr2CW2 === 0) &&
                              `/${teamScore?.Tr2CW2}${
                                teamScore?.Tr2CD2 && teamScore?.Tr2CD2 === 1
                                  ? "d"
                                  : ""
                              }`}{" "}
                        </Typography>
                      ) : (
                        <Typography className="team-score">
                          {teamScore?.Tr2C1 ?? "-"}
                          {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
                            ? ""
                            : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
                              `/ ${teamScore?.Tr2CW1}`}{" "}
                          <span>
                            {teamScore?.Tr2CO1 ? `(${teamScore?.Tr2CO1})` : ""}
                          </span>
                        </Typography>
                      )}
                    </Box>
                    <Typography className="match-status">
                      {teamScore?.ECo && teamScore?.ECo}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box className="match-point-Score-board">
                  <Box className="match-details-wrap ">
                    {teamScore?.Epr === 1 && (
                      <Box className="divider-indicator"></Box>
                    )}
                    <Box className="d-flex align-center content-space-between">
                      <Typography className="match-title">
                        {teamScore?.EtTx && teamScore?.EtTx}{" "}
                        {teamScore?.ErnInf?.trim() && `(${teamScore?.ErnInf})`}
                      </Typography>
                      <Typography className="match-title">
                        {teamScore?.Esd && teamScore?.Exd > "1"
                          ? teamScore?.Esd &&
                            `${moment(teamScore?.Esd).format(
                              "DD MMM"
                            )} - ${moment(teamScore?.Esd)
                              .add(Number(teamScore?.Exd) - 1, "days")
                              .format("DD MMM")}`
                          : teamScore?.Esd &&
                            moment(teamScore?.Esd).format("DD MMM")}
                      </Typography>
                    </Box>
                    <Typography className="match-status">
                      {teamScore?.Epr === 1
                        ? ""
                        : teamScore?.Esd &&
                          `${moment(teamScore?.Esd).format("HH:mm")} -`}{" "}
                      {teamScore?.EpsL && `${teamScore?.EpsL}`}
                    </Typography>

                    <Box className="d-flex align-center content-space-between">
                      <Typography className="match-team-name d-flex col-gap-5">
                        {teamScore?.Epr === 1 ? (
                          <span>
                            {teamScore?.Ebat === 1 ? (
                              <Bat className="bat" />
                            ) : (
                              <Ball className="ball" />
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                        {singleMatchData?.homeTeam?.name}
                      </Typography>
                      {teamScore?.Epr === 1 &&
                        (teamScore?.Exd > "1" ? (
                          <Typography className="team-score">
                            {teamScore?.Tr1C1 ?? "-"}
                            {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
                              ? ""
                              : (teamScore?.Tr1CW1 ||
                                  teamScore?.Tr1CW1 === 0) &&
                                `/${teamScore?.Tr1CW1}${
                                  teamScore?.Tr1CD1 && teamScore?.Tr1CD1 === 1
                                    ? "d"
                                    : ""
                                }`}{" "}
                            {(teamScore?.Tr1C2 || teamScore?.Tr1C2 === 0) &&
                              `& ${teamScore?.Tr1C2}`}
                            {teamScore?.Tr1CW2 && teamScore?.Tr1CW2 === 10
                              ? ""
                              : (teamScore?.Tr1CW2 ||
                                  teamScore?.Tr1CW2 === 0) &&
                                `/${teamScore?.Tr1CW2}${
                                  teamScore?.Tr1CD2 && teamScore?.Tr1CD2 === 1
                                    ? "d"
                                    : ""
                                }`}{" "}
                          </Typography>
                        ) : (
                          <Typography className="team-score">
                            {teamScore?.Tr1C1 ?? "-"}
                            {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
                              ? ""
                              : (teamScore?.Tr1CW1 ||
                                  teamScore?.Tr1CW1 === 0) &&
                                `/ ${teamScore?.Tr1CW1}`}{" "}
                            <span>
                              {" "}
                              {teamScore?.Tr1CO1
                                ? `(${teamScore?.Tr1CO1})`
                                : ""}
                            </span>{" "}
                          </Typography>
                        ))}
                    </Box>

                    <Box className="d-flex align-center content-space-between">
                      <Typography className="match-team-name d-flex col-gap-5">
                        {teamScore?.Epr === 1 ? (
                          <span>
                            {teamScore?.Ebat === 1 ? (
                              <Ball className="ball" />
                            ) : (
                              <Bat className="bat" />
                            )}
                          </span>
                        ) : (
                          ""
                        )}
                        {singleMatchData?.awayTeam?.name}
                      </Typography>
                      {teamScore?.Epr === 1 &&
                        (teamScore?.Exd > "1" ? (
                          <Typography className="team-score">
                            {teamScore?.Tr2C1 ?? "-"}
                            {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
                              ? ""
                              : (teamScore?.Tr2CW1 ||
                                  teamScore?.Tr2CW1 === 0) &&
                                `/${teamScore?.Tr2CW1}${
                                  teamScore?.Tr2CD1 && teamScore?.Tr2CD1 === 1
                                    ? "d"
                                    : ""
                                }`}{" "}
                            {(teamScore?.Tr2C2 || teamScore?.Tr2C2 === 0) &&
                              `& ${teamScore?.Tr2C2}`}
                            {teamScore?.Tr2CW2 && teamScore?.Tr2CW2 === 10
                              ? ""
                              : (teamScore?.Tr2CW2 ||
                                  teamScore?.Tr2CW2 === 0) &&
                                `/${teamScore?.Tr2CW2}${
                                  teamScore?.Tr2CD2 && teamScore?.Tr2CD2 === 1
                                    ? "d"
                                    : ""
                                }`}{" "}
                          </Typography>
                        ) : (
                          <Typography className="team-score">
                            {teamScore?.Tr2C1 ?? "-"}
                            {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
                              ? ""
                              : (teamScore?.Tr2CW1 ||
                                  teamScore?.Tr2CW1 === 0) &&
                                `/ ${teamScore?.Tr2CW1}`}{" "}
                            <span>
                              {teamScore?.Tr2CO1
                                ? `(${teamScore?.Tr2CO1})`
                                : ""}
                            </span>
                          </Typography>
                        ))}
                    </Box>

                    {teamScore?.Epr === 1 ? (
                      <Typography className="match-status">
                        {teamScore?.ECo && teamScore?.ECo}
                      </Typography>
                    ) : (
                      getFooterText(
                        singleMatchData,
                        teamScore?.TPa,
                        teamScore?.TCho
                      )
                    )}
                  </Box>
                </Box>
              )
            ) : params?.type === "soccer" ? (
              <SoccerCard
                theme="light-theme"
                type={
                  teamScore?.Epr == 0
                    ? "upcoming"
                    : teamScore?.Epr == 2
                    ? "past"
                    : teamScore?.Epr == 1
                    ? "live"
                    : "upcoming"
                }
                cardData={singleMatchData}
                index={index}
              />
            ) : (
              <>
                <Typography className="summary-text">Summary</Typography>
                {summaryData?.map((item, index) => {
                  return (
                    <>
                      <Box className="summary-details" key={index}>
                        <Typography className="details-text light">
                          11'
                        </Typography>
                        <Typography className="details-text dark">
                          Raul Jimenez
                        </Typography>
                        <Typography className="details-text dark">
                          0 - 1
                        </Typography>
                      </Box>
                    </>
                  );
                })}
              </>
            )}
          </Box>
        </Collapse>
      </Box>
    </>
  );
};

export default MatchSummary;
