import React from "react";
import { Box } from "@mui/material";

const RankingsLanding = () => {
  return (
    <>
      <Box>RankingsLanding</Box>
    </>
  );
};

export default RankingsLanding;
