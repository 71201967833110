import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { identifiers } from "src/helpers/constants/identifier";
import { ReactComponent as DownArrow } from "src/assets/images/icons/menuArrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/eventcalendar/arrowRight.svg";
import { CustomTooltip } from "./CustomMenuTooltip";
import "./headerMenuList.scss";
import { Config, fetchFromStorage } from "src/helpers/context";
import { useDispatch, useSelector } from "react-redux";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";

const HeaderMenuList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const reduxSubscriptionData = useSelector(
    (state) => state?.reduxData?.SubscripitionData
  );
  let searchParamsType = new URLSearchParams(window.location.search).get(
    "type"
  );
  const [menuItems, setMenuItems] = useState(identifiers?.Menu);

  const isActiveMenuItem = (item) => {
    if (location.pathname !== "/comingsoon") {
      if (location.pathname.includes("/racing")) {
        return item?.url === "/racing";
      } else if (
        item?.submenu?.length &&
        item?.submenu?.find(
          (obj) =>
            obj?.url?.includes(location.pathname) &&
            obj?.parentId == item?.id &&
            location?.pathname !== "/"
        )
      ) {
        return true;
      } else {
        return (
          item?.url === location.pathname ||
          ((location.pathname.includes("/responsible-gambling") ||
            location.pathname.includes("/privacy-policy") ||
            location.pathname.includes("/terms-and-conditions")) &&
            item?.id === 8) ||
          (location.pathname.includes("/statistics/trackprofiles") &&
            item?.id === 9) ||
          (location.pathname.includes("/teamsports") && item?.id === 3) ||
          (location.pathname.includes("/news") && item?.id === 10) ||
          (location.pathname.includes("/tipscompetition") && item?.id === 12)
        );
      }
    }
    return false;
  };

  const isActiveSubMenuItem = (menuData, item, sub) => {
    if (menuData?.id === 2) {
      return (
        ((sub?.url === location.pathname ||
          location.pathname.includes(sub?.url) ||
          location.pathname.includes(sub?.routeName)) &&
          menuData?.id === sub?.parentId) ||
        (menuData?.id === sub?.raceParentId &&
          location.pathname.includes(sub?.url)) ||
        (location?.pathname?.includes("/racing") &&
          (sub?.id == searchParamsType || sub?.id == params.sportId)) ||
        sub?.name === "Show All Sports" ||
        sub?.name === "Statistics"
      );
    } else {
      return (
        (sub?.url === location.pathname && menuData?.id === sub?.parentId) ||
        location.pathname.includes(sub?.url) ||
        (location.pathname.includes(sub?.routeName) &&
          menuData?.id === sub?.parentId) ||
        sub?.name === "Show All Sports" ||
        sub?.name === "Statistics"
      );
    }
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };


  // OPEN SUBMENUS

  const handleNavigate = (Navigate, data) => {
    if (
      (data?.parentId === 10 && data?.id === 2) ||
      (data?.parentId === 10 && data?.id === 3) ||
      (data?.parentId === 10 && data?.id === 5) ||
      (data?.parentId === 2 && data?.id === 6)
    ) {
      if (Navigate?.url) {
        if ((data?.parentId === 10 && data?.id === 5) || data?.id === 6) {
          if (handleRestrictedUser()) {
            data?.id === 5 && (window.location.href = Navigate?.url);
            data?.id === 6 && navigate(Navigate?.url);
          } else {
            dispatch(fetchRestrictedRoute(true));
            localStorage.setItem("prev_authroute", Navigate?.url);
          }
        } else {
          window.location.href = Navigate?.url;
        }
      }
    } else {
      if (
        data?.sportParentId === 98 ||
        (Navigate?.id === 99 && data?.parentId === 3) ||
        (data?.parentId === 11 && Navigate?.name === "Racing Blackbook") ||
        (data?.parentId === 11 && Navigate?.name === "Sports Smartbook") ||
        (data?.parentId === 12 && Navigate?.name === "Create Comps") ||
        (data?.parentId === 12 && Navigate?.name === "My Comps") ||
        (data?.parentId === 12 && Navigate?.name === "Tipping FAQs") ||
        (data?.parentId === 12 && Navigate?.name === "Prizes") ||
        (data?.parentId === 10 && data?.id === 11) ||
        (data?.parentId === 10 && data?.id === 12) ||
        (data?.parentId === 10 && data?.id === 13) ||
        (Navigate?.raceParentId === 2 && Navigate?.id === 100)
      ) {
        if (handleRestrictedUser()) {
          if (Navigate?.url) {
            Navigate?.raceParentId === 2 && Navigate?.id === 100
              ? navigate(Navigate?.url, { state: true })
              : navigate(Navigate?.url);
          }
        } else {
          if (Navigate?.raceParentId === 2 && Navigate?.id === 100) {
            navigate("/soc-signup");
          } else {
            dispatch(fetchRestrictedRoute(true));
            localStorage.setItem("prev_authroute", Navigate?.url);
          }
        }
      } else {
        if (
          !handleRestrictedUser() &&
          Navigate?.parentId === 10 &&
          Navigate?.id === 10
        ) {
          navigate("/soc-signup");
        } else {
          navigate(Navigate?.url);
        }
      }
    }
  };

  const subMenuList = (menuData, item) => {
    return item?.map((sub, i) => (
      <CustomTooltip
        placement="right-start"
        interactive
        title={
          sub?.secondSubMenu && sub?.secondSubMenu?.length > 0 ? (
            <Box className="v3-header-sub-menu-wrapper">
              {subMenuList(menuData, sub?.secondSubMenu)}
            </Box>
          ) : (
            ""
          )
        }
        // open={sub?.secondSubMenu?.length === 9 ? true : false}
        key={i}
        classes={{
          tooltip: "custom-sub-menu-tooltip",
        }}
        PopperProps={{
          style: {
            zIndex: 1200,
          },
        }}
      >
        <Box className="v3-header-sub-menu-list">
          <Box
            // to={sub?.url}
            // className="v3-header-sub-menu-btn"
            className={`v3-header-sub-menu-btn ${isActiveSubMenuItem(menuData, item, sub) &&
              sub?.name !== "Show All Sports"
              ? "active"
              : "inactive"
              }`}
            onClick={() => {
              handleNavigate(sub, sub);
            }}
          >
            {sub?.name}
            {sub?.secondSubMenu && sub?.secondSubMenu?.length > 0 && (
              <RightArrow className="v3-header-sub-menu-arrow-icon" />
            )}
          </Box>
        </Box>
      </CustomTooltip>
    ));
  };


  const handleRedirectfantasy = () => {
    const localAuth = fetchFromStorage("auth_token");
    if (handleRestrictedUser()) {
      // window.location.href = `${Config.fantasySiteBaseURL}?login_token=${localAuth}`;
      window.location.href = `${Config.fantasySiteBaseURL}`;
    } else {
      window.location.href = Config.fantasySiteBaseURL;
    }
  };



  return (
    <Box className="v3-header-menu-wrapper">
      {menuItems?.map((item, index) => {
        return (
          <>
            <Box className="v3-header-menu-list">
              <CustomTooltip
                placement="bottom-start"
                interactive
                title={
                  item?.submenu && item?.submenu?.length > 0 ? (
                    <Box className="v3-header-sub-menu-wrapper">
                      {subMenuList(item, item?.submenu)}
                    </Box>
                  ) : (
                    ""
                  )
                }
                // open={item?.submenu?.length === 3 ? true : false}
                key={index}
                PopperProps={{
                  style: {
                    zIndex: 1200,
                  },
                }}
              >
                <Box
                  // to={item?.url}
                  // className="v3-header-menu-btn"
                  className={`v3-header-menu-btn ${isActiveMenuItem(item) ? "active" : "inactive"
                    }`}
                  onClick={() => handleNavigate(item)}
                >
                  {item?.name}
                  {item?.submenu && item?.submenu?.length > 0 && (
                    <DownArrow className="v3-header-menu-arrow-icon" />
                  )}
                </Box>
              </CustomTooltip>
            </Box>
          </>
        );
      })}
      {/* {(reduxSubscriptionData &&
        reduxSubscriptionData?.subscription?.SubscriptionPlan?.key !==
        "odds") ||
        !handleRestrictedUser() ? (
        <Button
          className="v3-header-subscribe-btn"
          onClick={() => navigate("/membership")}
        >
          Join SmartB
        </Button>
      ) : (
        <></>
      )} */}

      <Button
        className="v3-header-subscribe-btn"
        onClick={() => handleRedirectfantasy()}
      >
        SmartPlay
      </Button>
    </Box>
  );
};

export default HeaderMenuList;
