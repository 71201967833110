import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  TextField,
  InputAdornment,
  Checkbox,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useParams } from "react-router";
import { ReactComponent as Search } from "../../../../../assets/images/icons/search.svg";
import { ReactComponent as Plus } from "../../../../../assets/images/icons/plus.svg";
import { ReactComponent as Right } from "../../../../../assets/images/icons/right.svg";
import { ReactComponent as BlackbookRight } from "../../../../../assets/images/icons/blackbookRight.svg";
import { ReactComponent as BlackbookPlus } from "../../../../../assets/images/icons/blackbookPlus.svg";
import { IntlContext } from "src/App";
import "./statisticsData.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import Loader from "src/components/Loader";
import NoDataComp from "src/views/component/UI/NoData";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchFromStorage } from "src/helpers/context";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import SmartBookModal from "src/views/component/UI/smartbookModal";
import { useDispatch } from "react-redux";

const StatisticsData = ({ teamSportType, stateTabValue, statsData }) => {
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const params = useParams();
  const alpha = [];
  for (let i = 65; i <= 90; i++) {
    alpha.push({
      letter: String.fromCharCode(i),
      isSelected: false
    });
  }
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [searchInput, setSearchInput] = useState("");
  const [letters, setLetters] = useState(alpha);
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [isViewAll, setIsViewAll] = useState(false);
  const [viewAllAlphabet, setViewAllAlphabet] = useState("");
  const [viewAllCount, setViewAllCount] = useState(0);
  const [viewAllOffset, setViewAllOffset] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [statisticsListData, setStatisticsListData] = useState([]);
  const [EventCount, setEventCount] = useState({});
  const [EventPage, setEventPage] = useState(0);
  const [SBopen, setSBOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedSportId, setSelectedSportId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");

  let SportId =
    teamSportType === "cricket"
      ? 4
      : teamSportType === "rugbyleague"
      ? 12
      : teamSportType === "rugbyunion"
      ? 13
      : teamSportType === "basketball"
      ? 10
      : teamSportType === "americanfootball"
      ? 15
      : teamSportType === "australianrules"
      ? 9
      : teamSportType === "golf"
      ? 16
      : teamSportType === "tennis"
      ? 7
      : teamSportType === "baseball"
      ? 11
      : teamSportType === "icehockey"
      ? 17
      : teamSportType === "boxing"
      ? 6
      : teamSportType === "mma"
      ? 5
      : teamSportType === "soccer"
      ? 8
      : 14;
  let SportsType =
    teamSportType === "cricket"
      ? `crickets`
      : teamSportType === "rugbyleague"
      ? `rls`
      : teamSportType === "rugbyunion"
      ? `rls`
      : teamSportType === "basketball"
      ? `nba`
      : teamSportType === "americanfootball"
      ? `afl`
      : teamSportType === "australianrules"
      ? `ar`
      : teamSportType === "golf"
      ? `golf`
      : teamSportType === "tennis"
      ? `tennis`
      : teamSportType === "baseball"
      ? `baseball`
      : teamSportType === "icehockey"
      ? `icehockey`
      : teamSportType === "boxing"
      ? `boxing`
      : teamSportType === "mma"
      ? `mma`
      : teamSportType === "soccer"
      ? `soccer`
      : `rls`;

  const lettersCount = [];
  for (let i = 65; i <= 90; i++) {
    lettersCount.push(String.fromCharCode(i));
  }

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
    // fetchAdsData();
  }, []);

  useEffect(() => {
    const generateLetters = () => {
      const lettersArray = [];
      for (let i = 65; i <= 90; i++) {
        lettersArray.push({
          letter: String.fromCharCode(i),
          isSelected: false
        });
      }
      return lettersArray;
    };

    setLetters(generateLetters());
  }, []);

  const toggleLetter = index => {
    setSearchInput("");
    setIsViewAll(false);
    setViewAllAlphabet("");
    const updatedLetters = [...letters];
    updatedLetters[index].isSelected = !updatedLetters[index].isSelected;
    setLetters(updatedLetters);

    if (updatedLetters[index].isSelected) {
      const filteredData = [...selectedLetters, updatedLetters[index]?.letter];
      fetchStatisticsList("", 0, filteredData);
      setSelectedLetters(prevSelected => [
        ...prevSelected,
        updatedLetters[index]?.letter
      ]);
    } else {
      setSelectedLetters(prevSelected =>
        prevSelected?.filter(letter => letter !== updatedLetters[index].letter)
      );
      fetchStatisticsList(
        "",
        0,
        selectedLetters?.filter(
          letter => letter !== updatedLetters[index]?.letter
        )
      );
    }
  };

  useEffect(() => {
    if (stateTabValue || stateTabValue == 0) {
      fetchStatisticsList("", 0, [], null);
    }
    setStatisticsListData({});
    setLetters(alpha);
    setSelectedLetters([]);
    setListCount(0);
    setViewAllOffset(0);
    setIsViewAll(false);
    setSearchInput("");
  }, [stateTabValue]);

  const fetchStatisticsList = async (
    inputValue,
    datacount,
    selectedLetters,
    searchcount
  ) => {
    setisLoading(true);
    const tab =
      stateTabValue === 1 ? "team" : stateTabValue === 2 ? "Player" : "";
    const passAPI =
      stateTabValue == 0
        ? `/allsport/tournament?SportId=${SportId}&search=${inputValue}&limit=${
            searchcount ? 50 : 10
          }&offset=0&Sport=true&unique=true&alphabet=${
            selectedLetters?.length > 0
              ? selectedLetters
              : lettersCount?.[datacount] +
                "," +
                lettersCount?.[datacount + 1] +
                "," +
                lettersCount?.[datacount + 2] +
                "," +
                lettersCount?.[datacount + 3]
          }`
        : stateTabValue === 1 || stateTabValue === 2
        ? `/${SportsType}/${tab}?search=${inputValue}&limit=${
            searchcount ? 50 : 10
          }&offset=0&alphabet=${
            selectedLetters?.length > 0
              ? selectedLetters
              : lettersCount?.[datacount] +
                "," +
                lettersCount?.[datacount + 1] +
                "," +
                lettersCount?.[datacount + 2] +
                "," +
                lettersCount?.[datacount + 3]
          }`
        : stateTabValue === 4
        ? `/allsport/stadium?SportId=${SportId}&search=${inputValue}&limit=${
            searchcount ? 50 : 10
          }&offset=0&alphabet=${
            selectedLetters?.length > 0
              ? selectedLetters
              : lettersCount?.[datacount] +
                "," +
                lettersCount?.[datacount + 1] +
                "," +
                lettersCount?.[datacount + 2] +
                "," +
                lettersCount?.[datacount + 3]
          }`
        : "";
    try {
      const { status, data } = await axiosInstance.get(passAPI);
      if (status === 200) {
        setEventCount(data?.result?.count);
        if (searchcount) {
          setViewAllCount(Math.ceil(searchcount / 50));
        }
        setStatisticsListData(data?.result?.rows);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };

  const handleOnScrollBottomEvents = async datacount => {
    if (datacount < 26 && selectedLetters?.length === 0 && searchInput === "") {
      const passApiCount =
        datacount < 24
          ? lettersCount?.[datacount] +
            "," +
            lettersCount?.[datacount + 1] +
            "," +
            lettersCount?.[datacount + 2] +
            "," +
            lettersCount?.[datacount + 3]
          : lettersCount?.[datacount] + "," + lettersCount?.[datacount + 1];
      setListCount(datacount);

      const tab =
        stateTabValue === 1 ? "team" : stateTabValue === 2 ? "Player" : "";
      const passAPI =
        stateTabValue == 0
          ? `/allsport/tournament?SportId=${SportId}&search=${searchInput}&limit=10&offset=0&Sport=true&unique=true&alphabet=${passApiCount}`
          : stateTabValue === 1 || stateTabValue === 2
          ? `/${SportsType}/${tab}?search=${searchInput}&limit=10&offset=0&alphabet=${passApiCount}`
          : stateTabValue === 4
          ? `/allsport/stadium?SportId=${SportId}&search=${searchInput}&limit=10&offset=0&alphabet=${passApiCount}`
          : "";

      try {
        // const passApi = stateTabValue == 0 ?   `/allsport/stadium?SportId=4&search=${searchInput}&limit=10&offset=0&alphabet=${passApiCount}`;
        const { status, data } = await axiosInstance.get(passAPI);
        if (status === 200) {
          setEventCount({ ...EventCount, ...data?.result?.count });
          setEventPage(EventPage);
          setStatisticsListData({
            ...statisticsListData,
            ...data?.result?.rows
          });
        } else {
        }
      } catch (err) {}
    }
  };

  const handleOnScrollBottomViewAll = async offset => {
    // setisLoading(true);
    const tab =
      stateTabValue === 1 ? "team" : stateTabValue === 2 ? "Player" : "";
    const passApi =
      stateTabValue == 0
        ? `/allsport/tournament?SportId=${SportId}&search=${searchInput}&limit=50&offset=${offset}&Sport=true&unique=true&alphabet=${selectedLetters}`
        : stateTabValue === 1 || stateTabValue === 2
        ? `/${SportsType}/${tab}?search=${searchInput}&limit=50&offset=${offset}&alphabet=${selectedLetters}`
        : stateTabValue === 4
        ? `/allsport/stadium?SportId=${SportId}&search=${searchInput}&limit=50&offset=${offset}&alphabet=${selectedLetters}`
        : "";
    try {
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setViewAllOffset(offset);
        let mergedData = {
          [selectedLetters]: [
            ...statisticsListData?.[selectedLetters],
            ...data?.result?.rows?.[selectedLetters]
          ]
        };
        setStatisticsListData(mergedData);
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
    }
  };

  const scrollToTopSection = () => {
    const targetSection = document.getElementById("statistics-wrap");

    if (targetSection) {
      targetSection.scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  const handleViewAll = async (key, count) => {
    scrollToTopSection();
    setIsViewAll(true);
    setViewAllAlphabet(key);
    setSelectedLetters([key]);
    setListCount(0);
    setViewAllOffset(0);
    const updatedAlphabet = letters?.map(letterInfo => {
      if (letterInfo.letter === key) {
        return { ...letterInfo, isSelected: true };
      }
      if (letterInfo.letter !== key) {
        return { ...letterInfo, isSelected: false };
      }
      return letterInfo;
    });
    setLetters(updatedAlphabet);
    fetchStatisticsList("", 0, key, count);
  };

  const handleSearch = e => {
    setSearchInput(e.target.value);
    setListCount(0);
    setViewAllOffset(0);
    const passAlphabet =
      selectedLetters?.length > 0 ? selectedLetters : lettersCount;

    fetchStatisticsList(e.target.value, 0, passAlphabet);
  };

  const handleBlackBookModal = (title, id, type, item) => {
    setSelectedTitle(title);
    setSelectedType(type);
    setSelectedId(id);
    setSelectedSportId(item?.SportId);
    handleModal();
  };
  const handleModal = () => {
    setSBOpen(true);
  };

  const handleClose = () => {
    setSBOpen(false);
  };

  const handleListingApi = () => {
    setListCount(0);
    const passAlphabet = searchInput !== "" ? lettersCount : selectedLetters;
    fetchStatisticsList(searchInput, 0, passAlphabet, null);
  };

  const handleNavigateToSmartBook = () => {
    navigate("/smartbook");
  };

  const handleNavigate = data => {
    if (stateTabValue == 0) {
      const tournamentSlug = data?.Scd ? data?.Scd : "tournament";
      const type = teamSportType === "cricket" ? "cricket" : "";
      navigate(
        `/teamsports/${type}/statistics/${tournamentSlug}/info/${data?.SportId}/${data?.id}`
      );
    }
  };

  return (
    <>
      <Box className="statistics-data-wrap" id="statistics-wrap">
        <Box className="search-filter-wrap">
          <TextField
            placeholder={
              stateTabValue === 0
                ? "Search (by league name)"
                : stateTabValue === 1
                ? "Search (by team name)"
                : stateTabValue === 2
                ? "Search (by player name)"
                : stateTabValue === 3
                ? "Search (by coach name)"
                : "Search (by venues name)"
            }
            variant="outlined"
            type="text"
            name="search"
            className="search_input"
            onChange={e => {
              handleSearch(e);
            }}
            value={searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
          />
          <Box className="alphabet-filter">
            {letters?.map((item, index) => (
              <Box
                key={index}
                className={`letters-alphabet ${
                  item.isSelected ? "selected" : ""
                }`}
                onClick={() => toggleLetter(index)}
              >
                {item.letter}
              </Box>
            ))}
          </Box>
        </Box>
        {isLoading ? (
          <div className="allsport-loader-center ">
            <Loader />
          </div>
        ) : !Object.values(statisticsListData).every(
            arr => arr.length === 0
          ) ? (
          <Box>
            <InfiniteScroll
              dataLength={Object.keys(statisticsListData)?.length}
              next={() => handleOnScrollBottomEvents(listCount + 4)}
              hasMore={listCount + 4 < 26}
              loader={
                selectedLetters?.length === 0 && searchInput === "" ? (
                  <div className="allsport-loader-center ">
                    <Loader />
                  </div>
                ) : (
                  <></>
                )
              }
              // scrollableTarget="scrollableDiv"
            >
              {Object.entries(statisticsListData)?.map(([key, item], index) => {
                return (
                  <>
                    {EventCount?.[key] > 0 && (
                      <Box className="track-accordion">
                        <Accordion key={index} defaultExpanded>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{key}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul className="trackprofilelist-wrap">
                              {!isViewAll ? (
                                <Box>
                                  {item?.length > 0 ? (
                                    item?.map(obj => {
                                      return (
                                        <>
                                          <li className="trackprofile-list">
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                            >
                                              <span
                                                className={
                                                  stateTabValue == 0
                                                    ? "statistics-name name-hover cursor-pointer"
                                                    : "statistics-name"
                                                }
                                                onClick={() =>
                                                  handleNavigate(obj)
                                                }
                                              >
                                                {obj?.name}
                                              </span>
                                            </Box>
                                            {stateTabValue == 0 ||
                                            stateTabValue == 1 ? (
                                              <>
                                                {screenWidth > 799 ? (
                                                  <span>
                                                    {obj?.isSmartBook ? (
                                                      <span
                                                        className="add-to-blackbook view-Blackbook"
                                                        onClick={() =>
                                                          handleNavigateToSmartBook()
                                                        }
                                                      >
                                                        <Right />
                                                        View in Smartbook
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className="add-to-blackbook"
                                                        onClick={() =>
                                                          handleBlackBookModal(
                                                            obj?.name,
                                                            obj?.id,
                                                            stateTabValue == 0
                                                              ? "Tournament"
                                                              : stateTabValue ==
                                                                1
                                                              ? "Team"
                                                              : "",
                                                            obj
                                                          )
                                                        }
                                                      >
                                                        <Plus />
                                                        Add to my Smartbook
                                                      </span>
                                                    )}
                                                  </span>
                                                ) : (
                                                  <>
                                                    {obj?.isSmartBook ? (
                                                      <Box
                                                        onClick={() =>
                                                          handleNavigateToSmartBook()
                                                        }
                                                      >
                                                        <BlackbookRight />
                                                      </Box>
                                                    ) : (
                                                      <Box
                                                        onClick={() =>
                                                          handleBlackBookModal(
                                                            obj?.name,
                                                            obj?.id,
                                                            stateTabValue == 0
                                                              ? "Tournament"
                                                              : stateTabValue ==
                                                                1
                                                              ? "Team"
                                                              : "",
                                                            obj
                                                          )
                                                        }
                                                      >
                                                        <BlackbookPlus />
                                                      </Box>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </li>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <>
                                      <Box style={{ border: "transparent" }}>
                                        <Box
                                          style={{
                                            textAlign: "center",
                                            border: "transparent"
                                          }}
                                        >
                                          No Data Available
                                        </Box>
                                      </Box>
                                    </>
                                  )}
                                </Box>
                              ) : (
                                <InfiniteScroll
                                  dataLength={item?.length}
                                  next={() =>
                                    handleOnScrollBottomViewAll(
                                      viewAllOffset + 50
                                    )
                                  }
                                  hasMore={
                                    viewAllCount !==
                                    Math.ceil(viewAllOffset / 50 + 1)
                                  }
                                  loader={
                                    <div className="allsport-loader-center ">
                                      <Loader />
                                    </div>
                                  }
                                  // scrollableTarget="scrollableDiv"
                                >
                                  {item?.length > 0 ? (
                                    item?.map(obj => {
                                      return (
                                        <>
                                          <li className="trackprofile-list">
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                            >
                                              <span
                                                className={
                                                  stateTabValue == 0
                                                    ? "statistics-name name-hover cursor-pointer"
                                                    : "statistics-name"
                                                }
                                                onClick={() =>
                                                  handleNavigate(obj)
                                                }
                                              >
                                                {obj?.name}
                                              </span>
                                            </Box>
                                            {stateTabValue == 0 ||
                                            stateTabValue == 1 ? (
                                              <>
                                                {screenWidth > 799 ? (
                                                  <span>
                                                    {obj?.isSmartBook ? (
                                                      <span
                                                        className="add-to-blackbook view-Blackbook"
                                                        onClick={() =>
                                                          handleNavigateToSmartBook()
                                                        }
                                                      >
                                                        <Right />
                                                        View in Smartbook
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className="add-to-blackbook"
                                                        onClick={() =>
                                                          handleBlackBookModal(
                                                            obj?.name,
                                                            obj?.id,
                                                            stateTabValue == 0
                                                              ? "Tournament"
                                                              : stateTabValue ==
                                                                1
                                                              ? "Team"
                                                              : "",
                                                            obj
                                                          )
                                                        }
                                                      >
                                                        <Plus />
                                                        Add to my Smartbook
                                                      </span>
                                                    )}
                                                  </span>
                                                ) : (
                                                  <>
                                                    {obj?.isSmartBook ? (
                                                      <Box
                                                        onClick={() =>
                                                          handleNavigateToSmartBook()
                                                        }
                                                      >
                                                        <BlackbookRight />
                                                      </Box>
                                                    ) : (
                                                      <Box
                                                        onClick={() =>
                                                          handleBlackBookModal(
                                                            obj?.name,
                                                            obj?.id,
                                                            stateTabValue == 0
                                                              ? "Tournament"
                                                              : stateTabValue ==
                                                                1
                                                              ? "Team"
                                                              : "",
                                                            obj
                                                          )
                                                        }
                                                      >
                                                        <BlackbookPlus />
                                                      </Box>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </li>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <>
                                      <Box style={{ border: "transparent" }}>
                                        <Box
                                          style={{
                                            textAlign: "center",
                                            border: "transparent"
                                          }}
                                        >
                                          No Data Available
                                        </Box>
                                      </Box>
                                    </>
                                  )}
                                </InfiniteScroll>
                              )}
                            </ul>
                            {!isViewAll && EventCount?.[key] > 10 && (
                              <Box className="view-all">
                                <span
                                  className="view-all-text"
                                  onClick={() =>
                                    handleViewAll(key, EventCount?.[key])
                                  }
                                >
                                  View all
                                </span>
                              </Box>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )}
                  </>
                );
              })}
            </InfiniteScroll>
          </Box>
        ) : (
          <>
            <Box style={{ border: "transparent" }}>
              <Box
                style={{
                  textAlign: "center",
                  border: "transparent"
                }}
              >
                {/* No Data Available */}
                <NoDataComp />
              </Box>
            </Box>
          </>
        )}
      </Box>
      <SmartBookModal
        open={SBopen}
        handleClose={handleClose}
        title={selectedTitle}
        type={selectedType}
        closeIcon={true}
        typeId={selectedId}
        dataId={selectedId}
        sportId={selectedSportId}
        listingFunction={() => handleListingApi()}
      />
    </>
  );
};

export default StatisticsData;
