import React, { useEffect, useState } from "react";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import Select, { components } from "react-select";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useParams } from "react-router";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const TournamnetListSelect = ({
  selectOption,
  setSelectOption,
  selectedValue,
  setSelectedValue,
  handleSelectedValueChange,
}) => {
  const params = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTournamnetList();
  }, []);

  const fetchTournamnetList = async () => {
    setLoading(true);
    const passApi = `allsport/tournament?SportId=${params?.sportsId}`;
    const { status, data } = await axiosInstance.get(passApi);
    try {
      if (status === 200) {
        const leagueData = data?.result?.rows?.map((item) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        });
        setSelectOption(leagueData);
        setSelectedValue(leagueData?.[0]?.value);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  return (
    <>
      <Select
        className="React league-select"
        value={selectOption?.find((item) => {
          return item?.value === selectedValue;
        })}
        onChange={(e) => {
          handleSelectedValueChange(e);
        }}
        options={selectOption}
        classNamePrefix="select"
        placeholder="league"
        isSearchable={true}
        isLoading={loading}
        components={{ DropdownIndicator }}
      />
    </>
  );
};

export default TournamnetListSelect;
