import React, { useState, useEffect } from "react";
import _ from "lodash";
import Select, { components } from "react-select";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import "./tournamentTeamDropdown.scss";
import { Box } from "@mui/material";

const TournamentTeamDropdown = ({
  teamSportType,
  selectedOrg,
  setSelectedOrg,
  selectedTeams,
  setSelectedTeams,
  selectedOrgLabel,
  setSelectedOrgLabel,
  selectedTeamsLabel,
  setSelectedTeamsLabel,
}) => {
  const [teamsAll, setTeamsAll] = useState([]);
  // const [selectedTeams, setSelectedTeams] = useState(null);
  const [OrgAll, setOrgAll] = useState([]);
  // const [selectedOrg, setSelectedOrg] = useState(null);

  const [count, setcount] = useState(0);
  const [isTeamSearch, setIsTeamSearch] = useState("");
  const [isTeamSelectOpen, setisTeamSelectOpen] = useState(false);
  const [searchTeam, setsearchTeam] = useState([]);
  const [searchTeamCount, setsearchTeamCount] = useState(0);
  const [SearchTeampage, setSearchTeampage] = useState(0);
  const [pageTeam, setpageTeam] = useState(0);
  const [teamApiCount, setTeamApiCount] = useState(0);

  const [isOrgSearch, setIsOrgSearch] = useState("");
  const [isOrgSelectOpen, setisOrgSelectOpen] = useState(false);
  const [searchOrg, setsearchOrg] = useState([]);
  const [searchOrgCount, setsearchOrgCount] = useState(0);
  const [SearchOrgpage, setSearchOrgpage] = useState(0);
  const [pageOrg, setpageOrg] = useState(0);
  const [OrgApiCount, setOrgApiCount] = useState(0);
  const [countVen, setcountVen] = useState(0);
  const [countOrg, setcountOrg] = useState(0);

  const SportId =
    teamSportType === "cricket"
      ? 4
      : teamSportType === "rugbyleague"
      ? 12
      : teamSportType === "rugbyunion"
      ? 13
      : teamSportType === "basketball"
      ? 10
      : teamSportType === "americanfootball"
      ? 15
      : teamSportType === "australianrules"
      ? 9
      : teamSportType === "golf"
      ? 16
      : teamSportType === "tennis"
      ? 7
      : teamSportType === "baseball"
      ? 11
      : teamSportType === "icehockey"
      ? 17
      : teamSportType === "boxing"
      ? 6
      : teamSportType === "mma"
      ? 5
      : teamSportType === "soccer"
      ? 8
      : 14;
  const fetchOrgData = async (page, sID, OrgAll) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/public/tournament?SportId=${sID}&offset=${page}&limit=20&groupByTournament=1`
      );
      if (status === 200) {
        // setOrgApiCount(teamApiCount + 1);
        setcountOrg(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.name,
            value: item?.id,
          });
        });

        let filterData = _.unionBy(OrgAll, newdata)?.sort((a, b) => {
          return a?.label.localeCompare(b?.label);
        });
        let allteamdatas = filterData?.unshift({
          label: "All Tournaments",
          value: 0,
        });
        let finalData = _.uniqBy(filterData, function(e) {
          return e.value;
        });
        setOrgAll(finalData);
      }
    } catch (err) {}
  };
  const handleOnScrollBottomOrg = () => {
    if (
      isOrgSearch !== "" &&
      searchOrgCount !== Math.ceil(SearchOrgpage / 20)
    ) {
      handleOrgInputChange(SearchOrgpage + 20, isOrgSearch, SportId);

      setSearchOrgpage(SearchOrgpage + 20);
    } else {
      if (countOrg !== 0 && countOrg !== Math.ceil(pageOrg / 20 + 1)) {
        fetchOrgData(pageOrg + 20, SportId, OrgAll);
        setpageOrg(pageOrg + 20);
      }
    }
  };

  const handleOrgInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/public/tournament?SportId=${sid}&limit=20&offset=${page}&search=${value}&groupByTournament=1`
      )
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchOrgCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.name,
              value: item?.id,
            });
          });
          const mergeData = _.unionBy(searchOrg, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchOrg(filterData);
          setIsOrgSearch(value);
        }
      });
  };

  const fetchTeamData = async (page, sID, teamsAll, tournamentId) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/public/team?SportId=${sID}&tournamentId=${
          tournamentId ? tournamentId : ""
        }&offset=${page}&limit=20`
      );
      if (status === 200) {
        setTeamApiCount(teamApiCount + 1);
        setcount(Math.ceil(data?.result?.count / 20));
        let newdata = [];
        let track = data?.result?.rows?.map((item) => {
          newdata.push({
            label: item?.name,
            value: item?.id,
          });
        });
        let allteamdatas = newdata?.unshift({
          label: "All Teams",
          value: 0,
        });
        let filterData = _.unionBy(teamsAll, newdata);
        setTeamsAll(
          _.uniqBy(filterData, function(e) {
            return e.value;
          })
        );
      }
    } catch (err) {}
  };
  const handleOnScrollBottomTeams = () => {
    if (
      isTeamSearch !== "" &&
      searchTeamCount !== Math.ceil(SearchTeampage / 20)
    ) {
      handleTeamInputChange(SearchTeampage + 20, isTeamSearch, SportId);

      setSearchTeampage(SearchTeampage + 20);
    } else {
      if (count !== 0 && count !== Math.ceil(pageTeam / 20 + 1)) {
        fetchTeamData(pageTeam + 20, SportId, teamsAll, selectedOrg);
        setpageTeam(pageTeam + 20);
      }
    }
  };
  const handleTeamInputChange = (page, value, sid) => {
    axiosInstance
      .get(
        `/public/team?SportId=${sid}&tournamentId=${
          selectedOrg ? selectedOrg : ""
        }&limit=20&offset=${page}&search=${value}`
      )
      .then((res) => {
        if (res.status === 200) {
          let response = res?.data?.result?.rows;
          setsearchTeamCount(Math.ceil(res?.data?.result?.count / 20));
          let newdata = [];
          let FinalData = response?.map((item) => {
            newdata.push({
              label: item?.name,
              value: item?.id,
            });
          });
          const mergeData = _.unionBy(searchTeam, newdata);
          const filterData = _.uniqBy(mergeData, function(e) {
            return e.value;
          });
          setsearchTeam(filterData);
          // setsearchTeam(
          //   _.uniqBy(filterData, function(e) {
          //     return e.value;
          //   })
          // );
          setIsTeamSearch(value);
        }
      });
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectIndicator />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    fetchOrgData(0, SportId, []);
  }, []);
  return (
    <Box className="tournament-team">
      <Select
        className={`React series-select`}
        onMenuScrollToBottom={(e) => handleOnScrollBottomOrg(e)}
        onInputChange={(e) => handleOrgInputChange(0, e, SportId)}
        onChange={(e) => {
          setSelectedOrg(e?.value);
          setSelectedOrgLabel(e?.label);
          setSelectedTeams(null);
          setSelectedTeamsLabel("");
          // fetchOrgData(e?.value, SportId, OrgAll);
          fetchTeamData(0, SportId, [], e?.value);
          // setOrgApiCount(0);
          setpageOrg(0);
          setisOrgSelectOpen(false);
        }}
        onFocus={() => setisOrgSelectOpen(true)}
        onBlur={() => setisOrgSelectOpen(false)}
        value={
          selectedOrg &&
          (isOrgSearch
            ? searchOrg?.find((item) => {
                return item?.value == selectedOrg;
              })
            : OrgAll?.find((item) => {
                return item?.value == selectedOrg;
              }))
        }
        options={isOrgSearch ? searchOrg : OrgAll}
        classNamePrefix="select"
        placeholder="All Tournaments"
        components={{ DropdownIndicator }}
      />
      <Select
        className={
          selectedOrg
            ? `React series-select`
            : `React series-select disable-state`
        }
        // options={teamsAll}
        onMenuScrollToBottom={(e) => handleOnScrollBottomTeams(e)}
        onInputChange={(e) => handleTeamInputChange(0, e, SportId)}
        onChange={(e) => {
          setSelectedTeams(e?.value);
          setSelectedTeamsLabel(e?.label);
          // fetchTeamData(e?.value, SportId, teamsAll);
          // setTeamApiCount(0);
          setpageTeam(0);
          setisTeamSelectOpen(false);
        }}
        onFocus={() => setisTeamSelectOpen(true)}
        onBlur={() => setisTeamSelectOpen(false)}
        value={
          selectedTeams &&
          (isTeamSearch
            ? searchTeam?.find((item) => {
                return item?.value == selectedTeams;
              })
            : teamsAll?.find((item) => {
                return item?.value == selectedTeams;
              }))
        }
        options={isTeamSearch ? searchTeam : teamsAll}
        classNamePrefix="select"
        placeholder="All Teams"
        isDisabled={selectedOrg ? false : true}
        components={{ DropdownIndicator }}
      />
    </Box>
  );
};

export default TournamentTeamDropdown;
