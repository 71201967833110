import React, { useContext, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { IntlContext } from "src/App";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Container,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Checkbox,
  StepConnector,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import withStyles from "@mui/styles/withStyles";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
import {
  LocalizationProvider,
  DatePicker,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import Select, { components } from "react-select";
import { ReactComponent as VisiblePassword } from "src/assets/images/icons/VisiblePassword.svg";
import { ReactComponent as UnVisiblePassword } from "src/assets/images/icons/UnVisiblePassword.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import { ReactComponent as Datepicker } from "src/assets/images/eventcalendar/Datepicker.svg";
import differenceInYears from "date-fns/differenceInYears";
import AuthTitleLogo from "src/assets/images/signup-img/auth-title-logo.webp";
import AuthTextLogo from "src/assets/images/signup-img/auth-text-logo.webp";
import finish_logo from "src/assets/images/finish-logo.png";
import leftimage from "src/assets/images/signup-img/auth-left.webp";
import DefaultImg from "src/assets/images/smartb_default.png";
import Loader from "src/components/Loader";
// import { useNavigate } from 'react-router';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Config } from "src/helpers/context";
const Register = ({
  formvalues,
  setformvalues,
  registerV2,
  isLoading,
  handleOnScrollBottomCountryPhone,
  handleCountryPhoneInputChange,
  isCountryPhoneSearch,
  searchCountryPhone,
  countryPhone,
  setisCountryPhoneSelectOpen,
  registerError,
  handleOnScrollBottomCountry,
  handleCountryInputChange,
  isCountrySearch,
  searchCountry,
  country,
  setCountryId,
  isCountrySelectOpen,
  setisCountrySelectOpen,
  handleOnScrollBottomState,
  handleStateInputChange,
  isStateSearch,
  searchState,
  state,
  setisStateSelectOpen,
  setStateApiCount,
  fetchState,
  setState,
  setpageState,
  isStateSelectOpen,
}) => {
  const navigate = useNavigate();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const { ValueContainer, Placeholder } = components;
  const [emailmsg, setEmailmsg] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [showRepeatPassword, setshowRepeatPassword] = useState(false);
  const nameRegex = /^[a-zA-Z\s'-]+$/;

  const formatOptionLabel = ({ label, flag, phoneCode }) => {
    if (isCountryPhoneSearch) {
      return label;
    } else {
      return (
        <div>
          <img
            src={flag}
            onError={(e) => (e.target.src = DefaultImg)}
            style={{ width: "24px" }}
          />
          <span
            style={{
              width: "42px",
              margin: "0px 5px",
              display: "inline-block",
            }}
          >
            {phoneCode ? `+${phoneCode}` : ""}
          </span>
          <span>{`(` + label + `)`}</span>
        </div>
      );
    }
  };
  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        // title:
        //     formvalues?.title && formvalues?.title !== ""
        //         ? formvalues?.title
        //         : "",
        firstname: formvalues?.firstname || "",
        lastname:
          formvalues?.lastname && formvalues?.lastname !== ""
            ? formvalues?.lastname
            : "",
        dob: formvalues?.dob ? formvalues?.dob : null,
        countryPhone:
          formvalues?.countryPhone && formvalues?.countryPhone !== ""
            ? formvalues?.countryPhone
            : "",

        phonenumber:
          formvalues?.phonenumber && formvalues?.phonenumber !== ""
            ? formvalues?.phonenumber
            : "",
        country:
          formvalues?.country && formvalues?.country !== ""
            ? formvalues?.country
            : "",
        state:
          formvalues?.state && formvalues?.state !== ""
            ? formvalues?.state
            : "",
        email:
          formvalues?.email && formvalues?.email !== ""
            ? formvalues?.email
            : "",
        password:
          formvalues?.password && formvalues?.password !== ""
            ? formvalues?.password
            : "",
        repeatpassword:
          formvalues?.repeatpassword && formvalues?.repeatpassword !== ""
            ? formvalues?.repeatpassword
            : "",
        documentsRead: Boolean(formvalues?.documentsRead),
        // wpUserName:
        //   formvalues?.wpUserName && formvalues?.wpUserName !== ""
        //     ? formvalues?.wpUserName
        //     : "",
        // hearAboutInfo:
        //     formvalues?.hearAbout && formvalues?.hearAbout?.length > 0
        //         ? formvalues?.hearAbout
        //         : "",
      }}
      validationSchema={Yup.object().shape({
        firstname: Yup.string()
          .trim()
          .required(localesData?.validation?.required_message)
          .test(
            "firstname",
            "Only letters, spaces, hyphens, and apostrophes are allowed.",
            (value) => value && value.match(nameRegex)
          ),
        lastname: Yup.string()
          .trim()
          .required(localesData?.validation?.required_message)
          .test(
            "lastname",
            "Only letters, spaces, hyphens, and apostrophes are allowed.",
            (value) => value && value.match(nameRegex)
          ),
        dob: Yup.date()
          .nullable()
          .typeError("")
          .test("dob", "You have to be at least 18", function (value) {
            return differenceInYears(new Date(), new Date(value)) >= 18;
          })
          .required(localesData?.validation?.required_message),
        countryPhone: Yup.string().required(
          localesData?.validation?.required_message
        ),
        phonenumber: Yup.string()
          .required(localesData?.validation?.required_message)
          .min(9, localesData?.validation?.NUMBER_MIN_MAX_LENGTH)
          .max(10, localesData?.validation?.NUMBER_MIN_MAX_LENGTH),
        //  wpUserName: Yup.string(),
        country: Yup.string().required(
          localesData?.validation?.required_message
        ),
        state: Yup.string().required(localesData?.validation?.required_message),
        email: Yup.string()
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            localesData?.validation?.valid_email
          )
          .max(255)
          .required(localesData?.validation?.required_message),
        password: Yup.string()
          .required("You’ll need to set a password to create an account.")
          .min(6, localesData?.validation?.passowrd_length),
        repeatpassword: Yup.string()
          .required("You’ll need to set a password to create an account.")
          .min(6, localesData?.validation?.passowrd_length)
          .oneOf(
            [Yup.ref("password"), null],
            localesData?.validation?.password_confpass_not_match
          ),

        documentsRead: Yup.boolean().oneOf(
          [true],
          localesData?.validation?.CHECKBOX_REQUIRED
        ),
      })}
      // validate={(values) => {
      //     if (!values.hearAboutInfo.length) {
      //         return {
      //             hearAboutInfo: "Please Choose Atleast One",
      //         };
      //     }
      //     return {};
      // }}

      onSubmit={async (values, { setSubmitting }) => {
        try {
          const { status, data } = await axiosInstance.get(
            `user/checkEmail/${values.email}`
          );
          if (status === 200 && data?.success === true) {
            setformvalues({ ...formvalues, ...values });
            setEmailmsg("");
            registerV2();
            // if (Config.release == "IN") {
            //     fetchSportData();
            // }
          }
        } catch (err) {
          setEmailmsg(err?.response?.data?.message);
        } finally {
          setSubmitting(false); // Allow the form to be submitted again
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className="stepper-main-wrap">
          <Box className="step1-container register-container">
            {isLoading ? (
              <div className="auth-loader">
                <Loader />
              </div>
            ) : (
              <>
                <Typography className="signup-header">
                  {localesData?.register?.SIGNUP_WELCOME_MSG_1}
                  <img src={AuthTextLogo} className="text-logo" alt="logo" />
                  {localesData?.register?.SIGNUP_WELCOME_MSG_2}
                </Typography>
                {/* <Box className="textfield-sec">
                            <Typography className="textfield-text">
                                {localesData?.register?.title}
                            </Typography>
                            <Select
                                className={`${touched?.title && errors?.title ? "text-error" : ""
                                    } select select-title React`}
                                classNamePrefix="select"
                                isSearchable={false}
                                placeholder="-Select-"
                                onChange={(e) => {
                                    setFieldValue("title", e?.value);
                                    setformvalues((prevFormValues) => ({
                                        ...prevFormValues,
                                        title: e?.value,
                                    }));
                                }}
                                value={titleOptions?.find((item) => {
                                    const titleValue =
                                        formvalues?.title && formvalues?.title !== ""
                                            ? formvalues?.title
                                            : values?.title;
                                    return item?.value == titleValue;
                                })}
                                options={titleOptions}
                                name="title"
                            />
                            <span className="text-danger">
                                {touched?.title && errors?.title ? errors?.title : ""}
                            </span>
                        </Box> */}
                <Grid container columnSpacing={3}>
                  <Grid item size={{ xs: 12, sm: 12 }}>
                    <Box className="textfield-sec">
                      {/* <Typography className="textfield-text">
                                        {localesData?.register?.first_name}
                                    </Typography> */}
                      <TextField
                        placeholder={""}
                        variant="outlined"
                        label="First name"
                        type="text"
                        name="firstname"
                        className="text-field"
                        error={Boolean(touched?.firstname && errors?.firstname)}
                        helperText={touched?.firstname ? errors?.firstname : ""}
                        onChange={(e) => {
                          handleChange(e);
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            [e?.target?.name]: e?.target?.value,
                          }));
                        }}
                        value={values?.firstname}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (!nameRegex.test(e.key)) e.preventDefault();
                        }}
                        // value={formvalues?.firstname && formvalues?.firstname !== "" ? formvalues?.firstname : values?.firstname}
                      />
                    </Box>
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 12 }}>
                    <Box className="textfield-sec">
                      {/* <Typography className="textfield-text">
                                        {localesData?.register?.last_name}
                                    </Typography> */}
                      <TextField
                        placeholder={""}
                        variant="outlined"
                        type="text"
                        name="lastname"
                        label="Last name"
                        className="text-field"
                        error={Boolean(touched?.lastname && errors?.lastname)}
                        helperText={touched?.lastname ? errors?.lastname : ""}
                        onChange={(e) => {
                          handleChange(e);
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            [e?.target?.name]: e?.target?.value,
                          }));
                        }}
                        // values={formvalues?.lastname && formvalues?.lastname !== "" ? formvalues?.lastname : values?.lastname}
                        value={values?.lastname}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (!nameRegex.test(e.key)) e.preventDefault();
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item size={{ xs: 12, sm: 6 }}>
                    <Box className="textfield-sec">
                      {/* <Typography className="textfield-text">
                                        {localesData?.register?.dob}
                                    </Typography> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          autoOk
                          // disableToolbar
                          disableFuture
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder="DD/MM/YYYY"
                          label="DOB"
                          margin="dense"
                          id="date-picker-inline"
                          // openTo="date month year"
                          inputVariant="outlined"
                          value={
                            formvalues?.dob ? formvalues?.dob : values?.dob
                          }
                          // views={["year", "month", "date"]}
                          onChange={(e) => {
                            setFieldValue("dob", e);
                            setformvalues((prevFormValues) => ({
                              ...prevFormValues,
                              dob: e,
                            }));
                          }}
                          onBlur={handleBlur}
                          slots={{
                            openPickerIcon: Datepicker, // Custom icon
                          }}
                          slotProps={{
                            field: {
                              id: "date-picker-inline",
                              placeholder: "DD/MM/YYYY",
                            },
                          }}
                          // KeyboardButtonProps={{
                          //     "aria-label": "change date",
                          // }}
                          // style={{ marginRight: 5 }}
                          className="details-search-picker text-field"
                        />
                      </LocalizationProvider>
                      <span className="text-danger">
                        {touched?.dob && errors?.dob ? errors?.dob : ""}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 6 }}>
                    <Box className="textfield-sec mobile-number-wrap">
                      {/* <Typography className="textfield-text">
                                        {localesData?.register?.country_of_residence}
                                    </Typography> */}
                      <Select
                        className="select phone-code"
                        classNamePrefix="select"
                        onMenuScrollToBottom={(e) =>
                          handleOnScrollBottomCountryPhone(e)
                        }
                        placeholder={"Code"}
                        onInputChange={(e) =>
                          handleCountryPhoneInputChange(0, e)
                        }
                        value={
                          isCountryPhoneSearch
                            ? searchCountryPhone?.find((item) => {
                                return item?.value == countryPhone;
                              })
                            : countryPhone?.find((item) => {
                                const countryValue =
                                  formvalues?.countryPhone &&
                                  formvalues?.countryPhone !== ""
                                    ? formvalues?.countryPhone
                                    : values?.countryPhone;
                                return item?.value == countryValue;
                              })
                        }
                        onChange={(e) => {
                          setFieldValue("countryPhone", e?.value);
                          setisCountryPhoneSelectOpen(false);
                          // setstateValue("");
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            countryPhone: e?.value,
                          }));
                        }}
                        // isSearchable={false}
                        onFocus={() => setisCountryPhoneSelectOpen(true)}
                        onBlur={() => setisCountryPhoneSelectOpen(false)}
                        options={
                          isCountryPhoneSearch
                            ? searchCountryPhone
                            : countryPhone
                        }
                        getOptionLabel={formatOptionLabel}
                        // getOptionValue={(option) => option.value}
                      />
                      <TextField
                        placeholder={""}
                        variant="outlined"
                        type="text"
                        name="phonenumber"
                        className="text-field mobile-textfield"
                        label="Mobile number"
                        error={Boolean(
                          touched?.phonenumber && errors?.phonenumber
                        )}
                        helperText={
                          touched?.phonenumber ? errors?.phonenumber : ""
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            [e?.target?.name]: e?.target?.value,
                          }));
                        }}
                        onBlur={handleBlur}
                        values={
                          formvalues?.phonenumber &&
                          formvalues?.phonenumber !== ""
                            ? formvalues?.phonenumber
                            : values?.phonenumber
                        }
                        value={
                          formvalues?.phonenumber &&
                          formvalues?.phonenumber !== ""
                            ? formvalues?.phonenumber
                            : values?.phonenumber
                        }
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Box>
                    <span className="text-danger">
                      {errors?.phonenumber || formvalues?.countryPhone
                        ? ""
                        : errors?.countryPhone
                          ? errors?.countryPhone
                          : ""}
                    </span>
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 6 }}>
                    <Box className="textfield-sec">
                      <Select
                        className="select"
                        classNamePrefix="select"
                        onMenuScrollToBottom={(e) =>
                          handleOnScrollBottomCountry(e)
                        }
                        onInputChange={(e) => handleCountryInputChange(0, e)}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        placeholder={
                          localesData?.register?.country_of_residence
                        }
                        styles={{
                          container: (provided, state) => ({
                            ...provided,
                          }),
                          valueContainer: (provided, state) => ({
                            ...provided,
                            overflow: "visible",
                          }),
                          placeholder: (provided, state) => ({
                            ...provided,
                            position: "absolute",
                            top:
                              state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused
                                ? -11
                                : "auto",
                            backgroundColor:
                              state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused
                                ? "white"
                                : "transparent",
                            transition: "top 2s, font-size 0.1s !important",
                            fontSize:
                              (state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused) &&
                              "12px !important",
                            color:
                              state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused
                                ? "#4455c7"
                                : "#a4a4a4",
                            padding:
                              (state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused) &&
                              "0px 3px",
                            paddingLeft:
                              (state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused) &&
                              "1px !important",
                            marginLeft:
                              (state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused) &&
                              "7px !important",
                            lineHeight:
                              (state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused) &&
                              "8px !important",
                          }),
                        }}
                        value={
                          isCountrySearch
                            ? searchCountry?.find((item) => {
                                return item?.value == country;
                              })
                            : country?.find((item) => {
                                const countryValue =
                                  formvalues?.country &&
                                  formvalues?.country !== ""
                                    ? formvalues?.country
                                    : values?.country;
                                return item?.value == countryValue;
                              })
                        }
                        onChange={(e) => {
                          setFieldValue("country", e?.value);
                          fetchState(e?.value, 0, true, 0);
                          setCountryId(e?.value);
                          setFieldValue("state", "");
                          setState([]);
                          setStateApiCount(0);
                          setpageState(0);
                          setisCountrySelectOpen(false);
                          // setstateValue("");
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            country: e?.value,
                          }));
                        }}
                        isFocused={isCountrySelectOpen}
                        onFocus={() => setisCountrySelectOpen(true)}
                        onBlur={() => setisCountrySelectOpen(false)}
                        options={isCountrySearch ? searchCountry : country}
                      />

                      <span className="text-danger">
                        {touched?.country && errors?.country
                          ? errors?.country
                          : ""}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 6 }}>
                    <Box className="textfield-sec">
                      <Select
                        className="select"
                        classNamePrefix="select"
                        onMenuScrollToBottom={(e) =>
                          handleOnScrollBottomState(e)
                        }
                        onInputChange={(e) => handleStateInputChange(0, e)}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        placeholder={localesData?.register?.state}
                        styles={{
                          container: (provided, state) => ({
                            ...provided,
                          }),
                          valueContainer: (provided, state) => ({
                            ...provided,
                            overflow: "visible",
                          }),
                          placeholder: (provided, state) => ({
                            ...provided,
                            position: "absolute",
                            top:
                              state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused
                                ? -11
                                : "auto",
                            backgroundColor:
                              state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused
                                ? "white"
                                : "transparent",
                            transition: "top 2s, font-size 0.1s !important",
                            fontSize:
                              (state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused) &&
                              "12px !important",
                            color:
                              state.hasValue ||
                              state.selectProps.inputValue ||
                              state.selectProps.isFocused
                                ? "#4455c7"
                                : "#a4a4a4",
                            padding:
                              (state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused) &&
                              "0px 3px",
                            paddingLeft:
                              (state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused) &&
                              "1px !important",
                            marginLeft:
                              (state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused) &&
                              "7px !important",
                            lineHeight:
                              (state.hasValue ||
                                state.selectProps.inputValue ||
                                state.selectProps.isFocused) &&
                              "8px !important",
                          }),
                        }}
                        value={
                          isStateSearch
                            ? searchState?.find((item) => {
                                return item?.value == state;
                              })
                            : (values?.state !== "" ||
                                formvalues?.state !== "") &&
                              state?.find((item) => {
                                const stateValue =
                                  formvalues?.state && formvalues?.state !== ""
                                    ? formvalues?.state
                                    : values?.state;
                                return item?.value == stateValue;
                              })
                        }
                        onChange={(e) => {
                          setFieldValue("state", e?.value);
                          setisStateSelectOpen(false);
                          setStateApiCount(0);
                          // setstateValue(e?.value);
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            state: e?.value,
                          }));
                        }}
                        isFocused={isStateSelectOpen}
                        onFocus={() => setisStateSelectOpen(true)}
                        onBlur={() => setisStateSelectOpen(false)}
                        options={isStateSearch ? searchState : state}
                      />
                      <span className="text-danger">
                        {touched?.state && errors?.state ? errors?.state : ""}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 12 }}>
                    <Box className="textfield-sec">
                      {/* <Typography className="textfield-text">
                                        {localesData?.register?.email}
                                    </Typography> */}
                      <TextField
                        placeholder={""}
                        variant="outlined"
                        type="email"
                        name="email"
                        label="Email"
                        className="text-field email-text-field"
                        error={Boolean(touched?.email && errors?.email)}
                        helperText={touched?.email ? errors?.email : ""}
                        onChange={(e) => {
                          handleChange(e);
                          setEmailmsg("");
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            [e?.target?.name]: e?.target?.value,
                          }))// Clear the error message when the email input changes
                        }}

                        inputProps={{
                          autoComplete: "new-password"
                        }}
                        onBlur={handleBlur}
                        value={values?.email}
                        // value={formvalues?.email && formvalues?.email !== "" ? formvalues?.email : values?.email}
                      />
                      <span className="text-danger">{emailmsg}</span>
                    </Box>
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 12 }}>
                    <Box className="textfield-sec">
                      {/* <Typography className="textfield-text">
                                        {localesData?.register?.password}
                                    </Typography> */}
                      <TextField
                        placeholder={""}
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        // value={values?.password}
                        // onChange={handleChange}
                        label="Password"
                        name="password"
                        className="text-field"
                        InputProps={{
                          endAdornment: !showPassword ? (
                            <UnVisiblePassword
                              onClick={() => setshowPassword(!showPassword)}
                            />
                          ) : (
                            <VisiblePassword
                              onClick={() => setshowPassword(!showPassword)}
                            />
                          ),
                          autoComplete: "new-password",
                        }}
                        error={Boolean(touched?.password && errors?.password)}
                        helperText={touched?.password ? errors?.password : ""}
                        onChange={(e) => {
                          handleChange(e);
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            [e?.target?.name]: e?.target?.value,
                          }));
                        }}
                        onBlur={handleBlur}
                        values={values?.password}
                      />
                    </Box>
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 12 }}>
                    <Box className="textfield-sec">
                      {/* <Typography className="textfield-text">
                                        {localesData?.register?.repeat_password}
                                    </Typography> */}
                      <TextField
                        placeholder={""}
                        variant="outlined"
                        type={showRepeatPassword ? "text" : "password"}
                        name="repeatpassword"
                        label="Repeat Password"
                        // value={values?.repeatpassword}
                        // onChange={handleChange}
                        className="text-field"
                        InputProps={{
                          endAdornment: !showRepeatPassword ? (
                            <UnVisiblePassword
                              onClick={() =>
                                setshowRepeatPassword(!showRepeatPassword)
                              }
                            />
                          ) : (
                            <VisiblePassword
                              onClick={() =>
                                setshowRepeatPassword(!showRepeatPassword)
                              }
                            />
                          ),
                        }}
                        error={Boolean(
                          touched?.repeatpassword && errors?.repeatpassword
                        )}
                        helperText={
                          touched?.repeatpassword ? errors?.repeatpassword : ""
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            [e?.target?.name]: e?.target?.value,
                          }));
                        }}
                        onBlur={handleBlur}
                        values={values?.repeatpassword}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box className="checkBox-wrap">
                  <FormControlLabel
                    className="documentsRead-check"
                    control={
                      <Checkbox
                        className="documentsRead-checkbox"
                        checked={values?.documentsRead}
                        onChange={(e) => {
                          setFieldValue(
                            "documentsRead",
                            !values?.documentsRead
                          );
                          setformvalues((prevFormValues) => ({
                            ...prevFormValues,
                            documentsRead: !values?.documentsRead,
                          }));
                        }}
                        color="primary"
                      />
                    }
                    label={
                      <Typography>
                        By clicking Sign Up, I agree to the{" "}
                        {/* <span onClick={() => navigate("/terms-and-conditions")}> */}
                        <a
                          href={Config.siteBaseURL + "terms-and-conditions"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Use
                        </a>{" "}
                        {/* </span>{" "} */} and confirm that I have read the{" "}
                        {/* <span onClick={() => navigate("/privacy-policy")}> */}
                        <a
                          href={Config.siteBaseURL + "privacy-policy"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy.
                        </a>
                        {/* </span> */}
                      </Typography>
                    }
                    error={touched?.documentsRead && errors?.documentsRead}
                  />
                  {touched?.documentsRead && errors?.documentsRead && (
                    <Typography variant="body2" color="error">
                      {errors?.documentsRead}
                    </Typography>
                  )}
                </Box>

                <Box className="text-danger">
                  {registerError ? registerError : ""}
                </Box>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  type="submit"
                  className="submit-btn step1-btn"
                  // onClick={() => {
                  //   step < 5 ? nextStep() : submit();
                  // }}
                >
                  {localesData?.auth_btn?.signup}
                </Button>
              </>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Register;
