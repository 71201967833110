import React from "react";
import Page from "src/components/Page";
import NewTeamSportsComponentPage from "src/views/component/newTeamSportPage";

const NewTeamSportsPage = () => {
  return (
    <Page title="Team Sports">
      <NewTeamSportsComponentPage />
    </Page>
  );
};

export default NewTeamSportsPage;
