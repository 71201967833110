import React, { useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { fetchFromStorage } from "src/helpers/context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import DefaultImg from "../../../../../../assets/images/smartb_default.png";
import { ReactComponent as BookmarkIcon } from "src/assets/images/icons/bookmark-icon.svg";
import { ReactComponent as ChatIcon } from "src/assets/images/icons/chat-icon.svg";
import moment from "moment";
import Loader from "src/components/Loader";
import he from "he";
import { setApiMessage } from "src/helpers/commonFunctions";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import _ from "lodash";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";
import { ReactComponent as RightArrow } from "../../../../../../assets/images/icons/rightArrowThick.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../Home/HomeComponents/featuredNews.scss";
import "./landingFeatureNews.scss";

const LandingFeaturedNews = () => {
  const params = useParams();
  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const menuValue = searchParamsMenu.get("menu");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setApiInfo } = useAutoSaveStore();
  const [featureNewsData, setFeatureNewsData] = useState([]);
  const [featureNewsLoader, setFeatureNewsLoader] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    fetchFeaturedNews();
  }, [params?.sportsId]);

  const fetchFeaturedNews = async () => {
    setFeatureNewsLoader(true);
    try {
      const passApi = `/v2/news/feature/articles?SportId=${params?.sportsId}&page=1&limit=3`;
      const { status, data } = await axiosInstance.get(passApi);
      if (status === 200) {
        setFeatureNewsLoader(false);
        const articalData = data?.result;
        setFeatureNewsData(articalData);
      }
    } catch (error) {
      setFeatureNewsLoader(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchNewsTime = (newsdate) => {
    const now = moment();
    const givenTime = moment(newsdate);
    const timeDiffMinutes = now.diff(givenTime, "minutes");
    const timeDiffHours = now.diff(givenTime, "hours");
    const timeDiffDays = now.diff(givenTime, "days");
    const timeDiffWeeks = now.diff(givenTime, "weeks");
    if (timeDiffMinutes > 60) {
      if (timeDiffMinutes / 60 > 24) {
        if (timeDiffDays > 7) {
          return `${timeDiffWeeks} ${
            timeDiffWeeks === 1 ? "week" : "weeks"
          } ago`;
        } else {
          return `${timeDiffDays} ${timeDiffDays === 1 ? "day" : "days"} ago`;
        }
      } else {
        return `${timeDiffHours} ${timeDiffHours === 1 ? "hour" : "hours"} ago`;
      }
    } else {
      return `${timeDiffMinutes} ${
        timeDiffMinutes === 1 ? "minute" : "minutes"
      } ago`;
    }
  };

  const fetchNewsSubtitle = (data) => {
    const parseHTMLContent = (body) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(body, "text/html");
      const firstPTag = doc.querySelector("p");
      return firstPTag ? firstPTag.innerHTML : "";
    };
    const decodeHTMLContent = (content) => {
      return he.decode(content);
    };
    const removeHtmlTags = (htmlString) => {
      return htmlString.replace(/<[^>]*>/g, "");
    };
    const renderSubtitle = (content) => {
      return (
        <Typography className="sub-details">
          <span
            dangerouslySetInnerHTML={{
              __html:
                screenWidth >= 1023
                  ? content?.slice(0, 100)
                  : content?.slice(0, 50),
            }}
          ></span>
          {/* {screenWidth >= 1023 ? content?.slice(0, 100) : content?.slice(0, 50)} */}
          {content && <span className="read-more"> ...read more</span>}
        </Typography>
      );
    };
    if (data?.NewsProviderId === 1) {
      const editorBlockData = data?.body?.find(
        (item) =>
          item?.type === "editor_block" && item?.data?.type === "paragraph",
      );
      const content = parseHTMLContent(editorBlockData?.data?.content);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    } else {
      const content = parseHTMLContent(data?.body);
      const decodedHtml = decodeHTMLContent(content);
      const optimizedText = removeHtmlTags(decodedHtml);
      return renderSubtitle(optimizedText);
    }
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handleNewsSave = async (e, item) => {
    e.stopPropagation();

    if (handleRestrictedUser()) {
      let payload = {
        status: item?.NewsArticle?.isSaved === 0 ? "active" : "deleted",
      };
      try {
        const { status, data } = await axiosInstance.post(
          `/v2/news/saved/${item?.NewsArticleId}`,
          payload,
        );
        if (status === 200) {
          setApiMessage(
            "success",
            item?.NewsArticle?.isSaved === 0
              ? "News successfully saved!"
              : "News removed from saved!",
          );

          const updateNews = featureNewsData?.map((article) =>
            article.NewsArticleId === item?.NewsArticleId
              ? {
                  ...article,
                  NewsArticle: {
                    ...article.NewsArticle,
                    isSaved: item?.NewsArticle?.isSaved === 0 ? 1 : 0,
                  },
                }
              : article,
          );
          setFeatureNewsData(updateNews);
        } else {
          setApiMessage("error", data?.message);
        }
      } catch (err) {
        setApiMessage("error", err?.response?.data?.message);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      setApiInfo({
        api: `/v2/news/saved/${item?.NewsArticleId}`,
        payload: {
          status: item?.NewsArticle?.isSaved === 0 ? "active" : "deleted",
        },
        method: "post",
      });
    }
  };

  const handleNavigate = (item) => {
    navigate(
      `/news/${item?.NewsArticle?.NewsCategoryId}/${item?.NewsArticle?.id}`,
    );
  };

  const handleNavigateToNews = () => {
    navigate(`/news/0`);
  };

  const settingsNews = {
    infinite: true,
    lazyLoad: "ondemand",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          variableWidth: true,
        },
      },
    ],
  };
  return (
    <>
      {featureNewsData?.length > 0 ? (
        <Box className="component-wrap landing-featured-news-component-wrap">
          <Box className="landing-header">
            <Typography variant="h3" className="component-header hander-text">
              Featured News
            </Typography>
          </Box>

          {featureNewsLoader ? (
            <div className="allsport-loader-center news-loader">
              <Loader />
            </div>
          ) : featureNewsData?.length > 0 ? (
            <>
              <Box className="grid-container ">
                {featureNewsData?.map((item, index) => (
                  <Box
                    key={index}
                    className={`grid-item grid-item${index + 1}`}
                  >
                    {index === 0 ? (
                      <Slider
                        {...settingsNews}
                        className="featured-news-slick-slider"
                      >
                        {featureNewsData?.map((ele) => {
                          return (
                            <Box
                              onClick={() => {
                                handleNavigate(ele);
                              }}
                              className="responsive-main-news-view"
                            >
                              <Box
                                className="background-overlay"
                                style={{
                                  content: "''",
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundImage: `url('${
                                    ele?.NewsArticle?.mainMedia?.gallery
                                      ? ele?.NewsArticle?.mainMedia?.gallery
                                          ?.url &&
                                        ele?.NewsArticle?.mainMedia?.gallery
                                          ?.url !== ""
                                        ? ele?.NewsArticle?.mainMedia?.gallery
                                            ?.url
                                        : DefaultImg
                                      : ele?.NewsArticle?.mainMedia?.[0]
                                            ?.gallery
                                        ? ele?.NewsArticle?.mainMedia?.[0]
                                            ?.gallery?.url &&
                                          ele?.NewsArticle?.mainMedia?.[0]
                                            ?.gallery?.url !== ""
                                          ? ele?.NewsArticle?.mainMedia?.[0]
                                              ?.gallery?.url
                                          : DefaultImg
                                        : DefaultImg
                                  }')`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center top",
                                  filter: "blur(6px)",
                                  zIndex: -1,
                                  borderRadius: "8px",
                                }}
                              />
                              <Box className="news-img">
                                <LazyLoadImage
                                  src={
                                    ele?.NewsArticle?.mainMedia?.gallery
                                      ? ele?.NewsArticle?.mainMedia?.gallery
                                          ?.url &&
                                        ele?.NewsArticle?.mainMedia?.gallery
                                          ?.url !== ""
                                        ? ele?.NewsArticle?.mainMedia?.gallery
                                            ?.url
                                        : DefaultImg
                                      : ele?.NewsArticle?.mainMedia?.[0]
                                            ?.gallery
                                        ? ele?.NewsArticle?.mainMedia?.[0]
                                            ?.gallery?.url &&
                                          ele?.NewsArticle?.mainMedia?.[0]
                                            ?.gallery?.url !== ""
                                          ? ele?.NewsArticle?.mainMedia?.[0]
                                              ?.gallery?.url
                                          : DefaultImg
                                        : DefaultImg
                                  }
                                  alt="news-img"
                                />
                              </Box>
                              <Box className="details-wrapper">
                                <span className="news-tag">
                                  {ele?.NewsArticle?.NewsCategory?.initialTitle}
                                </span>
                                <Box className="news-deatils">
                                  <Typography
                                    className="title-news"
                                    dangerouslySetInnerHTML={{
                                      __html: ele?.NewsArticle?.title
                                        ? ele?.NewsArticle?.title
                                        : ele?.NewsArticle?.subTitle,
                                    }}
                                  />
                                  {index === 0 && (
                                    <Box className="sub-details-wrap">
                                      {fetchNewsSubtitle(ele?.NewsArticle)}
                                    </Box>
                                  )}
                                  <Box className="news-time-comment-wrapper">
                                    <Typography className="news-time">
                                      {fetchNewsTime(
                                        ele?.NewsArticle?.rapidCreatedAt,
                                      )}
                                    </Typography>
                                    <Box className="bookmark-chat-wrapper">
                                      <Box
                                        onClick={(e) => handleNewsSave(e, ele)}
                                      >
                                        <Tooltip
                                          placement="bottom"
                                          arrow
                                          title='Save to "Saved articles"'
                                          classes={{
                                            tooltip: "news-tooltip",
                                          }}
                                        >
                                          <BookmarkIcon
                                            className={
                                              ele?.NewsArticle?.isSaved === 0
                                                ? ""
                                                : "saved-bookmark-icon"
                                            }
                                          />
                                        </Tooltip>
                                      </Box>
                                      <Tooltip
                                        placement="bottom"
                                        arrow
                                        title="Comments"
                                        classes={{
                                          tooltip: "news-tooltip",
                                        }}
                                      >
                                        <Box className="chat-details">
                                          <ChatIcon />
                                          <Typography className="news-time">
                                            {ele?.NewsArticle?.totalComments}
                                          </Typography>
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Slider>
                    ) : (
                      <Box
                        onClick={() => {
                          handleNavigate(item);
                        }}
                        className={`${
                          index !== 0
                            ? "responsive-news-view"
                            : "responsive-main-news-view"
                        }`}
                      >
                        <Box
                          className="background-overlay"
                          style={{
                            content: "''",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundImage: `url('${
                              item?.NewsArticle?.mainMedia?.gallery
                                ? item?.NewsArticle?.mainMedia?.gallery?.url &&
                                  item?.NewsArticle?.mainMedia?.gallery?.url !==
                                    ""
                                  ? item?.NewsArticle?.mainMedia?.gallery?.url
                                  : DefaultImg
                                : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                  ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      ?.url &&
                                    item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      ?.url !== ""
                                    ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                        ?.url
                                    : DefaultImg
                                  : DefaultImg
                            }')`,
                            backgroundSize: "cover",
                            backgroundPosition: "center top",
                            filter: "blur(6px)",
                            zIndex: -1,
                            borderRadius: "8px",
                          }}
                        />
                        <Box className="news-img">
                          <LazyLoadImage
                            src={
                              item?.NewsArticle?.mainMedia?.gallery
                                ? item?.NewsArticle?.mainMedia?.gallery?.url &&
                                  item?.NewsArticle?.mainMedia?.gallery?.url !==
                                    ""
                                  ? item?.NewsArticle?.mainMedia?.gallery?.url
                                  : DefaultImg
                                : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                  ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      ?.url &&
                                    item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      ?.url !== ""
                                    ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                        ?.url
                                    : DefaultImg
                                  : DefaultImg
                            }
                            alt="news-img"
                          />
                        </Box>
                        <Box className="details-wrapper">
                          <span className="news-tag">
                            {item?.NewsArticle?.NewsCategory?.initialTitle}
                          </span>
                          <Box className="news-deatils">
                            <Typography
                              className="title-news"
                              dangerouslySetInnerHTML={{
                                __html: item?.NewsArticle?.title
                                  ? item?.NewsArticle?.title
                                  : item?.NewsArticle?.subTitle,
                              }}
                            />
                            {index === 0 && (
                              <Box className="sub-details-wrap">
                                {fetchNewsSubtitle(item?.NewsArticle)}
                              </Box>
                            )}
                            <Box className="news-time-comment-wrapper">
                              <Typography className="news-time">
                                {fetchNewsTime(
                                  item?.NewsArticle?.rapidCreatedAt,
                                )}
                              </Typography>
                              <Box className="bookmark-chat-wrapper">
                                <Box onClick={(e) => handleNewsSave(e, item)}>
                                  <Tooltip
                                    placement="bottom"
                                    arrow
                                    title='Save to "Saved articles"'
                                    classes={{
                                      tooltip: "news-tooltip",
                                    }}
                                  >
                                    <BookmarkIcon
                                      className={
                                        item?.NewsArticle?.isSaved === 0
                                          ? ""
                                          : "saved-bookmark-icon"
                                      }
                                    />
                                  </Tooltip>
                                </Box>
                                <Tooltip
                                  placement="bottom"
                                  arrow
                                  title="Comments"
                                  classes={{
                                    tooltip: "news-tooltip",
                                  }}
                                >
                                  <Box className="chat-details">
                                    <ChatIcon />
                                    <Typography className="news-time">
                                      {item?.NewsArticle?.totalComments}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              <Box className="news-view-more">
                <span
                  className="view-more-text"
                  onClick={() => handleNavigateToNews()}
                >
                  View All <RightArrow />
                </span>
              </Box>
            </>
          ) : (
            <>
              <Box className="no-data-wrapper f-16">
                <Typography className="no-data-text">
                  No data Available
                </Typography>
              </Box>
            </>
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default LandingFeaturedNews;
