import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as RightArrow } from "../../../../../../assets/images/icons/rightArrowThick.svg";
import NewsComp from "src/views/component/NewsComponent/NewsComp";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useNavigate, useParams } from "react-router";
import "../../../../NewsComponent/newsComp.scss";
import "./landingNews.scss";
import Loader from "src/components/Loader";

const LandingNews = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    featchNewsList();
  }, []);

  const featchNewsList = async () => {
    setLoading(true);
    const passApi = `/v2/news/articles?NewsCategoryId=&limit=6&offset=0&SportId=${params?.sportsId}`;
    const { status, data } = await axiosInstance.get(passApi);
    try {
      if (status === 200) {
        setNewsData(data?.result?.raw);
        //   setSelectOption(leagueData);
        //   setSelectedValue(leagueData?.[0]?.value);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleNavigateNews = () => {
    // Navigate to news page
    navigate(`/news/0`);
  };
  return (
    <>
      {loading ? (
        <div className="allsport-loader-center news-loader">
          <Loader />
        </div>
      ) : (
        newsData?.length > 0 && (
          <Box className="news-section landing-news-section">
            <Box className="all-news category-news" id="news-section">
              <Box className="landing-header">
                <Typography
                  variant="h3"
                  className="component-header hander-text"
                >
                  Latest Cricket News
                </Typography>
                <Typography
                  className="view-all-text"
                  onClick={() => {
                    handleNavigateNews();
                  }}
                >
                  View All <RightArrow />
                </Typography>
              </Box>
              <NewsComp newsData={newsData} landing={true} />
            </Box>
          </Box>
        )
      )}
    </>
  );
};

export default LandingNews;
