import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ReactComponent as SortingArrow } from "src/assets/images/icons/sorting-arrow.svg";
import DefaultTeam from "src/assets/images/defaultTeam.png";
import "./landingICCRankingDetails.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { useLocation, useParams } from "react-router";
import { Config } from "src/helpers/context";
import Loader from "src/components/Loader";

const LandingICCRankingDetails = ({ setLastUpdated }) => {
  const params = useParams();
  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const touranamentId = searchParamsMenu.get("touranamentId");
  const [activeTab, setActiveTab] = useState(1);
  const [teamType, setTeamType] = useState(1);
  const [iccrankingList, setICCRankingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortingType, setSortingType] = useState({
    rankShort: null,
    teamShort: null,
    pointShort: null,
    ratingShort: null,
  });

  const tabs = [
    { label: "Men", id: 1 },
    { label: "Women", id: 2 },
  ];

  const teamTypeTabs = [
    { label: "ODI", id: 1 },
    { label: "Test", id: 2 },
    { label: "T20I", id: 3 },
  ];

  const womenTeamTypeTabs = [
    { label: "ODI", id: 1 },
    { label: "T20I", id: 3 },
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab?.id);
  };

  const handleTeamTypeTabClick = (tab) => {
    setTeamType(tab?.id);
    setSortingType({
      rankShort: null,
      teamShort: null,
      pointShort: null,
      ratingShort: null,
    });
  };

  useEffect(() => {
    fetchICCRanking();
  }, [activeTab, teamType]);

  const fetchICCRanking = async () => {
    setLoading(true);
    const passApi = `allsport/iccranking?SportId=${params?.sportsId}&isWomen=${activeTab === 2 ? 1 : 0}&formatType=${teamType === 1 ? "odi" : teamType === 2 ? "test" : "t20"}&category=teams`;
    const { status, data } = await axiosInstance.get(passApi);
    try {
      if (status === 200) {
        setICCRankingList(data?.result?.data);
        setLastUpdated(data?.result?.data?.[0]?.lastUpdatedOn);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const handleSorting = (field, isShorting) => {
    let sortedData = [...iccrankingList];

    if (field === "rank" || field === "points" || field === "rating") {
      sortedData?.sort((a, b) => {
        if (isShorting === null || isShorting === false) {
          return a?.[field] - b?.[field];
        } else {
          return b?.[field] - a?.[field];
        }
      });
    } else if (field === "name") {
      sortedData?.sort((a, b) => {
        if (isShorting === null || isShorting === false) {
          return a?.name?.localeCompare(b?.name);
        } else {
          return b?.name?.localeCompare(a?.name);
        }
      });
    }

    setICCRankingList(sortedData);

    // Update the sorting state dynamically
    setSortingType({
      rankShort: field === "rank" ? !isShorting : null,
      teamShort: field === "name" ? !isShorting : null,
      pointShort: field === "points" ? !isShorting : null,
      ratingShort: field === "rating" ? !isShorting : null,
    });
  };

  return (
    <>
      <Box className="landing-iccranking-details-wrap">
        <Box className="tab-container">
          <Box className="tab-wrap tab-wrap-bottom">
            {tabs?.map((tab, index) => (
              <Box
                key={index}
                className={`tab-details ${
                  activeTab === tab?.id ? "tab-active" : ""
                }`}
                onClick={() => handleTabClick(tab)}
              >
                <p className="tab-text">{tab?.label}</p>
              </Box>
            ))}
          </Box>
          <Box className="tab-wrap">
            {(activeTab === 1 ? teamTypeTabs : womenTeamTypeTabs)?.map(
              (tab, index) => (
                <Box
                  key={index}
                  className={`tab-details ${
                    teamType === tab?.id ? "tab-active" : ""
                  }`}
                  onClick={() => handleTeamTypeTabClick(tab)}
                >
                  <p className="tab-text">{tab?.label}</p>
                </Box>
              ),
            )}
          </Box>
        </Box>
        <Box className="table-container-details">
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              className="new-landing-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box
                      className="sorting-cell"
                      onClick={() =>
                        handleSorting("rank", sortingType?.rankShort)
                      }
                    >
                      #{" "}
                      <Box className="sort-icon-wrap">
                        <span>
                          <SortingArrow
                            className={
                              sortingType?.rankShort === null
                                ? ""
                                : sortingType?.rankShort
                                  ? "asc-sort"
                                  : ""
                            }
                          />
                        </span>
                        <span className="down-arrow">
                          <SortingArrow
                            className={
                              sortingType?.rankShort === null
                                ? ""
                                : sortingType?.rankShort
                                  ? ""
                                  : "desc-sort"
                            }
                          />
                        </span>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Box
                      className="sorting-cell"
                      onClick={() =>
                        handleSorting("name", sortingType?.teamShort)
                      }
                    >
                      Team{" "}
                      <Box className="sort-icon-wrap">
                        <span>
                          <SortingArrow
                            className={
                              sortingType?.teamShort === null
                                ? ""
                                : sortingType?.teamShort
                                  ? "asc-sort"
                                  : ""
                            }
                          />
                        </span>
                        <span className="down-arrow">
                          <SortingArrow
                            className={
                              sortingType?.teamShort === null
                                ? ""
                                : sortingType?.teamShort
                                  ? ""
                                  : "desc-sort"
                            }
                          />
                        </span>
                      </Box>
                    </Box>
                  </TableCell>
                  {/* <TableCell align="center">
                    <Box className="sorting-cell text-align-center">
                      Matches{" "}
                      <Box className="sort-icon-wrap ">
                        <span>
                          <SortingArrow />
                        </span>
                        <span className="down-arrow">
                          <SortingArrow />
                        </span>
                      </Box>
                    </Box>
                  </TableCell> */}
                  <TableCell align="center">
                    <Box
                      className="sorting-cell text-align-center"
                      onClick={() =>
                        handleSorting("points", sortingType?.pointShort)
                      }
                    >
                      Points{" "}
                      <Box className="sort-icon-wrap">
                        <span>
                          <SortingArrow
                            className={
                              sortingType?.pointShort === null
                                ? ""
                                : sortingType?.pointShort
                                  ? "asc-sort"
                                  : ""
                            }
                          />
                        </span>
                        <span className="down-arrow">
                          <SortingArrow
                            className={
                              sortingType?.pointShort === null
                                ? ""
                                : sortingType?.pointShort
                                  ? ""
                                  : "desc-sort"
                            }
                          />
                        </span>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      className="sorting-cell text-align-center"
                      onClick={() =>
                        handleSorting("rating", sortingType?.ratingShort)
                      }
                    >
                      Rating{" "}
                      <Box className="sort-icon-wrap">
                        <span>
                          <SortingArrow
                            className={
                              sortingType?.ratingShort === null
                                ? ""
                                : sortingType?.ratingShort
                                  ? "asc-sort"
                                  : ""
                            }
                          />
                        </span>
                        <span className="down-arrow">
                          <SortingArrow
                            className={
                              sortingType?.ratingShort === null
                                ? ""
                                : sortingType?.ratingShort
                                  ? ""
                                  : "desc-sort"
                            }
                          />
                        </span>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div className="allsport-loader-center ">
                        <Loader />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
                {!loading && iccrankingList?.length > 0
                  ? iccrankingList?.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{item?.rank}</TableCell>
                          <TableCell align="left">
                            <Box className="team-wrap">
                              <Box className="team-image-wrap">
                                <img
                                  src={
                                    item?.CricketTeam?.flag
                                      ? item?.CricketTeam?.flag?.includes(
                                          "uploads",
                                        )
                                        ? Config.mediaURL +
                                          item?.CricketTeam?.flag
                                        : item?.CricketTeam?.flag
                                      : DefaultTeam
                                  }
                                  onError={(e) => {
                                    e.target.src = DefaultTeam;
                                  }}
                                  alt="Team 1"
                                  className="team-image"
                                />
                              </Box>
                              <Box className="team-name-wrap">
                                <Typography className="team-name">
                                  {item?.name}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          {/* <TableCell align="center">-</TableCell> */}
                          <TableCell align="center">{item?.points}</TableCell>
                          <TableCell align="center">{item?.rating}</TableCell>
                        </TableRow>
                      );
                    })
                  : !loading && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <div className="allsport-loader-center ">No Data</div>
                        </TableCell>
                      </TableRow>
                    )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default LandingICCRankingDetails;
