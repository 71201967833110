import React from "react";
import { Box } from "@mui/material";

const FixtureAndResultLanding = () => {
  return (
    <>
      <Box>FixtureAndResultLanding</Box>
    </>
  );
};

export default FixtureAndResultLanding;
