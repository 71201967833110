import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Tabs,
  Tab,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Collapse,
  ClickAwayListener,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link, useParams, useNavigate } from "react-router-dom";
import { IntlContext } from "src/App";
import AdvertisingBannner from "../../../../component/advertisingBanner/index";
import banner from "../../../../../assets/images/banner/smart-b-banner-secodary.webp";
import moment from "moment-timezone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import "./outRights.scss";
import _ from "lodash";
import Loader from "src/components/Loader";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import NoDataComp from "src/views/component/UI/NoData";
import axios from "axios";
import { useSelector } from "react-redux";
let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    minWidth: "457px",
    width: "457px",
    backgroundColor: "#ffffff",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px",
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px",
    },
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    minWidth: "457px",
    width: "457px",
    backgroundColor: "#ffffff",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px",
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px",
    },
  },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  head: {},
  cell: {},
}));

const OutRights = ({
  eventList,
  teamSportType,
  fetchDayName,
  isEventLoading,
  // fetchOutrightsTable,
  sponsoredId,
  SlideTable,
  fetchSeeAllTeamOddsvalue,
  oddsiconNewOutright,
  fetchAllEvents,
  fetchOutrightTeamlogo,
  // fetchSeeAllOutRightsOdds,
  fetchLatestOdds,
  tabvalue,
  showOdds,
  handleOutsideOutrightsClick,
  handleOpenOutrughtTooltip,
  fetchBestAtOpenOdds,
  fetchBestAtOpenIconOdds,
  fetchFluctuationGraph,
  fetchNewOutrightCurrentBestOdds,
  fetchNewOutrightCurrentBestsOddsIcon,
  fetchNewOutrightClickableOdds,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const abortControllerRef = useRef(null);
  const BookkeeperData = useSelector(
    (state) => state?.reduxData?.BookkeeperData,
  );
  const [value, setValue] = useState(1);
  const [sportTabValue, setSportTabValue] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [scrollX, setscrollX] = useState("left");
  const [seeAll, setSeeAll] = useState(params?.seeall);
  const [selectedDate, setselectedDate] = useState(
    moment.tz(moment(), timezone).format("YYYY-MM-DD"),
  );
  const [pageHeadingData, setPageHeadingData] = useState([]);
  const [MobileBestOpenHome, setMobileBestOpenHome] = useState(-1);
  const [MobileBestOpenAway, setMobileBestOpenAway] = useState(-1);
  const [isSeeAllEventLoading, setIsSeeAllEventLoading] = useState(false);
  const [individualTeamData, setindividualTeamData] = useState({});
  const [individualTournamentData, setindividualTournamentData] = useState({});
  const [MobileBestOpenOutrights, setMobileBestOpenOutrights] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [outrightTeamData, setOutrightTeamData] = useState({});
  const [outrightTeamCount, setOutrightTeamCount] = useState(0);
  const [isTeamLoading, setIsTeamLoading] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [isResults, setIsResults] = useState([]);
  const [isLoadMoreTeam, setIsLoadMoreTeam] = useState(false);
  const [isLoad, setLoad] = useState(false);

  const handleChange = (index, item) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = {};
      if (!prevExpanded[index]) {
        newExpanded[index] = false;
      }
      newExpanded[index] = isExpanded;
      return newExpanded;
    });
    if (isExpanded) {
      if (!(item?.OutRightTeams?.length > 0)) {
        fetchNewTeamData(index, item, 0);
      }
      document.getElementById("outright-container").scrollIntoView();
    } else {
    }
  };

  const handleSeeBest = () => {
    setSeeAll("false");
    navigate(`/teamsports/${params?.type}/outrights/0/false`);
    // setindividualTeamData({});
    fetchAllEvents(0, 1, 0, 0, null, "outrights");
    // setEventPage(0);
  };
  const handleSeeAll = (item) => {
    setSeeAll("true");
    navigate(`/teamsports/${params?.type}/outrights/${item?.id}/true`);
    // setindividualTeamData(item);
  };
  const fetchTableHeading = async (Bookkeeper) => {
    let filteredHeading = BookkeeperData?.filter((item) =>
      Bookkeeper?.includes(item?.id),
    );
    setPageHeadingData(filteredHeading);
  };

  const renderTableCell = (BookKeeperId) => {
    return (
      <>
        <TableCell className={classes.head}>
          {oddsiconNewOutright(BookKeeperId, "header")}
        </TableCell>
      </>
    );
  };
  const groupedData = _.groupBy(eventList, (item) =>
    moment.utc(item?.startTime).local().format("DD/MM/YYYY"),
  );
  const result = _.map(groupedData, (objects, date) => ({ date, objects }));
  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (result) {
      let arr = [];
      const resultData = result?.map((item) => {
        arr.push(...item?.objects);
      });

      setIsResults(arr);
    }
  }, [result?.length]);

  const fetchSeeAllOutRightsData = (item) => {
    let OutRightTeams =
      teamSportType === "cricket"
        ? item?.CricketOutRightTeams
        : teamSportType === "basketball"
          ? item?.NBAOutRightTeams
          : teamSportType === "americanfootball"
            ? item?.AFLOutRightTeams
            : teamSportType === "australianrules"
              ? item?.AROutRightTeams
              : teamSportType === "golf"
                ? item?.GolfOutRightTeams
                : teamSportType === "tennis"
                  ? item?.TennisOutRightTeams
                  : teamSportType === "baseball"
                    ? item?.BaseballOutRightTeams
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyOutRightTeams
                      : teamSportType === "boxing"
                        ? item?.BoxingOutRightTeams
                        : teamSportType === "mma"
                          ? item?.MMAOutRightTeams
                          : teamSportType === "soccer"
                            ? item?.SoccerOutRightTeams
                            : item?.RLOutRightTeams;
    let OutrightsTeamdata = OutRightTeams?.map((obj) => {
      return teamSportType === "cricket"
        ? obj?.CricketTeam
        : teamSportType === "basketball"
          ? obj?.NBATeam
          : teamSportType === "americanfootball"
            ? obj?.AFLTeam
            : teamSportType === "australianrules"
              ? obj?.ARTeam
              : teamSportType === "golf"
                ? obj?.GolfTeam
                : teamSportType === "tennis"
                  ? obj?.TennisTeam
                  : teamSportType === "baseball"
                    ? obj?.BaseballTeam
                    : teamSportType === "icehockey"
                      ? obj?.IceHockeyTeam
                      : teamSportType === "boxing"
                        ? obj?.BoxingTeam
                        : teamSportType === "mma"
                          ? obj?.MMATeam
                          : teamSportType === "soccer"
                            ? obj?.SoccerTeam
                            : obj?.RLTeam;
    });
    return (
      <>
        {item?.OutRightTeams?.map((teamdata, index) => {
          let OutRightTeamObj =
            teamSportType === "cricket"
              ? teamdata?.CricketTeam
              : teamSportType === "basketball"
                ? teamdata?.NBATeam
                : teamSportType === "americanfootball"
                  ? teamdata?.AFLTeam
                  : teamSportType === "australianrules"
                    ? teamdata?.ARTeam
                    : teamSportType === "golf"
                      ? teamdata?.GolfTeam
                      : teamSportType === "tennis"
                        ? teamdata?.TennisTeam
                        : teamSportType === "baseball"
                          ? teamdata?.BaseballTeam
                          : teamSportType === "icehockey"
                            ? teamdata?.IceHockeyTeam
                            : teamSportType === "boxing"
                              ? teamdata?.BoxingTeam
                              : teamSportType === "mma"
                                ? teamdata?.MMATeam
                                : teamSportType === "soccer"
                                  ? teamdata?.SoccerTeam
                                  : teamdata?.RLTeam;
          return (
            <React.Fragment key={index}>
              <TableRow className="individual-team-row">
                <StickyTableCell className={`${classes.cell} body-runner-name`}>
                  <Box className="team-wrap">
                    {/* <span className="team-img-wrap">
                      {fetchOutrightTeamlogo(teamdata)}
                    </span> */}
                    <span className="team-name">
                      {OutRightTeamObj?.name ? OutRightTeamObj?.name : ""}
                    </span>
                  </Box>
                </StickyTableCell>
                {pageHeadingData?.length > 0 ? (
                  pageHeadingData?.map((id, index) => (
                    <TableCell className={classes.cell} key={index}>
                      <span className="odds">
                        {fetchSeeAllOutRightsOdds(
                          item?.eventsOdds,
                          id?.id,
                          teamdata?.id,
                        )}
                      </span>
                    </TableCell>
                  ))
                ) : (
                  <TableCell className={classes.cell} key={index}>
                    {" "}
                    <span className="odds">
                      <span className="odds-btn">NOA</span>
                    </span>
                  </TableCell>
                )}
                <TableCell className={classes.cell}></TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const fetchIndividualTeamdata = async () => {
    try {
      setIsSeeAllEventLoading(true);
      const passApi =
        teamSportType === "cricket"
          ? [
              `public/crickets/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`,
              `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=4`,
            ]
          : teamSportType === "rugbyleague"
            ? [
                `public/rls/event/${params?.id}?SportId=12&isOutright=true&isAdmin=false&isHome=false`,
                `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=12`,
              ]
            : teamSportType === "rugbyunion"
              ? [
                  `public/rls/event/${params?.id}?SportId=13&isOutright=true&isAdmin=false&isHome=false`,
                  `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=13`,
                ]
              : teamSportType === "basketball"
                ? [
                    `public/nba/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`,
                    `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=10`,
                  ]
                : teamSportType === "americanfootball"
                  ? [
                      `public/afl/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`,
                      `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=15`,
                    ]
                  : teamSportType === "australianrules"
                    ? [
                        `public/ar/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`,
                        `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=9`,
                      ]
                    : teamSportType === "golf"
                      ? [
                          `public/golf/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`,
                          `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=16`,
                        ]
                      : teamSportType === "tennis"
                        ? [
                            `public/tennis/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`,
                            `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=7`,
                          ]
                        : teamSportType === "baseball"
                          ? [
                              `public/baseball/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`,
                              `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=11`,
                            ]
                          : teamSportType === "icehockey"
                            ? [
                                `public/icehockey/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`,
                                `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=17`,
                              ]
                            : teamSportType === "boxing"
                              ? [
                                  `public/boxing/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`,
                                  `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=6`,
                                ]
                              : teamSportType === "mma"
                                ? [
                                    `public/mma/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`,
                                    `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=5`,
                                  ]
                                : teamSportType === "soccer"
                                  ? [
                                      `public/soccer/event/${params?.id}?isOutright=true&isAdmin=false&isHome=false`,
                                      `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=8`,
                                    ]
                                  : [
                                      `public/rls/event/${params?.id}?SportId=14&isOutright=true&isAdmin=false&isHome=false`,
                                      `event/outRightTeams/${params?.id}?limit=20&offset=0&SportId=14`,
                                    ];
      let tournamentData, oddsData;
      await Promise.all(
        passApi?.map(async (item, i) => {
          if (i === 0) tournamentData = await axiosInstance.get(item);
          if (i === 1) oddsData = await axiosInstance.get(item);
        }),
      );
      if (tournamentData.status === 200 && oddsData.status === 200) {
        let SportsOdds = oddsData?.data?.result?.eventsOdds;

        let Bookkeeper = [];
        let BookkeeperList = SportsOdds?.map((element) => {
          return Bookkeeper?.push(element?.BookKeeperId);
        });
        fetchTableHeading([...new Set(Bookkeeper)]);
        setindividualTeamData(oddsData?.data?.result);
        setindividualTournamentData(tournamentData?.data?.result);
        setTimeout(() => {
          setIsSeeAllEventLoading(false);
        }, 4000);
      }
    } catch {
      setIsSeeAllEventLoading(false);
    }
  };

  const fetchSeeAllOutRightsOdds = (item, ProviderId, teamid) => {
    // let allTeamOdds =
    //   teamSportType === "cricket"
    //     ? item?.CricketBetOffers?.[0]?.CricketOdds
    //     : teamSportType === "basketball"
    //     ? item?.NBABetOffers?.[0]?.NBAOdds
    //     : teamSportType === "americanfootball"
    //     ? item?.AFLBetOffers?.[0]?.AFLOdds
    //     : teamSportType === "australianrules"
    //     ? item?.ARBetOffers?.[0]?.AROdds
    //     : teamSportType === "golf"
    //     ? item?.GolfBetOffers?.[0]?.GolfOdds
    //     : teamSportType === "tennis"
    //     ? item?.TennisBetOffers?.[0]?.TennisOdds
    //     : teamSportType === "baseball"
    //     ? item?.BaseballBetOffers?.[0]?.BaseballOdds
    //     : teamSportType === "icehockey"
    //     ? item?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
    //     : teamSportType === "boxing"
    //     ? item?.BoxingBetOffers?.[0]?.BoxingOdds
    //     : teamSportType === "mma"
    //     ? item?.MMABetOffers?.[0]?.MMAOdds
    //     : teamSportType === "soccer"
    //     ? item?.SoccerBetOffers?.[0]?.SoccerOdds
    //     : item?.RLBetOffers?.[0]?.RLOdds;
    let OutrightsTeamOdds = item?.filter((obj) => {
      return teamSportType === "cricket"
        ? obj?.CricketTeamId == teamid
        : teamSportType === "basketball"
          ? obj?.NBATeamId == teamid
          : teamSportType === "americanfootball"
            ? obj?.AFLTeamId == teamid
            : teamSportType === "australianrules"
              ? obj?.ARTeamId == teamid
              : teamSportType === "golf"
                ? obj?.GolfTeamId == teamid
                : teamSportType === "tennis"
                  ? obj?.TennisTeamId == teamid
                  : teamSportType === "baseball"
                    ? obj?.BaseballTeamId == teamid
                    : teamSportType === "icehockey"
                      ? obj?.IceHockeyTeamId == teamid
                      : teamSportType === "boxing"
                        ? obj?.BoxingTeamId == teamid
                        : teamSportType === "mma"
                          ? obj?.MMATeamId == teamid
                          : teamSportType === "soccer"
                            ? obj?.SoccerTeamId == teamid
                            : obj?.RLTeamId == teamid;
    });
    let odds = OutrightsTeamOdds?.map((obj) => {
      if (obj?.BookKeeperId === ProviderId) {
        return obj?.odd;
      }
    })?.filter((x) => x !== undefined);
    if (odds?.length > 0) {
      return fetchNewOutrightClickableOdds(
        odds?.[0],
        ProviderId,
        "header",
        OutrightsTeamOdds,
        item,
        "betslip",
      );
    } else {
      return <span className="odds-btn">-</span>;
    }
  };

  const fetchMobileViewSeeAllOutrightsTable = (item) => {
    let OutRightTeams =
      teamSportType === "cricket"
        ? item?.CricketOutRightTeams
        : teamSportType === "basketball"
          ? item?.NBAOutRightTeams
          : teamSportType === "americanfootball"
            ? item?.AFLOutRightTeams
            : teamSportType === "australianrules"
              ? item?.AROutRightTeams
              : teamSportType === "golf"
                ? item?.GolfOutRightTeams
                : teamSportType === "tennis"
                  ? item?.TennisOutRightTeams
                  : teamSportType === "baseball"
                    ? item?.BaseballOutRightTeams
                    : teamSportType === "icehockey"
                      ? item?.IceHockeyOutRightTeams
                      : teamSportType === "boxing"
                        ? item?.BoxingOutRightTeams
                        : teamSportType === "mma"
                          ? item?.MMAOutRightTeams
                          : teamSportType === "soccer"
                            ? item?.SoccerOutRightTeams
                            : item?.RLOutRightTeams;
    let OutrightsTeamdata = OutRightTeams?.map((obj) => {
      return teamSportType === "cricket"
        ? obj?.CricketTeam
        : teamSportType === "basketball"
          ? obj?.NBATeam
          : teamSportType === "americanfootball"
            ? obj?.AFLTeam
            : teamSportType === "australianrules"
              ? obj?.ARTeam
              : teamSportType === "golf"
                ? obj?.GolfTeam
                : teamSportType === "tennis"
                  ? obj?.TennisTeam
                  : teamSportType === "baseball"
                    ? obj?.BaseballTeam
                    : teamSportType === "icehockey"
                      ? obj?.IceHockeyTeam
                      : teamSportType === "boxing"
                        ? obj?.BoxingTeam
                        : teamSportType === "mma"
                          ? obj?.MMATeam
                          : teamSportType === "soccer"
                            ? obj?.SoccerTeam
                            : obj?.RLTeam;
    });
    return (
      <>
        {item.OutRightTeams?.map((teamdata, index) => {
          let OutRightTeamObj =
            teamSportType === "cricket"
              ? teamdata?.CricketTeam
              : teamSportType === "basketball"
                ? teamdata?.NBATeam
                : teamSportType === "americanfootball"
                  ? teamdata?.AFLTeam
                  : teamSportType === "australianrules"
                    ? teamdata?.ARTeam
                    : teamSportType === "golf"
                      ? teamdata?.GolfTeam
                      : teamSportType === "tennis"
                        ? teamdata?.TennisTeam
                        : teamSportType === "baseball"
                          ? teamdata?.BaseballTeam
                          : teamSportType === "icehockey"
                            ? teamdata?.IceHockeyTeam
                            : teamSportType === "boxing"
                              ? teamdata?.BoxingTeam
                              : teamSportType === "mma"
                                ? teamdata?.MMATeam
                                : teamSportType === "soccer"
                                  ? teamdata?.SoccerTeam
                                  : teamdata?.RLTeam;
          return (
            <React.Fragment key={index}>
              <Box className="home-team-wrap">
                <Box className="team-wrap">
                  {/* <span className="team-img-wrap">
                    {fetchOutrightTeamlogo(teamdata)}
                  </span> */}
                  <span className="team-name">
                    {OutRightTeamObj?.name ? OutRightTeamObj?.name : ""}
                  </span>
                </Box>
                <Box className="mobile-best5odds-wrap">
                  <Box className="mobile-best5odds-select">
                    <Typography>{localesData?.sport?.SEE_ALL_ODDS}</Typography>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() =>
                        setMobileBestOpenOutrights(
                          MobileBestOpenOutrights === index ? -1 : index,
                        )
                      }
                    >
                      {MobileBestOpenOutrights === index ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Box>
                  <Box>
                    <Collapse
                      in={MobileBestOpenOutrights === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <TableContainer>
                        <Table className="individual-team-mobile-table">
                          <TableHead></TableHead>
                          <TableBody>
                            <TableRow className="individual-team-mobile-row">
                              {pageHeadingData?.length > 0 ? (
                                pageHeadingData?.map((id, index) => (
                                  <TableCell
                                    className={classes.cell}
                                    key={index}
                                  >
                                    <span className="odds">
                                      {fetchSeeAllOutRightsOdds(
                                        item?.eventsOdds,
                                        id?.id,
                                        teamdata?.id,
                                      )}
                                    </span>
                                  </TableCell>
                                ))
                              ) : (
                                <TableCell className={classes.cell}>
                                  <span className="odds">
                                    <span className="odds-btn">NOA</span>
                                  </span>
                                </TableCell>
                              )}
                            </TableRow>
                            <TableRow className="individual-team-mobile-row odds-icon-seeall">
                              {pageHeadingData?.map((id) =>
                                renderTableCell(id?.id),
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Collapse>
                  </Box>
                </Box>
              </Box>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const seeAllloadMoreData = async (offset) => {
    setIsLoadMoreTeam(true);
    const SportId =
      teamSportType === "cricket"
        ? 4
        : teamSportType === "rugbyleague"
          ? 12
          : teamSportType === "rugbyunion"
            ? 13
            : teamSportType === "basketball"
              ? 10
              : teamSportType === "americanfootball"
                ? 15
                : teamSportType === "australianrules"
                  ? 9
                  : teamSportType === "baseball"
                    ? 11
                    : teamSportType === "boxing"
                      ? 6
                      : teamSportType === "icehockey"
                        ? 17
                        : teamSportType === "mma"
                          ? 5
                          : teamSportType === "soccer"
                            ? 8
                            : teamSportType === "tennis"
                              ? 7
                              : teamSportType === "golf"
                                ? 16
                                : 14;
    try {
      const { status, data } = await axiosInstance.get(
        `event/outRightTeams/${params?.id}?limit=20&offset=${offset}&SportId=${SportId}`,
      );
      if (status === 200) {
        let teams = data?.result?.OutRightTeams;
        let oddsData = data?.result?.eventsOdds;

        individualTeamData.OutRightTeams =
          individualTeamData.OutRightTeams?.concat(teams);
        individualTeamData.eventsOdds =
          individualTeamData.eventsOdds?.concat(oddsData);
        setindividualTeamData(individualTeamData);
        setIsLoadMoreTeam(false);
        setLoad(!isLoad);
      } else {
        setIsLoadMoreTeam(false);
      }
    } catch (err) {
      console.log("error", err);

      setIsLoadMoreTeam(false);
    }
  };

  const fetchNewTeamData = async (index, item, offsetVal) => {
    setIsTeamLoading(true);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const newAbortController = new AbortController();
    abortControllerRef.current = newAbortController;
    try {
      const { status, data } = await axiosInstance.get(
        `event/outRightTeams/${item?.id}?limit=20&offset=${offsetVal}&SportId=${item?.SportId}`,
        {
          signal: newAbortController.signal,
        },
      );
      if (status === 200) {
        let teams = data?.result?.OutRightTeams;
        let oddsData = data?.result?.eventsOdds;
        await teams?.map((item) => {
          switch (isResults[index].SportId) {
            case 15:
              break;
            case 9:
              break;
            case 11:
              break;
            case 10:
              break;
            case 6:
              break;
            case 4:
              break;
            case 16:
              let odds = oddsData?.filter(
                (obj) => obj?.GolfTeam?.id == item?.GolfTeam?.id,
              );
              item.GolfTournamentId = isResults[index].GolfTournamentId;
              item.GolfBetOffers = [
                {
                  GolfOdds: odds,
                },
              ];
              break;
            case 17:
              break;
            case 5:
              break;
            case 12:
              break;
            case 13:
              break;
            case 8:
              break;
            case 7:
              break;
          }
        });
        isResults[index].count = data?.result?.count;
        isResults[index].OutRightTeams = teams;
        setIsResults(isResults);
        setOffset(offsetVal);
        setIsTeamLoading(false);

        // setOutrightTeamData(data?.result);
        // setOutrightTeamCount(data?.result?.count);
      } else {
        setIsTeamLoading(false);
      }
    } catch (err) {
      console.log("error", err);

      if (axios.isCancel(err)) {
      } else {
        setIsTeamLoading(false);
      }
    }
  };

  const loadMoreData = async (eventId, SportId, index, offset) => {
    if (eventId && SportId) {
      try {
        const { status, data } = await axiosInstance.get(
          `event/outRightTeams/${eventId}?limit=20&offset=${offset}&SportId=${SportId}`,
        );
        if (status === 200) {
          let teams = data?.result?.OutRightTeams;
          let oddsData = data?.result?.eventsOdds;

          await teams?.map((item) => {
            switch (isResults[index].SportId) {
              case 15:
                break;
              case 9:
                break;
              case 11:
                break;
              case 10:
                break;
              case 6:
                break;
              case 4:
                break;
              case 16:
                let odds = oddsData?.filter(
                  (obj) => obj?.GolfTeam?.id == item?.GolfTeam?.id,
                );
                item.GolfTournamentId = isResults[index].GolfTournamentId;
                item.GolfBetOffers = [
                  {
                    GolfOdds: odds,
                  },
                ];
                break;
              case 17:
                break;
              case 5:
                break;
              case 12:
                break;
              case 13:
                break;
              case 8:
                break;
              case 7:
                break;
            }
          });

          isResults[index].OutRightTeams =
            isResults[index].OutRightTeams?.concat(teams);
          setIsResults(isResults);
          // setOffset(offset);
          setIsLoadMoreTeam(false);
          setLoad(!isLoad);
          // setOutrightTeamData(data?.result);
          // setOutrightTeamCount(data?.result?.count);
        } else {
          setIsLoadMoreTeam(false);
        }
      } catch (err) {
        console.log("error", err);

        setIsLoadMoreTeam(false);
      }
    }
  };

  const fetchOutrightsTable = (item, indexVal) => {
    // let smartbookAdded = OutRightTeams?.map(obj => {
    //   return obj?.isSmartBookTeam;
    // });
    return (
      <>
        {item?.OutRightTeams?.map((teamdata, index) => {
          let OutRightTeams =
            teamSportType === "cricket"
              ? teamdata?.CricketTeam
              : teamSportType === "basketball"
                ? teamdata?.NBATeam
                : teamSportType === "americanfootball"
                  ? teamdata?.AFLTeam
                  : teamSportType === "australianrules"
                    ? teamdata?.ARTeam
                    : teamSportType === "golf"
                      ? teamdata?.GolfTeam
                      : teamSportType === "tennis"
                        ? teamdata?.TennisTeam
                        : teamSportType === "baseball"
                          ? teamdata?.BaseballTeam
                          : teamSportType === "icehockey"
                            ? teamdata?.IceHockeyTeam
                            : teamSportType === "boxing"
                              ? teamdata?.BoxingTeam
                              : teamSportType === "mma"
                                ? teamdata?.MMATeam
                                : teamSportType === "soccer"
                                  ? teamdata?.SoccerTeam
                                  : teamdata?.RLTeam;
          let smartbookAdded = teamdata?.isSmartBookTeam;
          return (
            <React.Fragment key={index}>
              {screenWidth >= 1023 ? (
                <TableRow className="teamsports-tablerow hometeam-row teamsport-row">
                  <TableCell
                    // className={
                    //   showOdds ? "team-wrap" : "show-odds-team-wrap team-wrap"
                    // }
                    className={`team-wrap ${
                      showOdds ? "" : tabvalue == 0 ? "show-odds-team-wrap" : ""
                    }`}
                    style={{ textAlign: "left" }}
                  >
                    {/* <span className="team-img-wrap">
                    {fetchOutrightTeamlogo(teamdata)}
                  </span> */}
                    <span className="team-name">
                      {OutRightTeams?.name ? OutRightTeams?.name : ""}
                    </span>
                    <ClickAwayListener
                      onClickAway={() =>
                        handleOutsideOutrightsClick(
                          teamdata,
                          indexVal,
                          OutRightTeams,
                          index,
                        )
                      }
                    >
                      <Box className="blackbook-icon">
                        {handleOpenOutrughtTooltip(
                          teamdata,
                          indexVal,
                          OutRightTeams,
                          index,
                          smartbookAdded,
                          isResults[0],
                        )}
                      </Box>
                    </ClickAwayListener>
                  </TableCell>
                  {teamSportType !== "golf" ||
                  (teamSportType === "golf" && tabvalue === 2) ? (
                    showOdds ? (
                      <TableCell className="border-right border-left td-cell">
                        <Box className="odds-wrap">
                          <span className="odds odds-left">
                            {fetchBestAtOpenOdds(
                              teamdata,
                              "odds",
                              "outrightsteam",
                              OutRightTeams?.id,
                            )}
                          </span>
                          {fetchBestAtOpenIconOdds(
                            teamdata,
                            "odds",
                            "outrightsteam",
                            OutRightTeams?.id,
                          )}
                        </Box>
                      </TableCell>
                    ) : (
                      <></>
                    )
                  ) : (
                    <TableCell className="hight-chart-cell border-left">
                      {fetchFluctuationGraph(
                        teamdata,
                        "outrightteam",
                        OutRightTeams?.id,
                      )}
                    </TableCell>
                  )}
                  {teamSportType !== "golf" ||
                  (teamSportType === "golf" && tabvalue === 2) ? (
                    showOdds ? (
                      <TableCell
                        className={`${
                          sponsoredId?.length > 0
                            ? "border-right border-left td-cell"
                            : "border-left td-cell"
                        }`}
                      >
                        <Box className="odds-wrap">
                          <span className="odds odds-left">
                            {fetchNewOutrightCurrentBestOdds(
                              teamdata,
                              "odds",
                              "outrightsteam",
                              OutRightTeams?.id,
                            )}
                          </span>
                          {fetchNewOutrightCurrentBestsOddsIcon(
                            teamdata,
                            "odds",
                            "outrightsteam",
                            OutRightTeams?.id,
                          )}
                        </Box>
                      </TableCell>
                    ) : (
                      <></>
                    )
                  ) : (
                    <TableCell
                      className={`${
                        sponsoredId?.length > 0
                          ? "border-right border-left td-cell"
                          : "border-left td-cell"
                      }`}
                    >
                      <Box className="odds-wrap">
                        <span className="odds odds-left">
                          {fetchNewOutrightCurrentBestOdds(
                            teamdata,
                            "odds",
                            "outrightsteam",
                            OutRightTeams?.id,
                          )}
                        </span>
                        {fetchNewOutrightCurrentBestsOddsIcon(
                          teamdata,
                          "odds",
                          "outrightsteam",
                          OutRightTeams?.id,
                        )}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ) : (
                <>
                  <Box className="responsive-team-tooltip">
                    <span className="team-name">
                      {OutRightTeams?.name ? OutRightTeams?.name : ""}
                    </span>
                    <ClickAwayListener
                      onClickAway={() =>
                        handleOutsideOutrightsClick(
                          teamdata,
                          indexVal,
                          OutRightTeams,
                          index,
                        )
                      }
                    >
                      <Box className="blackbook-icon">
                        {handleOpenOutrughtTooltip(
                          teamdata,
                          indexVal,
                          OutRightTeams,
                          index,
                          smartbookAdded,
                          isResults[0],
                        )}
                      </Box>
                    </ClickAwayListener>
                  </Box>

                  <TableContainer
                    className={
                      showOdds
                        ? "outrights-mobile-table-container"
                        : "show-outrights-mobile outrights-mobile-table-container"
                    }
                  >
                    <Table>
                      <TableHead className="mobile-data-head">
                        {teamSportType !== "golf" ||
                        (teamSportType === "golf" && tabvalue === 2) ? (
                          showOdds ? (
                            <TableCell
                              className="mobile-table-data border-right-white"
                              width="33.33%"
                            >
                              {" "}
                              {localesData?.RUNNER_TABLE?.BEST_AT_OPEN}
                            </TableCell>
                          ) : (
                            <></>
                          )
                        ) : (
                          <TableCell></TableCell>
                        )}
                        {teamSportType !== "golf" ||
                        (teamSportType === "golf" && tabvalue === 2) ? (
                          showOdds ? (
                            <TableCell
                              className={`mobile-table-data  ${
                                sponsoredId?.length > 0
                                  ? "border-right-white"
                                  : ""
                              }`}
                              width="33.33%"
                            >
                              {" "}
                              {localesData?.RUNNER_TABLE?.CURRENT_BEST}
                            </TableCell>
                          ) : (
                            <></>
                          )
                        ) : (
                          <TableCell
                            className={`mobile-table-data ${
                              sponsoredId?.length > 0
                                ? "border-right-white"
                                : ""
                            }`}
                            width="33.33%"
                          >
                            {" "}
                            {localesData?.RUNNER_TABLE?.CURRENT_BEST}
                          </TableCell>
                        )}
                      </TableHead>
                      <TableBody className="mobile-table-body">
                        <TableRow>
                          {teamSportType !== "golf" ||
                          (teamSportType === "golf" && tabvalue === 2) ? (
                            showOdds ? (
                              <TableCell className="mobile-table-data odds-column border-right-white">
                                {teamdata ? (
                                  <>
                                    <Box style={{ marginBottom: "5px" }}>
                                      {fetchBestAtOpenOdds(
                                        teamdata,
                                        "odds",
                                        "outrightsteam",
                                        OutRightTeams?.id,
                                      )}
                                    </Box>
                                    {fetchBestAtOpenIconOdds(
                                      teamdata,
                                      "odds",
                                      "outrightsteam",
                                      OutRightTeams?.id,
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                            ) : (
                              <></>
                            )
                          ) : (
                            <TableCell className="mobile-table-data odds-column border-right-white ptb-10 mobile-chart">
                              {fetchFluctuationGraph(
                                teamdata,
                                "outrightteam",
                                OutRightTeams?.id,
                              )}
                            </TableCell>
                          )}
                          {teamSportType !== "golf" ||
                          (teamSportType === "golf" && tabvalue === 2) ? (
                            showOdds ? (
                              <TableCell
                                className={`mobile-table-data current-best-odds-column ${
                                  sponsoredId?.length > 0
                                    ? "border-right-white"
                                    : ""
                                }`}
                              >
                                {teamdata ? (
                                  <>
                                    <Box style={{ marginBottom: "5px" }}>
                                      {fetchNewOutrightCurrentBestOdds(
                                        teamdata,
                                        "odds",
                                        "outrightsteam",
                                        OutRightTeams?.id,
                                      )}
                                    </Box>
                                    {fetchNewOutrightCurrentBestsOddsIcon(
                                      teamdata,
                                      "odds",
                                      "outrightsteam",
                                      OutRightTeams?.id,
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                            ) : (
                              <></>
                            )
                          ) : (
                            <TableCell
                              className={`mobile-table-data current-best-odds-column ${
                                sponsoredId?.length > 0
                                  ? "border-right-white"
                                  : ""
                              }`}
                            >
                              {teamdata ? (
                                <>
                                  <Box style={{ marginBottom: "5px" }}>
                                    {fetchNewOutrightCurrentBestOdds(
                                      teamdata,
                                      "odds",
                                      "outrightsteam",
                                      OutRightTeams?.id,
                                    )}
                                  </Box>
                                  {fetchNewOutrightCurrentBestsOddsIcon(
                                    teamdata,
                                    "odds",
                                    "outrightsteam",
                                    OutRightTeams?.id,
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    setSeeAll(params?.seeall);
    if (
      params?.oddstype === "outrights" &&
      params?.seeall === "true" &&
      BookkeeperData?.length > 0
    ) {
      fetchIndividualTeamdata();
      // fetchTableHeading();
      // fetchLatestOdds();
    } else {
      setindividualTeamData({});
    }
  }, [params?.id, params?.seeall, BookkeeperData?.length]);

  return (
    <>
      <Box className="content-wrap team-sports-layout" id="outright-container">
        {params?.seeall === "false" ? (
          isEventLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : result?.length > 0 ? (
            isResults?.map((obj, index) => {
              return (
                <>
                  <Box className="team-sport-table-wrap outrights-wrap">
                    <>
                      <Box className="individual-outrights-wrap">
                        <Box className="table-header-wrap">
                          <Box>
                            <Typography variant="h1">
                              {/* {fetchFormatedDayname(obj?.startTime
)} */}
                              {moment(obj?.startTime)
                                .tz(timezone)
                                .format("dddd DD/MM/YYYY")}
                            </Typography>
                          </Box>
                          {teamSportType !== "golf" ||
                            (teamSportType === "golf" && tabvalue === 2 && (
                              <Box className="currentbest-seeall">
                                <span>
                                  <Typography variant="h1">
                                    {" "}
                                    {localesData?.RUNNER_TABLE?.CURRENT_BEST}
                                  </Typography>
                                </span>
                                <span
                                  className="teamsports-seeall"
                                  onClick={() => handleSeeAll(obj)}
                                >
                                  <Typography variant="h1">See All</Typography>
                                </span>
                              </Box>
                            ))}
                        </Box>

                        <Box className="team-sport-table">
                          <Box className="team-sport-table-indicator">
                            <Box className="time-indicator-left">
                              <Box className="team-time-market">
                                {moment
                                  .utc(obj?.startTime)
                                  .local()
                                  .format("hh:mm A")}{" "}
                                |{" "}
                                {`${
                                  obj?.marketLength ? obj?.marketLength : 0
                                } Markets`}
                              </Box>
                              <span className="sport-indicator-btn">
                                {teamSportType === "cricket"
                                  ? obj?.CricketTournament?.name
                                  : teamSportType === "rugbyleague" ||
                                      teamSportType === "rugbyunion" ||
                                      teamSportType === "rugbyunionsevens"
                                    ? obj?.RLTournament?.name
                                    : teamSportType === "americanfootball"
                                      ? obj?.AFLTournament?.name
                                      : teamSportType === "australianrules"
                                        ? obj?.ARTournament?.name
                                        : teamSportType === "golf"
                                          ? obj?.GolfTournament?.name
                                          : teamSportType === "tennis"
                                            ? obj?.TennisTournament?.name
                                            : teamSportType === "baseball"
                                              ? obj?.BaseballTournament?.name
                                              : teamSportType === "icehockey"
                                                ? obj?.IceHockeyTournament?.name
                                                : teamSportType === "boxing"
                                                  ? obj?.BoxingTournament?.name
                                                  : teamSportType === "mma"
                                                    ? obj?.MMATournament?.name
                                                    : teamSportType === "soccer"
                                                      ? obj?.SoccerTournament
                                                          ?.name
                                                      : teamSportType ===
                                                          "basketball"
                                                        ? obj?.NBATournament
                                                            ?.NBACategory ===
                                                          null
                                                          ? obj?.NBATournament
                                                              ?.name
                                                          : obj?.NBATournament
                                                              ?.name +
                                                            " " +
                                                            obj?.NBATournament
                                                              ?.NBACategory
                                                              ?.name
                                                        : ""}
                              </span>
                            </Box>
                            {/* <Box>
                                <span
                                  className="ex-full see-filter"
                                  onClick={() => handleSeeAll(obj)}
                                >
                                  {localesData?.RUNNER_TABLE?.SEE_ALL}
                                </span>
                              </Box> */}
                          </Box>
                          <Accordion
                            className="outrights-accordian"
                            expanded={expanded[index] || false}
                            onChange={handleChange(index, obj)}
                            id={`outrights-accordian_${index}`}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="team-sport-table outrights-accordian-summary"
                            >
                              Outright Betting
                            </AccordionSummary>
                            <AccordionDetails className="outrights-accordian-details">
                              {screenWidth > 1023 ? (
                                <>
                                  <Table
                                    className="racing-data outrights-table"
                                    aria-label="customized table"
                                    style={{ minWidth: 700 }}
                                  >
                                    <TableHead className="racing-track-table-head">
                                      <TableRow
                                        style={{ fontSize: 12 }}
                                        className="head-row"
                                      >
                                        <TableCell
                                          className={
                                            showOdds
                                              ? "track-table-th"
                                              : "track-table-th show-odds-th"
                                          }
                                          style={{
                                            textAlign: "left",
                                            textTransform: "uppercase",
                                          }}
                                        >
                                          TEAMS
                                        </TableCell>
                                        {teamSportType !== "golf" ||
                                        (teamSportType === "golf" &&
                                          tabvalue === 2) ? (
                                          showOdds ? (
                                            <TableCell
                                              className="track-tabel-odds-th table-head"
                                              style={{
                                                textAlign: "center",
                                              }}
                                            >
                                              {
                                                localesData?.RUNNER_TABLE
                                                  ?.BEST_AT_OPEN
                                              }
                                            </TableCell>
                                          ) : (
                                            <></>
                                          )
                                        ) : (
                                          <TableCell></TableCell>
                                        )}

                                        {teamSportType !== "golf" ||
                                        (teamSportType === "golf" &&
                                          tabvalue === 2) ? (
                                          showOdds ? (
                                            <TableCell
                                              className="track-tabel-odds-th table-head current-best"
                                              style={{
                                                textAlign: "center",
                                              }}
                                            >
                                              {
                                                localesData?.RUNNER_TABLE
                                                  ?.CURRENT_BEST
                                              }
                                            </TableCell>
                                          ) : (
                                            <></>
                                          )
                                        ) : (
                                          <TableCell
                                            className="track-tabel-odds-th table-head current-best"
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {
                                              localesData?.RUNNER_TABLE
                                                ?.CURRENT_BEST
                                            }
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {isTeamLoading ? (
                                        <TableRow>
                                          <TableCell colSpan={3}>
                                            <div className="allsport-loader-center ">
                                              <Loader />
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        fetchOutrightsTable(obj, index)
                                      )}
                                    </TableBody>
                                  </Table>
                                  {isLoadMoreTeam ? (
                                    <div className="allsport-loader-center ">
                                      <Loader />
                                    </div>
                                  ) : obj.OutRightTeams?.length > 0 ? (
                                    <Box className="view-more">
                                      {obj?.count >
                                      obj?.OutRightTeams?.length ? (
                                        <span
                                          className="view-more-text"
                                          onClick={() => {
                                            setIsLoadMoreTeam(true);
                                            loadMoreData(
                                              obj.id,
                                              obj?.SportId,
                                              index,
                                              obj?.OutRightTeams?.length,
                                            );
                                          }}
                                        >
                                          View More
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      {obj?.OutRightTeams?.length > 20 ? (
                                        <span
                                          className="view-more-text view-lese-text"
                                          onClick={() => {
                                            obj.OutRightTeams.splice(-20);
                                            setLoad(!isLoad);
                                            document
                                              .getElementById(
                                                "outright-container",
                                              )
                                              .scrollIntoView();
                                          }}
                                        >
                                          View Less
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <Box className="mobile-table-container racing-data">
                                  {isTeamLoading ? (
                                    <div className="allsport-loader-center ">
                                      <Loader />
                                    </div>
                                  ) : (
                                    fetchOutrightsTable(obj, index)
                                  )}
                                  {isLoadMoreTeam ? (
                                    <div className="allsport-loader-center ">
                                      <Loader />
                                    </div>
                                  ) : obj.OutRightTeams?.length > 0 ? (
                                    <Box className="view-more">
                                      {obj?.count >
                                      obj?.OutRightTeams?.length ? (
                                        <span
                                          className="view-more-text"
                                          onClick={() => {
                                            setIsLoadMoreTeam(true);
                                            loadMoreData(
                                              obj.id,
                                              obj?.SportId,
                                              index,
                                              obj?.OutRightTeams?.length,
                                            );
                                          }}
                                        >
                                          View More
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                      {obj?.OutRightTeams?.length > 20 ? (
                                        <span
                                          className="view-more-text view-lese-text"
                                          onClick={() => {
                                            obj.OutRightTeams.splice(-20);
                                            setLoad(!isLoad);
                                            document
                                              .getElementById(
                                                "outright-container",
                                              )
                                              .scrollIntoView();
                                          }}
                                        >
                                          View Less
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </Box>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </Box>
                    </>

                    {/* {screenWidth > 1023 ? (
            <Table className="team-sport-table">
              <TableHead>
                <TableRow>
                  <TableCell className="border-right">
                    <Box className="team-sport-table-indicator">
                      <Box className="team-time-market">
                        {moment
                          .utc(item?.startTime)
                          .local()
                          .format("hh:mm A")}{" "}
                        |{" "}
                        {`${
                          item?.marketLength ? item?.marketLength : 0
                        } Markets`}
                      </Box>
                      <span className="sport-indicator-btn">
                        {teamSportType === "cricket"
                          ? item?.CricketTournament?.name
                          : teamSportType === "rugbyleague" ||
                            teamSportType === "rugbyunion" ||
                            teamSportType === "rugbyunionsevens"
                          ? item?.RLTournament?.name
                          : teamSportType === "americanfootball"
                          ? item?.AFLTournament?.name
                          : teamSportType === "australianrules"
                          ? item?.ARTournament?.name
                          : teamSportType === "golf"
                          ? item?.GolfTournament?.name
                          : teamSportType === "tennis"
                          ? item?.TennisTournament?.name
                          : teamSportType === "baseball"
                          ? item?.BaseballTournament?.name
                          : teamSportType === "icehockey"
                          ? item?.IceHockeyTournament?.name
                          : teamSportType === "boxing"
                          ? item?.BoxingTournament?.name
                          : teamSportType === "mma"
                          ? item?.MMATournament?.name
                          : teamSportType === "soccer"
                          ? item?.SoccerTournament?.name
                          : teamSportType === "basketball"
                          ? item?.NBATournament?.NBACategory === null
                            ? item?.NBATournament?.name
                            : item?.NBATournament?.name +
                              " " +
                              item?.NBATournament?.NBACategory?.name
                          : ""}
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{
                      width: sponsoredId?.length > 0 ? "25%" : "auto"
                    }}
                    className={"table-head table-head-after"}
                  >
                    {localesData?.sport?.BEST_AT_OPEN}
                  </TableCell>

                  <TableCell
                    className={`table-head table-current ${
                      sponsoredId?.length > 0 ? "table-head-after" : ""
                    } `}
                  >
                    {localesData?.sport?.CURRENT_BEST}
                  </TableCell>
                  {sponsoredId?.length > 0 ? (
                    <TableCell
                      colSpan={2}
                      className="table-head table-sponsored-head"
                    >
                      <Typography className="sponsored-head-to-head">
                        {localesData?.sport?.SPONSORED}
                      </Typography>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {item?.outrights === false ? (
                  <>
                    <TableRow className="teamsports-tablerow hometeam-row">
                      <TableCell className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(item, "hometeam")}
                        </span>
                        <span className="team-name">
                          {item?.homeTeam?.name ? item?.homeTeam?.name : ""}
                        </span>
                      </TableCell>
                      <TableCell className="border-right border-left td-cell">
                        <Box className="odds-wrap">
                          <span className="odds odds-left">
                            {fetchBestAtOpenOdds(item, "odds", "hometeam")}
                          </span>
                          {fetchBestAtOpenIconOdds(item, "odds", "hometeam")}
                        </Box>
                      </TableCell>
                      <TableCell className="border-right current-best">
                        {" "}
                        <Box className="odds-wrap">
                          <span className="odds odds-left">
                            {fetchCurrentBestOdds(item, "odds", "hometeam")}
                          </span>
                          {fetchCurrentBestsOddsIcon(item, "odds", "hometeam")}
                        </Box>
                      </TableCell>
                      {sponsoredId?.length > 0 ? (
                        fetchSponsoredOddsHome(item)
                      ) : (
                        <></>
                      )}
                    </TableRow>
                    <TableRow className="awayteam-row">
                      <TableCell className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(item, "awayteam")}
                        </span>
                        <span className="team-name">
                          {" "}
                          {item?.awayTeam?.name ? item?.awayTeam?.name : ""}
                        </span>
                      </TableCell>
                      <TableCell className="border-right border-left td-cell">
                        <Box className="odds-wrap">
                          <span className="odds odds-left">
                            {" "}
                            {fetchBestAtOpenOdds(item, "odds", "awayteam")}
                          </span>
                          {fetchBestAtOpenIconOdds(item, "odds", "awayteam")}
                        </Box>
                      </TableCell>
                      <TableCell className="border-right current-best">
                        <Box className="odds-wrap">
                          <span className="odds odds-left">
                            {fetchCurrentBestOdds(item, "odds", "awayteam")}
                          </span>
                          {fetchCurrentBestsOddsIcon(item, "odds", "awayteam")}
                        </Box>
                      </TableCell>
                      {sponsoredId?.length > 0 ? (
                        fetchSponsoredOddsAway(item)
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  </>
                ) : (
                  fetchOutrightsTable(item)
                )}
              </TableBody>
            </Table>
          ) : (
            <Box className="mobileview-teamsport-table-wrap">
              <Box className="team-match-wrap">
                <Box className="time-market-team-wrap">
                  <Box className="mobile-temsport-indicator">
                    <span className="sport-indicator-btn">
                      {teamSportType === "cricket"
                        ? item?.CricketTournament?.name
                        : teamSportType === "basketball"
                        ? item?.NBATournament?.name +
                          " " +
                          item?.NBATournament?.NBACategory?.name
                        : teamSportType === "rugbyleague" ||
                          teamSportType === "rugbyunion" ||
                          teamSportType === "rugbyunionsevens"
                        ? item?.RLTournament?.name
                        : teamSportType === "americanfootball"
                        ? item?.AFLTournament?.name
                        : teamSportType === "australianrules"
                        ? item?.ARTournament?.name
                        : teamSportType === "golf"
                        ? item?.GolfTournament?.name
                        : teamSportType === "tennis"
                        ? item?.TennisTournament?.name
                        : teamSportType === "baseball"
                        ? item?.BaseballTournament?.name
                        : teamSportType === "icehockey"
                        ? item?.IceHockeyTournament?.name
                        : teamSportType === "boxing"
                        ? item?.BoxingTournament?.name
                        : teamSportType === "mma"
                        ? item?.MMATournament?.name
                        : teamSportType === "soccer"
                        ? item?.SoccerTournament?.name
                        : ""}
                    </span>
                    <Typography className="des-label">
                      {" "}
                      {moment
                        .utc(item?.startTime)
                        .local()
                        .format("hh:mm A")}{" "}
                      |{" "}
                      {`${item?.marketLength ? item?.marketLength : 0} Markets`}
                    </Typography>
                  </Box>
                  {item?.[teamScores]?.length > 0 ? (
                    <Table className="mobile-live-table">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Score</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell className="table-head">
                            {item?.homeTeam?.name}V/S
                          </TableCell>
                          <TableCell>
                            {fetchteamscore(item, item?.homeTeamId)}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="table-head">
                            {item?.awayTeam?.name}
                          </TableCell>
                          <TableCell>
                            {fetchteamscore(item, item?.awayTeamId)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  ) : (
                    <Box className="teams-details">
                      <Box className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(item, "hometeam")}
                        </span>
                        <span className="team-name">
                          {item?.homeTeam?.name ? item?.homeTeam?.name : ""}
                        </span>
                      </Box>
                      <Box className="vs"> V/S </Box>
                      <Box className="team-wrap">
                        <span className="team-img-wrap">
                          {fetchTeamlogo(item, "awayteam")}
                        </span>
                        <span className="team-name">
                          {" "}
                          {item?.awayTeam?.name
                            ? item?.awayTeam?.name
                            : ""}{" "}
                        </span>
                      </Box>
                    </Box>
                  )}
                </Box>
                {item?.[teamScores]?.length > 0 ? (
                  <>
                    <Box className="win-odds-live">
                      <Box className="win-odds-padding">
                        <Box className="odds-label">
                          {" "}
                          {item?.homeTeam?.name ? item?.homeTeam?.name : ""}
                        </Box>
                        <Box className="odds-wrap">
                          <span className="odds odds-left odds-long">
                            {fetchCurrentBestOdds(item, "odds", "hometeam")}
                          </span>
                          {fetchCurrentBestsOddsIcon(item, "odds", "hometeam")}
                        </Box>
                      </Box>
                      <Box>
                        <Box className="odds-label">
                          {" "}
                          {item?.awayTeam?.name ? item?.awayTeam?.name : ""}
                        </Box>
                        <Box className="odds-wrap">
                          <span className="odds odds-left odds-long">
                            {fetchCurrentBestOdds(item, "odds", "awayteam")}
                          </span>
                          {fetchCurrentBestsOddsIcon(item, "odds", "awayteam")}
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Typography className="des-label des-head">
                      {localesData?.sport?.BEST_AT_OPEN}
                    </Typography>
                    <Box className="odds-detail">
                      <Box className="odds-body">
                        <Box>
                          <Box className="odds-wrap">
                            <span className="bookmaker-long odds-left">
                              {fetchBestAtOpenIconOdds(
                                item,
                                "odds",
                                "hometeam"
                              )}
                            </span>

                            <span className="odds">
                              {fetchBestAtOpenOdds(item, "odds", "hometeam")}
                            </span>
                          </Box>
                        </Box>
                        <Box>
                          <Box className="odds-wrap">
                            <span className="odds odds-left">
                              {" "}
                              {fetchBestAtOpenOdds(item, "odds", "awayteam")}
                            </span>
                            <span className="bookmaker-long">
                              {fetchBestAtOpenIconOdds(
                                item,
                                "odds",
                                "awayteam"
                              )}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Typography className="des-label des-head des-current-best">
                      {localesData?.sport?.CURRENT_BEST}
                    </Typography>
                    <Box className="odds-detail odds-details-best">
                      <Box className="odds-body">
                        <Box>
                          <Box className="odds-wrap">
                            <span className="bookmaker-long odds-left">
                              {fetchCurrentBestsOddsIcon(
                                item,
                                "odds",
                                "hometeam"
                              )}
                            </span>

                            <span className="odds">
                              {fetchCurrentBestOdds(item, "odds", "hometeam")}
                            </span>
                          </Box>
                        </Box>
                        <Box>
                          <Box className="odds-wrap">
                            <span className="odds odds-left">
                              {" "}
                              {fetchCurrentBestOdds(item, "odds", "awayteam")}
                            </span>
                            <span className="bookmaker-long">
                              {fetchCurrentBestsOddsIcon(
                                item,
                                "odds",
                                "awayteam"
                              )}
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}

                {sponsoredId?.length > 0 ? (
                  <>
                    <Typography className="des-label des-head des-head-to-head">
                      <span className="sponsored-head-to-head">
                        {" "}
                        {localesData?.sport?.SPONSORED_HEAD_TO_HEAD}
                      </span>
                    </Typography>
                    <Box
                      className={`${
                        sponsoredId?.length > 1 ? "odds-after" : ""
                      } odds-detail`}
                    >
                      <Box className="odds-body">
                        <Box className="sponsored-odds-body">
                          {fetchSponsoredOddsHome(item)}
                        </Box>
                        <Box className="sponsored-odds-body">
                          {fetchSponsoredOddsAway(item)}
                        </Box>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          )} */}
                  </Box>
                </>
              );
            })
          ) : (
            <Box style={{ textAlign: "center" }}>
              {/* No Data Available  */}
              <NoDataComp />
            </Box>
          )
        ) : screenWidth > 1023 ? (
          isSeeAllEventLoading ? (
            <div className="allsport-loader-center ">
              <Loader />
            </div>
          ) : (
            <Box className="teamsports-seeall-tablewrap outrights-seeall-tablewrap">
              <TableContainer
                className="table-seeall-container"
                id="individual-team-table"
              >
                <Box className="overlay"></Box>
                <Box className="arrow-wrap">
                  <span
                    className={scrollX == "right" ? "arrow" : "disable"}
                    onClick={() => {
                      SlideTable("left");
                      setscrollX("left");
                    }}
                  >
                    <ChevronLeftIcon />
                  </span>

                  <span
                    className={scrollX == "left" ? "arrow" : "disable"}
                    onClick={() => {
                      SlideTable("right");
                      setscrollX("left");
                    }}
                  >
                    <ChevronRightIcon />
                  </span>
                </Box>
                <Box className="see-best" onClick={() => handleSeeBest()}>
                  {" "}
                  See Best
                </Box>
                <Table
                  style={{ minWidth: 700 }}
                  className="individual-table outrights-individual-table"
                >
                  <TableHead className="individual-team-head">
                    <TableRow>
                      <StickyTableCell className={classes.head}>
                        <Box className="select-wrap">
                          {/* <Select
                            className="React oddstype"
                            value={MarketList?.find(item => {
                              return item?.value === selectedOption;
                            })}
                            onChange={e => {
                              setSelectedOption(e?.value);
                              fetchIndividualTeamdata(e?.value);
                            }}
                            menuPosition="fixed"
                            options={MarketList}
                            // isOptionDisabled={option => option.disabled}
                            classNamePrefix="select"
                            isSearchable={false}
                            // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            // menuPortalTarget={document.body}
                            // menuPosition={'fixed'}
                          /> */}
                        </Box>
                      </StickyTableCell>
                      {pageHeadingData?.map((id) => renderTableCell(id?.id))}
                      <TableCell className={classes.head}></TableCell>
                    </TableRow>
                  </TableHead>
                  {individualTeamData ? (
                    <TableBody className="individual-team-body">
                      <TableRow>
                        <StickyTableCell className="time-market">
                          {fetchDayName(individualTournamentData?.startTime)}{" "}
                          {moment
                            .utc(individualTournamentData?.startTime)
                            .local()
                            .format("DD/MM/YYYY")}{" "}
                          |{" "}
                          {moment
                            .utc(individualTournamentData?.startTime)
                            .local()
                            .format("hh:mm A")}{" "}
                          |{" "}
                          {`${
                            individualTournamentData?.marketLength
                              ? individualTournamentData?.marketLength
                              : 0
                          } Markets`}
                        </StickyTableCell>
                      </TableRow>
                      {fetchSeeAllOutRightsData(individualTeamData)}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={100}>
                          <Box style={{ textAlign: "center" }}>
                            {" "}
                            No Data Available
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {isLoadMoreTeam ? (
                <div className="allsport-loader-center ">
                  <Loader />
                </div>
              ) : individualTeamData.OutRightTeams?.length > 0 ? (
                <Box className="view-more">
                  {individualTeamData?.count >
                  individualTeamData?.OutRightTeams?.length ? (
                    <span
                      className="view-more-text"
                      onClick={() => {
                        setIsLoadMoreTeam(true);
                        seeAllloadMoreData(
                          individualTeamData?.OutRightTeams?.length,
                        );
                      }}
                    >
                      View More
                    </span>
                  ) : (
                    <></>
                  )}
                  {individualTeamData?.OutRightTeams?.length > 20 ? (
                    <span
                      className="view-more-text view-lese-text"
                      onClick={() => {
                        individualTeamData.OutRightTeams.splice(-20);
                        setLoad(!isLoad);
                      }}
                    >
                      View Less
                    </span>
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <></>
              )}
            </Box>
          )
        ) : (
          <>
            {isSeeAllEventLoading ? (
              <div className="allsport-loader-center ">
                <Loader />
              </div>
            ) : (
              <Box className="individual-table-mobileview outrights-table-mobileview">
                <Box className="individual-table-head">
                  <Box className="select-wrap">
                    {/* <Select
                      className="React oddstype"
                      value={MarketList?.find(item => {
                        return item?.value === selectedOption;
                      })}
                      onChange={e => {
                        setSelectedOption(e?.value);
                        fetchIndividualTeamdata(e?.value);
                      }}
                      options={MarketList}
                      // isOptionDisabled={option => option.disabled}
                      classNamePrefix="select"
                      isSearchable={false}
                      // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      // menuPortalTarget={document.body}
                      // menuPosition={'fixed'}
                    /> */}
                  </Box>
                  <Box className="current-best-head">
                    <Typography className="current-best-mobile">
                      Current Best
                    </Typography>
                    <Box className="see-best" onClick={() => handleSeeBest()}>
                      {" "}
                      See Best
                    </Box>
                  </Box>
                </Box>
                {individualTeamData ? (
                  <>
                    <Box className="date-markets-section">
                      {fetchDayName(individualTournamentData?.startTime)}{" "}
                      {moment
                        .utc(individualTournamentData?.startTime)
                        .local()
                        .format("DD/MM/YYYY")}{" "}
                      |{" "}
                      {moment
                        .utc(individualTournamentData?.startTime)
                        .local()
                        .format("hh:mm A")}{" "}
                      |{" "}
                      {`${
                        individualTournamentData?.marketLength
                          ? individualTournamentData?.marketLength
                          : 0
                      } Markets`}
                    </Box>

                    {fetchMobileViewSeeAllOutrightsTable(individualTeamData)}
                    {isLoadMoreTeam ? (
                      <div className="allsport-loader-center ">
                        <Loader />
                      </div>
                    ) : individualTeamData.OutRightTeams?.length > 0 ? (
                      <Box className="view-more">
                        {individualTeamData?.count >
                        individualTeamData?.OutRightTeams?.length ? (
                          <span
                            className="view-more-text"
                            onClick={() => {
                              setIsLoadMoreTeam(true);
                              seeAllloadMoreData(
                                individualTeamData?.OutRightTeams?.length,
                              );
                            }}
                          >
                            View More
                          </span>
                        ) : (
                          <></>
                        )}
                        {individualTeamData?.OutRightTeams?.length > 20 ? (
                          <span
                            className="view-more-text view-lese-text"
                            onClick={() => {
                              individualTeamData.OutRightTeams.splice(-20);
                              setLoad(!isLoad);
                            }}
                          >
                            View Less
                          </span>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Box style={{ textAlign: "center", padding: "10px" }}>
                    {" "}
                    No Data Available{" "}
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default OutRights;
