import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { useLocation, useNavigate, useParams } from "react-router";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import moment from "moment-timezone";
import { ReactComponent as LeftArrow } from "src/assets/images/left-arrow.svg";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import { ReactComponent as FirstRank } from "src/assets/images/icons/first_rank.svg";
import { ReactComponent as SecondRank } from "src/assets/images/icons/second_rank.svg";
import { ReactComponent as ThirdRank } from "src/assets/images/icons/third_rank.svg";
import { ReactComponent as SortingArrow } from "src/assets/images/icons/sorting-arrow.svg";
import playerAvtar from "../../../../../../assets/images/player-avatar.png";
import { Config } from "src/helpers/context";
import Loader from "src/components/Loader";
import Select from "react-select";
import _ from "lodash";
import "../../../../Home/HomeComponents/homeRanking.scss";
import "./landingTippingCompRanking.scss";

const StickyTableCell = withStyles((theme) => ({
  head: {
    left: 0,
    position: "sticky",
    zIndex: 98,
    minWidth: "338px",
    width: "338px",
    backgroundColor: "#ffffff",
    boxShadow: "inset -2px 0px 6px 0px #ebebeb",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px",
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px",
    },
  },
  body: {
    left: 0,
    position: "sticky",
    zIndex: 90,
    minWidth: "338px",
    width: "338px",
    backgroundColor: "#ffffff",
    boxShadow: "inset -2px 0px 6px 0px #ebebeb",
    "@media(max-width: 1090px)": {
      minWidth: "300px",
      width: "300px",
    },
    "@media(max-width: 479px)": {
      minWidth: "200px",
      width: "200px",
    },
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  head: {},
  cell: {},
}));

const LeadingHomeRanking = () => {
  const params = useParams();
  const location = useLocation();
  const searchParamsMenu = new URLSearchParams(location.search);
  const touranamentId = searchParamsMenu.get("touranamentId");
  const navigate = useNavigate();
  const classes = useStyles();
  const abortControllerRef = useRef(null);

  const [rankingList, setrankingList] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [roundDateInfo, setRoundDateInfo] = useState({});
  const [rankingCount, setrankingCount] = useState(0);
  const [rankingOffset, setrankingOffset] = useState(0);
  const [dummyStepperCount, setDummyStepperCount] = useState(0);
  // const [errorNoRound, setNoRoundError] = useState(false);
  const [sortType, setSortType] = useState("totalPoints");
  const [sortRound, setSortRound] = useState(true);
  const [sortOverallRound, setSortOverallRound] = useState(false);
  const [sortWinPoint, setSortWinPoint] = useState(true);
  const [loading, setLoading] = useState(true);
  const [newRoundDataOption, setNewRoundDataOption] = useState([]);
  const rowperPage = 10;

  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    getLastRound(params?.sportsId, touranamentId);
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [touranamentId]);

  const getLastRound = async (sId, tId) => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/getLastRound?SportId=${sId ? sId : ""}&tournamentId=${
          tId === "0" ? "" : tId
        }`,
      );
      if (status === 200) {
        // setLastRound(data?.result?.lastRound ? data?.result?.lastRound : 99);
        setNewRoundDataOption(
          data?.result?.roundList?.map((round) => ({
            value: round?.round,
            displayName: round?.displayName,
            label: `${
              round?.displayName
                ? round?.displayName
                : (sId === "4" ? "Day" : "Round") + " " + round?.round
            }`,
          })),
        );
        const finalRound = data?.result?.currentRound?.toString()
          ? data?.result?.currentRound
          : data?.result?.firstRound;
        setDummyStepperCount(finalRound);
        setSortRound(true);
        setSortOverallRound(false);
        setSortType("totalPoints");
        getRankingList(
          0,
          [],
          finalRound,
          false,
          tId,
          sId,
          "totalPoints",
          false,
        );
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getRankingList = async (
    offset,
    rankingLists,
    round,
    hasMore,
    tId,
    sId,
    type,
    order,
  ) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/tipping/ranking?offset=${offset}&SportId=${
          sId ? sId : ""
        }&tournamentId=${tId === "0" ? "" : tId}&limit=5&type=public&round=${Number(
          round,
        )}&sort=${type ? type : ""}&orderBy=${order ? "asc" : "desc"}`,
      );
      if (status === 200) {
        setLoading(false);
        setrankingOffset(offset);
        setrankingCount(data?.count);
        setCurrentUser(data?.currentUser);
        setRoundDateInfo(data?.roundInfo);
        const rankingDatas = hasMore
          ? [...rankingLists, ...data?.result]
          : data?.result;
        setrankingList(rankingDatas);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const sortLabelHandler = (type) => {
    setSortType(type);
    if (type === "roundPoints") {
      // getLadderList(ladderOffset, [], stepperCount, type, !sortRound);
      getRankingList(
        0,
        [],
        dummyStepperCount,
        false,
        touranamentId,
        params?.sportsId,
        type,
        !sortRound,
      );
      setSortRound(!sortRound);
      setSortOverallRound(true);
      setSortWinPoint(true);
    } else if (type === "totalPoints") {
      // getLadderList(ladderOffset, [], stepperCount, type, !sortOverallRound);
      getRankingList(
        0,
        [],
        dummyStepperCount,
        false,
        touranamentId,
        params?.sportsId,
        type,
        !sortOverallRound,
      );
      setSortRound(true);
      setSortOverallRound(!sortOverallRound);
      setSortWinPoint(true);
    } else {
      getRankingList(
        0,
        [],
        dummyStepperCount,
        false,
        touranamentId,
        params?.sportsId,
        type,
        !sortWinPoint,
      );
      setSortRound(true);
      setSortOverallRound(true);
      setSortWinPoint(!sortWinPoint);
    }
  };

  const startingEndingDate = (startDate, endDate) => {
    return (
      <span>
        : {moment(startDate).tz(timezone).format("dddd DD/MM/YYYY")} {"- "}
        {moment(endDate).tz(timezone).format("dddd DD/MM/YYYY")}
      </span>
    );
  };

  const handleNavigateToRanking = () => {
    navigate("/tipscompetition/public/rankings");
  };

  const handleSelectChange = (selectedOption) => {
    setDummyStepperCount(selectedOption.value);
    getRankingList(
      0,
      rankingList,
      selectedOption.value,
      false,
      touranamentId,
      params?.sportsId,
      "totalPoints",
      false,
    );
    setSortRound(true);
    setSortOverallRound(false);
    setSortType("totalPoints");
  };

  return (
    <>
      {newRoundDataOption?.length > 0 ? (
        <Box className="component-wrap home-ranking landing-home-ranking">
          <Box className="landing-header">
            <Typography variant="h3" className="component-header hander-text">
              Tipping Competitions (Rankings){" "}
            </Typography>
            {/* <Typography
              className="view-all-text"
              onClick={() => {
                handleNavigateToRanking();
              }}
            >
              View All <RightArrow />
            </Typography> */}
          </Box>

          <Box className="stepper-score-sec">
            <Box className="stepper-input">
              <Button
                className="stepper-input__button"
                onClick={(e) => {
                  // const index = rounds?.indexOf(stepperCount);
                  e.preventDefault();
                  const index = newRoundDataOption?.findIndex(
                    (item) => item?.value === dummyStepperCount,
                  );

                  setDummyStepperCount(newRoundDataOption[index - 1]?.value);
                  setSortRound(true);
                  setSortOverallRound(false);
                  setSortType("totalPoints");
                  getRankingList(
                    0,
                    rankingList,
                    newRoundDataOption[index - 1]?.value,
                    false,
                    touranamentId,
                    params?.sportsId,
                    "totalPoints",
                    false,
                  );
                }}
                disabled={
                  !dummyStepperCount ||
                  dummyStepperCount === newRoundDataOption[0]?.value
                    ? true
                    : false
                }
                startIcon={<LeftArrow />}
              >
                Previous
              </Button>

              <>
                <Select
                  value={newRoundDataOption?.find(
                    (item) => item?.value === dummyStepperCount,
                  )}
                  onChange={handleSelectChange}
                  options={newRoundDataOption}
                  className="React tipping-select"
                  classNamePrefix="select"
                  isSearchable={false}
                />
              </>
              <Button
                className="stepper-input__button Next-btn"
                onClick={(e) => {
                  e.preventDefault();
                  const index = newRoundDataOption?.findIndex(
                    (item) => item?.value === dummyStepperCount,
                  );
                  if (index - 1 !== -1) {
                    setDummyStepperCount(newRoundDataOption[index + 1]?.value);
                    setSortRound(true);
                    setSortOverallRound(false);
                    setSortType("totalPoints");
                    getRankingList(
                      0,
                      rankingList,
                      newRoundDataOption[index + 1]?.value,
                      false,
                      touranamentId,
                      params?.sportsId,
                      "totalPoints",
                      false,
                    );
                  }
                }}
                disabled={
                  dummyStepperCount == null ||
                  dummyStepperCount < 0 ||
                  dummyStepperCount ===
                    newRoundDataOption[newRoundDataOption?.length - 1]?.value
                    ? true
                    : false
                }
                endIcon={<RightArrow />}
              >
                Next
              </Button>
            </Box>
            {!loading && (
              <Box className="score-share-sec">
                <Box className="score-sec">
                  <Box className="bottom-line-sec">
                    <Typography className="final-txt">
                      {params?.sportsId == 4 ? "Day" : "Round"}{" "}
                      {dummyStepperCount?.toString()
                        ? Number(dummyStepperCount)
                        : ""}
                      {startingEndingDate(
                        roundDateInfo?.startOfRound,
                        roundDateInfo?.endOfRound,
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box className="page-deatils-wrap">
            <>
              <Box>
                {loading ? (
                  <div className="allsport-loader-center">
                    <Loader />
                  </div>
                ) : // dummyStepperCount !== 0 &&
                //   selectedOrgName !== "AFL" &&
                // dummyStepperCount > 0 &&
                // aflRoundZeroFlag &&
                dummyStepperCount !== null &&
                  rankingList &&
                  rankingList?.length > 0 ? (
                  <>
                    <Box className="home-rank-table-wrap">
                      <Box className="new-ladder-sec">
                        <Table
                          style={{ minWidth: 940 }}
                          className="new-ladder-table"
                        >
                          <TableHead>
                            <TableRow>
                              <StickyTableCell className={`${classes.head}`}>
                                <Table className="sticky-cell-table">
                                  <TableRow>
                                    <TableCell className="sticky-cell-table-pos primary-font-color">
                                      #Pos
                                    </TableCell>
                                    <TableCell className="primary-font-color">
                                      Tipster name
                                    </TableCell>
                                  </TableRow>
                                </Table>
                              </StickyTableCell>
                              {/* <TableCell
                              onClick={() => sortLabelHandler("winPoint")}
                              className="primary-font-color table-cell-center"
                            >
                              <Box className="sorting-cell">
                                Total Weekly Wins
                                <TableSortLabel
                                  active={true}
                                  direction={
                                    sortType === "winPoint"
                                      ? sortWinPoint
                                        ? "asc"
                                        : "desc"
                                      : "desc"
                                  }
                                />
                                <span className="sorting-arrow-wrap">
                                  <SortingArrow
                                    className={`${
                                      sortType == "winPoint" &&
                                      sortWinPoint == true
                                        ? "active"
                                        : ""
                                    }`}
                                  />
                                  <SortingArrow
                                    className={`${
                                      sortType == "winPoint" &&
                                      sortWinPoint == false
                                        ? "active"
                                        : ""
                                    } down-arrow`}
                                  />
                                </span>
                              </Box>
                            </TableCell> */}
                              <TableCell
                                onClick={() => sortLabelHandler("roundPoints")}
                                className="primary-font-color table-cell-center cursor-pointer"
                              >
                                <Box className="sorting-cell">
                                  {params?.sportsId == 4 ? "Day" : "Round"}{" "}
                                  Points{" "}
                                  {/* <TableSortLabel
                                active={true}
                                direction={
                                  sortType === "roundPoints"
                                    ? sortRound
                                      ? "asc"
                                      : "desc"
                                    : "desc"
                                }
                              /> */}
                                  <span className="sorting-arrow-wrap">
                                    <SortingArrow
                                      className={`${
                                        sortType == "roundPoints" &&
                                        sortRound == true
                                          ? "active"
                                          : ""
                                      }`}
                                    />
                                    <SortingArrow
                                      className={`${
                                        sortType == "roundPoints" &&
                                        sortRound == false
                                          ? "active"
                                          : ""
                                      } down-arrow`}
                                    />
                                  </span>
                                </Box>
                              </TableCell>
                              <TableCell className="primary-font-color table-cell-center">
                                {params?.sportsId == 4 ? "Day" : "Round"} Margin
                              </TableCell>
                              <TableCell
                                className="primary-font-color table-cell-center cursor-pointer"
                                onClick={() => sortLabelHandler("totalPoints")}
                              >
                                <Box className="sorting-cell">
                                  Overall Points{" "}
                                  {/* <TableSortLabel
                                active={true}
                                direction={
                                  sortType === "totalPoints"
                                    ? sortOverallRound
                                      ? "asc"
                                      : "desc"
                                    : "desc"
                                }
                              /> */}
                                  <span className="sorting-arrow-wrap">
                                    <SortingArrow
                                      className={`${
                                        sortType == "totalPoints" &&
                                        sortOverallRound == true
                                          ? "active"
                                          : ""
                                      }`}
                                    />
                                    <SortingArrow
                                      className={`${
                                        sortType == "totalPoints" &&
                                        sortOverallRound == false
                                          ? "active"
                                          : ""
                                      } down-arrow`}
                                    />
                                  </span>
                                </Box>
                              </TableCell>
                              <TableCell className="primary-font-color table-cell-center">
                                Overall Margin
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {currentUser && (
                              <TableRow className="current-user-row">
                                <StickyTableCell
                                  className={`${classes.cell} current-user-stickycell`}
                                >
                                  <Table className="sticky-cell-table">
                                    <TableRow>
                                      <TableCell className="highlight-border sticky-cell-table-pos">
                                        {currentUser?.rank == 1 ? (
                                          <FirstRank />
                                        ) : currentUser?.rank == 2 ? (
                                          <SecondRank />
                                        ) : currentUser?.rank == 3 ? (
                                          <ThirdRank />
                                        ) : (
                                          <span className="rank-name">
                                            {currentUser?.rank}
                                          </span>
                                        )}
                                      </TableCell>
                                      <TableCell className="highlight-border">
                                        <Box className="tipper-img-wrap">
                                          <Box
                                            className={
                                              currentUser?.filePath
                                                ? "profile-icon-wrap icon-border"
                                                : "profile-icon-wrap"
                                            }
                                          >
                                            <img
                                              src={
                                                currentUser?.filePath
                                                  ? Config?.countryMediaURL +
                                                    currentUser?.filePath
                                                  : playerAvtar
                                              }
                                              alt="avtar"
                                              className="profile-icon"
                                            />
                                          </Box>
                                          <span>
                                            {currentUser?.firstName}{" "}
                                            {currentUser?.lastName}
                                          </span>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </StickyTableCell>
                                {/* <TableCell className="table-cell-center highlight-border">
                                {currentUser?.roundData?.winPoint ?? "-"}
                              </TableCell> */}
                                <TableCell className="table-cell-center highlight-border">
                                  {currentUser?.roundData?.roundPoints ?? "-"}
                                </TableCell>
                                <TableCell className="table-cell-center highlight-border">
                                  {currentUser?.roundData?.margin ?? "-"}
                                </TableCell>
                                <TableCell className="table-cell-center highlight-border">
                                  {currentUser?.roundData?.totalPoints ?? "-"}
                                </TableCell>
                                <TableCell className="table-cell-center highlight-border">
                                  {currentUser?.roundData?.totalMargin ?? "-"}
                                </TableCell>
                              </TableRow>
                            )}
                            {rankingList?.map((item, index) => {
                              return (
                                <TableRow key={index}>
                                  <StickyTableCell
                                    className={`${classes.cell}`}
                                  >
                                    <Table className="sticky-cell-table">
                                      <TableRow>
                                        <TableCell className="sticky-cell-table-pos">
                                          {item?.rank ? (
                                            item?.rank == 1 ? (
                                              <FirstRank />
                                            ) : item?.rank == 2 ? (
                                              <SecondRank />
                                            ) : item?.rank == 3 ? (
                                              <ThirdRank />
                                            ) : (
                                              <span className="rank-name">
                                                {item?.rank}
                                              </span>
                                            )
                                          ) : (
                                            <span>{index + 1}</span>
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          <Box className="tipper-img-wrap">
                                            <Box
                                              className={
                                                item?.filePath
                                                  ? "profile-icon-wrap icon-border"
                                                  : "profile-icon-wrap"
                                              }
                                            >
                                              <img
                                                src={
                                                  item?.filePath
                                                    ? Config?.countryMediaURL +
                                                      item?.filePath
                                                    : playerAvtar
                                                }
                                                alt="avtar"
                                                className="profile-icon"
                                              />
                                            </Box>
                                            <span>
                                              {item?.firstName} {item?.lastName}
                                            </span>
                                          </Box>
                                        </TableCell>
                                      </TableRow>
                                    </Table>
                                  </StickyTableCell>
                                  {/* <TableCell className="table-cell-center">
                                  {item?.roundData?.winPoint ?? "-"}
                                </TableCell> */}
                                  <TableCell className="table-cell-center">
                                    {item?.roundData?.roundPoints ?? "-"}
                                  </TableCell>
                                  <TableCell className="table-cell-center">
                                    {item?.roundData?.margin ?? "-"}
                                  </TableCell>
                                  <TableCell className="table-cell-center">
                                    {item?.roundData?.totalPoints ?? "-"}
                                  </TableCell>
                                  <TableCell className="table-cell-center">
                                    {item?.roundData?.totalMargin ?? "-"}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Box>
                      {rankingCount > 5 ? (
                        <Box className="view-more">
                          <span
                            className="view-more-text"
                            onClick={() => handleNavigateToRanking()}
                          >
                            View All
                          </span>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </>
                ) : (
                  <Box
                    style={{
                      textAlign: "center",
                    }}
                    className="no-data"
                  >
                    No Data Available
                  </Box>
                )}
              </Box>
            </>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default LeadingHomeRanking;
