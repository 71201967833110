import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./homeLandingRight.scss";
import LandingICCRankingDetails from "./landingICCRankingDetails";
import LandingTableDetails from "./landingTableDetails";
import LandingFixturesResultDetails from "./landingFixturesResultsDetails";
import { useNavigate } from "react-router";

const HomeLandingRight = () => {
  const navigate = useNavigate();
  const [lastUpdated, setLastUpdated] = useState("");

  const handleNavigateTable = () => {
    navigate("/teamsports/cricket/odds/0/false");
  };

  const handleNavigateFixtures = () => {
    navigate("/teamsports/cricket/odds/0/false");
  };

  return (
    <>
      <Box className="landing-home-right-wrap">
        <Box className="right-side-wrap">
          <Box className="right-side-title-wrap">
            <Typography className="right-side-title">ICC Rankings </Typography>
            <Typography className="right-updated-text">
              Last updated: {lastUpdated ? lastUpdated : null}
            </Typography>
          </Box>
          <Box>
            <LandingICCRankingDetails setLastUpdated={setLastUpdated} />
          </Box>
        </Box>
        <Box className="right-side-wrap">
          <Box className="right-side-title-wrap">
            <Typography className="right-side-title">Table</Typography>
            <Typography
              className="view-all-text"
              onClick={() => {
                handleNavigateTable();
              }}
            >
              <span className="text-underline">View all</span>
              <ChevronRightIcon />
            </Typography>
          </Box>
          <Box>
            <LandingTableDetails />
          </Box>
        </Box>
        <Box className="right-side-wrap">
          <Box className="right-side-title-wrap">
            <Typography className="right-side-title">
              Fixtures & Results{" "}
            </Typography>
            <Typography
              className="view-all-text"
              onClick={() => {
                handleNavigateFixtures();
              }}
            >
              <span className="text-underline">View all</span>
              <ChevronRightIcon />
            </Typography>
          </Box>
          <Box>
            <LandingFixturesResultDetails />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomeLandingRight;
