import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Button,
  ClickAwayListener
} from "@mui/material";
import moment from "moment-timezone";
import { IntlContext } from "src/App";
import { ReactComponent as Batting } from "../../../../../assets/images/SportIcon/bat.svg";
import { ReactComponent as Bowling } from "../../../../../assets/images/SportIcon/ball.svg";
import { ReactComponent as WinnerIcon } from "../../../../../assets/images/icons/winner-cup.svg";
import { ReactComponent as WinArrow } from "../../../../../assets/images/icons/redWinArrow.svg";
const Score = ({
  item,
  teamSportType,
  fetchCurrentBestOdds,
  fetchCurrentBestsOddsIcon,
  sponsoredId,
  fetchSponsoredOddsScore,
  fetchSponsoredOddsHome,
  fetchSponsoredOddsAway,
  fetchDayName,
  scoreData,
  MarketName,
  showOdds,
  handleOpenTooltip,
  index,
  handleOutsideClick,
  fetchTeamlogo,
  IsHomeCurrentBestAvailable,
  IsAwayCurrentBestAvailable
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;

  useEffect(() => {
    window.addEventListener("resize", function() {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const fetchScoreHeader = (scoreData, teamId) => {
    const team = scoreData?.filter(item => item?.teamId === teamId);
    const teamScore =
      teamSportType === "cricket" || teamSportType === "soccer"
        ? scoreData?.[0]
        : team?.[0]?.score;
    return (
      <>
        {/* {teamSportType !== "baseball" ? (
          <> */}
        {teamSportType === "tennis" && (
          <>
            {/* {(teamScore?.period1 || teamScore?.period1 === 0) && (
              <TableCell className="cell-center cell-score-dark dark-bottom-border">
                1
              </TableCell>
            )}
            {(teamScore?.period2 || teamScore?.period2 === 0) && (
              <TableCell className="cell-center cell-score-dark dark-bottom-border">
                2
              </TableCell>
            )}
            {(teamScore?.period3 || teamScore?.period3 === 0) && (
              <TableCell className="cell-center cell-score-dark dark-bottom-border">
                3
              </TableCell>
            )}
            {(teamScore?.period4 || teamScore?.period4 === 0) && (
              <TableCell className="cell-center cell-score-dark dark-bottom-border">
                4
              </TableCell>
            )}
            {(teamScore?.period5 || teamScore?.period5 === 0) && (
              <TableCell className="cell-center cell-score-dark dark-bottom-border">
                5
              </TableCell>
            )}
            {(teamScore?.period6 || teamScore?.period6 === 0) && (
              <TableCell className="cell-center cell-score-dark dark-bottom-border">
                6
              </TableCell>
            )}
            {(teamScore?.period7 || teamScore?.period7 === 0) && (
              <TableCell className="cell-center cell-score-dark dark-bottom-border">
                7
              </TableCell>
            )}
            {(teamScore?.period8 || teamScore?.period8 === 0) && (
              <TableCell className="cell-center cell-score-dark dark-bottom-border">
                8
              </TableCell>
            )}
            {(teamScore?.period9 || teamScore?.period9 === 0) && (
              <TableCell className="cell-center cell-score-dark dark-bottom-border">
                9
              </TableCell>
            )} */}
            {teamSportType === "tennis" && (
              <>
                <TableCell
                  className={`cell-center ${
                    item?.status === "finished"
                      ? "result-header"
                      : "cell-score-dark  dark-bottom-border"
                  }`}
                >
                  Sets
                </TableCell>
                <TableCell
                  className={`cell-center ${
                    item?.status === "finished"
                      ? "result-header"
                      : "cell-score-dark dark-bottom-border"
                  }`}
                >
                  Games
                </TableCell>
                {(teamScore?.point || teamScore?.point === 0) && (
                  <TableCell
                    className={`cell-center ${
                      item?.status === "finished"
                        ? "result-header"
                        : "cell-score-dark dark-bottom-border dark-right-border"
                    }`}
                  >
                    Point
                  </TableCell>
                )}
              </>
            )}
          </>
        )}

        {teamSportType !== "tennis" ? (
          screenWidth >= 1023 ? (
            (teamScore?.current || teamScore?.current === 0) && (
              <TableCell
                className={`${
                  item?.status === "finished"
                    ? "result-header"
                    : "cell-score-dark dark-right-border dark-bottom-border"
                }  sport-score`}
              >
                {item?.status === "finished" ? "Results" : "Scores"}
              </TableCell>
            )
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        {teamSportType === "cricket" ? (
          screenWidth >= 1023 ? (
            <TableCell
              className={`${
                teamScore?.Epr === 1
                  ? "cell-score-dark dark-bottom-border"
                  : "result-header"
              } sport-score`}
              // style={{
              //   width: sponsoredId?.length > 0 ? "25%" : "auto"
              // }}
            >
              {teamScore?.Epr === 1 ? `Scores` : `Results`}
            </TableCell>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {teamSportType === "soccer" ? (
          screenWidth >= 1023 ? (
            <TableCell
              className={`${
                teamScore?.Epr === 1
                  ? "cell-score-dark dark-bottom-border"
                  : "result-header"
              } sport-score`}
              // style={{
              //   width: sponsoredId?.length > 0 ? "25%" : "auto"
              // }}
            >
              {teamScore?.Epr === 1 ? `Scores` : `Results`}
            </TableCell>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {/* </>
        ) : (
          <>
            {teamScore?.innings?.inning1 && <TableCell>1</TableCell>}
            {teamScore?.innings?.inning2 && <TableCell>2</TableCell>}
            {teamScore?.innings?.inning3 && <TableCell>3</TableCell>}
            {teamScore?.innings?.inning4 && <TableCell>4 </TableCell>}
            {teamScore?.innings?.inning5 && <TableCell>5</TableCell>}
            {teamScore?.innings?.inning6 && <TableCell>6</TableCell>}
            {teamScore?.innings?.inning7 && <TableCell>7</TableCell>}
            {teamScore?.innings?.inning8 && <TableCell>8</TableCell>}
            {teamScore?.innings?.inning9 && <TableCell>9</TableCell>}
            {(teamScore?.current || teamScore?.current === 0) && (
              <TableCell>R</TableCell>
            )}
            {teamSportType === "baseball" && teamScore?.inningsBaseball && (
              <>
                <TableCell>H</TableCell>
                <TableCell>E</TableCell>
              </>
            )}
          </>
        )} */}
      </>
    );
  };

  const fetchteamscore = (scoreData, teamId, teamType, teamData) => {
    const team = scoreData?.filter(item => item?.teamId === teamId);
    const teamScore =
      teamSportType === "cricket" || teamSportType === "soccer"
        ? scoreData?.[0]
        : team?.[0]?.score;
    return (
      <>
        {/* {teamSportType !== "baseball" ? (
          <> */}
        {teamSportType === "tennis" && (
          <>
            {/* {(teamScore?.period1 || teamScore?.period1 === 0) && (
              <TableCell className="cell-center cell-score-dark ">
                {teamScore?.period1}
                {teamScore?.period1TieBreak ? teamScore?.period1TieBreak : ""}
              </TableCell>
            )}
            {(teamScore?.period2 || teamScore?.period2 === 0) && (
              <TableCell className="cell-center cell-score-dark ">
                {teamScore?.period2}
                {teamScore?.period2TieBreak ? teamScore?.period2TieBreak : ""}
              </TableCell>
            )}
            {(teamScore?.period3 || teamScore?.period3 === 0) && (
              <TableCell className="cell-center cell-score-dark ">
                {teamScore?.period3}
                {teamScore?.period3TieBreak ? teamScore?.period3TieBreak : ""}
              </TableCell>
            )}
            {(teamScore?.period4 || teamScore?.period4 === 0) && (
              <TableCell className="cell-center cell-score-dark ">
                {teamScore?.period4}
                {teamScore?.period4TieBreak ? teamScore?.period4TieBreak : ""}
              </TableCell>
            )}
            {(teamScore?.period5 || teamScore?.period5 === 0) && (
              <TableCell className="cell-center cell-score-dark ">
                {teamScore?.period5}
                {teamScore?.period5TieBreak ? teamScore?.period5TieBreak : ""}
              </TableCell>
            )}
            {(teamScore?.period6 || teamScore?.period6 === 0) && (
              <TableCell className="cell-center cell-score-dark ">
                {teamScore?.period6}
                {teamScore?.period6TieBreak ? teamScore?.period6TieBreak : ""}
              </TableCell>
            )}
            {(teamScore?.period7 || teamScore?.period7 === 0) && (
              <TableCell className="cell-center cell-score-dark ">
                {teamScore?.period7}
                {teamScore?.period7TieBreak ? teamScore?.period7TieBreak : ""}
              </TableCell>
            )}
            {(teamScore?.period8 || teamScore?.period8 === 0) && (
              <TableCell className="cell-center cell-score-dark ">
                {teamScore?.period8}
                {teamScore?.period8TieBreak ? teamScore?.period8TieBreak : ""}
              </TableCell>
            )}
            {(teamScore?.period9 || teamScore?.period9 === 0) && (
              <TableCell className="cell-center cell-score-dark">
                {teamScore?.period9}
                {teamScore?.period9TieBreak ? teamScore?.period9TieBreak : ""}
              </TableCell>
            )} */}
            {teamSportType === "tennis" && (
              <>
                <TableCell
                  className={`cell-center   ${
                    item?.status === "finished"
                      ? "result-body"
                      : "cell-score-dark"
                  }`}
                >
                  {teamScore?.current}
                </TableCell>
                <TableCell
                  className={`cell-center   ${
                    item?.status === "finished"
                      ? "result-body"
                      : "cell-score-dark"
                  }`}
                >
                  {teamScore?.normaltime}
                </TableCell>
                {(teamScore?.point || teamScore?.point === 0) && (
                  <TableCell
                    className={`cell-center   ${
                      item?.status === "finished"
                        ? "result-body dark-right-border"
                        : "cell-score-dark dark-right-border"
                    }`}
                  >
                    {teamScore?.point}
                  </TableCell>
                )}
              </>
            )}
          </>
        )}
        {teamSportType !== "tennis" ? (
          (teamScore?.current || teamScore?.current === 0) && (
            <TableCell
              className={`cell-center ${
                item?.status == "finished"
                  ? "result-body dark-right-border"
                  : "cell-score-dark dark-right-border"
              } score-cell`}
            >
              {" "}
              <Box className="icon-score-wrap">
                <span className="win-icon">
                  {item?.winnerCode ? (
                    (teamType == "hometeam" && item?.winnerCode === 1) ||
                    (teamType == "awayteam" && item?.winnerCode === 2) ? (
                      <WinArrow />
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </span>
                <span
                  className={
                    (teamType == "hometeam" && item?.winnerCode === 2) ||
                    (teamType == "awayteam" && item?.winnerCode === 1)
                      ? "lose-team"
                      : ""
                  }
                >
                  {teamScore?.current}
                </span>
              </Box>
              <Box className="score-team">
                {teamType == "hometeam"
                  ? teamData?.homeTeam?.name
                  : teamData?.awayTeam?.name}
              </Box>
            </TableCell>
          )
        ) : (
          <></>
        )}

        {teamSportType === "cricket" ? (
          teamType === "hometeam" ? (
            <TableCell
              className={`cell-center score-cell score-50 ${
                teamScore?.Epr === 1
                  ? "cell-score-dark dark-right-border"
                  : "result-body dark-right-border"
              }`}
            >
              {teamScore?.Epr == 1 &&
              teamScore?.Tr1C1 == null &&
              teamScore?.Ebat == 2 ? (
                <Box>
                  Yet to bat
                  <Box className="score-team">
                    {teamType == "hometeam"
                      ? teamData?.homeTeam?.name
                      : teamData?.awayTeam?.name}
                  </Box>
                </Box>
              ) : teamScore?.Exd > "1" ? (
                <>
                  <Box className="icon-score-wrap">
                    <span className="win-icon">
                      {item?.winnerCode ? (
                        (teamType == "hometeam" && item?.winnerCode === 1) ||
                        (teamType == "awayteam" && item?.winnerCode === 2) ? (
                          <WinArrow />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </span>
                    <Typography
                      className={
                        (teamType == "hometeam" && item?.winnerCode === 2) ||
                        (teamType == "awayteam" && item?.winnerCode === 1)
                          ? "lose-team team-score"
                          : "team-score"
                      }
                    >
                      {teamScore?.Tr1C1 ?? "-"}
                      {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
                        ? ""
                        : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
                          `/${teamScore?.Tr1CW1}${
                            teamScore?.Tr1CD1 && teamScore?.Tr1CD1 === 1
                              ? "d"
                              : ""
                          }`}{" "}
                      {(teamScore?.Tr1C2 || teamScore?.Tr1C2 === 0) &&
                        `& ${teamScore?.Tr1C2}`}
                      {teamScore?.Tr1CW2 && teamScore?.Tr1CW2 === 10
                        ? ""
                        : (teamScore?.Tr1CW2 || teamScore?.Tr1CW2 === 0) &&
                          `/${teamScore?.Tr1CW2}${
                            teamScore?.Tr1CD2 && teamScore?.Tr1CD2 === 1
                              ? "d"
                              : ""
                          }`}{" "}
                    </Typography>
                  </Box>
                  <Box className="score-team">
                    {teamType == "hometeam"
                      ? teamData?.homeTeam?.name
                      : teamData?.awayTeam?.name}
                  </Box>
                </>
              ) : (
                <>
                  <Box className="icon-score-wrap">
                    <span className="win-icon">
                      {item?.winnerCode ? (
                        (teamType == "hometeam" && item?.winnerCode === 1) ||
                        (teamType == "awayteam" && item?.winnerCode === 2) ? (
                          <WinArrow />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </span>

                    <Typography
                      className={
                        (teamType == "hometeam" && item?.winnerCode === 2) ||
                        (teamType == "awayteam" && item?.winnerCode === 1)
                          ? "lose-team team-score"
                          : "team-score"
                      }
                    >
                      {teamScore?.Tr1C1 ?? "-"}
                      {teamScore?.Tr1CW1 && teamScore?.Tr1CW1 === 10
                        ? ""
                        : (teamScore?.Tr1CW1 || teamScore?.Tr1CW1 === 0) &&
                          `/ ${teamScore?.Tr1CW1}`}{" "}
                      <span>
                        {" "}
                        {teamScore?.Tr1CO1 ? `(${teamScore?.Tr1CO1})` : ""}
                      </span>{" "}
                    </Typography>
                  </Box>
                  <Box className="score-team">
                    {teamType == "hometeam"
                      ? teamData?.homeTeam?.name
                      : teamData?.awayTeam?.name}
                  </Box>
                </>
              )}
            </TableCell>
          ) : (
            <TableCell
              className={`cell-center score-50 score-cell ${
                teamScore?.Epr === 1 ? "cell-score-dark" : "result-body"
              }`}
            >
              {teamScore?.Epr == 1 &&
              teamScore?.Tr2C1 == null &&
              teamScore?.Ebat == 1 ? (
                <Box>
                  Yet to bat
                  <Box className="score-team">
                    {teamType == "hometeam"
                      ? teamData?.homeTeam?.name
                      : teamData?.awayTeam?.name}
                  </Box>
                </Box>
              ) : teamScore?.Exd > "1" ? (
                <>
                  <Box className="icon-score-wrap">
                    <span className="win-icon">
                      {item?.winnerCode ? (
                        (teamType == "hometeam" && item?.winnerCode === 1) ||
                        (teamType == "awayteam" && item?.winnerCode === 2) ? (
                          <WinArrow />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </span>
                    <Typography
                      className={
                        (teamType == "hometeam" && item?.winnerCode === 2) ||
                        (teamType == "awayteam" && item?.winnerCode === 1)
                          ? "lose-team team-score"
                          : "team-score"
                      }
                    >
                      {teamScore?.Tr2C1 ?? "-"}
                      {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
                        ? ""
                        : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
                          `/${teamScore?.Tr2CW1}${
                            teamScore?.Tr2CD1 && teamScore?.Tr2CD1 === 1
                              ? "d"
                              : ""
                          }`}{" "}
                      {(teamScore?.Tr2C2 || teamScore?.Tr2C2 === 0) &&
                        `& ${teamScore?.Tr2C2}`}
                      {teamScore?.Tr2CW2 && teamScore?.Tr2CW2 === 10
                        ? ""
                        : (teamScore?.Tr2CW2 || teamScore?.Tr2CW2 === 0) &&
                          `/${teamScore?.Tr2CW2}${
                            teamScore?.Tr2CD2 && teamScore?.Tr2CD2 === 1
                              ? "d"
                              : ""
                          }`}{" "}
                    </Typography>
                  </Box>
                  <Box className="score-team">
                    {teamType == "hometeam"
                      ? teamData?.homeTeam?.name
                      : teamData?.awayTeam?.name}
                  </Box>
                </>
              ) : (
                <>
                  <Box className="icon-score-wrap">
                    <span className="win-icon">
                      {item?.winnerCode ? (
                        (teamType == "hometeam" && item?.winnerCode === 1) ||
                        (teamType == "awayteam" && item?.winnerCode === 2) ? (
                          <WinArrow />
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </span>
                    <Typography
                      className={
                        (teamType == "hometeam" && item?.winnerCode === 2) ||
                        (teamType == "awayteam" && item?.winnerCode === 1)
                          ? "lose-team team-score"
                          : "team-score"
                      }
                    >
                      {teamScore?.Tr2C1 ?? "-"}
                      {teamScore?.Tr2CW1 && teamScore?.Tr2CW1 === 10
                        ? ""
                        : (teamScore?.Tr2CW1 || teamScore?.Tr2CW1 === 0) &&
                          `/ ${teamScore?.Tr2CW1}`}{" "}
                      <span>
                        {teamScore?.Tr2CO1 ? `(${teamScore?.Tr2CO1})` : ""}
                      </span>
                    </Typography>
                  </Box>
                  <Box className="score-team">
                    {teamType == "hometeam"
                      ? teamData?.homeTeam?.name
                      : teamData?.awayTeam?.name}
                  </Box>
                </>
              )}
            </TableCell>
          )
        ) : (
          <></>
        )}

        {teamSportType === "soccer" ? (
          <TableCell
            className={`cell-center score-50 score-cell ${
              teamScore?.Epr === 1
                ? "cell-score-dark dark-right-border"
                : "result-body dark-right-border"
            }`}
          >
            <Box className="icon-score-wrap">
              <span className="win-icon">
                {item?.winnerCode ? (
                  (teamType == "hometeam" && item?.winnerCode === 1) ||
                  (teamType == "awayteam" && item?.winnerCode === 2) ? (
                    <WinArrow />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
              </span>{" "}
              <span
                className={
                  (teamType == "hometeam" && item?.winnerCode === 2) ||
                  (teamType == "awayteam" && item?.winnerCode === 1)
                    ? "lose-team team-score"
                    : "team-score"
                }
              >
                {teamType === "hometeam" ? teamScore?.Tr1 : teamScore?.Tr2}
              </span>
            </Box>
            <Box className="score-team">
              {teamType == "hometeam"
                ? teamData?.homeTeam?.name
                : teamData?.awayTeam?.name}
            </Box>
          </TableCell>
        ) : (
          <></>
        )}

        {/* </>
        ) : (
          <>
            {teamScore?.innings?.inning1 && (
              <TableCell>{teamScore?.innings?.inning1?.run}</TableCell>
            )}
            {teamScore?.innings?.inning2 && (
              <TableCell>{teamScore?.innings?.inning2?.run}</TableCell>
            )}
            {teamScore?.innings?.inning3 && (
              <TableCell> {teamScore?.innings?.inning3?.run}</TableCell>
            )}
            {teamScore?.innings?.inning4 && (
              <TableCell> {teamScore?.innings?.inning4?.run}</TableCell>
            )}
            {teamScore?.innings?.inning5 && (
              <TableCell> {teamScore?.innings?.inning5?.run}</TableCell>
            )}
            {teamScore?.innings?.inning6 && (
              <TableCell> {teamScore?.innings?.inning6?.run}</TableCell>
            )}
            {teamScore?.innings?.inning7 && (
              <TableCell> {teamScore?.innings?.inning7?.run}</TableCell>
            )}
            {teamScore?.innings?.inning8 && (
              <TableCell> {teamScore?.innings?.inning8?.run}</TableCell>
            )}
            {teamScore?.innings?.inning9 && (
              <TableCell> {teamScore?.innings?.inning9?.run}</TableCell>
            )}
            {(teamScore?.current || teamScore?.current === 0) && (
              <TableCell>{teamScore?.current}</TableCell>
            )}
            {teamSportType === "baseball" && teamScore?.inningsBaseball && (
              <>
                <TableCell>{teamScore?.inningsBaseball?.hits}</TableCell>
                <TableCell>{teamScore?.inningsBaseball?.errors}</TableCell>
              </>
            )}
          </>
        )} */}
      </>
    );
  };
  const fetchIndicator = (teamId, teamType) => {
    // const team = scoreData?.filter(item => item?.teamId === teamId);
    return teamSportType === "cricket" ? (
      <>
        {teamType === "hometeam" ? (
          scoreData?.[0]?.Ebat == 1 ? (
            <span className="score-icon">
              <Batting />
            </span>
          ) : (
            <span className="score-icon">
              <Bowling />
            </span>
          )
        ) : teamType === "awayteam" ? (
          scoreData?.[0]?.Ebat == 1 ? (
            <span className="score-icon">
              <Bowling />
            </span>
          ) : (
            <span className="score-icon">
              <Batting />
            </span>
          )
        ) : (
          <></>
        )}

        {/* {(teamType === "hometeam" && scoreData?.[0]?.Ebat == 1) ||
        (teamType === "awayteam" && scoreData?.[0]?.Ebat == 2) ? (
          <span className="score-icon">
            <Batting />
          </span>
        ) : (
          <span className="score-icon">
            <Bowling />
          </span>
        )} */}
      </>
    ) : (
      <></>
    );
  };
  const fetchWinIndicator = (scoreData, teamcode) => {
    return item?.winnerCode === teamcode ? (
      <span className="score-icon">
        <WinnerIcon />
      </span>
    ) : (
      <></>
    );
  };
  const fetchIsNoOdds = data => {
    let allTeamOdds =
      teamSportType === "cricket"
        ? data?.CricketBetOffers?.[0]?.CricketOdds
        : teamSportType === "basketball"
        ? data?.NBABetOffers?.[0]?.NBAOdds
        : teamSportType === "americanfootball"
        ? data?.AFLBetOffers?.[0]?.AFLOdds
        : teamSportType === "australianrules"
        ? data?.ARBetOffers?.[0]?.AROdds
        : teamSportType === "golf"
        ? data?.GolfBetOffers?.[0]?.GolfOdds
        : teamSportType === "tennis"
        ? data?.TennisBetOffers?.[0]?.TennisOdds
        : teamSportType === "baseball"
        ? data?.BaseballBetOffers?.[0]?.BaseballOdds
        : teamSportType === "icehockey"
        ? data?.IceHockeyBetOffers?.[0]?.IceHockeyOdds
        : teamSportType === "boxing"
        ? data?.BoxingBetOffers?.[0]?.BoxingOdds
        : teamSportType === "mma"
        ? data?.MMABetOffers?.[0]?.MMAOdds
        : teamSportType === "soccer"
        ? data?.SoccerBetOffers?.[0]?.SoccerOdds
        : data?.RLBetOffers?.[0]?.RLOdds;
    if (allTeamOdds && allTeamOdds?.length > 0) {
      return "";
    } else {
      return "noodds-score";
    }
  };
  return (
    <>
      {screenWidth > 1023 ? (
        <Box className="live-section">
          <Table className="team-sport-table combat-sport-table-live">
            <TableHead>
              <TableRow>
                <TableCell
                // className={showOdds ? "border-right" : ""}
                >
                  <Box className="combat-sport-indicator-live">
                    <Box
                      className={
                        ((item?.SportId === 4 || item?.SportId === 8) &&
                          scoreData?.[0]?.Epr === 1) ||
                        (item?.status && item?.status === "inprogress")
                          ? `team-time-market live-indicator-wrap`
                          : `team-time-market`
                      }
                    >
                      {((item?.SportId === 4 || item?.SportId === 8) &&
                        scoreData?.[0]?.Epr === 1) ||
                      (item?.status && item?.status === "inprogress") ? (
                        <span className="live-indicator">LIVE</span>
                      ) : (
                        <></>
                      )}
                      <span className="sport-indicator-btn">
                        {teamSportType === "cricket"
                          ? item?.CricketTournament?.name
                          : teamSportType === "rugbyleague" ||
                            teamSportType === "rugbyunion" ||
                            teamSportType === "rugbyunionsevens"
                          ? item?.RLTournament?.name
                          : teamSportType === "americanfootball"
                          ? item?.AFLTournament?.name
                          : teamSportType === "australianrules"
                          ? item?.ARTournament?.name
                          : teamSportType === "golf"
                          ? item?.GolfTournament?.name
                          : teamSportType === "tennis"
                          ? item?.TennisTournament?.name
                          : teamSportType === "baseball"
                          ? item?.BaseballTournament?.name
                          : teamSportType === "icehockey"
                          ? item?.IceHockeyTournament?.name
                          : teamSportType === "boxing"
                          ? item?.BoxingTournament?.name
                          : teamSportType === "mma"
                          ? item?.MMATournament?.name
                          : teamSportType === "soccer"
                          ? item?.SoccerTournament?.name
                          : teamSportType === "basketball"
                          ? item?.NBATournament?.NBACategory === null
                            ? item?.NBATournament?.name
                            : item?.NBATournament?.name +
                              " " +
                              item?.NBATournament?.NBACategory?.name
                          : ""}
                      </span>
                    </Box>
                  </Box>
                </TableCell>

                {/* <TableCell
                  style={{
                    width: sponsoredId?.length > 0 ? "25%" : "auto"
                  }}
                  className={"table-head table-head-after"}
                >
                  Scores
                </TableCell> */}
                {fetchScoreHeader(scoreData, item?.homeTeamId)}

                {showOdds && IsHomeCurrentBestAvailable ? (
                  <TableCell
                    className={`table-head table-current ${
                      sponsoredId?.length > 0 ? "table-head-after" : ""
                    } `}
                  >
                    {scoreData?.[0]?.Epr === 1 ||
                    (item?.status && item?.status === "inprogress")
                      ? localesData?.sport?.CURRENT_BEST
                      : localesData?.RUNNER_TABLE?.BEST_AT_CLOSE}
                  </TableCell>
                ) : (
                  <></>
                )}

                {/* {sponsoredId?.length > 0 ? (
                  <>
                    {showOdds ? (
                      <TableCell
                        colSpan={2}
                        className="table-head table-sponsored-head"
                      >
                        <Typography className="sponsored-head-to-head">
                          {localesData?.sport?.SPONSORED}
                        </Typography>
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )} */}
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                <TableRow
                  className={
                    showOdds
                      ? " teamsports-tablerow hometeam-row"
                      : "show-teamsports-tablerow teamsports-tablerow hometeam-row"
                  }
                >
                  <TableCell className={showOdds ? "team-wrap" : "team-wrap"}>
                    <Box className="team-d-flex team-align-center">
                      <span className="team-img-wrap">
                        {fetchTeamlogo(item, "hometeam")}
                      </span>
                      <span className="team-name ">
                        {item?.homeTeam?.name ? item?.homeTeam?.name : ""}
                      </span>
                    </Box>
                    {item?.SportId === 4 &&
                      scoreData?.[0]?.Epr === 1 &&
                      fetchIndicator(item?.homeTeamId, "hometeam")}
                    {(item?.SportId === 4 || item?.SportId === 8) &&
                    item?.ScoreBoard?.Epr === 2
                      ? fetchWinIndicator(scoreData, 1)
                      : item?.status &&
                        item?.status === "finished" &&
                        fetchWinIndicator(scoreData, 1)}
                    <ClickAwayListener
                      onClickAway={() =>
                        handleOutsideClick(item, item?.homeTeamId, index)
                      }
                    >
                      <Box className="blackbook-icon">
                        {handleOpenTooltip(
                          item,
                          item?.homeTeamId,
                          index,
                          "hometeam",
                          "score"
                        )}
                      </Box>
                    </ClickAwayListener>
                  </TableCell>

                  {fetchteamscore(scoreData, item?.homeTeamId, "hometeam")}

                  {showOdds && IsHomeCurrentBestAvailable ? (
                    <TableCell className="current-best">
                      {" "}
                      <Box className="odds-wrap current-best-odds-wrap">
                        <span>
                          <Box className="points">
                            {fetchCurrentBestOdds(
                              item,
                              "points",
                              MarketName?.toLowerCase()?.includes("total")
                                ? "over"
                                : "hometeam",
                              null,
                              true
                            )}
                          </Box>
                          <span>
                            {fetchCurrentBestOdds(
                              item,
                              "odds",
                              MarketName?.toLowerCase()?.includes("total")
                                ? "over"
                                : "hometeam",
                              null,
                              true
                            )}
                          </span>
                        </span>
                        {fetchCurrentBestsOddsIcon(
                          item,
                          "odds",
                          MarketName?.toLowerCase()?.includes("total")
                            ? "over"
                            : "hometeam"
                        )}
                      </Box>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  {sponsoredId?.length > 0 && showOdds ? (
                    fetchSponsoredOddsHome(item)
                  ) : (
                    <></>
                  )}
                </TableRow>
                <TableRow className="awayteam-row">
                  <TableCell className={showOdds ? "team-wrap" : "team-wrap"}>
                    <Box className="team-d-flex team-align-center">
                      <span className="team-img-wrap">
                        {fetchTeamlogo(item, "awayteam")}
                      </span>
                      <span className="team-name ">
                        {" "}
                        {item?.awayTeam?.name ? item?.awayTeam?.name : ""}
                      </span>
                    </Box>
                    {item?.SportId === 4 &&
                      scoreData?.[0]?.Epr === 1 &&
                      fetchIndicator(item?.awayTeamId, "awayteam")}
                    {(item?.SportId === 4 || item?.SportId === 8) &&
                    item?.ScoreBoard?.Epr === 2
                      ? fetchWinIndicator(scoreData, 2)
                      : item?.status &&
                        item?.status === "finished" &&
                        fetchWinIndicator(scoreData, 2)}
                    <ClickAwayListener
                      onClickAway={() =>
                        handleOutsideClick(item, item?.awayTeamId, index)
                      }
                    >
                      <Box className="blackbook-icon">
                        {handleOpenTooltip(
                          item,
                          item?.awayTeamId,
                          index,
                          "awayteam",
                          "score"
                        )}
                      </Box>
                    </ClickAwayListener>
                  </TableCell>

                  {fetchteamscore(scoreData, item?.awayTeamId, "awayteam")}

                  {showOdds && IsAwayCurrentBestAvailable ? (
                    <TableCell className="current-best">
                      <Box className="odds-wrap current-best-odds-wrap">
                        <span>
                          <Box className="points">
                            {fetchCurrentBestOdds(
                              item,
                              "points",
                              MarketName?.toLowerCase()?.includes("total")
                                ? "under"
                                : "awayteam",
                              null,
                              true
                            )}
                          </Box>
                          <span>
                            {fetchCurrentBestOdds(
                              item,
                              "odds",
                              MarketName?.toLowerCase()?.includes("total")
                                ? "under"
                                : "awayteam",
                              null,
                              true
                            )}
                          </span>
                        </span>
                        {fetchCurrentBestsOddsIcon(
                          item,
                          "odds",
                          MarketName?.toLowerCase()?.includes("total")
                            ? "under"
                            : "awayteam"
                        )}
                      </Box>
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  {sponsoredId?.length > 0 && showOdds ? (
                    fetchSponsoredOddsAway(item)
                  ) : (
                    <></>
                  )}
                </TableRow>
              </>
            </TableBody>
          </Table>
        </Box>
      ) : (
        <>
          <Table className="mobile-live-table">
            <TableRow>
              {fetchteamscore(scoreData, item?.homeTeamId, "hometeam", item)}
              {fetchteamscore(scoreData, item?.awayTeamId, "awayteam", item)}
            </TableRow>
          </Table>
        </>
      )}
    </>
  );
};

export default Score;
